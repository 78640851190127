import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { useTheme } from '@mui/material/styles';

export const Notes = () => {
  const { color } = useTheme();
  return (
    <>
      <IconBtn href={`${Sprite}#icon-bell`} size={24} />
    </>
  );
};
