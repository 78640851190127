import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { nanoid } from 'nanoid';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Logo } from '@root/components';
import { ButtonBase, CustomScroll, IconBtn, TextArea } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Drawer, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const id = nanoid();
const scrollId = nanoid();

export const AISupport = () => {
  const name = useSelector(userSelectors.getUserName);
  const [open, setOpen] = useState(false);
  const [typerOpen, setTyperOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation(['common'], { useSuspense: false });

  const [conversation, setConversation] = useState([
    {
      role: 'assistant',
      content: t('common:ai_support.greeting'),
    },
  ]);

  const { color } = useTheme();

  useEffect(() => {
    scrollTo(scrollId + (conversation.length - 1));
  }, [conversation]);

  const onChangeText = name => value => {
    setMessage(value);
  };

  const scrollTo = id => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const userMessage = message.trim();

    const allConversation = [
      ...conversation,
      {
        role: 'user',
        content: userMessage,
      },
    ];

    setConversation(allConversation);
    setMessage('');

    setTyperOpen(true);

    fetch(`https://ai.redentu.top/api/chat/3/` + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ conversation: allConversation }),
    })
      .then(response => response.json())
      .then(({ content }) => {
        if (content) {
          setConversation([
            ...allConversation,
            {
              role: 'assistant',
              content,
            },
          ]);
        } else {
          setConversation([
            ...allConversation,
            {
              role: 'assistant',
              content: t('common:ai_support.errorMessage'),
            },
          ]);
        }
        setTyperOpen(false);
      })
      .catch(error => {
        setConversation([
          ...allConversation,
          {
            role: 'assistant',
            content: t('common:ai_support.errorMessage'),
          },
          setTyperOpen(false),
        ]);
      });
  };

  return (
    <>
      <IconBtn
        href={`${Sprite}#icon-AI`}
        onClick={() => setOpen(true)}
        size={24}
        tooltipText={t('common:tooltip.ai_support')}
      />

      <Drawer
        sx={{
          width: 0,
          flexShrink: 0,
          height: 0,

          '& .MuiDrawer-paper': {
            position: 'inherit',
            width: '500px',
            boxSizing: 'border-box',
            border: `1px solid ${color.line}`,
            display: 'none',
          },
          ...(open && {
            width: '500px',
            height: '100%',
            position: 'fixed',
            top: 0,
            right: '0',
            zIndex: 9000,
            '& .MuiDrawer-paper': {
              display: 'block',
            },
          }),
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Wrapper>
          <Header color={color}>
            <IconBtn
              sx={{ position: 'absolute', top: 0, right: 0 }}
              size="24px"
              href={`${Sprite}#icon-cross`}
              onClick={() => setOpen(false)}
            />

            <Logo sx={{ height: '34px' }} />
            <Typography variant={'h1'} component={'p'} sx={{ mt: '5px' }}>
              {t('common:ai_support.title')}
            </Typography>
          </Header>
          <Answer color={color}>
            {' '}
            <CustomScroll vertical={true}>
              <List>
                {conversation.map((el, index) => (
                  <Item
                    color={color}
                    id={scrollId + index}
                    key={el.role + el.content + index}
                  >
                    <Typography variant={'bodyBoldSmall'} component={'p'}>
                      {el.role === 'assistant' ? 'Viatec Assistant' : name}:{' '}
                      <Typography variant={'bodySmall'}>
                        {el.content}
                      </Typography>
                    </Typography>
                  </Item>
                ))}
              </List>
            </CustomScroll>
          </Answer>

          <WrapperArea
            onSubmit={handleSubmit}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                handleSubmit(e);
              }
            }}
          >
            <Typer>
              {typerOpen && (
                <>
                  <Typography variant={'bodySmall'}>
                    {t('common:ai_support.typer')}
                  </Typography>
                  <span>
                    <span class="dot">.</span>
                    <span class="dot">.</span>
                    <span class="dot">.</span>
                  </span>
                </>
              )}
            </Typer>

            <TextArea
              sx={{
                padding: '5px',
                border: `1px solid ${color.line}`,
                width: '100%',
              }}
              value={message}
              onChange={onChangeText('description')}
              placeholder={t('common:ai_support.placeholder')}
              type="text"
            />

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ width: '100%', mt: '5px' }}
              disabled={!message.length > 0}
            >
              {t('common:buttons.sent')}
            </ButtonBase>
          </WrapperArea>
        </Wrapper>
      </Drawer>
    </>
  );
};

const Wrapper = styled.div``;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 0px;

  background: ${({ color }) => color.line};
`;

const Answer = styled.div`
  width: 100%;
  background: ${({ color }) => color.gray_20};
  padding: 5px 5px 0 5px;

  height: calc(100vh - 360px);
  overflow: hidden;
  margin-bottom: 5px;

  p + p {
    margin-top: 5px;
  }
`;

const WrapperArea = styled.form`
  padding: 5px;
`;

const List = styled.ul`
  padding-right: 5px;
`;

const Item = styled.li`
  display: block;
  padding: 8px 5px;
  margin-top: 2px;
  margin-bottom: 0px;
  background: ${({ color }) => color.line};
  white-space: pre-line;
  border-radius: 4px;
`;

const Typer = styled.div`
  height: 20px;
  font-size: 12px;

  .dot {
    display: inline-block;
    animation: typing 1s infinite;
    font-weight: bold;
  }

  .dot:nth-child(1) {
    animation-delay: 0.2s;
  }

  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes typing {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
