import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Logo } from '@root/components';
import { getNavMenu } from '@root/conf';
import { BootstrapTooltip } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';

import { useTheme } from '@mui/material/styles';

export const SideBar = () => {
  const userRole = useSelector(authSelectors.getRole);
  const { pathname } = useLocation();
  const { t } = useTranslation(['common']);
  const { lang } = useParams();
  const currentPath = lang ? pathname.split(`/${lang}`).join('') : pathname;
  const parentFolder = currentPath.split('/')[1];
  const currentLang = lang ? `/${lang}` : '';
  const { color } = useTheme();
  const handleClick = (e, isActive) => {
    if (isActive) {
      e.preventDefault();
    }
  };

  return useMemo(() => {
    return (
      <NavBox color={color}>
        <ul>
          <Item visible={true}>
            <WrapperLogo color={color}>
              <Logo isSmall />
            </WrapperLogo>
          </Item>
          {getNavMenu(t).map(({ path, icon, name, role }) => {
            const isActive =
              parentFolder === 'saved'
                ? path === `/catalog`
                : path === `/${parentFolder}`;
            return (
              <Item key={path} visible={role.includes(userRole)}>
                <BootstrapTooltip title={name} placement="right">
                  <Wrapper
                    color={color}
                    isActive={isActive} //spike because saved page is deferent component accordion to catalog page
                  >
                    <NavLink
                      to={currentLang + path}
                      onClick={e => handleClick(e, isActive)}
                    >
                      <Svg color={color} width="24" height="24">
                        <use href={`${Sprite}#${icon}`}></use>
                      </Svg>
                    </NavLink>
                  </Wrapper>
                </BootstrapTooltip>
              </Item>
            );
          })}
        </ul>
      </NavBox>
    );
  }, [color, parentFolder]);
};

const NavBox = styled.nav`
  width: 56px;
  min-height: 450px;
  height: 100%;

  background: ${prop => prop.color.menu};
`;

const Item = styled.li`
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const WrapperLogo = styled.div`
  padding: 18px 7px 20px 7px;
  border-bottom: 1px solid ${prop => prop.color.line_light};
`;

const Wrapper = styled.div`
  padding: 16px;
  stroke: ${prop => (prop.isActive ? prop.color.white : prop.color.gray_60)};
  background: ${prop =>
    prop.isActive &&
    `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),${prop.color.menu}`};

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      ${prop => prop.color.menu};
    stroke: ${prop => prop.color.white};
  }
`;

const Svg = styled.svg``;
