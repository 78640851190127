const getProduct = state => state.product.product;
const getMeta = state => state.product.meta;
const getCategoryName = state => state.product.categoryName;
const getProductById = state => state.product.productById;
const getFullPrice = state => state.product.fullPrice;
const getElementCounter = state => state.product.elementsCounter;
const getLoading = state => state.product.loading;
const getLoadingItemPage = state => state.product.loadingItemPage;
const getProductByEditId = state => state.product.productByEditIdData;
const getLoadingEditPage = state => state.product.loadingEditPage;

const productSelectors = {
  getProduct,
  getElementCounter,
  getMeta,
  getCategoryName,
  getProductById,
  getFullPrice,
  getLoading,
  getLoadingItemPage,
  getProductByEditId,
  getLoadingEditPage,
};

export default productSelectors;
