import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import userSelectors from '@redux/user/user-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const ExchangeRate = () => {
  const currencyRates = useSelector(userSelectors.getCurrencyRates);

  const { t } = useTranslation(['header'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <ExchangeBox>
      <Typography
        sx={{ color: color.gray }}
        variant={'bodySmall'}
        component={'p'}
      >
        {t('header:widget_rate.exchange_rate')}
      </Typography>

      <Wrapper>
        {currencyRates.map(currencyRate => (
          <Typography
            sx={{ display: 'flex', color: color.gray_60 }}
            variant={'bodySmall'}
            component={'p'}
          >
            {currencyRate.public_name}
            <Typography
              sx={{ color: color.gray, ml: '4px', display: 'block' }}
              variant={'bodyBoldSmall'}
            >
              {currencyRate.rate}
            </Typography>
          </Typography>
        ))}
      </Wrapper>
    </ExchangeBox>
  );
};

const ExchangeBox = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
`;
