export const ProfileConf = t => [
  {
    label: t('profile:conf.personal_info.title'),
    description: t('profile:conf.personal_info.description'),
    icon: 'icon-person',
    value: 'personal_info',
    path: 'personal-info',
  },
  {
    label: t('profile:conf.company_info.title'),
    description: t('profile:conf.company_info.description'),
    icon: 'icon-company',
    value: 'company_info',
    path: 'company-info',
  },
  // {
  //   label: t('profile:conf.list_of_employees.title'),
  //   description: t('profile:conf.list_of_employees.description'),
  //   icon: 'icon-people',
  //   value: 'list_of_employees',
  //   path: 'list-of-employees',
  // },
  // {
  //   label: t('profile:conf.notification_settings.title'),
  //   description: t('profile:conf.notification_settings.description'),
  //   icon: 'icon-notification',
  //   value: 'notification_settings',
  //   path: 'notification-settings',
  // },
  // {
  //   label: t('profile:conf.workflow_settings.title'),
  //   description: t('profile:conf.workflow_settings.description'),
  //   icon: 'icon-settings',
  //   value: 'workflow_settings',
  //   path: 'workflow-settings',
  // },
  // {
  //   label: t('profile:conf.social_media.title'),
  //   description: t('profile:conf.social_media.description'),
  //   icon: 'icon-social-media',
  //   value: 'social_media',
  //   path: 'social-media',
  // },
  {
    label: t('profile:conf.account_activity.title'),
    description: t('profile:conf.account_activity.description'),
    icon: 'icon-account-activity',
    value: 'account_activity',
    path: 'account-activity',
  },
  {
    label: t('profile:conf.security.title'),
    description: t('profile:conf.security.description'),
    icon: 'icon-security',
    value: 'security',
    path: 'security',
  },
];

export const AccountActivityTableConf = t => [
  {
    label: t(
      'profile:account_activity_page.account_activity_table_conf.browser',
    ),
    value: 'browser',
    style: { width: '40%' },
  },
  {
    label: 'IP',
    value: 'ip',
    style: { width: '40%' },
  },
  {
    label: t('profile:account_activity_page.account_activity_table_conf.time'),
    value: 'time',
    style: { width: '20%' },
  },
];
