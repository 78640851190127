import * as React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TableCell, Typography, useTheme } from '@mui/material';

export const Status = ({ style, status }) => {
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        p: 0,
        ...style,
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      <StatusCircle status={status} red={color.red} green={color.green} />
      <Typography variant={'bodySmall'} component={'p'}>
        {status === 'active'
          ? t('admin:active_status')
          : t('admin:inactive_status')}
      </Typography>
    </TableCell>
  );
};

const StatusCircle = styled.div`
  background: ${({ status, red, green }) =>
    status === 'active' ? green : red};
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;

Status.propTypes = {
  style: PropTypes.object,
  status: PropTypes.string,
};
