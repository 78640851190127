import { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AxiosService } from '@root/conf';
import { langConf } from '@root/conf';
import { useLang } from '@root/hooks';
import {
  ErrorBoundary,
  Guard,
  History,
  ModalWatcher,
  NotificationWatcher,
  PrivateRoute,
  PublicRoute,
} from '@root/middleware';
import { TemplateMain, TemplateSignUp } from '@root/template';
import { Loader } from '@root/ui';

import operation from '@redux/auth/auth-operation';
import authSelectors from '@redux/auth/auth-selectors';
import userSelectors from '@redux/user/user-selectors';

const Home = lazy(() => import('./page/Home' /* webpackChunkName: 'Home' */));

const Page403 = lazy(() =>
  import('./page/Errors/Page403' /* webpackChunkName: 'Page403' */),
);

const Page404 = lazy(() =>
  import('./page/Errors/Page404' /* webpackChunkName: 'Page404' */),
);

const Page500 = lazy(() =>
  import('./page/Errors/Page500' /* webpackChunkName: 'Page500' */),
);

const InDevelopment = lazy(() =>
  import('./page/Errors/InDevelopment' /* webpackChunkName: 'InDevelopment' */),
);

const OnMaintenance = lazy(() =>
  import('./page/Errors/OnMaintenance' /* webpackChunkName: 'OnMaintenance' */),
);

const Login = lazy(() =>
  import('./page/Login' /* webpackChunkName: 'Login' */),
);

const Registration = lazy(() =>
  import('./page/Sign_Up/Registration' /* webpackChunkName: 'Registration' */),
);

const RegistrationContinuationForm = lazy(() =>
  import(
    './page/Sign_Up/RegistrationContinuationForm' /* webpackChunkName: 'Registration' */
  ),
);

const SuccessfullyMessagePage = lazy(() =>
  import(
    './page/Sign_Up/SuccessfullyMessagePage' /* webpackChunkName: 'SuccessfullyMessagePage' */
  ),
);

const Redirect = lazy(() =>
  import('./page/Redirect' /* webpackChunkName: 'Redirect' */),
);

const Catalog = lazy(() =>
  import('./page/Catalog' /* webpackChunkName: 'Catalog' */),
);

const ProductDetails = lazy(() =>
  import('./page/ProductDetails' /* webpackChunkName: 'ProductDetails' */),
);

const FavoriteTable = lazy(() =>
  import('./page/FavoriteTable' /* webpackChunkName: 'FavoriteTable' */),
);

const AdminPanel = lazy(() =>
  import('./page/AdminPanel' /* webpackChunkName: 'AdminPanel' */),
);

const CartPage = lazy(() =>
  import('./page/CartPage' /* webpackChunkName: 'CartPage' */),
);

const SearchPage = lazy(() =>
  import('./page/SearchPage' /* webpackChunkName: 'SearchPage' */),
);

const PlacingAnOrder = lazy(() =>
  import('./page/PlacingAnOrder' /* webpackChunkName: 'PlacingAnOrder' */),
);

const HistoryOrder = lazy(() =>
  import('./page/HistoryOrder' /* webpackChunkName: 'HistoryOrder' */),
);

const Documents = lazy(() =>
  import('./page/Documents' /* webpackChunkName: 'Documents' */),
);

const Logistics = lazy(() =>
  import('./page/Logistics' /* webpackChunkName: 'Logistics' */),
);

const Settings = lazy(() =>
  import('./page/Settings' /* webpackChunkName: 'Settings' */),
);

const Profile = lazy(() =>
  import('./page/Profile' /* webpackChunkName: 'Profile' */),
);
const ComparisonsPage = lazy(() =>
  import('./page/ComparisonsPage' /* webpackChunkName: 'ComparisonsPage' */),
);

export function App() {
  const isRefreshing = useSelector(authSelectors.getIsRefreshing);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { initLang, localLang, checkLanguageFromURL } = useLang();

  useEffect(() => {
    const urlLang = checkLanguageFromURL(pathname);
    const langVerify = langConf.includes(urlLang);
    const lang = langVerify ? urlLang : 'cs';

    AxiosService.setLang(lang);
    dispatch(operation.getUser({ signInToken: null }));

    if (lang !== localLang) {
      initLang(lang);
    }
  }, []);

  return (
    <>
      <div className="App" data-reactroot>
        <ToastContainer />

        {!isRefreshing ? (
          <ErrorBoundary fallback={<Page500 withLogo />}>
            <Guard>
              <History>
                <Suspense fallback={<Loader />}>
                  <Routes>
                    <Route path="/:lang?/error-500" element={<Page500 />} />
                    <Route
                      path="/:lang?/on-maintenance"
                      element={<OnMaintenance />}
                    />
                    <Route
                      path="/:lang?/in-development"
                      element={<InDevelopment />}
                    />
                    <Route path="/:lang?/error-403" element={<Page403 />} />
                    <Route path="/:lang?/error-404" element={<Page404 />} />
                    <Route
                      path="/:lang?/"
                      element={<PrivateRoute element={<TemplateMain />} />}
                    >
                      <Route
                        index
                        element={<PrivateRoute element={<Home />} />}
                      />
                      <Route
                        path="catalog/:category?/:categoryChildren?/?"
                        element={<PrivateRoute element={<Catalog />} />}
                      />
                      <Route
                        path="catalog/product/:category?/:categoryChildren?/:productId"
                        element={<PrivateRoute element={<ProductDetails />} />}
                      />
                      <Route
                        path="saved/:category?/:categoryChildren?/?"
                        element={<PrivateRoute element={<FavoriteTable />} />}
                      />
                      <Route
                        path="admin-panel/:adminTab?"
                        element={<PrivateRoute element={<AdminPanel />} />}
                      />
                      <Route
                        path="catalog/cart"
                        element={<PrivateRoute element={<CartPage />} />}
                      />
                      <Route
                        path="catalog/search-results/:category?/:searchString?/:searchType?"
                        element={<PrivateRoute element={<SearchPage />} />}
                      />
                      <Route
                        path="catalog/placing-an-order"
                        element={<PrivateRoute element={<PlacingAnOrder />} />}
                      />

                      <Route
                        path="my-orders"
                        element={<PrivateRoute element={<HistoryOrder />} />}
                      />

                      <Route
                        path="docs"
                        element={<PrivateRoute element={<Documents />} />}
                      />

                      <Route
                        path="logistics"
                        element={<PrivateRoute element={<Logistics />} />}
                      />

                      <Route
                        path="settings"
                        element={<PrivateRoute element={<Settings />} />}
                      />

                      <Route
                        path="comparisons"
                        element={<PrivateRoute element={<ComparisonsPage />} />}
                      />

                      <Route
                        path="profile/:profileTab?"
                        element={<PrivateRoute element={<Profile />} />}
                      />
                    </Route>

                    <Route path="/:lang?/" element={<TemplateSignUp />}>
                      <Route
                        path="sign-up"
                        element={<PublicRoute element={<Registration />} />}
                      />
                      <Route
                        path="sign-up-continuation-form/:step"
                        element={
                          <PrivateRoute
                            element={<RegistrationContinuationForm />}
                          />
                        }
                      />
                      <Route
                        path="successfully-sign-up"
                        element={
                          <PrivateRoute element={<SuccessfullyMessagePage />} />
                        }
                      />
                    </Route>
                    <Route
                      path="/:lang?/login"
                      element={<PublicRoute element={<Login />} />}
                    />
                    <Route path="/:lang?/*" element={<Redirect />} />
                  </Routes>
                </Suspense>
              </History>
            </Guard>
          </ErrorBoundary>
        ) : (
          <Loader />
        )}
      </div>
      <ModalWatcher />
      <NotificationWatcher />
    </>
  );
}
