import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn, OutlineButtonBase, TextInput } from '@root/ui';

import { Divider } from '@mui/material';
import { Typography, useTheme } from '@mui/material';

export const MainCategoryElement = ({
  index,
  item,
  children,
  handleCategoryVisible,
  handleSetCategoryName,
  handleDeleteCategory,
  subCategoryName,
  handleNewSubCategoryName,
  handleAddNewSubCategory,
  parent,
  customDragStyles,
}) => {
  const [open, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(null);
  const [openCreateSubCategory, setOpenCreateSubCategory] = useState(null);

  const { t } = useTranslation(['catalog'], { useSuspense: false });
  const { color } = useTheme();

  useEffect(() => {
    if (open) {
      setCategoryName(item.name);
    }
  }, [open]);

  const handleCategoryName = () => value => {
    setCategoryName(value);
  };

  const defaultCategory = slug => {
    switch (slug) {
      case 'prodej':
        return true;
      case 'ulozene':
        return true;
      case 'novinky':
        return true;
      default:
        return false;
    }
  };

  const handleConfirmCreateSubCategory = (categoryName, index) => {
    const subCategory = {
      id: nanoid(),
      active: true,
      can_delete: true,
      children: [],
      name: categoryName,
      is_new: true,
    };
    setOpenCreateSubCategory(false);
    handleNewSubCategoryName('');

    handleAddNewSubCategory(subCategory, index);
  };

  const handleConfirmChange = (categoryName, index) => {
    handleSetCategoryName()(categoryName, index);
    setOpen(false);
  };
  const handleAbortValue = () => {
    setOpen(false);
    setCategoryName(item.name);
  };
  return (
    <>
      {open ? (
        <TextInput
          id="input-with-icon-adornment"
          sx={{
            width: '349px',
            height: '32px',
            input: { pl: '10px' },
          }}
          max={100}
          styleWrapper={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '8px 0',
          }}
          placeholder={t(
            'catalog:modals.settings_category_modal.fields.category_name',
          )}
          endInputIcons={
            <>
              <IconBtn
                sx={{ marginLeft: '10px' }}
                href={`${Sprite}#icon-abort`}
                onClick={handleAbortValue}
                size={16}
              />
              <IconBtn
                sx={{ marginLeft: '15px' }}
                href={`${Sprite}#icon-confirm`}
                onClick={() => handleConfirmChange(categoryName, index)}
                size={16}
              />
            </>
          }
          onChange={handleCategoryName()}
          value={categoryName}
        />
      ) : (
        <SectionBody color={color.white}>
          <CategoryWrapper color={color.white} customStyles={customDragStyles}>
            <IconBtn href={`${Sprite}#icon-drag-and-drop`} size={16} />
            {}
            <Typography
              variant={parent ? 'bodyBoldMedium' : 'bodySmall'}
              color={color.gray_80}
              sx={{
                marginLeft: '8px',
                '&:first-letter': {
                  textTransform: 'capitalize',
                },
              }}
            >
              {categoryName ? categoryName : item.name}
            </Typography>
            <IconBtn
              sx={{ margin: '0 16px 0 auto' }}
              href={
                item.active ? `${Sprite}#icon-eye` : `${Sprite}#icon-eye-show`
              }
              saved={item.active}
              onClick={handleCategoryVisible}
              size={16}
            />
            <IconBtn
              sx={{
                marginRight: !item.can_delete ? '32px' : '',
                visibility: !item.can_delete ? 'hidden' : '',
              }}
              href={`${Sprite}#icon-pan`}
              onClick={() => setOpen(true)}
              size={16}
            />
            {item.can_delete && (
              <IconBtn
                sx={{ marginLeft: '16px' }}
                href={`${Sprite}#icon-delete`}
                onClick={() => handleDeleteCategory()(index, item.id)}
                size={16}
              />
            )}
          </CategoryWrapper>
          {children}
          {parent && !defaultCategory(item.slug) && !openCreateSubCategory && (
            <OutlineButtonBase
              onClick={() => setOpenCreateSubCategory(true)}
              sx={{
                width: '398px',
                height: '36px',
                margin: '5px  20px 16px 20px',
              }}
            >
              <Svg
                color={color.gray_60}
                width={16}
                height={16}
                rotateSvg={'180deg'}
              >
                <use href={`${Sprite}#icon-plus`}></use>
              </Svg>
              {t(
                'catalog:modals.settings_category_modal.buttons.add_subcategory',
              )}
            </OutlineButtonBase>
          )}

          {parent && openCreateSubCategory && (
            <TextInput
              id="input-with-icon-adornment"
              sx={{
                width: '349px',
                height: '32px',
                input: { pl: '10px' },
              }}
              max={100}
              styleWrapper={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '8px 0',
              }}
              placeholder={t(
                'catalog:modals.settings_category_modal.fields.category_name',
              )}
              endInputIcons={
                <>
                  <IconBtn
                    sx={{ marginLeft: '10px' }}
                    href={`${Sprite}#icon-abort`}
                    onClick={() => setOpenCreateSubCategory(false)}
                    size={16}
                  />
                  <IconBtn
                    sx={{ marginLeft: '15px' }}
                    href={`${Sprite}#icon-confirm`}
                    onClick={() =>
                      handleConfirmCreateSubCategory(subCategoryName, index)
                    }
                    size={16}
                    disabled={!subCategoryName.length}
                  />
                </>
              }
              onChange={handleNewSubCategoryName()}
              value={subCategoryName}
            />
          )}
        </SectionBody>
      )}
      {parent && <Divider />}
    </>
  );
};

const Svg = styled.svg`
  stroke: ${props => props.color};
  margin-right: 4px;
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.color};
`;

const CategoryWrapper = styled.div`
  display: flex;
  height: 35px;
  padding: 8px 20px 8px 20px;
  flex-direction: row;
  background: ${props => props.color};
  align-items: center;
  ${prop => prop.customStyles};
`;

MainCategoryElement.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.exact({
    active: PropTypes.bool,
    can_delete: PropTypes.bool,
    children: PropTypes.array,
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  children: PropTypes.node,
  handleCategoryVisible: PropTypes.func.isRequired,
  handleSetCategoryName: PropTypes.func.isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
  subCategoryName: PropTypes.string,
  handleNewSubCategoryName: PropTypes.func.isRequired,
  handleAddNewSubCategory: PropTypes.func.isRequired,
  parent: PropTypes.bool.isRequired,
  customDragStyles: PropTypes.object,
};
