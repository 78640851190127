import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { AccountActivityTableConf } from '@root/conf';
import { CustomScroll } from '@root/ui';

import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const AccountActivity = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['profile'], { useSuspense: false });
  const dispatch = useDispatch();

  const userLogins = useSelector(userSelectors.getUserLogins);

  document.title = t('profile:conf.account_activity.title');

  useEffect(() => {
    dispatch(userOperation.getUserLogins({}));
  }, []);

  return (
    <AccountActivityWrapper>
      <TableContainer
        sx={{
          border: `1px solid ${color.line}`,
          borderRadius: '4px',
        }}
      >
        <Table sx={{ display: 'table !important' }}>
          <TableHead>
            <TableRow
              sx={{
                display: 'flex',
                p: '12px 21px',
                borderBottom: `1px solid ${color.line}`,
              }}
            >
              {AccountActivityTableConf(t).map(el => (
                <TableCell
                  key={el.label}
                  sx={{
                    borderBottom: 'none',
                    display: 'flex',
                    ...el.style,
                    p: 0,
                    flexDirection: 'row',
                  }}
                >
                  <Typography variant="bodyBoldSmall" color={color.gray_60}>
                    {el.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ height: 'calc(100vh - 365px)' }}>
            <CustomScroll vertical>
              {userLogins &&
                userLogins.map(row => (
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      p: '12px 21px',
                      borderBottom: `1px solid ${color.line}`,
                      userSelect: 'auto',
                      th: {
                        borderBottom: 'none',
                      },
                      '&:last-child, &:last-child th': { borderBottom: 0 },
                    }}
                  >
                    {AccountActivityTableConf(t).map(el => {
                      switch (el?.value) {
                        case 'browser':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ padding: 0, ...el.style }}
                            >
                              <Typography
                                color={color.gray}
                                variant="bodySmall"
                              >
                                {row.browser} {t('profile:on')}{' '}
                                <Typography
                                  color={color.gray}
                                  variant="bodySmall"
                                  sx={{ textTransform: 'capitalize' }}
                                >
                                  {row.os}
                                </Typography>
                              </Typography>
                            </TableCell>
                          );
                        case 'ip':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ padding: 0, ...el.style }}
                            >
                              <Typography
                                color={color.gray_60}
                                variant="bodySmall"
                              >
                                {row.ip}
                              </Typography>
                            </TableCell>
                          );
                        case 'time':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                padding: 0,
                                ...el.style,
                                textTransform: 'capitalize',
                              }}
                            >
                              <Typography
                                color={color.gray_60}
                                variant="bodySmall"
                              >
                                {row.date}
                              </Typography>
                            </TableCell>
                          );
                      }
                    })}
                  </TableRow>
                ))}
            </CustomScroll>
          </TableBody>
        </Table>
      </TableContainer>
    </AccountActivityWrapper>
  );
};

const AccountActivityWrapper = styled.div``;
