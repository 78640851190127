export const catalogConf = [
  {
    label: 'Продукт',
    value: 'product',
    type: 'img',
    style: { maxWidth: '76px', width: '100%' },
  },
  {
    label: 'Назва',
    value: 'name',
    type: 'name',
    sort: true,
    style: { marginLeft: '15px', maxWidth: '423px', width: '100%' },
  },
  {
    label: 'Ціна',
    value: 'cost',
    type: 'price',
    sort: true,
    style: { marginLeft: 'auto', maxWidth: '110px', width: '100%' },
  }, // change
  {
    label: 'Наявність',
    value: 'availability',
    type: 'stock',
    sort: true,
    style: { marginLeft: '10px', maxWidth: '100px', width: '100%' },
  }, // change
  {
    label: '',
    value: 'action',
    type: 'action',
    style: { maxWidth: '158px', width: '100%' },
  }, // change
];

export const CardDetailsConf = t => [
  {
    slug: 'about',
    name: t('catalog:product_details.tabs_name.about'),
  },
  {
    slug: 'related_products',
    name: t('catalog:product_details.tabs_name.related_products'),
  },
  {
    slug: 'analogues',
    name: t('catalog:product_details.tabs_name.analogues'),
  },
  // {
  //   slug: 'discounted_products',
  //   name: 'Уцінені товари',
  // },
  // {
  //   slug: 'buy_together',
  //   name: 'Купують разом',
  // },
];

export const orderConf = t => [
  {
    label: t('order_history:order_history_conf.title'),
    value: 'product',
    type: 'img',
    style: { maxWidth: '76px', width: '100%' },
  },
  {
    label: t('order_history:order_history_conf.name'),
    value: 'name',
    type: 'name',
    style: { ml: '15px', maxWidth: '423px', width: '100%' },
  },
  {
    label: t('order_history:order_history_conf.quantity'),
    value: 'stock',
    type: 'stock',
    style: {
      ml: 'auto',
      maxWidth: '60px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }, // change
  {
    label: t('order_history:order_history_conf.price'),
    value: 'price',
    type: 'price',
    style: {
      ml: '28px',
      maxWidth: '100px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }, // change
  {
    label: t('order_history:order_history_conf.sum'),
    value: 'amount',
    type: 'amount',
    style: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flex: '0 0 150px',
    },
  }, // change
  {
    label: '',
    value: 'action',
    type: 'action',
    style: { ml: '50px', maxWidth: '50px', width: '100%' },
  }, // change
];
