import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { Camera } from '@root/assets/image';

import { useTheme } from '@mui/material';

import { SliderItem } from './SliderItem';

export const Slider = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['login'], { useSuspense: false });
  const TEST = [
    {
      img: Camera,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
    },
    {
      img: Camera,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
    },
    {
      img: Camera,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
    },
    {
      img: Camera,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
    },
    {
      img: Camera,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
    },
  ];

  return (
    <SwiperWrapper>
      <Swiper
        pagination={true}
        style={{
          '--swiper-pagination-color': color.white,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={600}
      >
        {TEST.map(({ img, title, text, slogan }, i) => (
          <SwiperSlide style={{ width: 'auto' }}>
            <SliderItem
              key={i}
              img={img}
              title={title}
              text={text}
              slogan={slogan}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};

const SwiperWrapper = styled.div`
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    opacity: 1;
    margin: 0;
  }
  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 16px;
  }
  .swiper-pagination-bullet-active {
    background: white;
  }

  .swiper-pagination {
    margin-bottom: 50px;
  }
`;
