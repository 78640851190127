import React from 'react';

import PropTypes from 'prop-types';

import { StatusLabel } from '@root/components';
import { ColorlibStepIconRoot } from '@root/styles';
import { StepperBase } from '@root/ui';

import { Box, Typography, useTheme } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    width: '100%',
    height: '1px',
    borderColor: theme.color.line,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={theme => ({
        color: theme.color.gray_60,
        width: 6,
        height: 6,
      })}
    />
  );
}

export const StepperStatusTyne = ({ steps }) => {
  const stepsWhitOutCancel = steps.filter(el => el.code !== 'canceled');
  const active = stepsWhitOutCancel.find(el => el.status === 'current');
  const activeStep = stepsWhitOutCancel.indexOf(active);

  return (
    <Box
      sx={{
        maxWidth: 400,
        minHeight: '30px',
      }}
    >
      <StatusLabel active={active ? active : steps[0]} />

      {active && active.code !== 'done' && (
        <StepperBase
          conf={stepsWhitOutCancel}
          step={activeStep}
          defection={'horizontal'}
          text={() => <></>}
          sx={{ p: '2px', minHeight: '10px' }}
          sxLabel={() => {
            return {
              '& .MuiStepLabel-iconContainer': {
                padding: 0,
              },
            };
          }}
          connector={<QontoConnector />}
          iconComponent={ColorlibStepIcon}
        />
      )}
    </Box>
  );
};

StepperStatusTyne.propTypes = {
  steps: PropTypes.array.isRequired,
};
