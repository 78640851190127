import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PricesTooltip } from '@root/components';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const Cost = ({ isHiddenTooltip, onlyCRN, data }) => {
  const { personal_price, price, prices } = data;
  const currencyCode = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  return (
    <Box>
      {personal_price[choosedCurrency] > 0 || price[choosedCurrency] > 0 ? (
        <PricesTooltip prices={prices} isHiddenTooltip={isHiddenTooltip}>
          <TooltipInner>
            {personal_price[choosedCurrency] > 0 && (
              <Typography
                sx={{
                  color: color.gray,
                  display: 'flex',
                  alignItems: 'baseline',
                }}
                variant={'bodyBoldLarge'}
                align={'center'}
                component={'p'}
              >
                {personal_price[choosedCurrency]}
                <Typography
                  sx={{ color: color.gray, ml: '4px', display: 'block' }}
                  variant={'bodyBoldSmall'}
                  align={'center'}
                >
                  {onlyCRN ? t('common:currency') : currencyCode}
                </Typography>
              </Typography>
            )}
            {price[choosedCurrency] > 0 && (
              <Typography
                sx={{ color: color.gray_60, display: 'flex' }}
                variant={'bodyExSmall'}
                align={'center'}
                component={'p'}
              >
                {price[choosedCurrency]}
                <Typography
                  sx={{ color: color.gray_60, ml: '2px', display: 'block' }}
                  variant={'bodyExSmall'}
                  align={'center'}
                >
                  {onlyCRN ? t('common:currency') : currencyCode}
                </Typography>
              </Typography>
            )}
          </TooltipInner>
        </PricesTooltip>
      ) : (
        <Typography
          sx={{
            color: color.gray,
            display: 'flex',
            alignItems: 'baseline',
            width: '90%',
            textAlign: 'left',
          }}
          variant={'bodyBoldMedium'}
          align={'center'}
          component={'p'}
        >
          {t('common:check_price_for_details')}
        </Typography>
      )}
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TooltipInner = styled.div``;

Cost.propTypes = {
  isHiddenTooltip: PropTypes.bool,
  onlyUAH: PropTypes.bool,
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_cron: PropTypes.bool,
      price_cron_no_dph: PropTypes.bool,
      price_no_dph: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    short_description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_cron: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_cron_no_dph: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_no_dph: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
};
