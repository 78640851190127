import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Arow_Serch } from '@root/assets/svg';
import { Sprite } from '@root/assets/svg';
import { IconBtn, TextInput } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const SearchInput = ({
  focus,
  onBlur,
  onChange,
  searchValue,
  placeholder,
  sx,
  sxLabel = {},
}) => {
  const { color } = useTheme();

  return (
    <Label style={sxLabel}>
      <TextInput
        id="input-with-icon-adornment"
        sx={{
          width: '100%',
          height: '36px',
          input: { pl: '10px' },
          fontSize: '0.875rem',
          ...sx,
        }}
        placeholder={placeholder}
        startAdornment={
          <Svg width={16} height={16}>
            <use href={`${Sprite}#icon-loop`}></use>
          </Svg>
        }
        onBlur={onBlur}
        onChange={onChange('searchValue')}
        value={searchValue}
      />
      {focus && (
        <>
          <Placeholder>
            <img src={Arow_Serch} alt="Icon Placeholder" />
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.line,
                ml: '4px',
              }}
              component={'p'}
            >
              Натисніть enter для пошуку
            </Typography>
          </Placeholder>
        </>
      )}
    </Label>
  );
};

const Svg = styled.svg``;

const Label = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-right: 16px;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  right: 25px;
  transform: translate(0, 50%);

  display: flex;
`;
SearchInput.propTypes = {
  focus: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
