import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  Empty,
  ModalLiqPay,
  StatusLabel,
  StepperStatusWithIcon,
} from '@root/components';
import { correctPhone, handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  ButtonBase,
  CustomScroll,
  Link,
  MessageError,
  ProgressLoader,
} from '@root/ui';

import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import orderOperation from '@redux/order/order-operation';
import orderSelectors from '@redux/order/order-selectors';
import savedOrdersOperation from '@redux/saved-orders/saved-orders-operation';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { TableHistoryOrder } from './TableHistoryOrder';

export const HistoryDetails = ({ repeatOrderBtn = true, isModal = false }) => {
  const [openLiqPay, setOpenLiqPay] = useState(false);
  const { color } = useTheme();
  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const orderHistoryById = useSelector(orderSelectors.getOrderHistoryById);
  const loading = useSelector(orderSelectors.getLoadingHistoryDetails);
  const onlineForm = useSelector(orderSelectors.getOnlineForm);
  const { t } = useTranslation(['order_history', 'common'], {
    useSuspense: false,
  });

  const callNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('order_history:add_to_cart_message'),
        action: (
          <>
            <ButtonBase
              onClick={() => navigation('/catalog/cart')}
              sx={{ height: '25px' }}
            >
              {t('common:buttons.view')}
            </ButtonBase>
          </>
        ),
      }),
    );
  };

  const handelActionBtn = () => {
    if (onlineForm) {
      setOpenLiqPay(true);
    } else if (checkIsOrderSaved()) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            savedOrdersOperation.addSavedOrderToCart({
              id: orderHistoryById?.id,
              onResolve,
              onReject,
            }),
          ),
        () => callNotification(),
      );
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            orderOperation.cloneHistoryOrderById({
              id: orderHistoryById?.id,
              onResolve,
              onReject,
            }),
          ),
        data => callNotification(),
        error => {},
      );
    }
  };

  const checkIsOrderSaved = () => orderHistoryById.model === 'saved_cart';

  const titleOrder = () =>
    checkIsOrderSaved()
      ? `${orderHistoryById.title} ( ${orderHistoryById?.quantity} )`
      : `${t('order_history:order')} № ${orderHistoryById?.title} ( ${
          orderHistoryById?.quantity
        } )`;

  const AboutOrderInMemo = () => {
    switch (orderHistoryById.model) {
      case 'saved_cart':
        return '';
      default:
        const { statuses } = orderHistoryById;
        const active = statuses.find(el => el.status === 'current');
        return (
          <Wrapper
            color={color.line}
            padding={'31px 7px 19px 24px'}
            switchDirection={true}
          >
            <GridWrapper>
              <GridItem>
                <Typography
                  variant={'bodyBoldSmall'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.gray_60,
                  }}
                >
                  {t('order_history:payment')}
                </Typography>
              </GridItem>

              <GridItem>
                <Typography
                  variant={'bodyBoldSmall'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.gray_60,
                  }}
                >
                  {t('order_history:delivery_address')}
                </Typography>
              </GridItem>

              <GridItem>
                {(orderHistoryById?.dpd_number ||
                  (!repeatOrderBtn && orderHistoryById?.dpd_error) ||
                  orderHistoryById?.proforma ||
                  orderHistoryById?.faktura) && (
                  <Typography
                    variant={'bodyBoldSmall'}
                    component={'p'}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.gray_60,
                    }}
                  >
                    {t('order_history:docs')}
                  </Typography>
                )}
              </GridItem>

              <GridItem>
                <Typography
                  variant={'h2'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: '4px',
                    color: color.gray,
                  }}
                >
                  {orderHistoryById?.amount}{' '}
                  {orderHistoryById?.currency_full
                    ? orderHistoryById?.currency_full
                    : orderHistoryById?.currency}
                </Typography>
                <Typography
                  variant={'bodyBoldMedium'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.gray,
                    mb: '4px',
                  }}
                >
                  {orderHistoryById?.payment_title}
                </Typography>
                <StatusLabel
                  active={active}
                  statuses={statuses}
                  id={orderHistoryById?.id}
                  onlyAdmin={repeatOrderBtn}
                />
              </GridItem>

              <GridItem>
                <Typography
                  variant={'bodyBoldMedium'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.gray,
                    maxWidth: '268px',
                  }}
                >
                  {orderHistoryById?.last_name} {orderHistoryById?.first_name}
                </Typography>

                <Typography
                  variant={'bodyMedium'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.gray_80,
                  }}
                >
                  {orderHistoryById?.email}
                </Typography>

                <Typography
                  variant={'bodyMedium'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.gray_80,
                  }}
                >
                  {correctPhone(orderHistoryById?.phone)}
                </Typography>
              </GridItem>

              <GridItem>
                {orderHistoryById?.proforma && (
                  <Typography
                    variant={'bodyBoldMedium'}
                    component={'p'}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.gray,
                      mb: '4px',
                    }}
                  >
                    <Link
                      href={orderHistoryById?.proforma}
                      target="_blank"
                      download="Proforma"
                    >
                      <Svg>
                        <use href={`${Sprite}#icon-link`} />
                      </Svg>
                      Proforma
                    </Link>
                  </Typography>
                )}
                {orderHistoryById?.faktura && (
                  <Typography
                    variant={'bodyBoldMedium'}
                    component={'p'}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.gray,
                      mb: '10px',
                    }}
                  >
                    <Link
                      href={orderHistoryById?.faktura}
                      target="_blank"
                      download="Faktura"
                    >
                      <Svg>
                        <use href={`${Sprite}#icon-link`} />
                      </Svg>
                      Factura
                    </Link>
                  </Typography>
                )}
              </GridItem>

              <GridItem />

              <GridItem>
                <Typography
                  variant={'bodyBoldMedium'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.gray,
                  }}
                >
                  {orderHistoryById?.delivery_title}
                </Typography>

                <Typography
                  variant={'bodyMedium'}
                  component={'p'}
                  sx={{
                    color: color.gray_80,
                    height: '40px',
                    maxWidth: '100%',
                  }}
                >
                  <EllipsisText title={orderHistoryById?.address}>
                    {orderHistoryById?.address}
                  </EllipsisText>
                </Typography>
              </GridItem>

              <GridItem>
                {orderHistoryById?.delivery_type !== 'Self_pickup' && (
                  <>
                    {repeatOrderBtn && orderHistoryById?.dpd_error ? null : (
                      <Typography
                        variant={'bodyBoldMedium'}
                        component={'p'}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: color.gray,
                        }}
                      >
                        {t('order_history:dpd_number')}
                      </Typography>
                    )}

                    {!repeatOrderBtn && orderHistoryById?.dpd_error && (
                      <MessageError errorText={orderHistoryById?.dpd_error} />
                    )}
                    {orderHistoryById?.dpd_number && (
                      <Typography
                        variant={'bodyMedium'}
                        component={'p'}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: color.gray_80,
                          mb: '4px',
                        }}
                      >
                        {orderHistoryById?.dpd_number}
                      </Typography>
                    )}
                  </>
                )}
              </GridItem>
            </GridWrapper>
            <StepperStatusWithIcon steps={orderHistoryById?.statuses} />
          </Wrapper>
        );
    }
  };

  return (
    <OrderHistory
      colors={{ line: color.line, white: color.white }}
      width={isModal ? '100%' : '70%'}
    >
      {Object.keys(orderHistoryById).length > 0 ? (
        <>
          <Wrapper color={color.line} padding={'17px 9px 17px 20px'}>
            <Typography
              variant={'h2'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '4px',
                color: color.gray,
              }}
            >
              {titleOrder()}
            </Typography>
            {repeatOrderBtn && (
              <ButtonBase
                onClick={handelActionBtn}
                sx={{
                  height: '38px',
                }}
              >
                {checkIsOrderSaved()
                  ? t('order_history:buttons.add_to_cart')
                  : onlineForm
                  ? t('order_history:buttons.pay_the_order')
                  : t('order_history:buttons.repeat_the_order')}
              </ButtonBase>
            )}
          </Wrapper>
          {orderHistoryById.model !== 'saved_cart' && (
            <Wrapper color={color.line} padding={'12px 20px'}>
              <Typography
                variant={'bodyBoldSmall'}
                component={'p'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: color.gray_60,
                }}
              >
                {t('order_history:order_info')}
              </Typography>
              <Status />
            </Wrapper>
          )}

          <Inner>
            <CustomScroll vertical={true}>
              <AboutOrderInMemo />
              <TableHistoryOrder
                data={orderHistoryById?.details}
                currency={
                  orderHistoryById?.currency_full
                    ? orderHistoryById?.currency_full
                    : orderHistoryById?.currency
                }
                checkIsOrderSaved={checkIsOrderSaved}
              />
            </CustomScroll>
          </Inner>
        </>
      ) : (
        !loading && (
          <Empty
            type={'EmptyBlank'}
            text={t('order_history:empty_order_history')}
          />
        )
      )}
      {loading && <ProgressLoader />}
      {openLiqPay && <ModalLiqPay onlineForm={onlineForm} />}
    </OrderHistory>
  );
};

const OrderHistory = styled.div`
  width: ${({ width }) => width};
  position: relative;
  border: 1px solid ${props => props.colors.line};
  background: ${props => props.colors.white};
  border-radius: 4px;
  //height: calc(100vh - 180px);
  min-width: 884px;
  min-height: 300px;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ switchDirection }) =>
    switchDirection ? 'column' : 'row'};
  justify-content: space-between;

  padding: ${({ padding }) => padding};
  border-bottom: 1px solid ${props => props.color};
`;

const Inner = styled.div`
  max-height: calc(100vh - 300px);
`;

const DeliveryWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 28px;
`;

const PayInfo = styled.div`
  width: 165px;
  flex-shrink: 0;
`;

const DeliveryInfo = styled.div`
  margin-left: 26px;
  margin-right: 60px;
  min-width: 268px;
`;

const Maps = styled.div`
  width: 311px;
  height: 100px;
  flex-shrink: 0;
`;

const Status = styled.div`
  width: 71px;
  height: 16px;
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Svg = styled.svg`
  width: 17px;
  height: 17px;
  margin-right: 3px;
`;

const InfoContainer = styled.div`
  min-height: 60px;
  margin-bottom: 10px;
`;

const GridItem = styled.div``;
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.7fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 10px 30px;
`;

const DocsInfo = styled.div``;

HistoryDetails.className = OrderHistory;

HistoryDetails.propTypes = {
  repeatOrderBtn: PropTypes.bool,
  isModal: PropTypes.bool,
};
