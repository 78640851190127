import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RadioCustom } from '@root/ui';

import { FormControlLabel, Typography, useTheme } from '@mui/material';

export const RadioItem = ({ data, sx, conf }) => {
  const { color } = useTheme();
  const { key, title, subTitle, icon } = data;

  const { t } = useTranslation(['common'], { useSuspense: false });

  return (
    <FormControlLabel
      value={key}
      control={<RadioCustom sx={{ pt: 0, ...conf?.radioStyle }} />}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        py: '14px',
        ...conf?.labelStyle,
        ...sx,
      }}
      label={
        !conf?.isCustomRadioBtn ? (
          <Wrapper>
            <Typography
              variant={'bodyBoldMedium'}
              sx={{ color: color.gray_80 }}
              component={'p'}
            >
              {title}
              {key === 'Self_pickup' && (
                <Typography
                  variant={'bodyBoldExSmall'}
                  sx={{
                    background: color.green,
                    borderRadius: '2px',
                    padding: ' 2px 4px',
                    marginLeft: '4px',
                    color: 'white',
                  }}
                >
                  {t('common:free')}
                </Typography>
              )}
            </Typography>
            <Typography
              variant={'bodySmall'}
              sx={{ color: color.gray_60 }}
              component={'p'}
            >
              {subTitle}
            </Typography>
          </Wrapper>
        ) : (
          <CustomWrapper color={color.line_light}>
            <Img src={icon} styl={conf.imgStyle} />
            <Typography
              variant={'bodySmall'}
              sx={{ color: color.gray_80 }}
              component={'p'}
            >
              {title}
            </Typography>
          </CustomWrapper>
        )
      }
    />
  );
};

const Wrapper = styled.div``;

const CustomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  max-width: 115px;
  min-height: 130px;
  width: 100%;
  text-align: center;
  padding: 8px 8px 18px 8px;
  border-radius: 4px;
  border: 1px solid ${props => props.color};
`;

const Img = styled.img`
  width: auto;
  height: auto;
  margin-bottom: 10px;
  ${({ styl }) => ({ ...styl })}
`;

RadioItem.propTypes = {
  data: PropTypes.exact({
    id: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    key: PropTypes.string,
    label: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
  }),
  sx: PropTypes.object,
  conf: PropTypes.exact({
    imgStyle: PropTypes.exact({
      maxWidth: PropTypes.string,
      marginBottom: PropTypes.string,
    }),
    isCustomRadioBtn: PropTypes.bool,
    key: PropTypes.string,
    labelStyle: PropTypes.exact({
      marginLeft: PropTypes.string,
      position: PropTypes.string,
    }),
    radioStyle: PropTypes.exact({
      left: PropTypes.string,
      padding: PropTypes.number,
      position: PropTypes.string,
      top: PropTypes.string,
    }),
    src: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
  }),
};
