import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getProduct = createAsyncThunk(
  'product/getProduct',
  async ({ id, page, per_page, order, sort, filter, stock }, thunkApi) => {
    try {
      const response = await axios.get(`product/category/${id}`, {
        params: { page, per_page, sort, order, filter, stock },
      });
      return { ...response.data, id };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getProductById = createAsyncThunk(
  'product/getProductById',
  async ({ id }, thunkApi) => {
    try {
      const response = await axios.get(`product/${id}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getProductByEditId = createAsyncThunk(
  'product/getProductByEditId',
  async ({ id }, thunkApi) => {
    try {
      const response = await axios.get(`product/edit/${id}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const editProductByEditId = createAsyncThunk(
  'product/editProductByEditId',
  async ({ id, editData }, thunkApi) => {
    try {
      const { data } = await axios.post(`product/edit/${id}`, { ...editData });
      window.history.replaceState(null, null, data?.data?.url);
      return data?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getProductsBySearch = createAsyncThunk(
  'product/getProductsBySearch',
  async ({ q, per_page, page }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/product/search', {
        params: {
          q,
          page,
          per_page,
          fields: 'name,model,description,sku,property,',
        },
      });
      return { ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addAnalogueProductsById = createAsyncThunk(
  'product/addAnalogueProductsById',
  async ({ id, products }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/product/${id}/analogs`, { products });
      return { ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addRelatedProductsById = createAsyncThunk(
  'product/addRelatedProductsById',
  async ({ id, related_products }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/product/${id}/related-products`, {
        related_products,
      });
      return { ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  getProduct,
  getProductById,
  getProductByEditId,
  editProductByEditId,
  getProductsBySearch,
  addAnalogueProductsById,
  addRelatedProductsById,
};
