export const updateComparison = (arr, id) => {
  if (!arr || arr.length === 0) return [];
  return [...arr].map(el => {
    if (el.id === id) {
      return { ...el, in_compare: !el.in_compare };
    } else {
      return el;
    }
  });
};

export const updateProductComparison = (arr, payload) => {
  if (!arr || arr.length === 0) return [];
  const copyData = JSON.parse(JSON.stringify(arr));

  return Object.keys(copyData).map(product => {
    const newData = payload.products.find(
      cart => cart.id === copyData[product].id,
    );
    if (newData) {
      return {
        ...newData,
        properties: { ...copyData[product].properties },
      };
    } else {
      return { ...copyData[product], cart: [], is_in_cart: false };
    }
  });
};
