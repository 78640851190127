const loading = state => state.comparisons.loading;
const productsLoading = state => state.comparisons.productsLoader;
const categoriesListLoading = state => state.comparisons.categoriesListLoading;
const createPdfLoading = state => state.comparisons.createPdfLoading;
const pdfListLoading = state => state.comparisons.pdfListLoading;
const comparisonItemPdfDetails = state =>
  state.comparisons.comparisonItemPdfDetails;
const getComparisonPdfLinksList = state =>
  state.comparisons.comparisonPdfLinksList;
const getProductsDetails = state =>
  state.comparisons.comparisonsProductsDetails;
const getComparisonsTotalCount = state =>
  state.comparisons.comparisonTotalCounter;

const comparisonCategoriesList = state =>
  state.comparisons.comparisonCategoriesList;

const comparisonsSelectors = {
  loading,
  categoriesListLoading,
  productsLoading,
  createPdfLoading,
  pdfListLoading,
  getComparisonsTotalCount,
  comparisonCategoriesList,
  getProductsDetails,
  comparisonItemPdfDetails,
  getComparisonPdfLinksList,
};
export default comparisonsSelectors;
