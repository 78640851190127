import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const MyLinksAccordionDetails = ({ data, currency }) => {
  const { t } = useTranslation('common');
  const { sku, price, model, image } = data;
  const { color } = useTheme();
  return (
    <AccordionDetailsInner borderColor={color.line}>
      <Img src={image} alt={model} />
      <Label>
        <Typography variant="bodyMedium" color={color.gray} component="p">
          {model}
        </Typography>
        <Typography variant="bodyBoldSmall" color={color.gray_60} component="p">
          {t('article')}: {sku}
        </Typography>
      </Label>

      <Typography
        sx={{
          flexShrink: 0,
        }}
        variant="bodyMedium"
        color={color.gray}
        component="p"
      >
        {price} {currency}
      </Typography>
    </AccordionDetailsInner>
  );
};

const Img = styled.img`
  width: 35px;
  height: 35px;
`;

const Label = styled.div`
  flex: 1 1 auto;
`;

const AccordionDetailsInner = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 9px 23px;
  padding-right: 15%;

  &:not(:last-child) {
    border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
  }
`;
