const getOrder = state => state.order.order;
const getOrderRes = state => state.order.orderRes;
const getError = state => state.order.error;
const getFilialList = state => state.order.filialList;
const getOrderHistory = state => state.order.orderHistory;
const getOrderHistoryById = state => state.order.orderHistoryById;
const getOrderHistoryMeta = state => state.order.orderHistoryMeta;
const getLoading = state => state.order.loading;
const getLoadingHistory = state => state.order.loadingHistory;
const getLoadingHistoryDetails = state => state.order.loadingDetails;
const getOnlineForm = state => state.order.onlineForm;
const getSavedOrderTemplate = state => state.order.savedOrderTemplate;

const orderSelectors = {
  getLoading,
  getLoadingHistory,
  getLoadingHistoryDetails,
  getFilialList,
  getOrder,
  getOrderRes,
  getError,
  getOrderHistory,
  getOrderHistoryById,
  getOrderHistoryMeta,
  getOnlineForm,
  getSavedOrderTemplate,
};
export default orderSelectors;
