import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';

export const useSaleData = ({ key, count }) => {
  const dataHomeTopSale = useSelector(homeSelectors.getHomeTopSale);
  const dataBuysMostOften = useSelector(homeSelectors.getHomeBuysMostOften);
  const getBuysMostOftenLoading = useSelector(
    homeSelectors.getBuysMostOftenLoading,
  );
  const getHomeTopSalleLoading = useSelector(
    homeSelectors.getHomeTopSalleLoading,
  );
  const { item, data, loading } = useMemo(() => {
    let loading;
    let data = [];

    switch (key) {
      case 'top_sale':
        data = dataHomeTopSale;
        loading = getHomeTopSalleLoading;
        break;
      case 'buys_most_often':
        data = dataBuysMostOften;
        loading = getBuysMostOftenLoading;
        break;
      default:
        break;
    }
    const item = data[count];
    return { item, data, loading };
  }, [count, dataBuysMostOften, dataHomeTopSale, key]);
  const dispatch = useDispatch();
  useEffect(() => {
    switch (key) {
      case 'top_sale':
        dispatch(homeOperation.getHomeTopSale());
        break;

      case 'buys_most_often':
        dispatch(homeOperation.getHomeBuysMostOften());
        break;

      default:
        break;
    }
  }, []);
  return { data, item, loading };
};
