import PropTypes from 'prop-types';

import { savedOrdersAccordionStyles } from '@root/styles/ui/AccordionStyles/savedOrdersAccordion';
import { AccordionUI } from '@root/ui';

export const SavedOrderAccordion = ({
  children,
  accordionSummary,
  defaultExpanded = false,
}) => {
  return (
    <AccordionUI
      summaryMurk={accordionSummary}
      dots={savedOrdersAccordionStyles.accordionStyles}
      accordionDetailsStyles={savedOrdersAccordionStyles.accordionDetailsStyles}
      defaultExpanded={defaultExpanded}
    >
      {children}
    </AccordionUI>
  );
};

SavedOrderAccordion.propTypes = {
  children: PropTypes.node,
  accordionSummary: PropTypes.node,
  defaultExpanded: PropTypes.bool,
};
