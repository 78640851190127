import { createSlice } from '@reduxjs/toolkit';

import operationAuth from '../auth/auth-operation';
import operation from './user-operation';

const initialState = {
  userProfile: {
    security: {
      password_updated_at: '',
      is_save_logins_active: true,
      is_two_factor_authentication_active: false,
    },
    company: {
      activity_areas: [],
      logo: null,
      employees_quantity: '',
      company_type_id: null,
      identification_code: null,
      contractor_code: null,
      official_name: '',
      site: '',
    },
    custom_color_conf: {},
    custom_logo_menu: {},
    additional_info: '',
    userName: null,
    userEmail: null,
    birthday: null,
    avatar: null,
    np_api_key: '',
    city_id: null,
    phones: [],
    logins: [],
    status: null,
  },
  loading: false,
  loginsLoading: false,
  twoFactorAuthenticationInfoLoading: false,
  activateTwoFactorAuthenticationLoading: false,
  prices: { price: {}, price_uah_pdv: {} },
  currency_prices: [],
  choosedCurrency: '',
  choosedCurrencyWidget: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    //====clearToken====//
    [operationAuth.logOut.fulfilled]: (state, { payload }) => {
      state.userProfile.status = null;
    },

    //====setUser====//
    [operationAuth.getUser.fulfilled]: (state, { payload }) => {
      const { user } = payload;
      const {
        name,
        email,
        avatar,
        prices,
        currency_rates,
        np_api_key,
        city_id,
        birthday,
        phones,
        company,
        ga_is_active,
        show_logins,
        password_updated_at,
        additional_info,
        status,
      } = user;
      state.userProfile = {
        ...state.userProfile,
        security: {
          ...state.userProfile.security,
          is_two_factor_authentication_active: ga_is_active,
          is_save_logins_active: show_logins,
          password_updated_at,
        },
        userName: name,
        userEmail: email,
        avatar,
        city_id,
        birthday,
        np_api_key,
        phones,
        company,
        additional_info,
        status,
      };
      state.currency_rates = currency_rates;
      state.prices = prices;
      const choosedCurrency = Object.entries(prices).filter(
        item => item[1].selected === true,
      );
      state.choosedCurrency = choosedCurrency[0][1].code;
      state.choosedCurrencyWidget = choosedCurrency[0][1];
    },
    //====setSignIn====//
    [operationAuth.signIn.fulfilled]: (state, { payload }) => {
      state.userProfile.userName = payload?.name;
      state.userProfile.avatar = payload?.avatar;
      state.userProfile.userEmail = payload?.email;
    },

    //====saveUserNpApiKey====//
    [operation.saveUserNpApiKey.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.saveUserNpApiKey.fulfilled]: (state, { payload }) => {
      state.userProfile.np_api_key = payload.key;
    },
    [operation.saveUserNpApiKey.rejected]: (state, action) => {
      state.loading = false;
    },

    //====putCurrencyValue====//
    [operation.putCurrencyValue.pending]: (state, action) => {},
    [operation.putCurrencyValue.fulfilled]: (state, { payload }) => {
      const { prices } = payload;
      const choosedCurrency = Object.entries(prices).filter(
        item => item[1].selected === true,
      );
      state.choosedCurrency = choosedCurrency[0][1].code;
      state.choosedCurrencyWidget = choosedCurrency[0][1];
    },
    [operation.putCurrencyValue.rejected]: (state, action) => {},

    //====updateUserProfile====//
    [operation.updateUserProfile.pending]: (state, action) => {},
    [operation.updateUserProfile.fulfilled]: (state, { payload }) => {
      const {
        avatar,
        name,
        email,
        birthday,
        city_id,
        phones,
        additional_info,
      } = payload;
      state.userProfile = {
        ...state.userProfile,
        avatar,
        userName: name,
        userEmail: email,
        birthday,
        phones,
        city_id,
        additional_info,
      };
    },
    [operation.updateUserProfile.rejected]: (state, { payload }) => {},
    //====updateUserCompany====//
    [operation.updateUserCompany.pending]: (state, action) => {},
    [operation.updateUserCompany.fulfilled]: (state, { payload }) => {
      state.userProfile.company = payload;
    },
    [operation.updateUserCompany.rejected]: (state, { payload }) => {},

    //====getUserLogins====//
    [operation.getUserLogins.pending]: (state, action) => {
      state.loginsLoading = true;
    },
    [operation.getUserLogins.fulfilled]: (state, { payload }) => {
      state.userProfile.logins = payload;
      state.loginsLoading = false;
    },
    [operation.getUserLogins.rejected]: (state, { payload }) => {
      state.loginsLoading = false;
    },

    //====updateSecuritySettings====//
    [operation.updateSecuritySettings.pending]: (state, action) => {},
    [operation.updateSecuritySettings.fulfilled]: (state, { payload }) => {
      const { show_logins } = payload;
      state.userProfile.security.is_save_logins_active = show_logins;
    },
    [operation.updateSecuritySettings.rejected]: (state, { payload }) => {},

    //====activateTwoFactorAuthentication====//
    [operation.activateTwoFactorAuthentication.pending]: (state, action) => {
      state.activateTwoFactorAuthenticationLoading = true;
    },
    [operation.activateTwoFactorAuthentication.fulfilled]: (
      state,
      { payload },
    ) => {
      const { ga_is_active } = payload;
      state.activateTwoFactorAuthenticationLoading = false;
      state.userProfile.security.is_two_factor_authentication_active =
        ga_is_active;
    },
    [operation.activateTwoFactorAuthentication.rejected]: (
      state,
      { payload },
    ) => {
      state.activateTwoFactorAuthenticationLoading = false;
    },

    //====deactivateTwoFactorAuthentication====//
    [operation.deactivateTwoFactorAuthentication.pending]: (state, action) => {
      state.activateTwoFactorAuthenticationLoading = true;
    },
    [operation.deactivateTwoFactorAuthentication.fulfilled]: (
      state,
      { payload },
    ) => {
      const { ga_is_active } = payload;
      state.activateTwoFactorAuthenticationLoading = false;
      state.userProfile.security.is_two_factor_authentication_active =
        ga_is_active;
    },
    [operation.deactivateTwoFactorAuthentication.rejected]: (
      state,
      { payload },
    ) => {
      state.activateTwoFactorAuthenticationLoading = false;
    },

    //====createNewColorConfig====//
    [operation.createNewColorConfig.pending]: (state, action) => {
      state.activateTwoFactorAuthenticationLoading = true;
    },
    [operation.createNewColorConfig.fulfilled]: (state, { payload }) => {
      const { custom_color_conf, custom_logo_menu } = payload;
      state.userProfile = {
        ...state.userProfile,
        custom_color_conf,
        custom_logo_menu,
      };
    },
    [operation.createNewColorConfig.rejected]: (state, { payload }) => {
      state.activateTwoFactorAuthenticationLoading = false;
    },
  },
});

export default userSlice.reducer;
