import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty, StepperStatusTyne } from '@root/components';
import { CustomScroll, IconBtn, ModalBase, ProgressLoader } from '@root/ui';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';
import orderOperation from '@redux/order/order-operation';
import { clearOrderHistoryDetails } from '@redux/order/order-slice';

import { Divider, Typography, useTheme } from '@mui/material';

export const OrderWidjet = ({ conf, historyDetailsModule }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const isExpanded = conf.size === '2fr';
  const { t } = useTranslation(['order_history', 'common'], {
    useSuspense: false,
  });
  const { color } = useTheme();
  const ordersList = useSelector(homeSelectors.getHomeOrders);
  const loading = useSelector(homeSelectors.getHomeOrdersLoading);
  const meta = useSelector(homeSelectors.getHomeOrdersMeta);
  const dispatch = useDispatch();

  useEffect(() => {
    getOrdersList(1);
  }, []);

  const getOrdersList = page =>
    dispatch(homeOperation.getHomeOrders({ page: page }));

  const loadMoreItems = isInView => {
    if (isInView) {
      setPage(page + 1);
      if (meta.current_page !== meta.last_page) {
        getOrdersList(page + 1);
      }
    }
  };

  const showOrderDetails = orderId => {
    setOpen(true);
    dispatch(orderOperation.getHistoryOrderById({ id: orderId }));
  };

  const onClose = () => {
    setOpen(false);
    dispatch(clearOrderHistoryDetails());
  };

  return (
    <ContentWrap>
      {loading && <ProgressLoader />}
      <InView onChange={loadMoreItems}>
        {({ ref }) => (
          <List expanded={isExpanded}>
            <CustomScroll vertical={true}>
              {!loading && !ordersList.length && <Empty type={'EmptyBox'} />}
              {ordersList.map(orderListItem => (
                <ListItem color={color} key={orderListItem.id} ref={ref}>
                  <ListItemHeader>
                    <Button
                      onClick={() => showOrderDetails(orderListItem.title)}
                    >
                      <Typography color={color.gray} variant={'bodyBoldSmall'}>
                        № {orderListItem.title}
                      </Typography>
                    </Button>
                    {isExpanded && (
                      <SmallDetailsOrder>
                        <Typography
                          variant={'bodySmall'}
                          color={color.gray_60}
                          sx={{ minWidth: '68px' }}
                        >
                          {orderListItem.quantity}{' '}
                          {t('order_history:items_count.key', {
                            count: orderListItem.quantity,
                          })}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem={true}
                          sx={{
                            margin: '0 7px',
                          }}
                        />
                        <Typography
                          variant={'bodyBoldSmall'}
                          color={color.gray}
                        >
                          {orderListItem.amount} {orderListItem.currency}
                        </Typography>
                      </SmallDetailsOrder>
                    )}
                    <StepperStatusTyne steps={orderListItem.statuses} />
                  </ListItemHeader>
                  <ListItemBody>
                    <Typography
                      sx={{ whiteSpace: 'nowrap' }}
                      color={color.gray_60}
                      variant={'bodySmall'}
                    >
                      {t('common:updated')} {orderListItem.updated}
                    </Typography>
                  </ListItemBody>
                </ListItem>
              ))}
            </CustomScroll>
          </List>
        )}
      </InView>
      {open && (
        <ModalBase
          sx={{
            padding: '45px 38px 38px',
          }}
          open={open}
          handleClose={onClose}
        >
          <IconBtn
            sx={{
              position: 'absolute',
              top: '10px',
              right: '38px',
            }}
            size={25}
            href={`${Sprite}#icon-cross`}
            onClick={onClose}
          />
          {historyDetailsModule}
        </ModalBase>
      )}
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 100%;
`;

const ListItem = styled.div`
  padding: 12px 10px;
  border-bottom: 1px solid ${({ color }) => color.line};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ListItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListItemBody = styled.div``;

const Button = styled.button`
  cursor: pointer;
  text-decoration: underline;
`;

const SmallDetailsOrder = styled.div`
  text-transform: lowercase;
  display: flex;
  align-items: center;
  height: 8px;
  margin: 0 140px;
  min-width: 160px;
`;

const List = styled.div`
  height: 307px;

  & ${ListItem} {
    flex-direction: ${({ expanded }) => (expanded ? 'row' : '')};
  }

  & ${ListItemHeader} {
    justify-content: ${({ expanded }) => (expanded ? 'initial' : '')};
    width: 100%;
  }
`;

OrderWidjet.propTypes = {
  conf: PropTypes.exact({
    id: PropTypes.number,
    isHide: PropTypes.bool,
    isNav: PropTypes.bool,
    key: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.object,
    size: PropTypes.string,
    sort: PropTypes.number,
  }),
  historyDetailsModule: PropTypes.object,
};
