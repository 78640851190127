import { styled } from '@mui/material/styles';

export const ColorlibStepIconRoot = styled('div')(
  ({
    theme,
    ownerState,
    sx = () => {},
    sxActive = () => {},
    sxComplete = () => {},
  }) => {
    return {
      width: 10,
      height: 10,
      display: 'flex',
      background: `${theme.color.line}`,
      border: `1px solid ${theme.color.line}`,
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',

      ...sx(theme),

      ...(ownerState.active && {
        color: `${theme.color.main}`,
        border: `1px solid ${theme.color.main}`,
        background: `${theme.color.main}`,
        ...sxActive(theme),
      }),
      ...(ownerState.completed && {
        background: `${theme.color.main}`,
        border: `1px solid ${theme.color.main}`,
        ...sxComplete(theme),
      }),
    };
  },
);
