import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '@mui/material/styles';

export const IconBtn = ({
  href,
  onClick,
  size = 16,
  display = 'block',
  rotateSvg,
  rotateSvgX,
  sx,
  ...prop
}) => {
  const { color } = useTheme();
  return (
    <Button
      type={'button'}
      onClick={onClick}
      color={color}
      size={size}
      style={{ display: display, ...sx }}
      {...prop}
    >
      <Svg
        width={size}
        height={size}
        rotateSvgX={rotateSvgX}
        rotateSvg={rotateSvg}
      >
        <use href={href}></use>
      </Svg>
    </Button>
  );
};

const Button = styled.button`
  width: ${prop => prop.size}px;
  height: ${prop => prop.size}px;
  fill: ${prop =>
    prop.saved || prop.compare ? prop.color.main : prop.color.gray_60};
  stroke: ${prop =>
    prop.saved || prop.compare ? prop.color.main : prop.color.gray_60};
  cursor: pointer;

  &:hover {
    fill: ${prop => prop.color.main};
    stroke: ${prop => prop.color.main};
  }
`;

const Svg = styled.svg`
  transform: ${({ rotateSvg, rotateSvgX }) =>
    rotateSvgX ? `rotateX(${rotateSvgX})` : `rotate(${rotateSvg})`};
`;

IconBtn.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  display: PropTypes.string,
  rotateSvg: PropTypes.string,
  rotateSvgX: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  sx: PropTypes.object,
};
