import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField } from '@root/components';

import { Typography, useTheme } from '@mui/material';

export const BusinessForm = ({ data }) => {
  const {
    name,
    contractor_code,
    identification_code,
    company_type,
    status,
    official_name,
    legal_address: {
      street_name_legal,
      house_number_legal,
      zip_code_legal,
      city_legal,
      country_legal,
    },
    mailing_address: {
      street_name_mailing,
      house_number_mailing,
      zip_code_mailing,
      city_mailing,
      country_mailing,
    },
  } = data;

  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();

  return (
    <Form>
      <Typography variant={'h2'} sx={{ textTransform: 'uppercase' }}>
        {official_name}
      </Typography>
      <Wrapper>
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.status')}
          value={
            status === 'active'
              ? t('admin:active_status')
              : t('admin:inactive_status')
          }
          variant={'bodyMedium'}
          goToStep={() => {}}
          sx={{
            mr: '12px',
            '&::before': {
              display: 'block',
              content: '""',
              width: '8px',
              height: '8px',
              background: status === 'active' ? color.green : color.red,
              borderRadius: '40px',
              mr: '5px',
            },
          }}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.business')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={name}
          goToStep={() => {}}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.type')}
          value={company_type}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.edrpou')}
          value={contractor_code}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.ipn')}
          value={identification_code}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('admin:counterparties_table_conf.slogan_legal_address')}
        </Typography>

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.country')}
          value={country_legal}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.city')}
          value={city_legal}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.street_name')}
          value={street_name_legal}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.house_number')}
          value={house_number_legal}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.zip_code')}
          value={zip_code_legal}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('admin:counterparties_table_conf.slogan_mailing_address')}
        </Typography>

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.country')}
          value={country_mailing}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.city')}
          value={city_mailing}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.street_name')}
          value={street_name_mailing}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.house_number')}
          value={house_number_mailing}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.zip_code')}
          value={zip_code_mailing}
          sx={{ mr: '12px' }}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />
      </Wrapper>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-bottom: 50px;
`;
const Wrapper = styled.div`
  margin-top: 28px;
`;

BusinessForm.propTypes = {
  data: PropTypes.exact({
    name: PropTypes.string,
    contractor_code: PropTypes.number,
    identification_code: PropTypes.number,
    company_type: PropTypes.string,
    status: PropTypes.string,
    official_name: PropTypes.string,
    legal_address: {
      street_name_legal: PropTypes.string,
      house_number_legal: PropTypes.string,
      zip_code_legal: PropTypes.string,
      city_legal: PropTypes.string,
      country_legal: PropTypes.string,
    },
    mailing_address: {
      street_name_mailing: PropTypes.string,
      house_number_mailing: PropTypes.string,
      zip_code_mailing: PropTypes.string,
      city_mailing: PropTypes.string,
      country_mailing: PropTypes.string,
    },
  }),
};
