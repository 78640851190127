import { useMemo } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { StepperStatusTyne } from '@root/components';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const CartHistory = ({ isActive, onClick, data }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['order_history'], { useSuspense: false });
  const { status, statuses } = data;

  const StatusInMemo = useMemo(
    () => () => {
      switch (status) {
        case 'Saved':
          return (
            <InfoOrderStatus>
              <Svg>
                <use href={`${Sprite}#icon-heart-status`} />
              </Svg>
              <Typography variant={'bodyBoldSmall'} color={color.gray_80}>
                {t('order_history:saved')}
              </Typography>
            </InfoOrderStatus>
          );

        default:
          return <StepperStatusTyne steps={statuses} />;
      }
    },
    [status],
  );

  return (
    <Components
      colors={{ main: color.main, line: color.line }}
      onClick={() => onClick(data?.id)}
      color={
        isActive
          ? `linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.92),
        rgba(255, 255, 255, 0.92)
      ),
    ${color.main}`
          : color.white
      }
      isActive={isActive}
    >
      <FlexContainer style={{ marginBottom: '8px' }}>
        <InfoOrder>
          <Typography
            variant={'bodyBoldMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '4px',
              color: color.gray,
            }}
          >
            {data.status === 'Saved' ? data.title : `№ ${data.title}`}
          </Typography>

          <Typography
            variant={'bodySmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray_60,
            }}
          >
            {data?.note && <EllipsisText text={data?.note} length={200} />}
          </Typography>
        </InfoOrder>
        <StatusInMemo />
      </FlexContainer>
      <FlexContainer>
        <FlexContainer>
          <Typography
            variant={'bodySmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray_60,
            }}
          >
            {data?.quantity}{' '}
            {t('order_history:items_count.key', { count: data?.quantity })}
          </Typography>
          <Typography
            variant={'bodyBoldSmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: '14px',
              color: color.gray,
            }}
          >
            {data?.amount}{' '}
            {data?.currency_full ? data.currency_full : data?.currency}
          </Typography>
        </FlexContainer>
        <Typography
          variant={'bodySmall'}
          component={'p'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 'auto',
            color: color.gray_60,
          }}
        >
          {t('order_history:create')} {data?.created}
        </Typography>
      </FlexContainer>
    </Components>
  );
};

const Components = styled.li`
  padding: 16px;
  cursor: pointer;
  background: ${({ color }) => color};
  border-left: ${({ isActive, colors }) =>
    isActive ? `3px solid ${colors.main}` : ''};
  border-bottom: 1px solid ${props => props.colors.line};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoOrder = styled.div`
  margin-right: 50px;
`;

const InfoOrderStatus = styled.div`
  display: flex;
  column-gap: 6px;
  margin-top: 1px;
`;
const Svg = styled.svg`
  width: 14px;
  height: 14px;
`;

CartHistory.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.exact({
    amount: PropTypes.string,
    created: PropTypes.string,
    currency: PropTypes.string,
    currency_full: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    note: PropTypes.string,
    quantity: PropTypes.number,
    status: PropTypes.string,
    statuses: PropTypes.arrayOf(
      PropTypes.exact({
        code: PropTypes.string,
        icon: PropTypes.string,
        id: PropTypes.number,
        label: PropTypes.string,
        sort: PropTypes.number,
        status: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }),
};
