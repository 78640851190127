import styled from 'styled-components';

import { Logo } from '@root/components';
import {
  AISupport,
  BonusWidjet,
  ExchangeRate,
  Locales,
  Notes,
  Rate,
  UserMenu,
} from '@root/widgets';

import { useTheme } from '@mui/material/styles';

export const Header = () => {
  const { color } = useTheme();

  return (
    <Component colors={{ white: color.white, line: color.line_light }}>
      <WrapperLogo color={color.line_light}>
        <Logo />
        <BetaWarning colors={{ red: color.red, white: color.white }}>
          Beta
        </BetaWarning>
      </WrapperLogo>

      <WrapperExchangeRate>
        <ExchangeRate />
      </WrapperExchangeRate>
      <Wrapper>
        <Rate />
      </Wrapper>
      <Wrapper>
        <Locales />
      </Wrapper>
      <Wrapper>
        <AISupport />
      </Wrapper>
      <Wrapper>
        <Notes />
      </Wrapper>
      <Wrapper>
        <UserMenu />
      </Wrapper>
    </Component>
  );
};

const Component = styled.header`
  display: flex;
  align-items: center;
  width: 100%;

  padding-right: 32px;
  height: 68px;

  background: ${prop => prop.colors.white};
  border-bottom: 1px solid ${prop => prop.colors.line};
`;

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 240px;
  justify-content: center;
  padding: 0 16px 0 16px;
  border-right: 1px solid ${props => props.color};
  column-gap: 23px;
`;

const Wrapper = styled.div`
  padding: ${prop => prop.padding};
  margin-left: 34px;
`;

const WrapperExchangeRate = styled.div`
  margin-left: 34px;
  margin-right: auto;
`;

const BetaWarning = styled.div`
  display: flex;
  background: ${props => props.colors.red};
  padding: 4px 10px;
  border-radius: 44px;
  font-weight: 700;
  font-size: 0.625rem;
  color: ${props => props.colors.white};
  margin-top: 4px;
`;

Header.className = Component;
