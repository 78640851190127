import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { whiteTooltipPriceStyles, whiteTooltipStyles } from '@root/styles';
import { BootstrapTooltip } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import Typography from '@mui/material/Typography';

const preventDefault = e => e.preventDefault();
const convertPricesObjectToArray = pricesObject =>
  pricesObject ? Object.entries(pricesObject) : [];

export const PricesTooltip = ({
  prices,
  children,
  isHiddenTooltip = false,
  showAllPrices = false,
}) => {
  const chosenCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  const userPrices = useSelector(userSelectors.getPrices);

  const pricesMap = () => {
    let temporaryPrices = {};
    for (const key in userPrices) {
      temporaryPrices[key] = userPrices[key].code_full;
    }
    return temporaryPrices;
  };

  const checkIsPriceNotZero = (pricesValue, key) => pricesValue[key] !== '0.00';
  const checkIsDisplayPrice = (pricesValue, pricesKey) =>
    (checkIsPriceNotZero(pricesValue, 'personal') ||
      checkIsPriceNotZero(pricesValue, 'product')) &&
    (showAllPrices ? true : pricesKey !== chosenCurrency);

  const TooltipPricesInMemo = useMemo(
    () => () => {
      const pricesArray = convertPricesObjectToArray(prices);
      const pricesWithoutZero = [];
      pricesArray.forEach(price => {
        if (
          price[0] !== chosenCurrency &&
          (price[1].personal !== '0.00' || price[1].product !== '0.00')
        ) {
          pricesWithoutZero.push(price);
        }
      });
      return pricesWithoutZero.length > 0 ? (
        <TooltipBodyInner onClick={preventDefault}>
          {pricesArray.map(price => {
            const pricesValue = price[1];
            const pricesKey = price[0];
            const currency = pricesMap()[pricesKey];
            return (
              checkIsDisplayPrice(pricesValue, pricesKey) && (
                <TooltipBodyPrices key={pricesKey}>
                  {checkIsPriceNotZero(pricesValue, 'personal') && (
                    <Typography sx={whiteTooltipPriceStyles.bold}>
                      {pricesValue?.personal} {currency}
                    </Typography>
                  )}
                  {checkIsPriceNotZero(pricesValue, 'product') && (
                    <Typography sx={whiteTooltipPriceStyles.light}>
                      {pricesValue?.product} {currency}
                    </Typography>
                  )}
                </TooltipBodyPrices>
              )
            );
          })}
        </TooltipBodyInner>
      ) : (
        ''
      );
    },
    [prices, chosenCurrency, showAllPrices],
  );
  return (
    <BootstrapTooltip
      tooltipStyles={whiteTooltipStyles}
      placement="top"
      title={isHiddenTooltip ? '' : <TooltipPricesInMemo />}
    >
      {children}
    </BootstrapTooltip>
  );
};

const TooltipBodyInner = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

const TooltipBodyPrices = styled.div``;

PricesTooltip.propTypes = {
  prices: PropTypes.exact({
    price: PropTypes.shape({
      personal: PropTypes.string,
      product: PropTypes.string,
    }),
    price_cron: PropTypes.shape({
      personal: PropTypes.string,
      product: PropTypes.string,
    }),
    price_cron_no_dph: PropTypes.shape({
      personal: PropTypes.string,
      product: PropTypes.string,
    }),
    price_no_dph: PropTypes.shape({
      personal: PropTypes.string,
      product: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  isHiddenTooltip: PropTypes.bool,
  showAllPrices: PropTypes.bool,
};
