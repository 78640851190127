export const imageCropperActions = [
  {
    iconName: 'icon-crop',
    actionName: 'crop',
    typeName: 'escape-crop',
  },
  {
    iconName: 'icon-horizontal-mirroring',
    actionName: 'horizontal-mirroring',
    typeName: 'rotate-flip',
  },
  {
    iconName: 'icon-vertical-mirroring',
    actionName: 'vertical-mirroring',
    typeName: 'rotate-flip',
  },
  {
    iconName: 'icon-rotate-right',
    actionName: 'rotate-right',
    typeName: 'rotate-flip',
  },
  {
    iconName: 'icon-rotate-left',
    actionName: 'rotate-left',
    typeName: 'rotate-flip',
  },
  {
    iconName: 'icon-remove-bg',
    actionName: 'remove-bg',
    typeName: 'remove-bg',
  },
];

export const cropActions = {
  crop: 'crop',
  horizontal_mirroring: 'horizontal-mirroring',
  vertical_mirroring: 'vertical-mirroring',
  rotate_right: 'rotate-right',
  rotate_left: 'rotate-left',
  remove_bg: 'remove-bg',
};
