export const DatePickerConf = t => [
  t('common:data_picker.january'),
  t('common:data_picker.february'),
  t('common:data_picker.march'),
  t('common:data_picker.april'),
  t('common:data_picker.may'),
  t('common:data_picker.june'),
  t('common:data_picker.july'),
  t('common:data_picker.august'),
  t('common:data_picker.september'),
  t('common:data_picker.october'),
  t('common:data_picker.november'),
  t('common:data_picker.december'),
];
