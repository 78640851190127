import PropTypes from 'prop-types';

import { Error } from '@root/assets/svg';

import FormHelperText from '@mui/material/FormHelperText';

export const MessageError = ({ errorText, sx = {} }) => {
  return (
    <FormHelperText
      error={true}
      id="component-error-text"
      sx={{ display: 'flex', ...sx }}
    >
      <img src={Error} alt="Error Icon" style={{ marginRight: '5px' }} />
      {errorText}
    </FormHelperText>
  );
};

MessageError.propTypes = {
  errorText: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
