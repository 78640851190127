import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import authSelectors from '@redux/auth/auth-selectors';

import { LangWatcher } from '../LangWatcher';

export function PublicRoute({ element }) {
  const token = useSelector(authSelectors.getUserToken);
  const { lang } = useParams();
  return (
    <LangWatcher>
      {token ? <Navigate to={lang ? `/${lang}/` : '/'} /> : element}
    </LangWatcher>
  );
}

PublicRoute.propTypes = {
  element: PropTypes.node,
};
