import { createSlice } from '@reduxjs/toolkit';

import savedOrdersOperation from './saved-orders-operation';

const initialState = {
  savedOrders: null,
  savedOrdersMeta: {},
  isSavedOrdersLoading: false,
  isInfiniteScrollLoading: false,
  isSavedOrdersError: false,
  savedOrdersTextError: '',
  isCreateSavedOrderLoading: false,
  isCreateSavedOrderError: false,
  createSavedOrderError: '',
};

const savedOrdersSlice = createSlice({
  name: 'savedOrders',
  initialState,
  reducers: {
    updateSavedOrderOnDelete(state, { payload }) {
      state.savedOrders = [...state.savedOrders].filter(
        savedOrder => savedOrder.id !== payload,
      );
    },
    updateSavedOrderProductOnDelete(state, { payload }) {
      const { id, productId } = payload;

      const currentSavedOrder = state.savedOrders.find(
        savedOrder => savedOrder.id === id,
      );

      state.savedOrders = [...state.savedOrders].map(savedOrder =>
        savedOrder.id === id
          ? {
              ...savedOrder,
              details: currentSavedOrder.details.filter(
                detail => detail.product_id !== productId,
              ),
            }
          : savedOrder,
      );
    },
  },
  extraReducers: {
    [savedOrdersOperation.createSavedOrder.pending]: (state, { payload }) => {
      state.isCreateSavedOrderLoading = true;
      state.isCreateSavedOrderError = false;
      state.createSavedOrderError = '';
    },
    [savedOrdersOperation.createSavedOrder.fulfilled]: (state, { payload }) => {
      state.isCreateSavedOrderLoading = false;
      state.isCreateSavedOrderError = false;
      state.createSavedOrderError = '';
      state.savedOrders = payload.data;
      state.savedOrdersMeta = payload.meta;
    },
    [savedOrdersOperation.createSavedOrder.rejected]: (state, { payload }) => {
      state.isCreateSavedOrderLoading = false;
      state.isCreateSavedOrderError = true;
      state.createSavedOrderError = payload;
    },
    [savedOrdersOperation.getSavedOrders.pending]: (state, { payload }) => {
      state.isSavedOrdersLoading = true;
      state.isSavedOrdersError = false;
      state.savedOrdersTextError = '';
    },
    [savedOrdersOperation.getSavedOrders.fulfilled]: (state, { payload }) => {
      state.savedOrders =
        payload.meta.current_page === 1
          ? [...payload.data]
          : [...state.savedOrders, ...payload.data];

      state.savedOrdersMeta = payload.meta;
      state.isSavedOrdersLoading = false;
      state.isSavedOrdersError = false;
      state.savedOrdersTextError = '';
    },
    [savedOrdersOperation.getSavedOrders.rejected]: (state, { payload }) => {
      state.isSavedOrdersLoading = false;
      state.isSavedOrdersError = true;
      state.savedOrdersTextError = payload;
    },
  },
});

export const { updateSavedOrderOnDelete, updateSavedOrderProductOnDelete } =
  savedOrdersSlice.actions;

export default savedOrdersSlice.reducer;
