import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Notification } from '@root/ui';

import notificationWatcherSelectors from '@redux/notification-watcher/notification-watcher-selectors';
import {
  clearNotificationState,
  setOpenNotification,
} from '@redux/notification-watcher/notification-watcher-slice';

export const NotificationWatcher = () => {
  const configuration = useSelector(notificationWatcherSelectors.configuration);
  const dispatch = useDispatch();
  const { width, message, action, open } = configuration;
  const { pathname } = useLocation();

  useEffect(() => {
    if (Object.keys(configuration).length > 0) {
      dispatch(clearNotificationState());
    }
  }, [pathname]);

  const closeNotification = () => {
    dispatch(
      setOpenNotification({
        ...configuration,
        open: false,
      }),
    );
  };

  return (
    <Notification
      open={open}
      handleCloseNotification={closeNotification}
      width={width}
      message={message}
      action={action}
    />
  );
};
