import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getErrorStack, handleDispatchFetch, validURL } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  ButtonBase,
  CheckboxBase,
  MessageError,
  OutlineButtonBase,
  TextArea,
  TextInput,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';
import optionOperation from '@redux/option/option-operation';
import optionSelectors from '@redux/option/option-selectors';

import Typography from '@mui/material/Typography';

export const FieldOfActivity = ({ initData, pathParrent }) => {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const { t } = useTranslation(['registration', 'validation', 'common'], {
    useSuspense: false,
  });
  const dispatch = useDispatch();
  const activity = useSelector(optionSelectors.getActivityAreas);
  const { navigation } = useNavigation();

  useEffect(() => {
    dispatch(optionOperation.getActivityAreas());
  }, []);

  useEffect(() => {
    const { name, employees_quantity, additional_info, site, activity_areas } =
      initData.company;

    setData({
      name,
      employees_quantity,
      additional_info,
      site,
      activity_areas: [...activity_areas].map(el => el.id),
    });
  }, [initData]);

  const onChange = (name, subName) => value => {
    if (name === 'activity_areas') {
      let dataCopy = [...data.activity_areas];
      if (value) {
        dataCopy.push(subName);
      } else {
        dataCopy = dataCopy.filter(id => id !== subName);
      }
      setData(prevState => ({ ...prevState, [name]: dataCopy }));
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handelSubmit = e => {
    e.preventDefault();
    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.signUpStepFours({
            formData: {
              ...data,
            },
            onResolve,
            onReject,
          }),
        ),
      () => navigation(`${pathParrent}/4`),
    );
  };

  const validation = () => {
    let flag = false;

    let currentError = {};
    const { name, site, activity_areas } = data;

    if (!name || name === '') {
      currentError = getErrorStack(
        currentError,
        'name',
        t('validation:required'),
      );
      flag = true;
    } else if (name.length < 2) {
      currentError = getErrorStack(
        currentError,
        'name',
        t(`${t('validation:minLengthLetters')} 2`),
      );
      flag = true;
    }

    if (site) {
      if (!validURL(site)) {
        currentError = getErrorStack(
          currentError,
          'site',
          t('validation:enter_valid_url'),
        );
        flag = true;
      }
    }

    if (activity_areas.length === 0) {
      currentError = getErrorStack(
        currentError,
        'activity_areas',
        t('validation:choose_one_of_activity'),
      );
      flag = true;
    }
    setError(currentError);
    return flag;
  };

  const { name, employees_quantity, site, additional_info, activity_areas } =
    data;

  return (
    <Wrapper marginBottom={60}>
      <Form onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t('registration:step_fourth.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_fourth.slogan')}
        </Typography>

        <TextInput
          error={error?.name?.init}
          errorText={error?.name?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={name}
          onChange={onChange('name')}
          placeholder={t('registration:field.company_name', { req: '*' })}
          type="text"
          max={255}
        />
        <TextInput
          sx={{ mt: '28px', height: '48px' }}
          value={employees_quantity}
          onChange={onChange('employees_quantity')}
          placeholder={t('registration:field.number_of_employees')}
          type="text"
          max={9}
          digitsOnly={true}
          withOutSpace={true}
        />

        <TextInput
          error={error?.site?.init}
          errorText={error?.site?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={site}
          onChange={onChange(`site`)}
          placeholder={t('registration:field.site_url')}
          type="text"
          max={255}
          withOutSpace={true}
        />

        <WrapperBoxRow>
          <Typography sx={{}} variant={'bodyLarge'} component={'p'}>
            {t('registration:step_fourth.type_of_activity')}
          </Typography>
          <FieldOfActivityPossibility>
            {activity &&
              activity_areas &&
              activity.map(({ name, id }) => (
                <WrapperCheckBox key={id}>
                  <CheckboxBase
                    width={24}
                    onChange={onChange('activity_areas', id)}
                    checked={activity_areas.includes(id)}
                    error={error?.activity_areas?.init}
                  >
                    <Typography variant={'bodyLarge'} sx={{ ml: '8px' }}>
                      {name}
                    </Typography>
                  </CheckboxBase>
                </WrapperCheckBox>
              ))}
          </FieldOfActivityPossibility>
          {error?.activity_areas?.text && (
            <MessageError errorText={error?.activity_areas?.text} />
          )}
        </WrapperBoxRow>

        <TextArea
          error={error?.company?.init}
          errorText={error?.company?.text}
          sx={{ marginTop: '28px' }}
          value={additional_info}
          onChange={onChange('additional_info')}
          placeholder={t('registration:field.additional_info')}
          type="text"
          max={5000}
        />

        <WrapperRow>
          <Wrapper marginBottom={0}>
            <OutlineButtonBase
              type="button"
              sx={{ flex: 1, maxWidth: '208px' }}
              onClick={() => {
                navigation(`${pathParrent}/2`);
              }}
            >
              <Typography variant={'bodyBoldLarge'} align={'center'}>
                {t('common:buttons.back')}
              </Typography>
            </OutlineButtonBase>

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ flex: 1, ml: '12px', maxWidth: '208px' }}
            >
              <Typography variant={'bodyBoldLarge'}>
                {t('common:buttons.continue')}
              </Typography>
            </ButtonBase>
          </Wrapper>
        </WrapperRow>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const WrapperRow = styled.div`
  margin-top: 28px;
`;

const WrapperBoxRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

const FieldOfActivityPossibility = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
  margin-bottom: -16px;
  margin-left: -33px;
`;

const WrapperCheckBox = styled.div`
  display: flex;
  margin-bottom: 16px;
  margin-left: 33px;
`;

const Svg = styled.svg`
  margin-right: 4px;
`;

FieldOfActivity.propTypes = {
  initData: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.string,
    city_id: PropTypes.number,
    company: PropTypes.exact({
      activity_areas: PropTypes.arrayOf(
        PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
      ),
      additional_info: PropTypes.string,
      company_type: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      employees: PropTypes.array,
      employees_quantity: PropTypes.number,
      identification_code: PropTypes.number,
      legal_address: PropTypes.exact({
        city_legal: PropTypes.string,
        country_legal: PropTypes.string,
        house_number_legal: PropTypes.string,
        street_name_legal: PropTypes.string,
        zip_code_legal: PropTypes.string,
      }),
      logo: PropTypes.string,
      mailing_address: PropTypes.exact({
        city_mailing: PropTypes.string,
        country_mailing: PropTypes.string,
        house_number_mailing: PropTypes.string,
        street_name_mailing: PropTypes.string,
        zip_code_mailing: PropTypes.string,
      }),
      name: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
    }),
    currency_rates: PropTypes.arrayOf(
      PropTypes.exact({
        public_name: PropTypes.string,
        rate: PropTypes.string,
      }),
    ),
    email: PropTypes.string,
    ga_is_active: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    np_api_key: PropTypes.string,
    password_updated_at: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.exact({ confirm: PropTypes.bool, phone: PropTypes.string }),
    ),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        code: PropTypes.string,
        code_full: PropTypes.string,
        field_name: PropTypes.string,
        multiplier: PropTypes.number,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_cron: PropTypes.exact({
        code: PropTypes.string,
        code_full: PropTypes.string,
        field_name: PropTypes.string,
        multiplier: PropTypes.number,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_cron_no_dph: PropTypes.exact({
        code: PropTypes.string,
        code_full: PropTypes.string,
        field_name: PropTypes.string,
        multiplier: PropTypes.number,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_no_dph: PropTypes.exact({
        code: PropTypes.string,
        code_full: PropTypes.string,
        field_name: PropTypes.string,
        multiplier: PropTypes.number,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
    }),
    role: PropTypes.arrayOf(PropTypes.string),
    show_logins: PropTypes.bool,
    status: PropTypes.string,
  }),
  pathParrent: PropTypes.string.isRequired,
};
