import axios from 'axios';

class AxiosServiceConf {
  setLang(lang) {
    axios.defaults.headers.common['Content-Language'] = `${lang}`;
  }
  setToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  unsetToken() {
    axios.defaults.headers.common['Authorization'] = ``;
  }
}

export const AxiosService = new AxiosServiceConf();
