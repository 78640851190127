import { getErrorStack } from '@root/helpers';

const validationMes = (key, t) => {
  let res;
  if (key === 'city_title') {
    res = t('validation:chose_city');
  } else if (key === 'department_data') {
    res = t('validation:chose_department');
  } else if (key === 'npPhone' || key === 'zip_code') {
    res = t('validation:required');
  } else if (key === 'street_title') {
    res = t('validation:input_street');
  } else if (key === 'street_number') {
    res = t('validation:input_street_number');
  } else {
    res = t('validation:input_post_office');
  }
  return res;
};
export const servicesValidation = (data, setError, currentError, t, flag) => {
  const { delivery_filial, delivery_type, delivery_info } = data;
  const { city_title, street_title, street_number, department_data, zip_code } =
    delivery_info;

  switch (delivery_type) {
    case 'Self_pickup':
      if (!delivery_filial || delivery_filial === '') {
        currentError = getErrorStack(
          currentError,
          'delivery_filial',
          t('validation:required'),
        );
        flag = true;
      }
      break;
    case 'DPD_branch':
      if (
        data.delivery_info.type === 'department' &&
        Object.keys(department_data).length === 0
      ) {
        currentError = getErrorStack(
          currentError,
          'department_data',
          validationMes('department_data', t),
        );
        flag = true;
      }

      break;
    case 'Address':
      Object.keys({
        city_title,
        street_title,
        street_number,
        zip_code,
      }).forEach(key => {
        if (!data.delivery_info[key] || data.delivery_info[key] === '') {
          currentError = getErrorStack(
            currentError,
            key,
            validationMes(key, t),
          );
          flag = true;
        }
      });

      const isZipCodeNotEmpty =
        zip_code && zip_code.length !== 0 && zip_code.length < 3;

      if (isZipCodeNotEmpty) {
        currentError = getErrorStack(
          currentError,
          'zip_code',
          `${t('validation:minLengthLetters')}3`,
        );
        flag = true;
      }

      break;
    default:
      break;
  }

  setError(prevState => ({ ...prevState, ...currentError }));

  return flag;
};
