import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Arow_Serch, Sprite } from '@root/assets/svg';
import { SearchInput } from '@root/components';
import { categoryList } from '@root/conf';
import { useDebounce, useIsFirstRender, useQueryParams } from '@root/hooks';
import { BasicSelect, OutlineButtonBase, TextInput, ToggleBtn } from '@root/ui';

import adminOperation from '@redux/admin/admin-operation';
import authSelectors from '@redux/auth/auth-selectors';
import categorySelectors from '@redux/category/category-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const AdminSearch = ({ accordionActive }) => {
  const [searchValue, setSearchValue] = useState('');
  const [focus, setFocus] = useState(false);
  const dispatch = useDispatch();
  const { color } = useTheme();
  const { adminTab } = useParams();
  const category = useSelector(categorySelectors.getCategoryListToEdit);
  const { getQueryObj, replaceQueryParam, setSearchParams } = useQueryParams();
  const role = useSelector(authSelectors.getRole);
  const debounceSearchValue = useDebounce(searchValue, 400);
  const { t } = useTranslation(['admin'], { useSuspense: false });

  const { page, per_page, category_id } = getQueryObj();

  const isFirstRender = useIsFirstRender();

  const onBlur = e => setFocus(false);

  useEffect(() => {
    if (!isFirstRender) {
      fetchAdminData(debounceSearchValue);
    }
  }, [debounceSearchValue]);

  useEffect(() => {
    if (isFirstRender) return;

    setSearchValue('');
  }, [adminTab]);

  const onChange = name => value => {
    setSearchValue(value);
    if (value) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  };

  const fetchAdminData = e => {
    const isSearchNotEmpty = !!searchValue;
    if (isSearchNotEmpty) {
      replaceQueryParam('q', searchValue);
    } else {
      const searchParams = getQueryObj();
      delete searchParams.q;

      setSearchParams(searchParams);
    }

    switch (adminTab) {
      case 'businesses': {
        isSearchNotEmpty
          ? dispatch(
              adminOperation.getBusinessesSearch({
                search: searchValue,
                role,
              }),
            )
          : dispatch(
              adminOperation.getBusinessData({
                page: page || 1,
                per_page: per_page || 10,
              }),
            );
        break;
      }
      case 'counterparts': {
        isSearchNotEmpty
          ? dispatch(
              adminOperation.getCounterpartsSearch({
                search: searchValue,
                role,
              }),
            )
          : dispatch(
              adminOperation.getCounterpartyData({
                page: page || 1,
                per_page: per_page || 10,
              }),
            );
        break;
      }
      case 'people': {
        isSearchNotEmpty
          ? dispatch(
              adminOperation.getPeopleSearch({
                search: searchValue,
                role,
              }),
            )
          : dispatch(
              adminOperation.getPeopleData({
                page: page || 1,
                per_page: per_page || 10,
              }),
            );
        break;
      }
      case 'order': {
        dispatch(
          adminOperation.getAdminOrderList({
            id: searchValue,
            page: 1,
            per_page: 10,
          }),
        );
        break;
      }
      case 'products': {
        dispatch(
          adminOperation.getAdminProducts({
            search: searchValue,
            page: 1,
            per_page: 10,
          }),
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCategoryChose = category_id => {
    dispatch(adminOperation.getAdminProducts({ category_id }));
    setSearchParams({
      category_id,
      page: 1,
      per_page: 10,
    });
  };



  return (
    <Component>
      <Form>
        <Label>
          <SearchInput
            onChange={onChange}
            placeholder={t('admin:search')}
            searchValue={searchValue}
          />
        </Label>
        <AdminButtons>
          {adminTab === 'people' && (
            <BasicSelect
              sx={{
                height: '36px',
                p: 0,
                width: accordionActive ? '196px' : '300px',
              }}
              height={'36px !important'}
              data={[{ id: 1, name: t('admin:business_placeholder') }]}
              name={t('admin:business_placeholder')}
            />
          )}
          {adminTab === 'people' && (
            <BasicSelect
              sx={{
                height: '36px',
                p: 0,
                width: accordionActive ? '196px' : '300px',
              }}
              height={'36px !important'}
              data={[{ id: 1, name: t('admin:counterparty_placeholder') }]}
              name={t('admin:counterparty_placeholder')}
            />
          )}
          {adminTab === 'counterparts' && (
            <BasicSelect
              sx={{ height: '36px', p: 0, width: '300px' }}
              height={'36px !important'}
              data={[{ id: 1, name: t('admin:business_placeholder') }]}
              name={t('admin:business_placeholder')}
            />
          )}
          {adminTab === 'products' && (
            <BasicSelect
              sx={{
                p: 0,
                width: '380px',
              }}
              group={true}
              currentId={
                categoryList(category)[0]?.id === +category_id
                  ? 'none'
                  : category_id
              }
              height={'36px !important'}
              data={categoryList(category)}
              name={t('common:buttons.add')}
              labelSx={{
                fontWeight: 400,
              }}
              callBack={handleCategoryChose}
            />
          )}

          {adminTab !== 'products' && (
            <>
              <OutlineButtonBase
                sx={{
                  height: '36px',
                  width: '100px',
                  alignItems: 'center',
                  gap: ' 4px',
                }}
                onClick={() => {}}
              >
                <svg height={16} width={16}>
                  <use href={`${Sprite}#icon-appearance`}></use>
                </svg>
                {t('admin:appearance')}
              </OutlineButtonBase>
              <ToggleBtn
                size={36}
                onClick={() => {}}
                href={`${Sprite}#icon-archive`}
              />
              <ToggleBtn
                size={36}
                onClick={() => {}}
                href={`${Sprite}#icon-filter`}
              />
            </>
          )}
        </AdminButtons>
      </Form>
    </Component>
  );
};

const AdminButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Component = styled.div`
  position: relative;
`;

const Form = styled.form`
  padding: 21px 20px;
  display: flex;
  align-items: center;
`;

const Svg = styled.svg``;

const Label = styled.label`
  display: block;
  position: relative;
  width: 100%;
  margin-right: 16px;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  transform: translate(0, 50%);

  display: flex;
`;

AdminSearch.propTypes = {
  accordionActive: PropTypes.bool,
};
