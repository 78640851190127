import { useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';

import { Typography, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';

export const Tabs = ({
  handleChange,
  text,
  id,
  active,
  handleById,
  setActiveId,
  type = 'static',
  path,
}) => {
  const { color } = useTheme();

  const { category, categoryChildren, lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const currentPath = `/catalog/${category}${
    categoryChildren ? '/' + categoryChildren : ''
  }`;

  const categoryIcon = () => {
    switch (path) {
      case 'prodej':
        return (
          <svg width={16} height={16} style={{ marginRight: '4px' }}>
            <use href={`${Sprite}#icon-persent`}></use>
          </svg>
        );
      case 'ulozene':
        return (
          <svg width={16} height={16} style={{ marginRight: '4px' }}>
            <use href={`${Sprite}#icon-star`}></use>
          </svg>
        );
      case 'novinky':
        return (
          <svg width={16} height={16} style={{ marginRight: '4px' }}>
            <use href={`${Sprite}#icon-fire`}></use>
          </svg>
        );
      default:
        return <Dots color={color.gray_60}></Dots>;
    }
  };

  const Wrapper = ({ children }) => {
    switch (type) {
      case 'static':
        return (
          <Link
            onClick={() => {
              handleById({
                id,
                page: 1,
                per_page: 10,
              });
              handleChange(text);
              setActiveId(id);
            }}
            style={{
              width: '100%',
              ...(active
                ? {
                    color: color.main,
                    stroke: color.main,
                  }
                : {
                    color: color.gray_80,
                    stroke: color.gray_80,
                  }),
            }}
          >
            {children}
          </Link>
        );

      case 'link':
        return (
          <NavLink
            to={currentLang + `/catalog/${path}?id=${id}&page=1&per_page=10`}
            onClick={() => {
              handleById();
              handleChange(text);
            }}
            style={({ isActive }) => ({
              width: '100%',
              ...(isActive || `/catalog/${path}` === currentPath
                ? {
                    color: color.main,
                    stroke: color.main,
                  }
                : {
                    color: color.gray_80,
                    stroke: color.gray_80,
                  }),
            })}
          >
            {children}
          </NavLink>
        );

      default:
        break;
    }
  };
  return (
    <Wrapper>
      {' '}
      <Typography
        variant={'bodyMedium'}
        sx={{
          padding: '8px 24px',
          display: 'flex',
          alignItems: 'center',
          color: 'inherit !important',
        }}
        component={'p'}
      >
        {categoryIcon()}
        <Text>{text}</Text>
      </Typography>
      <Divider />
    </Wrapper>
  );
};

const Text = styled.span`
  display: block;
  color: inherit !important;
`;

const Link = styled.div`
  display: block;
  cursor: pointer;
`;

const Dots = styled.span`
  width: 4px;
  height: 4px;
  background: ${props => props.color};
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 5px;
`;

Tabs.propTypes = {
  active: PropTypes.bool.isRequired,
  handleById: PropTypes.func.isRequired,
  setActiveId: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['static', 'link']),
  path: PropTypes.string,
};
