import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CircularProgress, useTheme } from '@mui/material';

export const ProgressLoader = ({ position = 'absolute' }) => {
  const { color } = useTheme();
  return (
    <Overlay position={position}>
      <CircularProgress sx={{ color: color.black }} />
    </Overlay>
  );
};

const Overlay = styled.div`
  position: ${({ position }) => position};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(43, 48, 66, 0.3);
  z-index: 10;
`;

ProgressLoader.propTypes = {
  position: PropTypes.string,
};
