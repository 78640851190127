export const handleDispatchFetch = (
  dispatchFetch,
  onSuccess,
  onFail,
  onEnd,
) => {
  new Promise((resolve, reject) => {
    dispatchFetch({ onResolve: resolve, onReject: reject });
  })
    .then(onSuccess)
    .catch(onFail)
    .finally(onEnd);
};
