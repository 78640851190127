import { useEffect, useState } from 'react';
import { CircleStencil } from 'react-advanced-cropper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { AvatarManager, InputLabel, PhoneConfirmation } from '@root/components';
import { getErrorStack, handleDispatchFetch, validEmail } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  BasicSelect,
  ButtonBase,
  DatePickerCustom,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import optionSelectors from '@redux/option/option-selectors';
import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const PersonalInfo = () => {
  const { color } = useTheme();
  const { t } = useTranslation(
    ['profile', 'validation', 'profile', 'registration', 'common'],
    { useSuspense: false },
  );
  const { navigation } = useNavigation();

  const dispatch = useDispatch();

  const cities = useSelector(optionSelectors.getCities);
  const userProfile = useSelector(userSelectors.getUserProfile);

  const [profile, setProfile] = useState({});
  const [error, setError] = useState({});
  const [avatar, setAvatar] = useState(null);

  document.title = t('profile:conf.personal_info.title');

  useEffect(() => {
    setProfile({
      ...userProfile,
      phones:
        userProfile.phones.length > 0
          ? userProfile.phones
          : [{ id: nanoid(), phone: null, confirm: null }],
    });
  }, [userProfile]);

  const handleOpenNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('profile:notifications.update_data_successfully'),
      }),
    );
  };

  const onChange = (name, subName) => value => {
    if (name === 'phone') {
      const dataCopy = JSON.parse(JSON.stringify(profile));
      dataCopy.phones[subName][name] = value;
      dataCopy.phones[subName].confirm = false;
      setProfile(dataCopy);
    } else {
      setProfile(prevProfile => ({ ...prevProfile, [name]: value }));
    }
  };

  const handleConfirmPhone = phone => {
    setProfile(prevProfile => ({
      ...prevProfile,
      phones: prevProfile.phones.map(el =>
        el.phone === phone ? { ...el, confirm: true } : el,
      ),
    }));
  };

  const handelAddPhoneInput = () => {
    setProfile(prevProfile => ({
      ...prevProfile,
      phones: [...prevProfile.phones, { phone: '', confirm: false }],
    }));
  };

  const handleSetImage = img => {
    setProfile(prevProfile => ({
      ...prevProfile,
      is_delete_file: !img,
      avatar: img,
    }));
    setAvatar(img);
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { userName, userEmail, phones } = profile;

    if (!userName || userName === '') {
      currentError = getErrorStack(
        currentError,
        'userName',
        t('validation:required'),
      );
      flag = true;
    } else if (userName.length < 2) {
      currentError = getErrorStack(
        currentError,
        'userName',
        t(`${t('validation:minLengthLetters')} 2`),
      );
      flag = true;
    }

    if (!userEmail || userEmail === '') {
      currentError = getErrorStack(
        currentError,
        'userEmail',
        t('validation:required'),
      );
      flag = true;
    }

    if (validEmail(userEmail) && userEmail !== '') {
      currentError = getErrorStack(
        currentError,
        'userEmail',
        t('validation:incorrect_email'),
      );
      flag = true;
    }

    let arrError = [];
    arrError = phones.reduce((acc, { phone }, i) => {
      if (!phone) {
        acc = [
          ...acc,
          {
            init: false,
            text: '',
          },
        ];
        return acc;
      }
      if (phone.replace('+', '').length === 12 || phone.length === 3) {
        acc = [
          ...acc,
          {
            init: false,
            text: '',
          },
        ];
      } else {
        acc = [
          ...acc,
          {
            init: true,
            text: t('validation:not_full_fill'),
          },
        ];
        flag = true;
      }
      return acc;
    }, []);

    currentError = { ...currentError, phones: arrError };

    setError(currentError);
    return flag;
  };

  const handleAlreadyRegisteredEmailError = errors => {
    let currentError = {};
    let errorsArray = Object.entries(errors);

    for (const [key, value] of errorsArray) {
      currentError = getErrorStack(currentError, key, value);
    }

    setError(currentError);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          userOperation.updateUserProfile({
            avatar: profile.avatar
              ? profile.avatar.includes('https')
                ? ''
                : profile.avatar
              : '',
            is_delete_file: profile.is_delete_file,
            birthday: profile.birthday,
            city_id: profile.city_id,
            phones: profile.phones,
            userName: profile.userName,
            userEmail: profile.userEmail,
            onResolve,
            onReject,
          }),
        ),
      () => {
        handleOpenNotification();
      },
      error => {
        handleAlreadyRegisteredEmailError(error.response.data.errors);
      },
    );
  };

  return (
    <>
      <PersonalInfoWrapper>
        <AvatarManagerWrapper>
          <AvatarManager
            img={profile?.avatar}
            handleSetImage={handleSetImage}
            stencilVariant={CircleStencil}
            outputImageOptions={{ width: 100, height: 100 }}
            title={t('registration:step_two.loader.title')}
          />
        </AvatarManagerWrapper>

        <Form onSubmit={handleSubmit}>
          <TextInput
            labelTextVariant={'bodyMedium'}
            label={t('profile:fields.name')}
            value={profile?.userName}
            onChange={onChange('userName')}
            placeholder={`${t('registration:field.name', { req: '*' })}`}
            error={error?.userName?.init}
            errorText={error?.userName?.text}
            max={50}
            sx={{
              height: '48px',
            }}
            required
            labelRowDirection={true}
            labelRowGap={'30px'}
            errorPositionLeft={'180px'}
          />

          <TextInput
            labelTextVariant={'bodyMedium'}
            label={'Email'}
            placeholder={'Email*'}
            value={profile.userEmail}
            onChange={onChange('userEmail')}
            error={error?.userEmail?.init}
            errorText={error?.userEmail?.text}
            max={255}
            sx={{
              height: '48px',
            }}
            required
            labelRowDirection={true}
            labelRowGap={'30px'}
            errorPositionLeft={'180px'}
          />

          {profile?.phones &&
            profile?.phones.map((phone_info, i) => (
              <PhoneConfirmation
                error={error?.phones && error?.phones[i]?.init}
                errorText={error?.phones && error?.phones[i]?.text}
                key={phone_info.id}
                sx={{
                  width: '100%',
                  height: '48px',
                  maxWidth: i > 0 ? '426px' : 'initial',
                  marginLeft: i > 0 ? 'auto' : 'initial',
                }}
                placeholder={t('registration:field.phone')}
                onChange={onChange('phone', i)}
                value={phone_info.phone}
                confirm={phone_info.confirm}
                changeConfirm={handleConfirmPhone}
                label={i === 0 ? t('profile:fields.telephone') : ''}
                labelRowDirection={true}
                labelRowGap={'30px'}
                labelTextVariant={'bodyMedium'}
              />
            ))}
          <InputWrapper>
            <EmptyTag />
            {profile?.phones?.length < 10 && (
              <OutlineButtonBase type="button" onClick={handelAddPhoneInput}>
                <Svg width="16" height="16">
                  <use href={`${Sprite}#icon-plus`} />
                </Svg>
                <Typography variant={'bodySmall'} align={'center'}>
                  {t('registration:step_two.add_phone_btn')}
                </Typography>
              </OutlineButtonBase>
            )}
          </InputWrapper>

          <InputWrapper>
            <InputLabel>{t('profile:fields.birthdate')}</InputLabel>
            <DatePickerCustom
              onChange={onChange(`birthday`)}
              value={profile?.birthday}
              placeholder={t('registration:field.birthday')}
              sx={{
                height: '48px',
              }}
            />
          </InputWrapper>
          <BasicSelect
            currentId={profile?.city_id}
            data={cities}
            name={t('registration:field.city')}
            callBack={onChange('city_id')}
            height="48px"
            label={t('profile:fields.city')}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            labelRowGap={'30px'}
          />
          <ButtonWrapper>
            <ButtonBase type="submit">{t('common:buttons.save')}</ButtonBase>
          </ButtonWrapper>
        </Form>
      </PersonalInfoWrapper>
    </>
  );
};

const PersonalInfoWrapper = styled.div``;

const InputWrapper = styled.div`
  display: grid;
  column-gap: 30px;
  grid-template-columns: 150px 1fr;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Svg = styled.svg``;

const EmptyTag = styled.div``;

const PhonesWrapper = styled.div``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
  max-width: 606px;
`;

const AvatarManagerWrapper = styled.div`
  max-width: 606px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 3px;
  min-width: 150px;
`;

const RequiredStarImg = styled.img``;
