import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Double_Check } from '@root/assets/svg';
import { EditButton, Empty } from '@root/components';
import { correctPhone } from '@root/helpers';
import { AvatarUI } from '@root/ui';

import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

export const ContactField = ({
  isEdit = false,
  lastStep,
  type = 'field',
  variant = 'bodyLarge',
  title,
  value,
  subValue,
  goToStep,
  padding = ' 12px 0',
  sx,
  discardChanges,
  applyChanges,
  titleStyles,
}) => {
  const getHead = type => {
    switch (type) {
      case 'img':
        return (
          <AvatarUI src={value}>
            <Empty
              type={'EmptyLogo'}
              isCompany={false}
              name={subValue}
              noAvatarSx={{
                fontSize: '2rem',
              }}
            />
          </AvatarUI>
        );

      case 'companyLogo':
        return value ? (
          <CompanyLogo src={value} alt="Logo Company" />
        ) : (
          <Empty
            type={'EmptyLogo'}
            isCompany
            tag={'div'}
            wrapperStyles={{
              width: '100%',
              maxWidth: '160px',
              height: '58px',
            }}
            contentStyles={{
              width: '100%',
              height: '100%',
            }}
          />
        );

      default:
        return (
          <Typography
            sx={{ flex: '0 0 174px', ...titleStyles }}
            variant={variant}
            component={'p'}
          >
            {title}
          </Typography>
        );
    }
  };

  const getDescription = type => {
    switch (type) {
      case 'phone':
        return (
          <WrapperBox>
            {value.map(({ phone, confirm }, i) => (
              <PhoneWrapper key={i + 200}>
                <Typography variant={variant} component={'p'}>
                  {lastStep
                    ? phone
                      ? correctPhone(phone)
                      : '-'
                    : correctPhone(phone)}
                </Typography>
                {confirm && <img src={Double_Check} alt="Double Check" />}
              </PhoneWrapper>
            ))}
          </WrapperBox>
        );

      case 'activity_areas':
        return (
          <WrapperBox>
            {value.map(({ name }) => (
              <PhoneWrapper>
                <Typography
                  sx={{ mr: '8px', ...sx }}
                  variant={'bodyLarge'}
                  component={'p'}
                >
                  {name}
                </Typography>
              </PhoneWrapper>
            ))}
          </WrapperBox>
        );

      case 'field':
        return (
          <WrapperText>
            <Typography
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                width: '203px',
                wordBreak: 'break-all',

                ...sx,
              }}
              variant={variant}
              component={'p'}
            >
              {type === 'area' ? '' : value}
            </Typography>
          </WrapperText>
        );

      default:
        break;
    }
  };

  return (
    <>
      <Wrapper
        align={
          type === 'phone' || type === 'activity_areas' ? 'start' : 'center'
        }
        padding={padding}
      >
        {getHead(type)}
        {getDescription(type)}
        {goToStep && (
          <EditButton
            goToStep={goToStep}
            isEdit={isEdit}
            applyChanges={applyChanges}
            discardChanges={discardChanges}
          />
        )}
      </Wrapper>
      {type === 'area' && (
        <Typography sx={{ mt: '12px' }} variant={variant} component={'p'}>
          {value}
        </Typography>
      )}
      <Divider />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: ${({ padding }) => padding};
  align-items: ${({ align }) => align};
  justify-content: space-between;
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 47px;
`;

const WrapperText = styled.div`
  margin-right: auto;
  margin-left: 47px;
  padding-right: 5px;
`;

const PhoneWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

const CompanyLogo = styled.img`
  height: 58px;
`;

ContactField.propTypes = {
  isEdit: PropTypes.bool,
  lastStep: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  subValue: PropTypes.string.isRequired,
  goToStep: PropTypes.func.isRequired,
  padding: PropTypes.string,
  sx: PropTypes.object,
  discardChanges: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
  titleStyles: PropTypes.object,
};
