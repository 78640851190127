import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { langConf } from '@root/conf';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: langConf,
    fallbackLng: 'cs',
    ns: [
      'common, header, login, registration, validation, admin, cart, catalog, error,order_history, order, saved, search',
    ],
    defaultNS: 'common',
    debug: false,
    detection: {
      lookupFromPathIndex: 1,
      order: ['localStorage', 'path'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
