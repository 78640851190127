import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme, size, variant, isActive }) => ({
  color: theme.color.gray_60,
  backgroundColor: theme.color.white,
  padding: theme.padding.outlineButton,
  textTransform: 'none',
  border: `1px solid ${theme.color.line} !important`,
  boxShadow: 'none !important',
  fill: isActive ? theme.color.white : theme.color.gray_60,
  stroke: theme.color.gray_60,
  '&:hover': {
    background: theme.color.gray_20,
    color: theme.color.gray_80,
    stroke: isActive ? theme.color.white : theme.color.gray_60,
  },
  '&:active': {
    background: theme.color.gray_20,
    color: theme.color.main,
    stroke: isActive ? theme.color.white : theme.color.gray_60,
  },
}));

export const OutlineButtonBase = ({
  children,
  isActive,
  type,
  onClick,
  size,
  sx,
  disabled,
}) => {
  return (
    <ColorButton
      variant="outlined"
      sx={{ ...sx }}
      type={type}
      isActive={isActive}
      onClick={onClick}
      size={size}
      disableRipple={true}
      disabled={disabled}
    >
      {' '}
      {children}
    </ColorButton>
  );
};

OutlineButtonBase.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  sx: PropTypes.object,
};
