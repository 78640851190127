import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { handleDispatchFetch } from '@root/helpers';
import { ButtonBase, IconBtn } from '@root/ui';

import cartOperation from '@redux/cart/cart-operation';

import { useTheme } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

export const QuantityManagerLarge = ({
  availableNumber,
  handleChoseItem,
  handleUpdateProductDetails,
  itemInCart,
  productCartData,
  itemPrice,
  isActive,
}) => {
  const dispatch = useDispatch();
  const [productQuantity, setProductQuantity] = useState(0);
  const [inputWidth, setInputWidth] = useState(50);

  const { t } = useTranslation('common', { useSuspense: false });
  const { color } = useTheme();

  const handleAddProduct = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.putOneToCart({
            id: productCartData.id,
            quantity: productQuantity + 1,
            onResolve,
            onReject,
          }),
        ),
      data => handleUpdateProductDetails(data, productCartData.id),
      error => {},
    );
  };

  const handleDeleteOneProduct = e => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.putOneToCart({
            id: productCartData.id,
            quantity: productQuantity - 1,
            onResolve,
            onReject,
          }),
        ),
      data => handleUpdateProductDetails(data, productCartData.id),
      error => {},
    );
  };

  const handleDeleteProduct = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.deleteOneFromCart({
            id: productCartData.id,
            onResolve,
            onReject,
          }),
        ),
      data => handleUpdateProductDetails(data, productCartData.id),
      error => {},
    );
  };

  const addToCArt = () => {
    productQuantity > availableNumber
      ? handleDispatchFetch(
          ({ onResolve, onReject }) =>
            dispatch(
              cartOperation.putOneToCart({
                id: productCartData.id,
                quantity: availableNumber,
                onResolve,
                onReject,
              }),
            ),
          data => handleUpdateProductDetails(data, productCartData.id),
          error => {},
        )
      : handleDispatchFetch(
          ({ onResolve, onReject }) =>
            dispatch(
              cartOperation.putOneToCart({
                id: productCartData.id,
                quantity: productQuantity,
                onResolve,
                onReject,
              }),
            ),
          data => handleUpdateProductDetails(data, productCartData.id),
          error => {},
        );
  };

  const handleEnter = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      addToCArt();
    }
  };
  const handleClickOutside = event => {
    event.preventDefault();
    addToCArt();
  };

  const handleSetNumberAddToCart = e => {
    setProductQuantity(Number.parseInt(e.target.value));
    setInputWidth(e.target.value.length * 8 + 34);
  };

  useEffect(() => {
    if (productCartData.quantity) {
      setProductQuantity(Number.parseInt(productCartData.quantity));
      setInputWidth(productCartData.quantity.toString().length * 8 + 34);
    }
  }, [productCartData]);

  const getButton = number => {
    switch (true) {
      case number === 0: {
        return (
          <ButtonBase
            sx={{
              width: '249px',
              height: '56px',
              stroke: 'white',
            }}
          >
            {t('common:report_availability')}
          </ButtonBase>
        );
      }
      case itemInCart: {
        return (
          <QuantityWrapper>
            {productCartData.quantity === 1 ? (
              <IconBtn
                style={{
                  justifyContent: 'center',
                  height: '56px',
                  width: '56px',
                  background: 'white',
                  display: 'flex',
                  borderRadius: '4px',
                  alignItems: 'center',
                }}
                size={24}
                onClick={handleDeleteProduct}
                href={`${Sprite}#icon-delete`}
              />
            ) : (
              <IconBtn
                style={{
                  justifyContent: 'center',
                  height: '56px',
                  width: '56px',
                  background: 'white',
                  display: 'flex',
                  borderRadius: '4px',
                  alignItems: 'center',
                }}
                size={24}
                onClick={handleDeleteOneProduct}
                href={`${Sprite}#icon-minus`}
              />
            )}
            <form onBlur={handleClickOutside}>
              <OutlinedInput
                id="phone-number-input"
                type="number"
                onKeyDown={handleEnter}
                sx={{
                  height: '56px',
                  background: 'white',
                  width: inputWidth,
                  '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                inputProps={{
                  notched: false,
                  min: 1,
                  style: {
                    minWidth: 60,
                    padding: 0,
                    paddingLeft: '10px',
                  },
                }}
                value={productQuantity}
                onChange={handleSetNumberAddToCart}
              />
            </form>
            <IconBtn
              style={{
                justifyContent: 'center',
                height: '56px',
                width: '56px',
                background: color.main,
                display: 'flex',
                borderRadius: '4px',
                alignItems: 'center',
                fill: 'white',
                stroke: 'white',
                position: 'relative',
                zIndex: 1,
              }}
              size={24}
              onClick={handleAddProduct}
              href={`${Sprite}#icon-plus`}
              disabled={productCartData.quantity === availableNumber}
            />
          </QuantityWrapper>
        );
      }
      case number > 0: {
        return (
          <ButtonBase
            sx={{
              gap: '10px',
              width: '180px',
              height: '56px',
              stroke: 'white',
            }}
            disabled={!isActive}
            onClick={handleChoseItem}
          >
            <svg width={24} height={24}>
              <use href={`${Sprite}#icon-grocery_cart`}></use>
            </svg>
            {t('common:buttons.buy')}
          </ButtonBase>
        );
      }

      default:
        break;
    }
  };

  return <>{getButton(availableNumber)}</>;
};

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 180px;
  height: 56px;
`;

QuantityManagerLarge.propTypes = {
  availableNumber: PropTypes.number.isRequired,
  handleChoseItem: PropTypes.func.isRequired,
  handleUpdateProductDetails: PropTypes.func.isRequired,
  itemInCart: PropTypes.bool,
  productCartData: PropTypes.exact({
    cart_product_id: PropTypes.number,
    id: PropTypes.number,
    personal_amount: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
    quantity: PropTypes.number,
  }),
  isActive: PropTypes.bool,
};
