import { digitsOnlyRegex, firstCharacterFromNumbersRegex } from '@root/conf';

export const sanitizeOnlyDigitsAndDot = value => {
  let sanitizedValue = '';
  let dotCount = 0;

  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (firstCharacterFromNumbersRegex.test(char)) {
      sanitizedValue += char;
    }
    if (char === '.' && dotCount < 1) {
      sanitizedValue += char;
      dotCount++;
    }
  }

  return sanitizedValue;
};

export const sanitizeOnlyDigits = value => {
  return value.replace(digitsOnlyRegex, '');
};
