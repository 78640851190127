import React from 'react';

import PropTypes from 'prop-types';

import { Mark } from '@root/assets/svg';
import { ColorlibStepIconRoot } from '@root/styles';
import { StepperBase } from '@root/ui';

import { Box, Typography, useTheme } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    display: 'none',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      display: 'none',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      display: 'none',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    display: 'none',
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={theme => ({
        color: theme.color.gray_60,
        width: 34,
        height: 34,
        background: 'transparent',
      })}
      sxActive={theme => ({
        color: theme.color.green,
        border: `1px solid ${theme.color.green} `,
        background: theme.color.green_light,
      })}
      sxComplete={theme => ({
        background: `${theme.color.green} !important`,
        border: `1px solid ${theme.color.green}`,
      })}
    >
      {completed ? (
        <img src={Mark} alt="Mark" />
      ) : (
        <Iterable ownerState={{ completed, active }}>{icon}</Iterable>
      )}
    </ColorlibStepIconRoot>
  );
}

const Iterable = styled('p')(({ theme, ownerState }) => {
  return {
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: 1.3,

    color: theme.color.gray_60,

    ...(ownerState.active && {
      color: theme.color.green,
    }),
  };
});

export const StepperBar = ({ conf, step, defection, text }) => {
  const { color } = useTheme();
  return (
    <Box sx={{ width: '100%' }}>
      <StepperBase
        conf={conf}
        step={step}
        defection={defection}
        text={(label, i) => (
          <Typography
            sx={{ ml: '4px' }}
            variant={'bodyMedium'}
            component={'p'}
            color={color.gray}
          >
            {label}
          </Typography>
        )}
        sxLabel={() => {
          return { padding: '0 0 28px 0' };
        }}
        connector={<QontoConnector />}
        iconComponent={ColorlibStepIcon}
      />
    </Box>
  );
};

StepperBar.propTypes = {
  conf: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  step: PropTypes.number.isRequired,
  defection: PropTypes.string,
  text: PropTypes.string,
};
