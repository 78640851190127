import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Action,
  Availability,
  CardDetails,
  Cost,
  ErrorAvailabilityProduct,
  ImgPrev,
  LabelProduct,
} from '@root/components';
import { CheckboxBase } from '@root/ui';

import {
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';

export const CartTable = ({
  conf,
  data,
  choseProduct,
  error,
  handelClickCard,
  accordionActive,
  choseItem,
  animationRef,
  handleActionChose,
  handleChoseMain,
  handelOpenSavedModal,
  addOrRemoveFromComparisons,
}) => {
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const { t } = useTranslation(['validation'], { useSuspense: false });
  const { color } = useTheme();
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const handelDetailCard = id => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };
  const stopPropagation = e => e.stopPropagation();
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: `calc(100% - 170px)`,
        overflowX: 'initial',
      }}
    >
      <Table size="small" aria-label="a dense table" sx={{ height: '100% ' }}>
        <TableHead>
          <TableRow sx={{ display: 'flex', p: '12px 16px' }}>
            {conf.map((el, i) => {
              if (el.hide && accordionActive) return '';
              return el.value === 'selectProduct' ? (
                <TableCell
                  key={'s' + i + el.label}
                  sx={{
                    borderBottom: 'none',
                    ...el.style,
                    p: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CheckboxBase
                    checked={choseProduct.length === data.length}
                    onChange={() =>
                      handleChoseMain(choseProduct.length === data.length)
                    }
                    width={16}
                  />
                  <Typography
                    variant={'bodyBoldSmall'}
                    component={'p'}
                    sx={{
                      color: color.gray_60,
                      marginLeft: '4px',
                    }}
                  >
                    {el.label}
                  </Typography>
                </TableCell>
              ) : (
                <TableCell
                  key={'s' + i + el.label}
                  sx={{
                    borderBottom: 'none',
                    ...el.style,
                    p: 0,
                  }}
                >
                  <Typography
                    variant={'bodyBoldSmall'}
                    component={'p'}
                    sx={{ color: color.gray_60 }}
                  >
                    {el.label}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <Divider />
        <TableBody
          colors={{ gray: color.gray_40, white: color.white, line: color.line }}
        >
          {data &&
            data.map((row, i) => (
              <>
                <TableRow
                  sx={{
                    borderBottom: `1px solid ${color.line}`,
                    th: {
                      borderBottom: 'none',
                    },
                    '&:last-child, &:last-child th': { borderBottom: 0 },
                  }}
                  key={'ror' + row.id + i}
                >
                  <TableRowInner>
                    {conf.map((el, idx) => {
                      switch (el?.value) {
                        case 'selectProduct':
                          return (
                            <NavLink
                              to={currentLang + row.url}
                              style={{ ...el.style }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                key={'sot' + row.id + idx}
                                sx={{
                                  p: 0,
                                  ...el.style,
                                  cursor: 'pointer',
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                                ref={animationRef}
                              >
                                <CheckboxBaseWrapper onClick={stopPropagation}>
                                  <CheckboxBase
                                    checked={choseProduct.includes(row.cart.id)}
                                    onChange={e => {
                                      handleActionChose(row.cart.id);
                                    }}
                                    width={16}
                                  />
                                </CheckboxBaseWrapper>
                                <ImgPrev
                                  src={row?.image}
                                  arr={row.images_quantity}
                                />
                              </TableCell>
                            </NavLink>
                          );

                        case 'name':
                          return (
                            <NavLink
                              to={currentLang + row.url}
                              style={{ ...el.style }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                key={'sot' + row.id + idx}
                                sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                                onClick={() => handelClickCard(row.url)}
                              >
                                <LabelProduct
                                  label={row?.name}
                                  parm={row?.model}
                                  article={row?.sku}
                                />
                              </TableCell>
                            </NavLink>
                          );

                        case 'cost':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'sot' + row.id + idx}
                              sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                              onClick={() => handelClickCard(row.url)}
                            >
                              <Cost data={row} />
                            </TableCell>
                          );
                        case 'availability':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'sot' + row.id + idx}
                              onClick={() => handelClickCard(row.url)}
                              sx={{
                                p: 0,
                                display: 'flex',
                                gap: '4px',
                                cursor: 'pointer',
                                ...el.style,
                              }}
                            >
                              <Availability
                                present={row?.stock_percent}
                                inStock={row?.stock}
                                notAvailable={row?.stock}
                                storage={row?.stock}
                              />
                            </TableCell>
                          );

                        case 'action':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'sot' + row.id + idx}
                              sx={{ p: 0, ...el.style }}
                            >
                              <Action
                                errorBorder={
                                  error.length > 0 &&
                                  error.find(err => err.id === row.id)
                                }
                                chooseProduct={row.cart}
                                isOpenDetail={
                                  openCard && choseIdCard === row.id
                                }
                                availableNumber={row?.stock}
                                handelDetailCard={() =>
                                  handelDetailCard(row.id)
                                }
                                productPrice={row.personal_amount}
                                handelOpenSavedModal={e =>
                                  handelOpenSavedModal(e, row.is_saved, row.id)
                                }
                                addOrRemoveFromComparisons={() =>
                                  addOrRemoveFromComparisons(
                                    row.id,
                                    row.in_compare,
                                  )
                                }
                                addedToCart={true}
                                isSaved={row.is_saved}
                                inCompare={row.in_compare}
                                onClick={() => choseItem(row)}
                                data={row}
                              />
                            </TableCell>
                          );
                        default:
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'sot' + row.id + idx}
                            >
                              {row.product[el.value]}
                            </TableCell>
                          );
                      }
                    })}
                  </TableRowInner>
                </TableRow>
                {openCard && row.id === choseIdCard && (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ p: 0, width: '100%', display: 'block' }}
                    >
                      <CardDetails
                        id={choseIdCard}
                        handelOpenSavedModal={handelOpenSavedModal}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {error.length > 0 &&
                  error.map(
                    err =>
                      err.id === row.id && (
                        <>
                          <TableRow>
                            {' '}
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ p: 0, width: '100%', display: 'block' }}
                            >
                              <ErrorAvailabilityProduct
                                text={err?.error}
                                url={row?.url}
                                has_analogs={err?.has_analogs}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ),
                  )}
              </>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableRowInner = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 16px;
`;

const CheckboxBaseWrapper = styled.div``;

const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);

  overflow-y: auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.colors.gray};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.colors.white};
    border-left: 1px solid ${props => props.colors.line};
  }
`;

CartTable.propTypes = {
  conf: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      style: PropTypes.object,
      type: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(
    PropTypes.exact({
      brand: PropTypes.string,
      can_buy: PropTypes.exact({
        price: PropTypes.bool,
        price_cron: PropTypes.bool,
        price_cron_no_dph: PropTypes.bool,
        price_no_dph: PropTypes.bool,
      }),
      cart: PropTypes.exact({
        cart_product_id: PropTypes.number,
        id: PropTypes.number,
        personal_amount: PropTypes.exact({
          price: PropTypes.string,
          price_cron: PropTypes.string,
          price_cron_no_dph: PropTypes.string,
          price_no_dph: PropTypes.string,
        }),
        quantity: PropTypes.number,
      }),
      cart_product_id: PropTypes.number,
      description: PropTypes.string,
      has_analogs: PropTypes.bool,
      id: PropTypes.number,
      image: PropTypes.string,
      images_quantity: PropTypes.number,
      is_in_cart: PropTypes.bool,
      is_saved: PropTypes.bool,
      model: PropTypes.string,
      name: PropTypes.string,
      personal_price: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      price: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      prices: PropTypes.exact({
        price: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_cron: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_cron_no_dph: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_no_dph: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
      }),
      quantity: PropTypes.number,
      short_description: PropTypes.string,
      sku: PropTypes.string,
      slug: PropTypes.string,
      stock: PropTypes.number,
      stock_max: PropTypes.number,
      stock_percent: PropTypes.number,
      url: PropTypes.string,
      warehouses: PropTypes.arrayOf(
        PropTypes.exact({
          city_name: PropTypes.string,
          warehouses: PropTypes.exact({
            name: PropTypes.string,
            stock: PropTypes.number,
            stock_max: PropTypes.number,
            stock_percent: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
  choseProduct: PropTypes.arrayOf(PropTypes.number),
  error: PropTypes.object,
  handelClickCard: PropTypes.func.isRequired,
  accordionActive: PropTypes.bool,
  choseItem: PropTypes.func.isRequired,
  animationRef: PropTypes.node,
  handleActionChose: PropTypes.func.isRequired,
  handleChoseMain: PropTypes.func.isRequired,
  handelOpenSavedModal: PropTypes.func.isRequired,
};
