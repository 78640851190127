import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

export const MenuItemCustoms = ({
  handelClick,
  children,
  styled,
  selected,
  colorText,
}) => {
  const { color } = useTheme();

  return (
    <MenuItem
      color={'secondary'}
      sx={{
        padding: '4px 5px',
        borderRadius: '2px',
        color: colorText ? colorText : `${color.gray}`,
        stroke: `${color.gray_80}!important`,
        fill: `${color.gray_80}!important`,

        '&:hover': {
          backgroundColor: `${color.gray_20} `,
          color: colorText ? colorText : `${color.gray}`,
        },
        '&:active': {
          backgroundColor: `${color.main}!important`,
          color: `${color.white} !important`,
        },
        '&.Mui-selected': {
          backgroundColor: `${color.main}!important`,
          color: `${color.white} !important`,
        },
        ...styled,
      }}
      onClick={handelClick}
      selected={selected}
    >
      {children}
    </MenuItem>
  );
};

MenuItemCustoms.propTypes = {
  handelClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  styled: PropTypes.object,
  selected: PropTypes.bool,
  colorText: PropTypes.string,
};
