import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';

export const Loader = () => {
  return (
    <Overlay>
      <CircularProgress />
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(43, 48, 66, 0.8);
  z-index: 1200;
`;
