import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const HistoryContext = createContext();

export const History = ({ children }) => {
  const [history, setHistory] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    setHistory([...history, pathname]);
  }, [pathname]);

  return (
    <HistoryContext.Provider value={history}>
      {children}
    </HistoryContext.Provider>
  );
};
