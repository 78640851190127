import React from 'react';
import EllipsisText from 'react-ellipsis-text';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Cost, ImgPrev, QuantityManager } from '@root/components';
import { IconBtn, ToggleBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const ComparisonsCardDetails = ({
  data,
  handelOpenSavedModal,
  handleAddToCart,
  removeProductFromComparisons,
}) => {
  const { color } = useTheme();
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  return (
    <CardWrap>
      <CardImgWrap>
        <NavLink to={currentLang + data?.url}>
          <ImgPrev src={data?.image} countImg={data?.images_quantity} />
        </NavLink>
      </CardImgWrap>
      <CardMainWrap>
        <CardHeader>
          <CardHeaderWrap>
            <NavLink to={currentLang + data?.url}>
              <Typography variant={'bodyBoldSmall'} color={color.gray}>
                <EllipsisText text={data?.model} length={'60'} />
              </Typography>
            </NavLink>
            <Typography variant={'bodySmall'} color={color.gray}>
              {data?.sku}
            </Typography>
          </CardHeaderWrap>
          <IconBtn
            size="16px"
            href={`${Sprite}#icon-cross`}
            onClick={() => removeProductFromComparisons(data.id)}
          />
        </CardHeader>
        <CardBody>
          <div style={{ cursor: 'pointer' }}>
            <Cost data={data} />
          </div>
          <IconBtn
            href={`${Sprite}#icon-hart`}
            onClick={e => handelOpenSavedModal(e, data.is_saved, data.id)}
            size={16}
            saved={data.is_saved}
            sx={{ margin: '0 40px 0 30px', position: 'relative', top: '-2px' }}
          />
          {data.is_in_cart ? (
            <QuantityManager data={data} hide_amount />
          ) : (
            <Wrapper margin={'16px'}>
              <ToggleBtn
                disabled={!data.can_buy[choosedCurrency]}
                onClick={e => handleAddToCart(e, data)}
                size={32}
                isActive={data.can_buy[choosedCurrency]}
                href={`${Sprite}#icon-grocery_cart`}
              />
            </Wrapper>
          )}
        </CardBody>
      </CardMainWrap>
    </CardWrap>
  );
};

const CardWrap = styled.div`
  display: flex;
  align-items: center;
`;
const CardImgWrap = styled.div`
  align-self: flex-start;
  margin: -5px 8px 0 0;
`;
const CardMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;
const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 14px;
`;
const CardHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardBody = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  width: initial;
`;
