import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@root/styles';

import { Typography } from '@mui/material';

export const StockBar = ({ warehouse, index }) => {
  const subColor =
    warehouse.stock_percent > 70
      ? theme.color.green_light
      : warehouse.stock_percent <= 30
      ? theme.color.red_light
      : theme.color.orange_light;
  const backgroundColor =
    warehouse.stock_percent > 70
      ? theme.color.green
      : warehouse.stock_percent <= 30
      ? theme.color.red
      : theme.color.orange;

  return (
    <CityRegionInfo
      style={{
        borderRight: index <= 3 ? `1px dashed ${theme.color.line_light}` : '',
      }}
    >
      <Bar style={{ background: subColor }}>
        <BarPercentValue
          style={{
            height: `${warehouse.stock_percent}%`,
            backgroundColor,
          }}
        ></BarPercentValue>
      </Bar>
      <Typography
        variant={'bodySmall'}
        sx={{
          width: '29px',
          color: theme.color.gray,
        }}
      >
        {warehouse.stock > 999 ? 999 + '+' : warehouse.stock}
      </Typography>
      <Typography
        variant={'bodySmall'}
        sx={{
          width: '110px',
          color: theme.color.gray,
        }}
      >
        - {warehouse.name}
      </Typography>
    </CityRegionInfo>
  );
};

const CityRegionInfo = styled.div`
  display: flex;
  width: 160px;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px 4px 0;
  margin-right: 14px;
`;

const Bar = styled.div`
  height: 12px;
  width: 4px;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 6px;
  position: relative;
`;

const BarPercentValue = styled.div`
  position: absolute;
  bottom: 0;
  height: 0;
  transition: margin 0.5s;
  width: 4px;
  border-radius: 0 0 2px 2px;
`;

StockBar.propTypes = {
  warehouse: PropTypes.exact({
    name: PropTypes.string.isRequired,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number.isRequired,
  }),
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
