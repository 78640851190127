export const AdminProductsConf = t => [
  {
    label: t('common:table.table_heads.product'),
    value: 'product',
    type: 'product',
    style: { ml: '16px', minWidth: '105px' },
  },
  {
    label: t('common:table.table_heads.name'),
    value: 'name',
    type: 'name',
    sort: true,
    style: { ml: '16px', width: '100%', minWidth: '300px' },
  },
  {
    label: t('common:table.table_heads.euro_price'),
    value: 'price',
    type: 'price',
    sort: true,
    style: { ml: '16px', width: '100%', maxWidth: '105px' },
  },
  {
    label: t('common:table.table_heads.krona_price'),
    value: 'price_cron',
    type: 'price_cron',
    sort: true,
    style: { ml: '16px', width: '100%', maxWidth: '105px' },
  },
  {
    label: t('common:table.table_heads.availability'),
    value: 'stock',
    type: 'stock',
    sort: true,
    style: { ml: '16px', width: '100%', maxWidth: '105px' },
  },
  {
    label: '',
    value: 'actions',
    type: 'actions',
    style: { ml: '16px', minWidth: '136px' },
  },
];
