import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Mark_Big } from '@root/assets/svg';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const SuccessfullyMessage = ({ email }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['registration'], { useSuspense: false });
  const emailUser = useRef(email);

  return (
    <Content>
      <WrapperImage
        bgcolor={color.green}
        src={Mark_Big}
        alt="Successfully Icon"
      >
        <img src={Mark_Big} alt="Mark Big" width={'28'} height={'21'} />
      </WrapperImage>

      <Typography variant={'h1'} sx={{ mt: '20px' }}>
        {t('registration:successfully.tittle')}
      </Typography>

      <Typography
        variant={'bodyLarge'}
        sx={{ mt: '20px', maxWidth: '470px' }}
        component={'p'}
      >
        {t('registration:successfully.slogan')}
        <Typography variant={'bodyLarge'} sx={{ color: color.main }}>
          {' '}
          {emailUser.current}
        </Typography>
      </Typography>
    </Content>
  );
};

const Content = styled.div`
  margin-top: 245px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const WrapperImage = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 50%;
`;

SuccessfullyMessage.propTypes = {
  email: PropTypes.string,
};
