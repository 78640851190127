import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { useOrder } from '@root/hooks';
import { ButtonBase, ModalBase, OutlineButtonBase } from '@root/ui';

import orderOperation from '@redux/order/order-operation';

import { Typography } from '@mui/material';

import { FormPersonalInfo } from '../FormPersonalInfo';
import { servicesValidation } from '../FormPersonalInfo/DeliveryClientInfo/Validation/validation';

export const ModalPersonalInfo = ({
  open,
  toggleOpenModal,
  available_methods,
  currentObj,
  flag = 'New',
  openAllList,
}) => {
  const {
    onChange,
    error,
    onChangeRadio,
    handleSelectFilial,
    handleCardRadio,
    validation,
    data,
    setData,
  } = useOrder({ servicesValidation, available_methods });

  const { t } = useTranslation(['common', 'placing_order'], {
    useSuspense: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (flag === 'New') return;
    const { name, email, surname, phone, delivery_info, is_drop } =
      currentObj.info;
    const { id, key } = currentObj;
    switch (key) {
      case 'Self_pickup':
        setData({
          ...data,
          name,
          surname,
          phone,
          email,
          delivery_type: key,
          delivery_filial: delivery_info.filial_id,
          id,
        });
        break;
      case 'Address':
        setData({
          ...data,
          name,
          surname,
          phone,
          email,
          delivery_type: key,
          delivery_info: { ...delivery_info },
          its_drop: is_drop,
          id,
        });
        break;
      case 'DPD_branch':
        setData({
          ...data,
          name,
          surname,
          phone,
          email,
          delivery_type: key,
          delivery_info: delivery_info,
          its_drop: is_drop,
          id,
        });
        break;
      default:
        break;
    }
  }, []);

  const handelSubmit = e => {
    e.preventDefault();
    if (validation()) return;
    switch (flag) {
      case 'edit':
        dispatch(
          orderOperation.editSaveOrderTemplate({
            orderData: data,
            entity: 'delivery',
            id: data.id,
          }),
        );
        break;

      default:
        dispatch(
          orderOperation.addSaveOrderTemplate({
            orderData: data,
            entity: 'delivery',
          }),
        );
        break;
    }

    toggleOpenModal();
    openAllList();
  };

  return (
    <ModalBase
      open={open}
      handleClose={toggleOpenModal}
      sx={{
        maxWidth: '600px',
        width: '100%',
      }}
      modalHeader={
        <HeaderDefault
          text={t(
            'placing_order:modals.template_for_receiving_order_modal.template_for_receiving_order',
          )}
          onClose={toggleOpenModal}
        />
      }
      modalBottom={
        <WrapperBtn>
          <OutlineButtonBase sx={{ p: '11px 8px' }} onClick={toggleOpenModal}>
            <Typography variant={'bodyBoldSmall'}>
              {t('common:buttons.cancel')}
            </Typography>
          </OutlineButtonBase>

          <ButtonBase
            onClick={handelSubmit}
            type="submit"
            size={'large'}
            sx={{
              minWidth: '68px',
              height: '38px',
              ml: '10px',
              p: '11px 8px',
            }}
          >
            <Typography variant={'bodyBoldSmall'}>
              {t('common:buttons.save')}
            </Typography>
          </ButtonBase>
        </WrapperBtn>
      }
    >
      <Wrapper>
        <FormPersonalInfo
          data={data}
          error={error}
          onChange={onChange}
          onChangeRadio={onChangeRadio}
          handleSelectFilial={handleSelectFilial}
          handleCardRadio={handleCardRadio}
          setData={setData}
          available_methods={available_methods}
        />
      </Wrapper>
    </ModalBase>
  );
};

const WrapperBtn = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 20px;
`;

const Wrapper = styled.div`
  min-height: 601px;
`;

ModalPersonalInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpenModal: PropTypes.func.isRequired,
  available_methods: PropTypes.exact({
    delivery_types: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        items: PropTypes.arrayOf(
          PropTypes.exact({
            icon: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            key: PropTypes.string,
            subTitle: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        key: PropTypes.string,
        label: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    payment_types: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        key: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
  currentObj: PropTypes.object,
  flag: PropTypes.string,
  openAllList: PropTypes.func.isRequired,
};
