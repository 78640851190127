import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { updateAdminOrder } from '../admin/admin-slice';

const getOrder = createAsyncThunk(
  'order/getOrder',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//*******OrderTemplate**********//
const getSaveOrderTemplate = createAsyncThunk(
  'order/getSaveOrderTemplate',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/saved-payment-delivery');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addSaveOrderTemplate = createAsyncThunk(
  'order/addSaveOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const { orderData, entity } = payload;
    try {
      const { data } = await axios.post(
        `order/saved-payment-delivery/${entity}`,
        orderData,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const editSaveOrderTemplate = createAsyncThunk(
  'order/editSaveOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const { orderData, entity, id } = payload;
    try {
      const { data } = await axios.put(
        `order/saved-payment-delivery/${entity}/${id}`,
        orderData,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const changePinnedTemplate = createAsyncThunk(
  'order/changePinnedTemplate',
  async (payload, { rejectWithValue }) => {
    const { entity, id, pin } = payload;
    try {
      const { data } = await axios.put(
        `order/saved-payment-delivery/${entity}/${id}?pin=${pin}`,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const deleteTemplate = createAsyncThunk(
  'order/deleteTemplate',
  async (payload, { rejectWithValue }) => {
    const { entity, id } = payload;
    try {
      const { data } = await axios.delete(
        `order/saved-payment-delivery/${entity}/${id}`,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

//*******EndOrderTemplate**********//

const confirmOrder = createAsyncThunk(
  'order/confirmOrder',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put('order', payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const getFilialList = createAsyncThunk(
  'order/getFilialList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('filial/list');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHistoryOrder = createAsyncThunk(
  'order/getHistoryOrder',
  async ({ page, search, filterActive }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/history', {
        params: { page, search, filterActive },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHistoryOrderBySearch = createAsyncThunk(
  'order/getHistoryOrderBySearch',
  async ({ page, search, filterActive }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/history', {
        params: { page, search, filterActive },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHistoryOrderById = createAsyncThunk(
  'order/getHistoryOrderById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`order/history/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const cloneHistoryOrderById = createAsyncThunk(
  'order/cloneHistoryOrderById',
  async ({ id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`order/history/${id}/clone`);
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

//*******OrderAdmin**********//

const orderAdminByID = createAsyncThunk(
  'order/orderAdminByID',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`order/admin/${id}`);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const orderAdminUpdateStatus = createAsyncThunk(
  'order/orderAdminUpdateStatus',
  async ({ id, status }, thunkApi) => {
    try {
      const { data } = await axios.put(`order/admin/${id}`, {
        status: status,
      });
      thunkApi.dispatch(updateAdminOrder(data.data));
      return data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export default {
  getOrder,
  getSaveOrderTemplate,
  getFilialList,
  confirmOrder,
  getHistoryOrder,
  getHistoryOrderById,
  getHistoryOrderBySearch,
  cloneHistoryOrderById,
  changePinnedTemplate,
  deleteTemplate,
  addSaveOrderTemplate,
  editSaveOrderTemplate,
  orderAdminByID,
  orderAdminUpdateStatus,
};
