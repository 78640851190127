import { createSlice } from '@reduxjs/toolkit';

import {
  updateComparison,
  updateFavorite,
  updateProducts,
} from '@root/helpers';

import cartOperation from '../cart/cart-operation';
import comparisonsOperation from '../comparisons/comparisons-operation';
import favoriteOperation from '../favorite/favorite-operation';
import operation from './search-operation';

const initialState = {
  loading: false,
  searchData: null,
  searchPageData: null,
  totalSearch: 0,
  paramSearch: '',
  searchMeta: {},
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch(state, { payload }) {
      state.searchData = null;
      state.paramSearch = '';
      state.totalSearch = {};
    },
  },
  extraReducers: {
    [favoriteOperation.postProductToFavorite.fulfilled]: (
      state,
      { payload },
    ) => {
      if (state.searchPageData) {
        const { id } = payload;
        state.searchPageData = updateFavorite(state.searchPageData, id);
      }
    },
    [favoriteOperation.deleteProductFromSaved.fulfilled]: (
      state,
      { payload },
    ) => {
      if (state.searchPageData) {
        const { id } = payload;
        state.searchPageData = updateFavorite(state.searchPageData, id);
      }
    },
    [comparisonsOperation.addToComparisons.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      state.cartData = updateComparison(state.searchPageData, id);
    },
    [comparisonsOperation.removeFromComparisons.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.cartData = updateComparison(state.searchPageData, id);
    },
    [cartOperation.addProductToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      if (state.searchPageData) {
        state.searchPageData = updateProducts(state.searchPageData, data.data);
      }
      if (state.searchData) {
        state.searchData = updateProducts(state.searchData, data.data);
      }
    },
    [cartOperation.putOneToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      if (state.searchPageData) {
        state.searchPageData = updateProducts(state.searchPageData, data.data);
      }
      if (state.searchData) {
        state.searchData = updateProducts(state.searchData, data.data);
      }
    },
    [cartOperation.deleteOneFromCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      if (state.searchPageData) {
        state.searchPageData = updateProducts(state.searchPageData, data.data);
      }
      if (state.searchData) {
        state.searchData = updateProducts(state.searchData, data.data);
      }
    },
    //====getProductsBySearch====//
    [operation.getProductsBySearch.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getProductsBySearch.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.searchData = payload.data;
      state.totalSearch = payload.meta.total;
      state.paramSearch = payload.search;
    },
    [operation.getProductsBySearch.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getProductsBySearch====//
    [operation.getProductsBySearchForPage.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getProductsBySearchForPage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.searchPageData = payload.data;
      state.searchMeta = payload.meta;
      state.paramSearch = payload.search;
    },
    [operation.getProductsBySearchForPage.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const { clearSearch } = searchSlice.actions;

export default searchSlice.reducer;
