import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar } from '@root/assets/svg';
import { BaseModalHeader } from '@root/components';
import { BasicSelect, ModalBase, TextInput } from '@root/ui';

import { CircularProgress, Typography, useTheme } from '@mui/material';

export const WarehousesModal = ({
  open,
  close,
  isActionButtons,
  isSearch,
  defPadding,
  data,
  createOrUpdate,
  loading,
}) => {
  const [editData, setEditData] = useState({});

  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();
  useEffect(() => {
    const cloneData = JSON.parse(JSON.stringify(data));
    setEditData(cloneData);
  }, [data]);

  const setCurrentCity = val => {
    setEditData(prevState => {
      return {
        ...prevState,
        city_id: val,
      };
    });
  };

  const onChange = name => value => {
    setEditData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={close}
      sx={{
        maxWidth: '857px',
        width: '100%',
        height: 'auto',
        padding: '25px 20px',
      }}
      modalHeader={
        <BaseModalHeader
          mainTitle={`${t(
            'catalog:modals.settings_current_warehouse_modal.settings_current_warehouse',
          )}: ${editData.name || ''}`}
          closeModalCallback={close}
          isActionButtons={isActionButtons}
          isSearch={isSearch}
          toolbarSx={{
            padding: `${defPadding} !important`,
            minHeight: '100% !important',
          }}
          defPadding={'0px'}
          saveOrUpdate={() => createOrUpdate(editData)}
        />
      }
    >
      <ContentWrap>
        <CustomLabel>
          <Typography
            variant={'bodySmall'}
            sx={{
              color: color.gray,
            }}
            component={'span'}
          >
            {t('catalog:modals.settings_current_warehouse_modal.fields.city')}
          </Typography>
          <BasicSelect
            currentId={editData.city_id || 'none'}
            callBack={setCurrentCity}
            data={editData.cities}
            height={36}
            sx={{
              width: '100%',
            }}
          />
        </CustomLabel>
        <CustomLabel>
          <CustomLabelSubHeader>
            <CustomImg src={RequiredStar} />
            <Typography
              variant={'bodySmall'}
              sx={{
                color: color.gray,
              }}
              component={'span'}
            >
              {t(
                'catalog:modals.settings_current_warehouse_modal.fields.sort_order',
              )}
            </Typography>
          </CustomLabelSubHeader>
          <TextInput
            digitsOnly
            onChange={onChange('sort')}
            sx={{
              height: '36px',
            }}
            value={editData.sort}
          />
        </CustomLabel>
        <Typography
          variant={'bodyBoldLarge'}
          sx={{
            color: color.gray,
          }}
          component={'p'}
        >
          {t('catalog:modals.settings_current_warehouse_modal.warehouse_name')}
        </Typography>
        <CustomLabel>
          <CustomLabelSubHeader>
            <CustomImg src={RequiredStar} />
            <Typography
              variant={'bodySmall'}
              sx={{
                color: color.gray,
              }}
              component={'span'}
            >
              {t(
                'catalog:modals.settings_current_warehouse_modal.fields.type_warehouse_public_name',
              )}
            </Typography>
          </CustomLabelSubHeader>
          <TextInput
            onChange={onChange('public_name')}
            sx={{
              height: '36px',
            }}
            value={editData.public_name}
          />
        </CustomLabel>
        <CustomLabel>
          <CustomLabelSubHeader>
            <CustomImg src={RequiredStar} />
            <Typography
              variant={'bodySmall'}
              sx={{
                color: color.gray,
              }}
              component={'span'}
            >
              {t('catalog:modals.settings_current_warehouse_modal.fields.guid')}
            </Typography>
          </CustomLabelSubHeader>
          <TextInput
            disabled
            sx={{
              height: '36px',
              '.Mui-disabled': {
                '-webkit-text-fill-color': color.gray,
              },
            }}
            value={editData.guid}
            withOutSpace={true}
          />
        </CustomLabel>
        {loading && (
          <Overlay>
            <CircularProgress
              sx={{
                color: color.main,
              }}
            />
          </Overlay>
        )}
      </ContentWrap>
    </ModalBase>
  );
};

const CustomImg = styled.img`
  margin-right: 1px;
  align-self: baseline;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const CustomLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
`;

const CustomLabelSubHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

WarehousesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isActionButtons: PropTypes.bool,
  isSearch: PropTypes.bool,
  defPadding: PropTypes.string,
  data: PropTypes.exact({
    cities: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    city_id: PropTypes.number,
    guid: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    public_name: PropTypes.string,
    sort: PropTypes.number,
  }),
  createOrUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
