import axios from 'axios';

import { useNavigation } from '@root/hooks';

export const Guard = ({ children }) => {
  const { navigation } = useNavigation();

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      switch (error.response.status) {
        case 401:
          window.location.reload();
          break;
        case 404:
          navigation('/error-404');
          break;
        case 403:
          navigation('/error-403');
          break;
      }

      return Promise.reject(error);
    },
  );

  return <>{children}</>;
};
