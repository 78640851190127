import { createSlice } from '@reduxjs/toolkit';

import productOperation from '../product/product-operation';
import operation from './category-operation';

const initialState = {
  loading: false,
  category: [],
  filters: [],
  stock: {},
  categoryListToEdit: [],
  manageFiltersList: [],
  manageWarehousesList: [],
  manageWarehouseEditData: {},
  listWarehouses: [],
  searchValue: '',
  meta: null,
  warehouseId: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    recordSearchData(state, action) {
      state.searchValue = action.payload;
    },
    clearWarehouseEditData(state, { payload }) {
      state.manageWarehouseEditData = initialState.manageWarehouseEditData;
    },
    choseWarehousesHandler(state, { payload }) {
      const { nestedId } = payload;
      state.warehouseId = nestedId;
    },
  },
  extraReducers: {
    // ================================Global-Store-Listeners=============================//
    //====setProduct====//
    [productOperation.getProduct.fulfilled]: (state, { payload }) => {
      const { filters, stock } = payload;
      state.filters = filters;
      state.stock = stock;
    },
    //====setProductByEditId====//
    [productOperation.getProductByEditId.fulfilled]: (state, { payload }) => {
      const { categories } = payload.data;
      state.categoryListToEdit = categories;
    },
    // ================================End-Global-Store-Listeners=============================//
    //====getCatalog====//
    [operation.getCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.category = payload;
    },
    [operation.getCategory.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getFilersCategory====//
    [operation.getFilersCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.filters = payload;
    },
    //====getCategoryListToAdmin====//
    [operation.getCategoryListToAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getCategoryListToAdmin.fulfilled]: (state, { payload }) => {
      state.categoryListToEdit = payload;
    },
    [operation.getCategoryListToAdmin.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====postCategoryList====//
    [operation.postCategoryList.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.postCategoryList.fulfilled]: (state, { payload }) => {
      state.categoryListToEdit = [];
      state.category = payload;
    },
    [operation.postCategoryList.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //===getManageFilters===//
    [operation.getManageFilters.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getManageFilters.fulfilled]: (state, { payload }) => {
      state.manageFiltersList =
        payload.meta.current_page === 1
          ? [...payload.data]
          : [...state.manageFiltersList, ...payload.data];
      state.meta = payload.meta;
    },
    [operation.getManageFilters.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //===postNewFilter===//
    [operation.postNewFilter.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.postNewFilter.fulfilled]: (state, { payload }) => {
      state.manageFiltersList = payload.data;
    },
    [operation.postNewFilter.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //===removeFilter==//
    [operation.removeFilter.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.removeFilter.fulfilled]: (state, { payload }) => {
      state.manageFiltersList = payload.data;
      state.meta = payload.meta;
    },
    [operation.removeFilter.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //===getManageWarehouses===//
    [operation.getManageWarehouses.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getManageWarehouses.fulfilled]: (state, { payload }) => {
      state.manageWarehousesList = payload.data;
    },
    [operation.getManageWarehouses.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //===editManageWarehouse===//
    [operation.editManageWarehouse.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.editManageWarehouse.fulfilled]: (state, { payload }) => {
      state.manageWarehouseEditData = payload.data;
      state.loading = false;
    },
    [operation.editManageWarehouse.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //===UpdateManageWarehouse===//
    [operation.updateManageWarehouseData.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.updateManageWarehouseData.fulfilled]: (state, { payload }) => {
      state.manageWarehousesList = payload.data;
    },
    [operation.updateManageWarehouseData.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //===RemoveManageWarehouse===//
    [operation.removeManageWarehouseById.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.removeManageWarehouseById.fulfilled]: (state, { payload }) => {
      state.manageWarehousesList = payload.data;
    },
    [operation.removeManageWarehouseById.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //======getListWarehouses=====//
    [operation.getListWarehouses.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getListWarehouses.fulfilled]: (state, { payload }) => {
      state.listWarehouses = payload;
    },
    [operation.getListWarehouses.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});
export const {
  recordSearchData,
  clearWarehouseEditData,
  choseWarehousesHandler,
} = categorySlice.actions;
export default categorySlice.reducer;
