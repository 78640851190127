import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { QuantityManager } from '@root/components/Managers/QuantityManager';
import { IconBtn, ToggleBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';

export const Action = ({
  onClick,
  handelDetailCard,
  addedToCart,
  isOpenDetail,
  chooseProduct,
  addAnimation,
  handelOpenSavedModal,
  isSaved,
  inCompare,
  relation_quantity,
  errorBorder,
  data,
  addOrRemoveFromComparisons,
  isHideArrowCard = false,
}) => {
  const QuantityMemo = useMemo(
    () => () =>
      (
        <QuantityManager
          errorBorder={errorBorder}
          addAnimation={addAnimation}
          data={data}
        />
      ),
    [chooseProduct, errorBorder],
  );

  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  return (
    <FlexContainer>
      <Box>
        {addedToCart ? (
          QuantityMemo()
        ) : (
          <>
            <ToggleBtnWrapper onClick={e => e.preventDefault()}>
              <ToggleBtn
                onClick={onClick}
                size={32}
                disabled={!data.can_buy[choosedCurrency]}
                isActive={data.can_buy[choosedCurrency]}
                href={`${Sprite}#icon-grocery_cart`}
              />
            </ToggleBtnWrapper>
            {relation_quantity && (
              <Typography
                sx={{ ml: '8px' }}
                variant={'bodyBoldSmall'}
                component={'p'}
              >
                x{relation_quantity}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Controllers onClick={e => e.preventDefault()}>
        <IconBtn
          href={`${Sprite}#icon-hart`}
          onClick={handelOpenSavedModal}
          size={16}
          saved={isSaved}
        />

        <IconBtn
          href={`${Sprite}#icon-counter`}
          onClick={addOrRemoveFromComparisons}
          size={16}
          sx={{ marginTop: '10px' }}
          compare={inCompare}
        />
        {!isHideArrowCard && (
          <IconBtn
            href={`${Sprite}#icon-arrow_card`}
            onClick={handelDetailCard}
            size={16}
            sx={{ marginTop: '10px' }}
            rotateSvg={isOpenDetail ? '180deg' : '0'}
          />
        )}
      </Controllers>
    </FlexContainer>
  );
};

const ToggleBtnWrapper = styled.div`
  display: block;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 100%;
`;

const Controllers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

Action.propTypes = {
  isHideArrowCard: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  handelDetailCard: PropTypes.func.isRequired,
  addedToCart: PropTypes.bool,
  isOpenDetail: PropTypes.bool,
  chooseProduct: PropTypes.shape({
    amount: PropTypes.array,
    quantity: PropTypes.number,
    cart_product_id: PropTypes.number,
    id: PropTypes.number,
    personal_amount: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
  }),
  addAnimation: PropTypes.func,
  handelOpenSavedModal: PropTypes.func.isRequired,
  isSaved: PropTypes.bool,
  inCompare: PropTypes.bool,
  relation_quantity: PropTypes.number,
  errorBorder: PropTypes.bool,
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_cron: PropTypes.bool,
      price_cron_no_dph: PropTypes.bool,
      price_no_dph: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    short_description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_cron: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_cron_no_dph: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_no_dph: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
