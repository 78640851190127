export const memoSelectedItem = (obj, prevObj) => {
  const { delivery, payment } = prevObj;
  let newObj = {};
  newObj.delivery = sort(obj.delivery, delivery);
  newObj.payment = sort(obj.payment, payment);
  return newObj;
};

function sort(arr, prevArr) {
  const choseItem = prevArr.find(el => el.is_selected);
  const isExists = arr.find(el => el.id === choseItem.id);

  if (choseItem && isExists) {
    return arr.map(el => {
      if (el.id === choseItem.id) el.is_selected = true;
      return el;
    });
  } else {
    arr[0].is_selected = true;
    return arr;
  }
}
