import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { ProfileConf } from '@root/conf';
import { AccountActivity } from '@root/modules/ProfileSettingsDetails/AccountActivity';
import { CustomScroll, ProgressLoader } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CompanyInfo } from './CompanyInfo';
import { ListOfEmployees } from './ListOfEmployees';
import { NotificationSettings } from './NotificationSettings';
import { PersonalInfo } from './PersonalInfo';
import { Security } from './Security';
import { SocialMedia } from './SocialMedia';
import { WorkflowSettings } from './WorkflowSettings';

export const ProfileSettingsDetails = () => {
  const { profileTab } = useParams();
  const { t } = useTranslation(['profile'], { useSuspense: false });
  const { color } = useTheme();

  const loginsLoading = useSelector(userSelectors.getIsUserLoginsLoading);

  return (
    <ProfileSettingsDetailsWrapper
      colors={{ white: color.white, line: color.line }}
    >
      {loginsLoading && <ProgressLoader />}
      <CustomScroll vertical={profileTab !== 'account-activity'}>
        <ProfileSettingsDetailsInner>
          <ProfileSettingsDetailsHeader>
            {ProfileConf(t).map(
              profile =>
                profile.path === profileTab && (
                  <>
                    <Typography color={color.gray} variant="h1">
                      {profile.label}
                    </Typography>
                    <Typography
                      color={color.gray_80}
                      variant="bodyMedium"
                      component="p"
                      marginTop="8px"
                    >
                      {profile.description}
                    </Typography>
                  </>
                ),
            )}
          </ProfileSettingsDetailsHeader>
          {profileTab === 'personal-info' && <PersonalInfo />}
          {profileTab === 'company-info' && <CompanyInfo />}
          {profileTab === 'list-of-employees' && <ListOfEmployees />}
          {profileTab === 'notification-settings' && <NotificationSettings />}
          {profileTab === 'workflow-settings' && <WorkflowSettings />}
          {profileTab === 'social-media' && <SocialMedia />}
          {profileTab === 'account-activity' && <AccountActivity />}
          {profileTab === 'security' && <Security />}
        </ProfileSettingsDetailsInner>
      </CustomScroll>
    </ProfileSettingsDetailsWrapper>
  );
};

const ProfileSettingsDetailsWrapper = styled.div`
  position: relative;
  width: 70%;
  background: ${props => props.colors.white};
  border: 1px solid ${props => props.colors.line};
  border-radius: 4px;
  height: calc(100vh - 171px);
`;

const ProfileSettingsDetailsInner = styled.div`
  padding: 32px;
`;

const ProfileSettingsDetailsHeader = styled.div`
  margin-bottom: 24px;
`;
