import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

const Accordion = styled(props => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
    sx={{
      padding: '8px 24px',
      ...props.dots,
    }}
  />
))(({ theme }) => ({
  border: `none`,
  width: '100%',

  svg: {
    width: '16px',
    height: '16px',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
}));

const AccordionSummary = styled(props => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    padding: 0,
    minHeight: 'revert',
    '& .Mui-expanded': {
      color: theme.color.main,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color: theme.color.gray_60,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  }),
);

const AccordionDetails = styled(props => <MuiAccordionDetails {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
  }),
);

export const AccordionUI = ({
  children,
  summaryMurk,
  expanded,
  handleChange,
  dots = {},
  defaultExpanded,
  accordionSummaryStyles,
  accordionDetailsStyles,
}) => {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={handleChange}
      dots={dots}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={accordionSummaryStyles}
      >
        {summaryMurk}
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: '10px 0 16px 5px', ...accordionDetailsStyles }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

AccordionUI.propTypes = {
  children: PropTypes.node,
  summaryMurk: PropTypes.node,
  expanded: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  dots: PropTypes.exact({
    '&::before': PropTypes.exact({
      background: PropTypes.string,
      borderRadius: PropTypes.string,
      content: PropTypes.string,
      height: PropTypes.string,
      left: PropTypes.string,
      position: PropTypes.string,
      top: PropTypes.string,
      transform: PropTypes.string,
      width: PropTypes.string,
    }),
  }),
  defaultExpanded: PropTypes.bool,
  accordionSummaryStyles: PropTypes.object,
  accordionDetailsStyles: PropTypes.object,
};
