import PropTypes from 'prop-types';
import styled from 'styled-components';

export const AnimateText = ({ text }) => {
  return (
    <ScrollTextWrapper>
      <ScrollText>{text}</ScrollText>
    </ScrollTextWrapper>
  );
};

const ScrollText = styled.div`
  animation: scrollText 10s infinite linear;
  white-space: nowrap;
  @keyframes scrollText {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
`;

const ScrollTextWrapper = styled.div`
  width: 177px;
  overflow: hidden;
`;

AnimateText.propTypes = {
  text: PropTypes.string,
};
