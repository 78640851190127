import PropTypes from 'prop-types';

import { Divider, RadioGroup } from '@mui/material';

import { RadioItem } from '../RadioItem';

export const FormPaymentInfo = ({
  payment_type,
  onChangeRadio,
  available_methods,
}) => {
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
      value={payment_type}
      onChange={onChangeRadio('payment_type')}
    >
      {available_methods?.payment_types.map(el => (
        <>
          <RadioItem data={el} />
          <Divider />
        </>
      ))}
    </RadioGroup>
  );
};

FormPaymentInfo.propTypes = {
  payment_type: PropTypes.string.isRequired,
  onChangeRadio: PropTypes.func.isRequired,
  available_methods: PropTypes.exact({
    delivery_types: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        items: PropTypes.arrayOf(
          PropTypes.exact({
            icon: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            key: PropTypes.string,
            subTitle: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        key: PropTypes.string,
        label: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    payment_types: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        key: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
};
