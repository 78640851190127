import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField, Empty } from '@root/components';

import { Typography, useTheme } from '@mui/material';

export const AgencyForm = ({ data }) => {
  const { activity_areas, site, employees_quantity, name, logo, status } = data;
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <Form>
      {logo ? (
        <CompanyLogo src={logo} alt="Logo Company" />
      ) : (
        <Empty
          type={'EmptyLogo'}
          isCompany
          tag={'div'}
          wrapperStyles={{
            maxWidth: '230px',
            height: '60px',
            width: '100%',
          }}
          contentStyles={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <Typography
        variant={'h2'}
        sx={{ textTransform: 'uppercase', mt: '24px' }}
      >
        {name}
      </Typography>
      <Wrapper>
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:business_table_conf.status')}
          value={
            status === 'active'
              ? t('admin:business_table_conf.status')
              : t('admin:business_table_conf.status')
          }
          variant={'bodyMedium'}
          goToStep={() => {}}
          sx={{
            mr: '12px',
            '&::before': {
              display: 'block',
              content: '""',
              width: '8px',
              height: '8px',
              background: status === 'active' ? color.green : color.red,
              borderRadius: '40px',
              mr: '5px',
            },
          }}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:business_table_conf.number_of_employees')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={employees_quantity}
          goToStep={() => {}}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:business_table_conf.site')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={site}
          goToStep={() => {}}
        />

        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          type={'activity_areas'}
          padding={'28px 0'}
          title={t('admin:business_table_conf.areas_of_activity')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={activity_areas}
          goToStep={() => {}}
        />
      </Wrapper>
    </Form>
  );
};

const CompanyLogo = styled.img`
  height: 60px;
  object-fit: contain;
  align-self: flex-start;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-bottom: 50px;
`;

const Wrapper = styled.div`
  margin-top: 28px;
`;

AgencyForm.propTypes = {
  data: PropTypes.exact({
    activity_areas: PropTypes.arrayOf(
      PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
    ),
    additional_info: PropTypes.string,
    company_type: PropTypes.string,
    company_type_id: PropTypes.number,
    contractor_code: PropTypes.number,
    employees_quantity: PropTypes.number,
    id: PropTypes.number,
    identification_code: PropTypes.number,
    logo: PropTypes.string,
    name: PropTypes.string,
    official_name: PropTypes.string,
    site: PropTypes.string,
    status: PropTypes.string,
  }),
};
