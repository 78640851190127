import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Sprite } from '@root/assets/svg';
import { theme } from '@root/styles';
import { ButtonBase, ToggleBtn } from '@root/ui';

import cartSelectors from '@redux/cart/cart-selectors';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const CartButton = ({
  refs,
  handelSave,
  isDisable = false,
  isCartPage = false,
}) => {
  const { color } = useTheme();
  const totalQuantity = useSelector(cartSelectors.getTotalQuantity);
  const specialPrise = useSelector(cartSelectors.getSpacialPrise);
  const totalPrise = useSelector(cartSelectors.getTotalPrice);
  const currencyCode = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const { t } = useTranslation(['common'], { useSuspense: false });

  return (
    <>
      {totalQuantity ? (
        <PriceWrapper
          ref={refs}
          colors={{
            white: color.white,
            gray: color.gray_80,
            line: color.line,
            gray_20: color.gray_20,
          }}
        >
          <NavLink
            to={currentLang + '/catalog/cart'}
            data-test-id="NoEmptyCart"
          >
            <PriceInner>
              <Wrapper>
                <svg width={16} height={16}>
                  <use href={`${Sprite}#icon-grocery_cart`}></use>
                </svg>
                <ItemsCounter
                  color={color.main}
                  data-test-id="TotalQuantityCart"
                >
                  {totalQuantity}
                </ItemsCounter>
                <PriceValueWrapper>
                  <Typography
                    color={theme.color.gray}
                    variant={'bodyBoldLarge'}
                  >
                    {specialPrise[choosedCurrency]} {''}
                    {currencyCode}
                  </Typography>
                  <Typography
                    color={theme.color.gray_60}
                    variant={'bodyBoldExSmall'}
                  >
                    {totalPrise[choosedCurrency]} {currencyCode}
                  </Typography>
                </PriceValueWrapper>
              </Wrapper>
              {isCartPage && (
                <ButtonBase
                  onClick={handelSave}
                  disabled={isDisable}
                  sx={{
                    height: '38px',
                  }}
                  data-test-id="CreateOrder"
                >
                  {t('common:buttons.cart_btn')}
                </ButtonBase>
              )}
            </PriceInner>
          </NavLink>
        </PriceWrapper>
      ) : (
        <NavLink to={currentLang + '/catalog/cart'}>
          <ToggleBtn
            size={50}
            href={`${Sprite}#icon-grocery_cart`}
            refs={refs}
            style={{ backgroundColor: color.white }}
            data-test-id="EmptyCartBtn"
          />
        </NavLink>
      )}
    </>
  );
};

const PriceWrapper = styled.div`
  background: ${props => props.colors.white};
  stroke: ${props => props.colors.gray};
  cursor: pointer;
  height: 50px;
  border: 1px solid ${props => props.colors.line};
  border-radius: 4px;
  transition: 0.25s;

  &:hover {
    background: ${props => props.colors.gray_20};
  }
`;

const PriceInner = styled.div`
  padding: 5px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemsCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 16px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 100%;
  margin-bottom: 21px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.color};
  padding: 4px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -1.6px;
    left: -1px;
    border: 3px solid transparent;
    border-top: 0;
    border-bottom: 6px solid ${props => props.color};
    transform: rotate(-130deg);
  }
`;

const PriceValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 45px;
`;

CartButton.propTypes = {
  refs: PropTypes.object,
  handelSave: PropTypes.func,
  isDisable: PropTypes.bool,
  isCartPage: PropTypes.bool,
};
