export const updateFavorite = (arr, id) => {
  if (!arr || arr.length === 0) return [];
  return [...arr].map(el => {
    if (el.id === id) {
      return { ...el, is_saved: !el.is_saved };
    } else {
      return el;
    }
  });
};

export const updateComparisonsListBySaved = (arr, payload) => {
  if (!arr || arr.length === 0) return [];
  const copyData = JSON.parse(JSON.stringify(arr));
  if (Object.keys(copyData).length > 0) {
    return Object.keys(copyData).map(product => {
      if (copyData[product].id === payload.savedId) {
        return {
          ...copyData[product],
          is_saved: !copyData[product].is_saved,
        };
      } else {
        return copyData[product];
      }
    });
  }
};
