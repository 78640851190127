import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import { InputLabel } from '@root/components';
import { getErrorStack, handleDispatchFetch, validURL } from '@root/helpers';
import {
  BasicSelect,
  ButtonBase,
  CheckboxBase,
  FileLoader,
  MaskInputUI,
  MessageError,
  TextArea,
  TextInput,
} from '@root/ui';

import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import optionOperation from '@redux/option/option-operation';
import optionSelectors from '@redux/option/option-selectors';
import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';

export const CompanyInfo = () => {
  const { t } = useTranslation(
    ['profile', 'validation', 'registration', 'common'],
    { useSuspense: false },
  );

  const dispatch = useDispatch();

  const fileRef = useRef();

  const [profile, setProfile] = useState({});
  const [error, setError] = useState({});

  const activity = useSelector(optionSelectors.getActivityAreas);
  const userCompany = useSelector(userSelectors.getUserCompany);

  document.title = t('profile:conf.company_info.title');

  useEffect(() => {
    dispatch(optionOperation.getActivityAreas());
  }, []);

  useEffect(() => {
    setProfile({
      ...userCompany,
      activity_areas: userCompany?.activity_areas
        ? userCompany?.activity_areas.map(activity_area => activity_area.id)
        : [],
    });
  }, [userCompany]);

  const handleOpenNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('profile:notifications.update_data_successfully'),
      }),
    );
  };

  const onChange = (name, subName) => value => {
    const { activity_areas } = profile;
    if (name === 'activity_areas') {
      let dataCopy = activity_areas ? [...activity_areas] : [];
      if (value) {
        dataCopy.push(subName);
      } else {
        dataCopy = dataCopy.filter(id => id !== subName);
      }
      setProfile(prevProfile => ({ ...prevProfile, [name]: dataCopy }));
    } else if (subName === 'legal_address' || subName === 'mailing_address') {
      const dataCopy = JSON.parse(JSON.stringify(profile));
      dataCopy[subName][name] = value;
      setProfile(dataCopy);
    } else if (name === 'identification_code') {
      setProfile(prevState => ({
        ...prevState,
        identification_code: value ? 'CZ' + value : '',
      }));
    } else {
      setProfile(prevProfile => ({ ...prevProfile, [name]: value }));
    }
  };

  const handleSetImage = logo => {
    setProfile(prevProfile => ({
      ...prevProfile,
      is_delete_file: false,
      logo,
    }));
  };

  const handleDeleteImage = () => {
    setProfile(prevProfile => ({
      ...prevProfile,
      is_delete_file: true,
      logo: null,
    }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const {
      logo,
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
      activity_areas,
      site,
      legal_address: {
        street_name_legal,
        house_number_legal,
        zip_code_legal,
        city_legal,
        country_legal,
      },
      mailing_address: {
        street_name_mailing,
        house_number_mailing,
        zip_code_mailing,
        city_mailing,
        country_mailing,
      },
    } = profile;

    Object.keys({
      street_name_legal,
      house_number_legal,
      zip_code_legal,
      city_legal,
      country_legal,
    }).forEach(key => {
      if (!profile.legal_address[key] || profile[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    Object.keys({
      street_name_mailing,
      house_number_mailing,
      zip_code_mailing,
      city_mailing,
      country_mailing,
    }).forEach(key => {
      if (!profile.mailing_address[key] || profile[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (!official_name || official_name === '') {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t('validation:required'),
      );
      flag = true;
    } else if (official_name.length < 2) {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t(`${t('validation:minLengthLetters')} 2`),
      );
      flag = true;
    }

    if (!company_type_id || company_type_id === '') {
      currentError = getErrorStack(
        currentError,
        'company_type_id',
        t('validation:required'),
      );
      flag = true;
    }

    if (!contractor_code || contractor_code === '') {
      currentError = getErrorStack(
        currentError,
        'contractor_code',
        t('validation:required'),
      );
      flag = true;
    } else if (contractor_code.length < 8) {
      currentError = getErrorStack(
        currentError,
        'contractor_code',
        t(`${t('validation:minLengthLetters')} 8`),
      );
      flag = true;
    }

    if (!identification_code || identification_code === '') {
      currentError = getErrorStack(
        currentError,
        'identification_code',
        t('validation:required'),
      );
      flag = true;
    } else if (identification_code.length < 10) {
      currentError = getErrorStack(
        currentError,
        'identification_code',
        t(`${t('validation:minLengthLetters')} 8`),
      );
      flag = true;
    }

    if (site) {
      if (!validURL(site)) {
        currentError = getErrorStack(
          currentError,
          'site',
          t('validation:enter_valid_url'),
        );
        flag = true;
      }
    }

    if (activity_areas.length === 0) {
      currentError = getErrorStack(
        currentError,
        'activity_areas',
        t('validation:choose_one_of_activity'),
      );
      flag = true;
    }
    if (logo)
      if (logo?.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'logo',
          t('validation:file_size'),
        );
        flag = true;
      }
    setError(currentError);
    return flag;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validation()) return;
    const { logo, ...restProfile } = profile;
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          userOperation.updateUserCompany({
            formData: serialize(
              {
                ...restProfile,
                logo: typeof logo === 'string' ? null : logo,
              },
              {
                indices: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      () => handleOpenNotification(),
    );
  };

  return (
    <>
      <CompanyInfoWrapper>
        <AvatarManagerWrapper>
          <FileLoader
            fileRef={fileRef}
            file={profile.logo}
            setFile={handleSetImage}
            title={t('registration:step_tree.loader.title')}
            handleSetImage={handleDeleteImage}
            isLogo={true}
            initError={error?.logo}
          />
        </AvatarManagerWrapper>

        <Form onSubmit={handleSubmit}>
          <TextInput
            value={profile.official_name}
            onChange={onChange('official_name')}
            error={error?.official_name?.init}
            errorText={error?.official_name?.text}
            placeholder={t('registration:field.company_name', { req: '*' })}
            max={255}
            sx={{
              height: '48px',
            }}
            label={t('profile:fields.company_name')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            required
          />
          <BasicSelect
            error={error?.company_type_id?.init}
            helperText={error?.company_type_id?.text}
            currentId={profile.company_type_id}
            data={[
              { id: 1, name: t('registration:option.tov') },
              { id: 2, name: t('registration:option.fop') },
            ]}
            name={t('registration:field.type_of_cooperation', { req: '*' })}
            callBack={onChange('company_type_id')}
            height="48px"
            label={t('profile:fields.type')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            required
          />

          <TextInput
            error={error?.contractor_code?.init}
            errorText={error?.contractor_code?.text}
            value={profile.contractor_code}
            onChange={onChange('contractor_code')}
            placeholder={t('registration:field.company_registration_number', {
              req: '*',
            })}
            type="text"
            max={8}
            digitsOnly={true}
            sx={{
              height: '48px',
            }}
            label={t('registration:field.company_registration_number', {
              req: '',
            })}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            required
          />

          <InputWrapper>
            <InputLabel required>
              {t('registration:field.ipn', {
                req: '',
              })}
            </InputLabel>
            <MaskInputUI
              error={error?.identification_code?.init}
              errorText={error?.identification_code?.text}
              value={profile?.identification_code}
              onChange={onChange('identification_code')}
              placeholder={t('registration:field.ipn', {
                req: '*',
              })}
              type="text"
              max={9}
              digitsOnly={true}
              withOutSpace={false}
              mask="CZ999999999"
              maskChar={''}
              requireMaskLength={0}
              sx={{ height: '48px' }}
            />
          </InputWrapper>

          <TextInput
            value={profile.employees_quantity}
            onChange={onChange('employees_quantity')}
            placeholder={t('registration:field.number_of_employees')}
            max={10}
            removeLeadingZero={true}
            digitsOnly
            sx={{
              height: '48px',
            }}
            label={t('registration:field.number_of_employees')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
          />

          <TextInput
            value={profile.site}
            onChange={onChange(`site`)}
            placeholder={t('registration:field.site_url')}
            error={error?.site?.init}
            errorText={error?.site?.text}
            max={255}
            sx={{
              height: '48px',
            }}
            label={t('registration:field.site_url')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
          />

          <InputWrapper>
            <InputLabel required aligning="flex-start">
              {t('registration:field.type_of_activity')}
            </InputLabel>

            <ActivityAreaWrapper>
              <ActivityAreaGridWrapper>
                {activity &&
                  profile.activity_areas &&
                  activity.map(({ name, id }) => (
                    <WrapperCheckBox key={id}>
                      <CheckboxBase
                        width={24}
                        onChange={onChange('activity_areas', id)}
                        checked={profile?.activity_areas.includes(id)}
                        error={error?.activity_areas?.init}
                      >
                        <Typography variant={'bodyLarge'} sx={{ ml: '8px' }}>
                          {name}
                        </Typography>
                      </CheckboxBase>
                    </WrapperCheckBox>
                  ))}
              </ActivityAreaGridWrapper>

              {error?.activity_areas?.text && (
                <MessageError errorText={error?.activity_areas?.text} />
              )}
            </ActivityAreaWrapper>
          </InputWrapper>

          <TextArea
            error={error?.company?.init}
            errorText={error?.company?.text}
            value={profile?.additional_info}
            onChange={onChange('additional_info')}
            placeholder={t('registration:field.additional_info')}
            type="text"
            max={5000}
            styleWrapper={{
              alignItems: 'flex-start',
            }}
            label={t('registration:field.additional_info')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
          />

          <Typography
            sx={{ mt: '28px' }}
            variant={'bodyLarge'}
            align={'center'}
            component={'p'}
          >
            {t('registration:step_tree.slogan_legal_address')}
          </Typography>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.street_name', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.street_name_legal?.init}
              errorText={error?.street_name_legal?.text}
              value={profile?.legal_address?.street_name_legal}
              onChange={onChange('street_name_legal', 'legal_address')}
              placeholder={t('registration:field.street_name', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.house_number', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.house_number_legal?.init}
              errorText={error?.house_number_legal?.text}
              value={profile?.legal_address?.house_number_legal}
              onChange={onChange('house_number_legal', 'legal_address')}
              placeholder={t('registration:field.house_number', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.zip_code', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.zip_code_legal?.init}
              errorText={error?.zip_code_legal?.text}
              value={profile?.legal_address?.zip_code_legal}
              onChange={onChange('zip_code_legal', 'legal_address')}
              placeholder={t('registration:field.zip_code', {
                req: '*',
              })}
              type="text"
              max={10}
              withOutSpace={true}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.country', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.country_legal?.init}
              errorText={error?.country_legal?.text}
              value={profile?.legal_address?.country_legal}
              onChange={onChange('country_legal', 'legal_address')}
              placeholder={t('registration:field.country', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.city', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.city_legal?.init}
              errorText={error?.city_legal?.text}
              value={profile?.legal_address?.city_legal}
              onChange={onChange('city_legal', 'legal_address')}
              placeholder={t('registration:field.city', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>

          <Typography
            sx={{ mt: '28px' }}
            variant={'bodyLarge'}
            align={'center'}
            component={'p'}
          >
            {t('registration:step_tree.slogan_mailing_address')}
          </Typography>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.street_name', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.street_name_mailing?.init}
              errorText={error?.street_name_mailing?.text}
              value={profile?.mailing_address?.street_name_mailing}
              onChange={onChange('street_name_mailing', 'mailing_address')}
              placeholder={t('registration:field.street_name', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.house_number', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.house_number_mailing?.init}
              errorText={error?.house_number_mailing?.text}
              value={profile?.mailing_address?.house_number_mailing}
              onChange={onChange('house_number_mailing', 'mailing_address')}
              placeholder={t('registration:field.house_number', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.zip_code', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.zip_code_mailing?.init}
              errorText={error?.zip_code_mailing?.text}
              value={profile?.mailing_address?.zip_code_mailing}
              onChange={onChange('zip_code_mailing', 'mailing_address')}
              placeholder={t('registration:field.zip_code', {
                req: '*',
              })}
              type="text"
              max={10}
              withOutSpace={true}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.country', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.country_mailing?.init}
              errorText={error?.country_mailing?.text}
              value={profile?.mailing_address?.country_mailing}
              onChange={onChange('country_mailing', 'mailing_address')}
              placeholder={t('registration:field.country', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>

          <InputWrapper>
            {' '}
            <InputLabel required disableSelfCenter>
              {t('registration:field.city', {
                req: '',
              })}
            </InputLabel>
            <TextInput
              error={error?.city_mailing?.init}
              errorText={error?.city_mailing?.text}
              value={profile?.mailing_address?.city_mailing}
              onChange={onChange('city_mailing', 'mailing_address')}
              placeholder={t('registration:field.city', {
                req: '*',
              })}
              type="text"
              max={255}
            />
          </InputWrapper>
          <ButtonWrapper>
            <ButtonBase type="submit">{t('common:buttons.save')}</ButtonBase>
          </ButtonWrapper>
        </Form>
      </CompanyInfoWrapper>
    </>
  );
};

const CompanyInfoWrapper = styled.div``;

const InputWrapper = styled.div`
  display: grid;
  column-gap: 30px;
  grid-template-columns: 150px 1fr;
  // align-items: baseline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
  max-width: 606px;
`;

const AvatarManagerWrapper = styled.div`
  max-width: 606px;
`;

const ActivityAreaGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 23px;
  max-width: 460px;
`;

const WrapperCheckBox = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const ActivityAreaWrapper = styled.div``;
