import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  IconBtn,
  ModalBase,
  NavigationButton,
  OutlineButtonBase,
} from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DashboardManagers = ({
  conf,
  changeConf,
  isEdit,
  confDashboardElement,
}) => {
  const [open, setOpen] = useState(false);
  const { name, isNav, isHide, key, size, position: positionConf } = conf;
  const userRole = useSelector(authSelectors.getRole);
  const { color } = useTheme();
  const [active, setActive] = useState('');
  const { t } = useTranslation([], { useSuspense: false });

  const bannerButtonStyles = key === 'banner' && {
    backgroundColor: color.white,
    borderRadius: '50%',
    fill: color.gray,
    stroke: color.gray,
    padding: '3px',
    width: '21px',
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '.7',
  };

  useEffect(() => {
    if (isNav) {
      setActive(name[0].key_nav);
    }
  }, [isNav]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const ActionMarkUp = position =>
    isEdit && (
      <Action position={position}>
        <IconBtn
          onClick={() => {}}
          sx={{ cursor: 'grab', ...bannerButtonStyles }}
          disableBorder
          size={16}
          href={`${Sprite}#icon-dnd-anchor`}
        />

        <IconBtn
          onClick={() =>
            changeConf({
              size: size === '1fr' ? '2fr' : '1fr',
              position: { ...positionConf, w: size === '1fr' ? 2 : 1 },
            })
          }
          sx={{ ...bannerButtonStyles }}
          onMouseDown={e => e.stopPropagation()}
          disableBorder
          size={16}
          href={`${Sprite}#icon-resize`}
        />
        {key !== 'banner' && key !== 'top_sale' && (
          <IconBtn
            sx={{
              position: 'relative',
              zIndex: 100,
              stroke: isHide && color.main,
              fill: isHide && color.main,
            }}
            onClick={() => changeConf({ isHide: !isHide })}
            disableBorder
            size={16}
            href={!isHide ? `${Sprite}#icon-eye` : `${Sprite}#icon-eye-show`}
            onMouseDown={e => e.stopPropagation()}
          />
        )}
      </Action>
    );

  return (
    <Card color={color} isHide={isHide} size={size} isEdit={isEdit}>
      <Flex color={color}>
        {key !== 'banner' && (
          <Header
            color={color}
            isNav={isNav}
            onMouseDown={e => e.stopPropagation()}
          >
            <HeaderTitle>
              {isNav ? (
                name.map(el => (
                  <NavigationButton
                    isActive={active === el.key_nav}
                    onClick={() => setActive(el.key_nav)}
                    key={el.name}
                    disabled={el.disabled}
                  >
                    {el.name}
                  </NavigationButton>
                ))
              ) : (
                <Typography variant={'bodyBoldSmall'} component={'h3'}>
                  {name}
                </Typography>
              )}
            </HeaderTitle>
          </Header>
        )}
        {ActionMarkUp('absolute')}

        <WidgetWrapper onMouseDown={e => e.stopPropagation()}>
          {confDashboardElement[key].widget({ activeTab: active, conf })}
        </WidgetWrapper>
      </Flex>
      {userRole === 'admin' &&
        confDashboardElement[key].onChangeEl &&
        isEdit && (
          <OutlineButtonBase
            sx={{
              position: 'absolute',
              bottom: '16px',
              right: '16px',
              zIndex: 10,
            }}
            onClick={() => {
              setOpen(true);
            }}
            onMouseDown={e => e.stopPropagation()}
          >
            {t('home:buttons.change')}
          </OutlineButtonBase>
        )}
      {open && (
        <ModalBase
          open={open}
          handleClose={handleCloseModal}
          onMouseDown={e => e.stopPropagation()}
          modalHeader={confDashboardElement[key].headerModal({
            handleCloseModal,
          })}
          modalBottom={confDashboardElement[key].footer({
            handleCloseModal,
          })}
        >
          {confDashboardElement[key].onChangeEl({ handleCloseModal })}
        </ModalBase>
      )}
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  width: ${({ size }) => size};
  max-height: 349px;
  height: 100%;
  background-color: ${({ color }) => color.white};
  border: 1px solid ${({ color }) => color.line};
  border-radius: 4px;
  overflow: hidden;
  opacity: ${({ isEdit, isHide }) => (isEdit ? '' : isHide ? 0 : '')};
  visibility: ${({ isEdit, isHide }) => (isEdit ? '' : isHide ? 'hidden' : '')};

  &::before {
    display: ${({ isHide }) => (isHide ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;

    background-color: ${({ color }) => color.white};
    opacity: 0.4;
    z-index: 50;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ color }) => color.line};

  min-height: 42px;
  padding: ${({ isNav }) => (isNav ? '8px 16px 8px 0' : '8px 16px 8px 26px')};
`;

const HeaderTitle = styled.div`
  flex-grow: 1;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Action = styled.div`
  position: ${({ position }) => position};
  top: 13px;
  right: 17px;
  display: flex;
  align-items: center;

  margin-left: auto;
  gap: 12px;
  z-index: 100;
`;

const WidgetWrapper = styled.div`
  height: 100%;
`;

NavigationButton.propTypes = {
  conf: PropTypes.object.isRequired,
  changeConf: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};
