import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Empty } from '@root/components';
import { useNavigation } from '@root/hooks';
import {
  AnimateText,
  AvatarUI,
  MenuBody,
  MenuButton,
  MenuItemCustoms,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';
import userSelectors from '@redux/user/user-selectors';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const UserMenu = () => {
  const { color } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const name = useSelector(userSelectors.getUserName);
  const companyName = useSelector(userSelectors.getUserCompanyName);
  const avatar = useSelector(userSelectors.getAvatar);

  const { t } = useTranslation('header', { useSuspense: false });

  const { navigation } = useNavigation();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <MenuButton
        sx={{ minWidth: '16px', textTransform: 'initial', textAlign: 'left' }}
        open={open}
        handleClick={handleClick}
      >
        <AvatarUI src={avatar} width={40} height={40}>
          <Empty
            type={'EmptyLogo'}
            isCompany={false}
            name={name}
            noAvatarSx={{
              fontSize: '1.375rem',
            }}
          />
        </AvatarUI>
        <Wrapper>
          <Typography
            sx={{ color: color.gray }}
            variant={'bodyBoldMedium'}
            component={'p'}
          >
            {name && (name.length > 20 ? <AnimateText text={name} /> : name)}
          </Typography>
          <Typography
            sx={{ color: color.gray_80, fontWeight: 400 }}
            variant={'bodySmall'}
            component={'p'}
          >
            {companyName &&
              (companyName.length > 20 ? (
                <AnimateText text={companyName} />
              ) : (
                companyName
              ))}
          </Typography>
        </Wrapper>
        <KeyboardArrowDownRoundedIcon sx={{ fontSize: 16 }} />
      </MenuButton>

      <MenuBody
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        sx={{ width: '127px' }}
      >
        <MenuItemCustoms
          handelClick={() => {
            handleClose();
            navigation('/profile/personal-info');
          }}
        >
          <Typography variant={'bodySmall'}>
            {t('header:user_menu_widget.private_data')}
          </Typography>
        </MenuItemCustoms>
        <MenuItemCustoms
          handelClick={() => {
            dispatch(authOperation.logOut());
            handleClose();
          }}
        >
          <Typography variant={'bodySmall'}>
            {t('header:user_menu_widget.logout')}
          </Typography>
        </MenuItemCustoms>
      </MenuBody>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
`;
