import { useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryObj = () => {
    const filter = searchParams.getAll('filter');
    const query = Object.fromEntries([...searchParams]);
    return {
      ...query,
      filter,
    };
  };

  const handleHasQuery = query => {
    if (searchParams.has(query)) {
      searchParams.delete(query);
    }
  };

  const replaceQueryParam = (name, value) => {
    setSearchParams({ ...getQueryObj(), [name]: value });
  };

  const appendParam = (key, value) => searchParams.append(key, value);
  const handleUpdateString = () => setSearchParams(searchParams.toString());

  return {
    handleHasQuery,
    getQueryObj,
    handleUpdateString,
    setSearchParams,
    appendParam,
    replaceQueryParam,
  };
};
