export const PeopleConf = t => [
  {
    label: '',
    value: 'checkBox',
    type: 'checkBox',
    style: active =>
      active ? { ml: '32px', width: '16px' } : { ml: '32px', width: '16px' },
  },
  {
    label: t('admin:people_table_conf.user'),
    value: 'user',
    type: 'user',
    style: active =>
      active ? { ml: '16px', width: '110px' } : { ml: '16px', width: '156px' },
  },
  {
    label: t('admin:people_table_conf.role'),
    value: 'role',
    type: 'number',
    style: active =>
      active ? { ml: '20px', width: '97px' } : { ml: '20px', width: '97px' },
  }, // change
  {
    label: 'Email',
    value: 'email',
    type: 'number',
    style: active =>
      active ? { ml: '25px', width: '180px' } : { ml: '25px', width: '180px' },
  }, // change
  {
    label: t('admin:people_table_conf.phone'),
    value: 'phone',
    type: 'longNumber',
    style: active =>
      active ? { ml: '25px', width: '120px' } : { ml: '25px', width: '120px' },
  }, // change
  {
    label: t('admin:people_table_conf.city'),
    value: 'city',
    type: 'businesses',
    style: active =>
      active
        ? { ml: '25px', width: '97px', textAlign: 'center' }
        : { ml: '25px', width: '97px', textAlign: 'center' },
    hide: true,
  },
  {
    label: t('admin:people_table_conf.business'),
    value: 'peopleBusinesses',
    type: 'status',
    style: active =>
      active ? { ml: '25px', width: '137px' } : { ml: '25px', width: '137px' },
    hide: true,
  },
  {
    label: t('admin:people_table_conf.counterparts'),
    value: 'counterparts',
    type: 'status',
    style: active =>
      active ? { ml: '25px', width: '133px' } : { ml: '25px', width: '133px' },
    hide: true,
  },
  {
    label: t('admin:people_table_conf.status'),
    value: 'status',
    type: 'status',
    style: active =>
      active ? { ml: '25px', width: '105px' } : { ml: '25px', width: '105px' },
    hide: true,
  },
  {
    label: '',
    value: 'edit',
    type: 'action',
    style: active => (active ? { ml: 'auto' } : { ml: 'auto' }),
    accordionData: ['Переглянути', 'Редагувати', 'Архівувати'],
  },
];
