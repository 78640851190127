import { useEffect, useRef, useState } from 'react';
import { CircleStencil } from 'react-advanced-cropper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { nanoid } from 'nanoid';
import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { AvatarManager, PhoneConfirmation } from '@root/components';
import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  BasicSelect,
  ButtonBase,
  DatePickerCustom,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';
import optionOperation from '@redux/option/option-operation';
import optionSelectors from '@redux/option/option-selectors';

import Typography from '@mui/material/Typography';

export const ContactInformation = ({ initData, pathParrent }) => {
  const [data, setData] = useState({ ...initData, is_delete_file: false });
  const [avatar, setAvatar] = useState(null);
  const [error, setError] = useState({});
  const { t } = useTranslation(['registration', 'validation', 'common'], {
    useSuspense: false,
  });
  const dispatch = useDispatch();
  const cities = useSelector(optionSelectors.getCities);
  const { navigation } = useNavigation();

  useEffect(() => {
    const { phones, birthday, name, city_id, avatar } = initData;
    setData({
      phones:
        phones.length > 0
          ? phones
          : [{ id: nanoid(), phone: null, confirm: null }],
      birthday,
      name,
      city_id,
    });
    setAvatar(avatar);
    dispatch(optionOperation.getCity());
  }, [dispatch, initData]);

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { name, phones } = data;

    if (!name || name === '') {
      currentError = getErrorStack(
        currentError,
        'name',
        t('validation:required'),
      );
      flag = true;
    } else if (name.length < 2) {
      currentError = getErrorStack(
        currentError,
        'name',
        t(`${t('validation:minLengthLetters')} 2`),
      );
      flag = true;
    }

    let arrError = [];
    arrError = phones.reduce((acc, { phone }, i) => {
      if (!phone) {
        acc = [
          ...acc,
          {
            init: false,
            text: '',
          },
        ];
        return acc;
      }
      if (phone.length === 12 || phone.length === 3) {
        acc = [
          ...acc,
          {
            init: false,
            text: '',
          },
        ];
      } else {
        acc = [
          ...acc,
          {
            init: true,
            text: t('validation:not_full_fill'),
          },
        ];
        flag = true;
      }
      return acc;
    }, []);

    currentError = { ...currentError, phones: arrError };

    setError(currentError);
    return flag;
  };

  const handelSubmit = e => {
    e.preventDefault();
    if (validation()) return;
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.signUpStepTwo({
            formData: serialize(
              {
                ...data,
                avatar: avatar
                  ? avatar.includes('data')
                    ? avatar
                    : null
                  : null,
              },
              {
                indices: true,
                nullsAsUndefineds: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      () => navigation(`${pathParrent}/2`),
    );
  };

  const onChange = (name, subName) => value => {
    if (name === 'phone') {
      const dataCopy = JSON.parse(JSON.stringify(data));
      dataCopy.phones[subName][name] = value;
      dataCopy.phones[subName].confirm = false;
      setData(dataCopy);
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handelAddPhoneInput = () => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.phones = [...dataCopy.phones, { phone: '', confirm: false }];
    setData(dataCopy);
  };

  const ConfirmPhones = phone => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.phones.map(el => {
      if (el.phone === phone) el.confirm = true;
      return el;
    });
    setData(dataCopy);
  };

  const handleSetImage = img => {
    if (!img) setData(prevState => ({ ...prevState, is_delete_file: true }));
    setAvatar(img);
  };

  const { name, phones, city_id, birthday } = data;
  return (
    <Wrapper>
      <Form onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t('registration:step_two.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_two.slogan')}
        </Typography>

        <AvatarManager
          img={avatar}
          title={t('registration:step_two.loader.title')}
          handleSetImage={handleSetImage}
          stencilVariant={CircleStencil}
          outputImageOptions={{ width: 100, height: 100 }}
        />

        <TextInput
          error={error?.name?.init}
          errorText={error?.name?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={name}
          onChange={onChange('name')}
          placeholder={t('registration:field.name', { req: '*' })}
          type="text"
          data-test-id="Name"
          autoComplete={'name'}
          inputProps={{
            maxLength: 50,
          }}
        />
        <WrapperRow>
          {phones.map((el, i) => {
            return (
              <PhoneConfirmation
                error={error?.phones && error?.phones[i]?.init}
                errorText={error?.phones && error?.phones[i]?.text}
                key={el.id}
                sx={{ width: '100%', height: '48px' }}
                placeholder={t('registration:field.phone')}
                onChange={onChange(`phone`, i)}
                value={el.phone}
                margin={i > 0 && '10px 0 0 0'}
                confirm={el.confirm}
                changeConfirm={ConfirmPhones}
                autoCompleteType={'tel'}
              />
            );
          })}

          {phones.length < 10 && (
            <OutlineButtonBase
              type="button"
              sx={{ mt: '10px', width: '100%' }}
              onClick={handelAddPhoneInput}
            >
              <Svg width="16" height="16">
                <use href={`${Sprite}#icon-plus`}></use>
              </Svg>
              <Typography variant={'bodySmall'} align={'center'}>
                {t('registration:step_two.add_phone_btn')}
              </Typography>
            </OutlineButtonBase>
          )}
        </WrapperRow>
        <WrapperRow>
          <DatePickerCustom
            onChange={onChange(`birthday`)}
            value={birthday}
            placeholder={t('registration:field.birthday')}
            sx={{
              height: '48px',
            }}
          />
        </WrapperRow>
        <BasicSelect
          currentId={city_id}
          data={cities}
          name={t('registration:field.city', {
            req: '',
          })}
          callBack={onChange('city_id')}
          sx={{ mt: '28px' }}
          height="48px"
        />

        <WrapperRow>
          <Wrapper>
            <OutlineButtonBase
              type="button"
              sx={{ flex: 1, maxWidth: '208px' }}
              onClick={() => {}}
            >
              <Typography variant={'bodyBoldLarge'} align={'center'}>
                {t('common:buttons.back')}
              </Typography>
            </OutlineButtonBase>

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ flex: 1, ml: '12px', maxWidth: '208px' }}
            >
              <Typography variant={'bodyBoldLarge'}>
                {t('common:buttons.continue')}
              </Typography>
            </ButtonBase>
          </Wrapper>
        </WrapperRow>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const WrapperRow = styled.div`
  margin-top: 28px;
`;

const Svg = styled.svg`
  margin-right: 4px;
`;
ContactInformation.propTypes = {
  initData: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.string,
    city_id: PropTypes.number,
    company: PropTypes.exact({
      activity_areas: PropTypes.arrayOf(
        PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
      ),
      additional_info: PropTypes.string,
      company_type: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      employees: PropTypes.array,
      employees_quantity: PropTypes.number,
      identification_code: PropTypes.number,
      legal_address: PropTypes.exact({
        city_legal: PropTypes.string,
        country_legal: PropTypes.string,
        house_number_legal: PropTypes.string,
        street_name_legal: PropTypes.string,
        zip_code_legal: PropTypes.string,
      }),
      logo: PropTypes.string,
      mailing_address: PropTypes.exact({
        city_mailing: PropTypes.string,
        country_mailing: PropTypes.string,
        house_number_mailing: PropTypes.string,
        street_name_mailing: PropTypes.string,
        zip_code_mailing: PropTypes.string,
      }),
      name: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
    }),
    currency_rates: PropTypes.arrayOf(
      PropTypes.exact({
        public_name: PropTypes.string,
        rate: PropTypes.string,
      }),
    ),
    email: PropTypes.string,
    ga_is_active: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    np_api_key: PropTypes.string,
    password_updated_at: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.exact({ confirm: PropTypes.bool, phone: PropTypes.string }),
    ),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_cron: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_cron_no_dph: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_no_dph: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
    }),
    role: PropTypes.arrayOf(PropTypes.string),
    show_logins: PropTypes.bool,
    status: PropTypes.string,
  }),
  pathParrent: PropTypes.string.isRequired,
};
