export const AdminSettingMenu = (t, color, isPersonal, isOrder) => {
  return [
    {
      id: 1,
      title: t('common:buttons.view'),
      typographyVariant: 'bodySmall',
      type: 'review',
      visible: true,
    },
    {
      id: 2,
      title: t('common:buttons.edit'),
      typographyVariant: 'bodySmall',
      type: 'edit',
      visible: !isOrder,
    },
    {
      id: 3,
      title: t('common:buttons.archive'),
      typographyVariant: 'bodySmall',
      type: 'archive',
      visible: !isOrder,
    },
    {
      id: 4,
      title: t('common:buttons.personal_price'),
      typographyVariant: 'bodySmall',
      type: 'personal_price',
      visible: isPersonal,
    },
  ];
};
