import { createSlice } from '@reduxjs/toolkit';

import {
  removeFromCart,
  updateComparison,
  updateFavorite,
  updateFromCart,
} from '@root/helpers';

import homeOperation from '@redux/home/home-operation';

import operationAuth from '../auth/auth-operation';
import operationCart from '../cart/cart-operation';
import operationComparisons from '../comparisons/comparisons-operation';
import operationFavorite from '../favorite/favorite-operation';

const initialState = {
  homeConfig: [],
  isUpdatingHomeConfig: false,
  homeBanner: [],
  isHomeBannerLoading: false,
  homeTopSale: [],
  isHomeTopSalleLoading: true,
  homeOrderChart: [],
  isHomeOrderChartLoading: false,
  homeBuysMostOften: [],
  isHomeBuysMostOftenLoading: true,
  homeOrders: [],
  homeOrdersMeta: {},
  isHomeOrdersLoading: false,
};

const homeSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateHomeBanner: (state, { payload }) => {
      state.homeBanner = payload;
    },
  },
  extraReducers: {
    //================================Global-Store-Listeners=============================//
    //====setConf====//
    [operationAuth.getUser.fulfilled]: (state, { payload }) => {
      const { user } = payload;
      const { dashboard } = user;

      state.homeConfig = dashboard;
    },
    //====setHomeConfig====//
    [homeOperation.updateHomeConfig.fulfilled]: (state, { payload }) => {
      state.homeConfig = payload;
    },
    //====setHomeConfig====//
    [operationCart.addProductToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      const { products } = data.data;
      state.homeTopSale = updateFromCart(state.homeTopSale, products);
      state.homeBuysMostOften = updateFromCart(
        state.homeBuysMostOften,
        products,
      );
    },
    //====setPutOneToCart====//
    [operationCart.putOneToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      const { products } = data.data;
      state.homeTopSale = updateFromCart(state.homeTopSale, products);
      state.homeBuysMostOften = updateFromCart(
        state.homeBuysMostOften,
        products,
      );
    },

    //====deleteOneFromCart====//
    [operationCart.deleteOneFromCart.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      state.homeTopSale = removeFromCart(state.homeTopSale, id);
      state.homeBuysMostOften = removeFromCart(state.homeBuysMostOften, id);
    },

    //====setProductToFavorite====//
    [operationFavorite.postProductToFavorite.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.homeTopSale = updateFavorite(state.homeTopSale, id);
      state.homeBuysMostOften = updateFavorite(state.homeBuysMostOften, id);
    },

    //====deleteProductToFavorite====//
    [operationFavorite.deleteProductFromSaved.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.homeTopSale = updateFavorite(state.homeTopSale, id);
      state.homeBuysMostOften = updateFavorite(state.homeBuysMostOften, id);
    },

    // //====setComparisons====//
    [operationComparisons.addToComparisons.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      state.homeTopSale = updateComparison(state.homeTopSale, id);
      state.homeBuysMostOften = updateComparison(state.homeBuysMostOften, id);
    },

    // //====deleteComparisons====//
    [operationComparisons.removeFromComparisons.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.homeTopSale = updateComparison(state.homeTopSale, id);
      state.homeBuysMostOften = updateComparison(state.homeBuysMostOften, id);
    },
    //================================End-Global-Store-Listeners=============================//

    // ---- getHomeBanner ----- //
    [homeOperation.getHomeBanner.pending]: (state, { payload }) => {
      state.isHomeBannerLoading = true;
    },
    [homeOperation.getHomeBanner.fulfilled]: (state, { payload }) => {
      state.homeBanner = payload;
      state.isHomeBannerLoading = false;
    },
    [homeOperation.getHomeBanner.rejected]: (state, { payload }) => {},

    // ---- getHomeTopSalle ----- //
    [homeOperation.getHomeTopSale.pending]: (state, { payload }) => {
      state.isHomeTopSalleLoading = true;
    },
    [homeOperation.getHomeTopSale.fulfilled]: (state, { payload }) => {
      state.homeTopSale = payload;
      state.isHomeTopSalleLoading = false;
    },
    [homeOperation.getHomeTopSale.rejected]: (state, { payload }) => {},

    // ---- getHomeOrderChart ----- //
    [homeOperation.getHomeOrderChart.pending]: (state, { payload }) => {
      state.isHomeOrderChartLoading = true;
    },
    [homeOperation.getHomeOrderChart.fulfilled]: (state, { payload }) => {
      state.homeOrderChart = payload.orders;
      state.isHomeOrderChartLoading = false;
    },
    [homeOperation.getHomeOrderChart.rejected]: (state, { payload }) => {},

    // ---- getHomeBuysMostOften ----- //
    [homeOperation.getHomeBuysMostOften.pending]: (state, { payload }) => {
      state.isHomeBuysMostOftenLoading = true;
    },
    [homeOperation.getHomeBuysMostOften.fulfilled]: (state, { payload }) => {
      state.homeBuysMostOften = payload;
      state.isHomeBuysMostOftenLoading = false;
    },
    [homeOperation.getHomeBuysMostOften.rejected]: (state, { payload }) => {},

    // ---- updateHomeBanner ----- //
    [homeOperation.updateHomeBanner.pending]: (state, { payload }) => {
      state.isUpdatingHomeConfig = true;
    },
    [homeOperation.updateHomeBanner.fulfilled]: (state, { payload }) => {
      state.updateHomeBanner = payload;
      state.isUpdatingHomeConfig = false;
    },
    [homeOperation.updateHomeBanner.rejected]: (state, { payload }) => {},

    // ---- deleteHomeBanner ----- //
    [homeOperation.deleteHomeBanner.pending]: (state, { payload }) => {
      state.isUpdatingHomeConfig = true;
    },
    [homeOperation.deleteHomeBanner.fulfilled]: (state, { payload }) => {
      state.homeBanner = payload;
      state.isUpdatingHomeConfig = false;
    },
    [homeOperation.deleteHomeBanner.rejected]: (state, { payload }) => {},

    // ---- getHomeOrders ----- //
    [homeOperation.getHomeOrders.pending]: (state, { payload }) => {
      state.isHomeOrdersLoading = true;
    },
    [homeOperation.getHomeOrders.fulfilled]: (state, { payload }) => {
      state.homeOrders =
        payload.meta.current_page === 1
          ? [...payload.data]
          : [...state.homeOrders, ...payload.data];
      state.homeOrdersMeta = payload.meta;
      state.isHomeOrdersLoading = false;
    },
    [homeOperation.getHomeOrders.rejected]: (state, { payload }) => {},
    //=============================Top-Sale-Modal=====================================//
    // ---- createHomeTopSale ----- //
    [homeOperation.createHomeTopSale.pending]: (state, { payload }) => {
      state.isHomeTopSalleLoading = true;
    },
    [homeOperation.createHomeTopSale.fulfilled]: (state, { payload }) => {
      state.homeTopSale = payload;
      state.isHomeTopSalleLoading = false;
    },

    [homeOperation.createHomeTopSale.rejected]: (state, { payload }) => {
      state.isHomeTopSalleLoading = false;
    },
    //=============================End-Top-Sale-Modal=====================================//
  },
});

export const { updateHomeBanner } = homeSlice.actions;
export default homeSlice.reducer;
