import React from 'react';

import styled from 'styled-components';

import { Typography, useTheme } from '@mui/material';

export const ComparisonsCardRow = ({
  descriptionCharacteristicsBold = false,
  name,
  rowHeight,
}) => {
  const { color } = useTheme();
  return (
    <TableRow rowHeight={rowHeight} color={color.line}>
      <Typography
        variant={
          descriptionCharacteristicsBold ? 'bodyBoldMedium' : 'bodyMedium'
        }
        color={descriptionCharacteristicsBold ? color.gray : color.gray_80}
      >
        {name}
      </Typography>
    </TableRow>
  );
};

const TableRow = styled.div`
  padding: 8px 0 8px 20px;
  border-bottom: 1px solid ${props => props.color};
  height: ${props => props.rowHeight + 18}px;
`;
