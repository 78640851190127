import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const LabelProduct = ({
  label,
  parm,
  article,
  payment,
  isOrder = false,
  isAvailable = false,
  canBuy,
  isEllipsis = false,
  lengthEllipsis = {
    label: 50,
    parm: 50,
    article: 50,
  },
}) => {
  const currencyCode = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(userSelectors.getChoosedCurrencyWidget);
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  const objSpan = isEllipsis
    ? ''
    : { span: { background: color.orange_light } };
  return (
    <Box>
      {label && (
        <Typography
          sx={{
            color: color.gray,
            ...objSpan,
          }}
          variant={'bodyBoldMedium'}
          component={'div'}
        >
          {isEllipsis ? (
            <EllipsisText text={label} length={lengthEllipsis.label} />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            ></p>
          )}
        </Typography>
      )}
      {parm && (
        <Typography
          sx={{
            color: color.gray_80,
            ...objSpan,
          }}
          variant={'bodyMedium'}
          component={'div'}
        >
          {isEllipsis ? (
            <EllipsisText text={parm} length={lengthEllipsis.parm} />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: parm,
              }}
            ></p>
          )}
        </Typography>
      )}
      {article && (
        <Typography
          sx={{
            color: color.gray_60,
            mt: parm ? '4px' : 0,
            display: 'flex',
            ...objSpan,
          }}
          variant={'bodySmall'}
          component={'div'}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: `${t('common:article')}:   ${article}`,
            }}
          ></p>
        </Typography>
      )}

      {payment && (
        <Typography
          sx={{ color: color.gray_60, mt: '4px', display: 'flex' }}
          variant={'bodySmall'}
          component={'p'}
        >
          {payment.quantity} {t('order_history:piece')} x
          <Article>
            {payment.price[choosedCurrency.field_name]}{' '}
            {isOrder ? choosedCurrency.code_full : currencyCode}
          </Article>
        </Typography>
      )}

      {isAvailable && (
        <Typography
          color={canBuy ? color.green : color.orange}
          variant="bodySmall"
          marginTop="2px"
          component="p"
        >
          {canBuy ? t('common:in_stock') : t('common:out_of_stock')}
        </Typography>
      )}
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: fit-content;
`;

const Article = styled.span`
  display: block;
  margin-left: 4px;
`;

LabelProduct.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  parm: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  article: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  payment: PropTypes.shape({
    price: PropTypes.string,
    quantity: PropTypes.number,
  }),
  isOrder: PropTypes.bool,
  isAvailable: PropTypes.bool,
  canBuy: PropTypes.bool,
  isEllipsis: PropTypes.bool,
  lengthEllipsis: PropTypes.shape({
    label: PropTypes.number,
    parm: PropTypes.number,
    article: PropTypes.number,
  }),
};
