import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { t } from 'i18next';
import PropTypes from 'prop-types';

import { useQueryParams } from '@root/hooks';

import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import usePagination from '@mui/material/usePagination';

import { PaginationSelect } from './selectPerPage';

const List = styled('ul')({
  listStyle: 'none',
  margin: 0,
  display: 'flex',
});

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  padding: '19px 21px 19px 13px',
  borderTop: `1px solid ${theme.color.line}`,
}));

const Button = styled('button')(({ theme }) => ({
  fontWeight: 700,
  fontSize: '0.75rem',
  lineHeight: '130%',
  padding: '10px 7px',
  background: theme.color.white,
  border: `1px solid ${theme.color.line}`,
  cursor: 'pointer',

  '&:hover': {
    background: theme.color.gray_20,
  },

  '&:active': {
    background: theme.color.gray_20,
    color: theme.color.main,
  },
}));

const Wrapper = styled('div')({
  width: '58px',
});

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
});

export const Pagination = ({
  meta,
  handelPagination,
  subTitle = t('common:pagination.products'),
}) => {
  const { color } = useTheme();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { getQueryObj } = useQueryParams();
  const { t } = useTranslation(['common'], { useSuspense: false });
  useEffect(() => {
    const { page, per_page } = getQueryObj();
    if (page) setPage(page);
    if (per_page) setRowsPerPage(per_page);
  }, []);

  useEffect(() => {
    if (meta?.current_page && meta?.per_page) {
      setPage(meta.current_page);
      setRowsPerPage(meta.per_page);
    }
  }, [meta]);

  const changeRowsPerPage = perPage => {
    const { sort, order } = getQueryObj();
    if (meta.per_page && meta.per_page !== perPage) {
      handelPagination({
        page: 1,
        per_page: perPage,
        sort,
        order,
      });
    }
  };

  const { items } = usePagination({
    siblingCount: 0,
    page: page,
    count: meta?.last_page,
    defaultPage: 1,
    onChange: handelPage,
  });

  const rowsPerPageOption = [
    { id: 10, name: 10 },
    { id: 25, name: 25 },
    { id: 50, name: 50 },
    { id: 100, name: 100 },
  ];

  function handelPage(e) {
    const { sort, order } = getQueryObj();

    const id = e.target.id;
    if (id === 'next') {
      handelPagination({
        page: meta?.current_page + 1,
        per_page: rowsPerPage,
        sort,
        order,
      });
      setPage(meta.current_page + 1);
    } else if (id === 'previous') {
      handelPagination({
        page: meta?.current_page - 1,
        per_page: rowsPerPage,
        sort,
        order,
      });
      setPage(meta?.current_page - 1);
    } else {
      handelPagination({
        page: e.target.innerText,
        per_page: rowsPerPage,
        sort,
        order,
      });
      setPage(Number(e.target.innerText));
    }
  }

  return (
    <Nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = children = (
              <Button type="button" disable>
                ...
              </Button>
            );
          } else if (type === 'page') {
            children = (
              <Button
                type="button"
                style={{
                  color: selected ? color.main : color.gray_80,
                }}
                {...item}
              >
                {page}
              </Button>
            );
          } else {
            children = (
              <Button
                type="button"
                id={type}
                style={{
                  borderRadius: '0px 2px 2px 0px',
                }}
                {...item}
              >
                {type === 'previous'
                  ? t('common:buttons.back')
                  : t('common:buttons.next')}
              </Button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>

      <Box>
        <Typography
          sx={{ color: color.gray_80, mr: '10px' }}
          variant={'bodyBoldSmall'}
          component={'p'}
        >
          {t('common:pagination.show')}
        </Typography>
        <Wrapper>
          <PaginationSelect
            sx={{ height: '36px', p: 0 }}
            height={'36px !important'}
            data={rowsPerPageOption}
            currentId={rowsPerPage}
            callBack={changeRowsPerPage}
          />
        </Wrapper>
        <Typography
          sx={{ color: color.gray_80, ml: '10px', textTransform: 'lowercase' }}
          variant={'bodyBoldSmall'}
          component={'p'}
        >
          {subTitle === 'Товарів' ? t('common:pagination.products') : subTitle}
        </Typography>
      </Box>
    </Nav>
  );
};

Pagination.propTypes = {
  meta: PropTypes.exact({
    current_page: PropTypes.number,
    from: PropTypes.number,
    last_page: PropTypes.number,
    links: PropTypes.arrayOf(
      PropTypes.exact({
        url: PropTypes.string,
        label: PropTypes.string,
        active: PropTypes.bool,
      }),
    ),
    path: PropTypes.string,
    per_page: PropTypes.number,
    to: PropTypes.number,
    total: PropTypes.number,
  }),
  handelPagination: PropTypes.func.isRequired,
  subTitle: PropTypes.string,
};
