import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import styled from 'styled-components';

import { Container } from '@root/middleware';
import { Header } from '@root/modules';
import { Loader } from '@root/ui';
import { SideBar } from '@root/widgets';

import Box from '@mui/material/Box';

export const TemplateMain = () => {
  return (
    <Container width={1400}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <SideBar />

        <Page>
          <Header />
          <Suspense fallback={<Loader />}>
            <Outlet />{' '}
          </Suspense>
        </Page>
      </Box>
    </Container>
  );
};

const Page = styled.div`
  width: 100%;
  overflow: hidden;
`;
