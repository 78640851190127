import * as React from 'react';

import { theme } from '@root/styles';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const defaultTooltipStyles = {
  popper: {
    sx: {
      zIndex: 3000,
    },
  },
  tooltip: {
    sx: {
      position: 'relative',
      backgroundColor: theme.color.menu,
      padding: '8px 6px',
      marginLeft: '6px !important',
    },
  },

  arrow: {
    sx: {
      position: 'relative',
      color: theme.color.menu,
    },
  },
};

export const BootstrapTooltip = styled(
  ({ className, tooltipStyles, ...props }) => {
    return (
      <Tooltip
        {...props}
        arrow
        classes={{ popper: className }}
        componentsProps={tooltipStyles || defaultTooltipStyles}
      />
    );
  },
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {},
  [`& .${tooltipClasses.tooltip}`]: {},
}));
