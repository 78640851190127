import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  Availability,
  Cost,
  ImgPrev,
  LabelProduct,
  QuantityManager,
} from '@root/components';
import { ToggleBtn } from '@root/ui';

import searchSelectors from '@redux/search/search-selectors';
import userSelectors from '@redux/user/user-selectors';

export const Card = ({
  data,
  handleMoveToItemPage,
  addAnimation,
  handleAddToCart,
  customCardSx,
}) => {
  const search = useSelector(searchSelectors.getParamSearch);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const preventDefault = e => e.preventDefault();

  const { image, name, model, sku, is_in_cart, stock, stock_percent, can_buy } =
    data;

  return (
    <Body
      style={{
        padding: '15px 53px 15px 16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <WrapperFlex
        maxWidth={customCardSx[0].style.maxWidth}
        width={customCardSx[0].style.width}
        onClick={() => handleMoveToItemPage(data?.slug)}
      >
        <ImgPrev src={image} />
        <Wrapper pointer={'pointer'} margin={'8px'} style={{ width: '400px' }}>
          <LabelProduct
            label={name && name.replace(search, `<span >${search}</span>`)}
            parm={model && model.replace(search, `<span >${search}</span>`)}
            article={sku && sku.replace(search, `<span >${search}</span>`)}
          />
        </Wrapper>
      </WrapperFlex>

      <Wrapper
        margin={'16px'}
        width={customCardSx[1].style.width}
        maxWidth={customCardSx[1].style.maxWidth}
        pointer={'pointer'}
      >
        <Cost data={data} />
      </Wrapper>
      <Wrapper
        margin={'16px'}
        width={customCardSx[2].style.width}
        maxWidth={customCardSx[2].style.maxWidth}
      >
        <Availability
          present={stock_percent}
          inStock={stock}
          notAvailable={stock}
          storage={stock}
        />
      </Wrapper>
      <Wrapper
        width={customCardSx[3].style.width}
        maxWidth={customCardSx[3].style.maxWidth}
        display={customCardSx[3].style.display}
        onClick={preventDefault}
      >
        {is_in_cart ? (
          <QuantityManager addAnimation={addAnimation} data={data} />
        ) : (
          <Wrapper margin={'16px'}>
            <ToggleBtn
              disabled={!can_buy[choosedCurrency]}
              onClick={e => handleAddToCart(e, data)}
              size={32}
              isActive={can_buy[choosedCurrency]}
              href={`${Sprite}#icon-grocery_cart`}
            />
          </Wrapper>
        )}
      </Wrapper>
    </Body>
  );
};

const Body = styled.div`
  padding: 15px 53px 15px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  margin-left: ${prop => prop.margin};
  max-width: ${prop => (prop.maxWidth ? prop.maxWidth : 'initial')};
  width: ${prop => (prop.width ? prop.width : 'initial')};
  display: ${prop => (prop.display ? prop.display : 'initial')};
  cursor: ${prop => (prop.pointer ? prop.pointer : 'initial')};
`;

const WrapperFlex = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  max-width: ${prop => (prop.maxWidth ? prop.maxWidth : 'initial')};
  width: ${prop => (prop.width ? prop.width : 'initial')};
`;

Card.propTypes = {
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_cron: PropTypes.bool,
      price_cron_no_dph: PropTypes.bool,
      price_no_dph: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    short_description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_cron: PropTypes.string,
      price_cron_no_dph: PropTypes.string,
      price_no_dph: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_cron: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_cron_no_dph: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_no_dph: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
  handleMoveToItemPage: PropTypes.func.isRequired,
  addAnimation: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  customCardSx: PropTypes.array,
};
