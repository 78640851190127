import {
  AddProductToListOfCategoryModal,
  FooterDefault,
  HeaderDefault,
} from '@root/components';
import { HistoryDetails } from '@root/modules';
import { Banner, OrderChart, OrderWidjet, TopSale } from '@root/widgets';
import { DownloadBannerModal } from '@root/widgets/Banner/DownloadBannerModal';
import { TopSaleModal } from '@root/widgets/TopSale/TopSaleModal';

export function getConfDashboardElement(t) {
  return {
    banner: {
      widget: ({ conf }) => <Banner conf={conf} />,
      onChangeEl: ({ handleCloseModal }) => (
        <DownloadBannerModal handleCloseModal={handleCloseModal} />
      ),
      headerModal: ({ handleCloseModal }) => (
        <HeaderDefault
          onClose={handleCloseModal}
          text={t('home:modals.download_banner_modal.title')}
        />
      ),
      footer: ({ handleCloseModal }) => (
        <FooterDefault
          conf={[
            {
              type: 'outline',
              text: t('common:buttons.cancel'),
              onClose: handleCloseModal,
              form: '',
            },
            {
              type: 'inline',
              text: t('common:buttons.save'),
              onClose: handleCloseModal,
              form: 'banner',
            },
          ]}
        />
      ),
    },
    top_sale: {
      widget: prop => <TopSale {...prop} />,
      onChangeEl: prop => <TopSaleModal {...prop} />,
      headerModal: ({ handleCloseModal }) => (
        <HeaderDefault
          onClose={handleCloseModal}
          text={t('home:modals.total_price_modal.title')}
        />
      ),
      footer: ({ handleCloseModal }) => (
        <FooterDefault
          conf={[
            {
              type: 'outline',
              text: t('common:buttons.reject'),
              onClose: handleCloseModal,
              form: '',
            },
            {
              type: 'inline',
              text: t('common:buttons.add_product'),
              onClose: handleCloseModal,
              form: 'top_sale',
            },
          ]}
        />
      ),
    },
    order: {
      widget: prop => (
        <>
          <OrderWidjet
            {...prop}
            historyDetailsModule={<HistoryDetails isModal />}
          />
        </>
      ),
      onChangeEl: null,
    },
    services: {
      widget: prop => <></>,
      onChangeEl: null,
    },
    order_chart: {
      widget: prop => (
        <>
          <OrderChart {...prop} />
        </>
      ),
      onChangeEl: null,
    },
    buys_most_often: {
      widget: prop => <TopSale {...prop} />,
      onChangeEl: null,
    },
  };
}
