import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  CardDetails,
  DrawerCategory,
  Empty,
  Pagination,
  ProductCard,
  SearchInput,
} from '@root/components';
import { useDebounce } from '@root/hooks';
import { CustomScroll, IconBtn, MessageError, ProgressLoader } from '@root/ui';

import productOperation from '@redux/product/product-operation';
import productSelectors from '@redux/product/product-selectors';
import { onClearProduct } from '@redux/product/product-slice';

import { Divider, useTheme } from '@mui/material';

export const AddProductToListOfCategoryModal = ({
  handleCloseModal,
  data,
  onSubmitCallBack,
  customElAction,
  modalWidth = 900,
  callBackChoseProduct = () => {},
  isTopSale,
}) => {
  const [expanded, setExpanded] = useState('');
  const [activeId, setActiveId] = useState(0);
  const [search, setSearch] = useState('');
  const [dataChecked, setDataChecked] = useState([]);
  const { t } = useTranslation(['home', 'validation'], {
    useSuspense: false,
  });
  const debounceSearchValue = useDebounce(search, 400);
  const { color } = useTheme();
  const dataCategory = useSelector(productSelectors.getProduct);
  const meta = useSelector(productSelectors.getMeta);
  const loading = useSelector(productSelectors.getLoading);
  const [openError, setOpenError] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(0);

  useEffect(() => {
    return () => {
      dispatch(onClearProduct());
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) setDataChecked(data);
  }, [data]);

  useEffect(() => {
    if (debounceSearchValue === '') return;
    setActiveId(null);
    dispatch(
      productOperation.getProductsBySearch({
        q: debounceSearchValue,
        page: 1,
        per_page: 10,
      }),
    );
  }, [debounceSearchValue]);

  useEffect(() => {
    if (activeId === null) return;
    setSearch('');
  }, [activeId]);

  const dispatch = useDispatch();

  const handleChange = name => value => {
    setSearch(value);
  };

  const handleChoseProduct = obj => status => {
    if (status) {
      setDataChecked([...dataChecked, { ...obj, relation_quantity: 1 }]);
      callBackChoseProduct([...dataChecked, { ...obj, relation_quantity: 1 }]);
    } else {
      const newData = [...dataChecked].filter(el => el.id !== obj.id);
      setDataChecked(newData);
      callBackChoseProduct(newData);
    }
  };

  const chosenId = useMemo(
    () => [...dataChecked].map(el => el.id),
    [dataChecked],
  );

  const dataCategoryInToView = useMemo(
    () =>
      [...dataCategory].map(el => {
        const findEl = [...dataChecked].find(findEl => findEl.id === el.id);
        if (findEl) {
          return findEl;
        } else {
          return el;
        }
      }),
    [dataChecked, dataCategory],
  );

  const handlePagination = param => {
    if (debounceSearchValue.length > 0) {
      dispatch(
        productOperation.getProductsBySearch({
          q: debounceSearchValue,
          ...param,
        }),
      );
    } else {
      dispatch(
        productOperation.getProduct({
          id: activeId,
          ...param,
        }),
      );
    }
  };

  const validation = () => {
    if (chosenId.length === 0 && isTopSale) {
      setOpenError(true);
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!validation()) return;
    onSubmitCallBack(chosenId);
  };

  const renderData =
    activeId === 0 && debounceSearchValue === ''
      ? dataChecked
      : dataCategoryInToView;

  const handelDetailCard = id => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };

  const customAction = el => {
    return (
      <Row>
        {chosenId.includes(el.id) && customElAction && customElAction(el)}
        <IconBtn
          href={`${Sprite}#icon-arrow_card`}
          onClick={() => handelDetailCard(el.id)}
          size={16}
          sx={{ marginTop: '10px' }}
          rotateSvg={openCard && choseIdCard === el.id ? '180deg' : '0'}
        />
      </Row>
    );
  };

  return (
    <>
      <Wrapper color={color} width={modalWidth}>
        <SearchInput
          onChange={handleChange}
          searchValue={search}
          placeholder={t('home:modals.total_price_modal.placeholder_search')}
          sx={{
            '& input': {
              fontSize: '0.875rem',
            },
          }}
          sxLabel={{ marginRight: '0', padding: '20px' }}
        />
        <Form id={'top_sale'} onSubmit={onSubmit} color={color}>
          <DrawerCategory
            expanded={expanded}
            setExpanded={setExpanded}
            handleById={payload =>
              dispatch(productOperation.getProduct(payload))
            }
            activeId={activeId}
            setActiveId={setActiveId}
            defaultOpen={true}
          >
            <Flex color={color}>
              {loading && <ProgressLoader />}
              <WrapperList>
                {renderData.length > 0 ? (
                  <>
                    <WrapperCatalogList activeId={activeId}>
                      <CustomScroll vertical={true}>
                        {renderData.map(el => (
                          <li key={el.id}>
                            <ProductCard
                              style={{
                                wrapper: { borderLeft: 'none' },
                                wrapperLabel: {
                                  width: '238px',
                                  marginLeft: '10px',
                                },
                                wrapperCost: { marginLeft: '10px' },
                              }}
                              data={el}
                              isHideAction={true}
                              checked={chosenId.includes(el.id)}
                              handleChoseProduct={handleChoseProduct(el)}
                              isCheckBox={true}
                              customAction={customAction}
                            />
                            {openCard && choseIdCard === el.id && (
                              <>
                                <CardDetails id={el.id} aboutOnly />
                                <Divider />{' '}
                              </>
                            )}
                          </li>
                        ))}
                      </CustomScroll>
                    </WrapperCatalogList>

                    {activeId !== 0 && (
                      <Pagination
                        meta={meta}
                        handelPagination={handlePagination}
                      />
                    )}
                  </>
                ) : !loading && search.length === 0 ? (
                  <Empty
                    type={'EmptyBlank'}
                    text={t('common:empty_modal_related_and_analog')}
                  />
                ) : (
                  !loading && <Empty type={'EmptySearchLogo'} />
                )}
              </WrapperList>
            </Flex>
          </DrawerCategory>
        </Form>{' '}
      </Wrapper>
      {openError && <MessageError errorText={t('validation:least_one_item')} />}
    </>
  );
};

const WrapperList = styled.div`
  height: 675px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  border: 1px solid ${({ color }) => color.line};
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${({ color }) => color.line};
`;

const WrapperCatalogList = styled.ul`
  min-width: 500px;
  height: ${({ activeId }) => (activeId === 0 ? '675px' : '600px')};
  width: 100%;

  overflow: hidden;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-left: 1px solid ${({ color }) => color.line};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  gap: 35px;
`;

PropTypes.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onSubmitCallBack: PropTypes.func.isRequired,
  customElAction: PropTypes.node.isRequired,
  modalWidth: PropTypes.number.isRequired,
  isTopSale: PropTypes.bool,
};
