import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LangManager } from '@root/components';
import { ButtonBase, OutlineButtonBase } from '@root/ui';

import optionSelectors from '@redux/option/option-selectors';

import { Typography } from '@mui/material';

export const AdminController = ({
  isEdit,
  onChangeMode,
  currentLang,
  handleChangeLang,
  onSubmit,
}) => {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  });
  const langsConf = useSelector(optionSelectors.getLangs);

  return (
    <>
      {!isEdit ? (
        <FlexWrapper>
          <ButtonBase
            onClick={() => onChangeMode(!isEdit)}
            size="medium"
            sx={{ height: '36px' }}
          >
            <Typography variant={'bodyBoldSmall'}>
              {t('common:buttons.edit')}
            </Typography>
          </ButtonBase>
        </FlexWrapper>
      ) : (
        <FlexStart>
          {' '}
          <LangManager
            conf={langsConf}
            currentLang={currentLang}
            handleChangeLang={handleChangeLang}
          />
          <div>
            <OutlineButtonBase
              onClick={() => {
                onSubmit(true);
              }}
              size="medium"
              sx={{ height: '36px' }}
            >
              <Typography variant={'bodyBoldSmall'}>
                {t('common:buttons.saveAndClose')}
              </Typography>
            </OutlineButtonBase>
            <ButtonBase
              onClick={() => onSubmit(false)}
              size="medium"
              sx={{ height: '36px', ml: '16px' }}
            >
              <Typography variant={'bodyBoldSmall'}>
                {t('common:buttons.save')}
              </Typography>
            </ButtonBase>
          </div>
        </FlexStart>
      )}
    </>
  );
};
const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlexStart = styled.div`
  display: flex;
  justify-content: space-between;
`;
AdminController.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onChangeMode: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  handleChangeLang: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
