import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Cropper, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/corners.css';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { cropActions, imageCropperActions } from '@root/conf';
import { theme } from '@root/styles';
import { ButtonBase, IconBtn, OutlineButtonBase } from '@root/ui';

import { useTheme } from '@mui/material';

export const ImageCropper = ({
  file,
  handleSetImage,
  handleCloseModal,
  movable = true,
  resizable = true,
  stencilVariant,
  stencilLines = false,
  stencilSize = false,
  stencilGrid = false,
  outputImageOptions,
  cropperVariant = ['rotate-flip'],
}) => {
  const cropperRef = useRef(null);
  const [cropData, setCropData] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isImageHeight, setIsImageHeight] = useState(null);
  const { t } = useTranslation();

  const getImageUrl = useMemo(
    () => () => file ? URL.createObjectURL(file) : '',
    [file],
  );

  const { color } = useTheme();

  const handleSetIsActive = () => {
    setIsActive(prevIsDisabled => !prevIsDisabled);
  };

  const handleRotatingImage = rotateDirection => {
    if (!cropperRef.current) return;
    cropperRef.current.rotateImage(rotateDirection);
  };
  const handleMirroringImage = (horizontal, vertical) => {
    if (!cropperRef.current) return;
    cropperRef.current.flipImage(horizontal, vertical);
  };

  const getCropData = () => {
    if (!cropperRef.current) return;
    const cropper = cropperRef.current;
    const outputImage = cropper?.getCanvas(outputImageOptions).toDataURL();
    setCropData(outputImage);
    handleSetImage(outputImage);
    handleCloseModal();
  };

  const handleUndoAllChanges = () => {
    if (!cropperRef.current) return;
    cropperRef.current.reset();
    handleCloseModal();
    if (cropData) {
      setCropData(null);
    }
  };

  const removeImageBackground = () => {};

  const onActionChange = action => {
    const { actionName } = action;
    switch (actionName) {
      case cropActions.crop: {
        return handleSetIsActive();
      }
      case cropActions.horizontal_mirroring: {
        return handleMirroringImage(true, false);
      }
      case cropActions.vertical_mirroring: {
        return handleMirroringImage(false, true);
      }
      case cropActions.rotate_right: {
        return handleRotatingImage(-90);
      }
      case cropActions.rotate_left: {
        return handleRotatingImage(90);
      }
      case cropActions.remove_bg: {
        return removeImageBackground();
      }
    }
  };

  useEffect(() => {
    const tempURL = URL.createObjectURL(file);
    const image = new Image();

    image.src = tempURL;
    image.onload = () => {
      if (image.naturalHeight < 500) {
        setIsImageHeight(`${image.naturalHeight}px !important`);
      } else {
        setIsImageHeight(
          (image.naturalWidth / image.naturalHeight) * 500 + 'px !important',
        );
      }
    };
  }, [file]);

  return (
    <CropperWrapper>
      {isImageHeight && (
        <ImageWrapper getAspectRatioImageHeight={isImageHeight}>
          <Cropper
            ref={cropperRef}
            src={getImageUrl()}
            className="cropper"
            stencilComponent={stencilVariant}
            stencilProps={{
              aspectRatio: 23 / 6,
              overlayClassName: 'overlay',
              lines: stencilLines,
              grid: stencilGrid,
              movable,
              resizable,
            }}
            imageRestriction={ImageRestriction.fitArea}
            minHeight={stencilSize.height}
            minWidth={stencilSize.width}
            maxHeight={stencilSize.height}
            maxWidth={stencilSize.width}
          />
        </ImageWrapper>
      )}

      <ActionsFlexWrapper color={color.background}>
        <UndoButtonWrapper>
          <OutlineButtonBase
            onClick={handleUndoAllChanges}
            sx={{ padding: '5px 16px' }}
          >
            {t('common:buttons.reject')}
          </OutlineButtonBase>
        </UndoButtonWrapper>
        <ToolbarList color={color.line_light} padding="20px 29px">
          {imageCropperActions.map(toolbarAction => (
            <Item
              visible={cropperVariant.includes(toolbarAction.typeName)}
              key={toolbarAction.actionName}
              onClick={() => onActionChange(toolbarAction)}
            >
              <IconBtn
                saved={toolbarAction.actionName === 'crop' ? isActive : false}
                size="24px"
                href={`${Sprite}#${toolbarAction.iconName}`}
              />
            </Item>
          ))}
        </ToolbarList>
        <SaveButtonWrapper>
          <ButtonBase onClick={getCropData}>
            {' '}
            {t('common:buttons.save')}
          </ButtonBase>
        </SaveButtonWrapper>
      </ActionsFlexWrapper>
    </CropperWrapper>
  );
};

const CropperWrapper = styled.div``;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  width: 100%;

  .cropper {
    background: ${() => theme.color.white};
  }

  .advanced-cropper-boundary__stretcher {
    width: auto !important;
    height: ${({ getAspectRatioImageHeight }) => getAspectRatioImageHeight};
    max-height: 700px;
  }
`;

const ActionsFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 72px;
  background-color: ${props => props.color};
  border-radius: 4px;
`;

const UndoButtonWrapper = styled.div`
  padding-right: 20px;
  padding-left: 20px;
`;
const SaveButtonWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const ToolbarList = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 36px;
  padding: ${props => props.padding};
  border-left: 1px solid ${props => props.color};
  border-right: 1px solid ${props => props.color};
`;

const Item = styled.li`
  display: ${props => (props.visible ? 'block' : 'none')};
  flex: 1 1 24px;
  cursor: pointer;
`;

ImageCropper.propTypes = {
  file: PropTypes.object.isRequired,
  handleSetImage: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  movable: PropTypes.bool,
  resizable: PropTypes.bool,
  stencilVariant: PropTypes.object,
  stencilLines: PropTypes.bool,
  stencilSize: PropTypes.bool,
  stencilGrid: PropTypes.bool,
  outputImageOptions: PropTypes.object,
  cropperVariant: PropTypes.arrayOf(PropTypes.string),
};
