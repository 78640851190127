import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { SavedOrderAccordion } from '@root/components';
import { CustomScroll } from '@root/ui';

import comparisonsSelectors from '@redux/comparisons/comparisons-selectors';

import { Typography } from '@mui/material';

import { MyLinksAccordionDetails } from './MyLinksAccordionDetails';
import { MyLinksAccordionSummary } from './MyLinksAccordionSummary';

export const MyLinks = ({ openConfirmModal }) => {
  const listData = useSelector(comparisonsSelectors.getComparisonPdfLinksList);
  const { t } = useTranslation('comparisons', { useSuspense: false });

  return (
    <MyLinksWrapper>
      <CustomScroll vertical>
        <MyLinksInner>
          {listData && listData.length > 0 ? (
            listData.map(row => (
              <SavedOrderAccordion
                key={row.id}
                accordionSummary={
                  <MyLinksAccordionSummary
                    id={row.id}
                    name={row.name}
                    description={row.description}
                    fileLink={row.url}
                    viewsCount={row.views_count}
                    openConfirmModal={openConfirmModal}
                  />
                }
              >
                {row.products.map(product => (
                  <MyLinksAccordionDetails
                    key={product.id}
                    data={product}
                    currency={row.currency}
                  />
                ))}
              </SavedOrderAccordion>
            ))
          ) : (
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                alignSelf: 'center',
              }}
            >
              {t('no_files_text')}
            </Typography>
          )}
        </MyLinksInner>
      </CustomScroll>
    </MyLinksWrapper>
  );
};

const MyLinksWrapper = styled.div`
  height: 600px;
  overflow-y: auto;
`;

const MyLinksInner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-right: 5px;

  .MuiPaper-root:before {
    display: none !important;
  }
`;
