import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { AddProductToListOfCategoryModal } from '@root/components';

import productOperation from '@redux/product/product-operation';

export const SimilarProductsModal = ({ data, handleCloseModal, id }) => {
  const dispatch = useDispatch();

  const onSubmit = chosenId => {
    dispatch(
      productOperation.addAnalogueProductsById({ id, products: chosenId }),
    ).then(() => handleCloseModal());
  };
  return (
    <>
      <AddProductToListOfCategoryModal
        data={data}
        handleCloseModal={handleCloseModal}
        onSubmitCallBack={onSubmit}
      />
    </>
  );
};

SimilarProductsModal.propTypes = {
  data: PropTypes.array.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
