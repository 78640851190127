import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';

import { Snackbar, SnackbarContent, useTheme } from '@mui/material';

export const Notification = ({
  open,
  handleCloseNotification,
  action,
  message,
  width,
}) => {
  const { color } = useTheme();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleCloseNotification}
      autoHideDuration={3000}
    >
      <SnackbarContent
        sx={{
          borderRadius: '4px',
          border: `1px solid ${color.line}`,
          background: color.gray_20,
          height: '60px',
          color: 'black',
          width,
        }}
        onClose={handleCloseNotification}
        message={
          <MessageWrapper>
            <svg height={16} width={16} style={{ marginRight: '5px' }}>
              <use href={`${Sprite}#icon-done`}></use>
            </svg>
            {message}
          </MessageWrapper>
        }
        action={action}
      ></SnackbarContent>
    </Snackbar>
  );
};

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseNotification: PropTypes.func.isRequired,
  action: PropTypes.node,
  message: PropTypes.string,
  width: PropTypes.string,
};
