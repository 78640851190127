import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CopyToClipboard } from '@root/components';
import { OutlineButtonBase } from '@root/ui';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const MyLinksAccordionSummary = ({
  id,
  name,
  fileLink,
  description,
  viewsCount,
  openConfirmModal,
}) => {
  const { color } = useTheme();
  const [copied, setCopied] = useState(false);

  const onCopySuccess = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1200);
  };

  return (
    <AccordionSummaryWrapper>
      <AccordionSummaryText onClick={e => e.stopPropagation()}>
        <Link href={fileLink}>
          <Typography variant="bodyMedium" color={color.main} component="p">
            {name}
          </Typography>
        </Link>
        <Typography
          component="p"
          variant="bodySmall"
          color={color.gray_60}
          marginTop="4px"
        >
          {description}
        </Typography>
        <Typography
          component="p"
          variant="bodySmall"
          color={color.gray_60}
          sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
          marginTop="7px"
        >
          <Svg>
            <use href={`${Sprite}#icon-eye`} />
          </Svg>
          {viewsCount}
        </Typography>
      </AccordionSummaryText>

      <AccordionSummaryActions onClick={e => e.stopPropagation()}>
        <CopyToClipboard
          copyText={fileLink}
          copyIcon={false}
          onCopySuccess={onCopySuccess}
        >
          <OutlineButtonBase sx={{ minWidth: '36px' }}>
            {!copied ? (
              <Svg>
                <use href={`${Sprite}#icon-copy-link`} />
              </Svg>
            ) : (
              <Svg fill={color.green}>
                <use href={`${Sprite}#icon-mark_big`} />
              </Svg>
            )}
          </OutlineButtonBase>
        </CopyToClipboard>
        <OutlineButtonBase
          onClick={() => openConfirmModal(id)}
          sx={{ minWidth: '36px' }}
        >
          <Svg>
            <use href={`${Sprite}#icon-delete`} />
          </Svg>
        </OutlineButtonBase>
      </AccordionSummaryActions>
    </AccordionSummaryWrapper>
  );
};

const AccordionSummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-right: 24px;
  column-gap: 18px;
  justify-content: space-between;
`;
const AccordionSummaryText = styled.div``;

const AccordionSummaryActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const Svg = styled.svg`
  fill: ${props => props.fill};
  width: 15px !important;
  height: 15px !important;
`;

const Link = styled.a``;
