import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ToggleBtn } from '@root/ui';

export const Managing = ({ onAddToCartProduct, onDeleteProduct, canBuy }) => {
  return (
    <ManagingWrapper>
      <ToggleBtn
        isActive={canBuy}
        disabled={!canBuy}
        size={32}
        href={`${Sprite}#icon-grocery_cart`}
        onClick={() => onAddToCartProduct()}
      />
      <ToggleBtn
        size={32}
        href={`${Sprite}#icon-delete`}
        onClick={() => onDeleteProduct()}
      />
    </ManagingWrapper>
  );
};

const ManagingWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

Managing.propTypes = {
  onAddToCartProduct: PropTypes.func.isRequired,
  onDeleteProduct: PropTypes.func.isRequired,
  canBuy: PropTypes.bool.isRequired,
};
