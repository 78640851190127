import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '@root/assets/image';
import { Sprite } from '@root/assets/svg';
import {
  ConfirmModal,
  ImgPrev,
  LabelProduct,
  Pagination,
} from '@root/components';
import { AdminProductsConf } from '@root/conf';
import { handleDispatchFetch } from '@root/helpers';
import { useQueryParams } from '@root/hooks';
import {
  IconBtn,
  OutlineButtonBase,
  ProgressLoader,
  TextInput,
} from '@root/ui';

import adminOperation from '@redux/admin/admin-operation';
import adminSelectors from '@redux/admin/admin-selectors';
import {
  updateAdminProductById,
  updateAdminProductOnDeleteById,
  updateAdminProductVisibility,
} from '@redux/admin/admin-slice';
import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';

import {
  Divider,
  FormControl,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const AdminProductsTable = memo(({ handleNavSection }) => {
  // const [adminProductId, setAdminProductId] = useState();
  const [sortingType, setSortingType] = useState(null);
  const [sortingOn, setSortingOn] = useState(false);
  const [sortDirection, setSortDirection] = useState(null);

  const loadingProducts = useSelector(adminSelectors.getLoadingProducts);
  const businessesData = useSelector(adminSelectors.getData);
  const meta = useSelector(adminSelectors.getMeta);

  const { adminTab } = useParams();
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();
  const { getQueryObj, setSearchParams } = useQueryParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!adminTab) return;
    const { page, per_page, category_id, sort, order } = getQueryObj();
    const initPagination = { page, per_page };
    handleNavSection(adminTab, initPagination);
    dispatch(
      adminOperation.getAdminProducts({
        ...initPagination,
        category_id,
        sort,
        order,
      }),
    );
  }, []);

  const handleSorting = type => {
    switch (type) {
      case 'name': {
        handleGetSortedAdminProducts(type, sortDirection);
        setSortDirection(sortDirection === type ? null : type);
        break;
      }
      case 'price': {
        handleGetSortedAdminProducts(type, sortDirection);
        setSortDirection(sortDirection === type ? null : type);
        break;
      }
      case 'price_cron': {
        handleGetSortedAdminProducts(type, sortDirection);
        setSortDirection(sortDirection === type ? null : type);
        break;
      }
      case 'stock': {
        handleGetSortedAdminProducts(type, sortDirection);
        setSortDirection(sortDirection === type ? null : type);
        break;
      }
      default: {
        break;
      }
    }
    setSortingType(type);
    setSortingOn(!sortingOn);
  };

  const handleGetSortedAdminProducts = (type, sortDirection) => {
    dispatch(
      adminOperation.getAdminProducts({
        category_id: getQueryObj().category_id,
        page: getQueryObj().page,
        per_page: getQueryObj().per_page,
        sort: type,
        order: sortDirection === type ? 'desc' : 'asc',
      }),
    );

    setSearchParams({
      category_id: getQueryObj().category_id,
      page: getQueryObj().page,
      per_page: getQueryObj().per_page,
      sort: type,
      order: sortDirection === type ? 'desc' : 'asc',
    });
  };

  const handleUpdateAdminProductOnSubmit = ({ e, field, id }) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      const value = e.target.value;
      dispatch(adminOperation.updateAdminProductById({ id, field, value }));
    }
  };

  const handleUpdateAdminProductsOnBlur = ({ e, field, id }) => {
    const value = e.target.value;
    dispatch(adminOperation.updateAdminProductById({ id, field, value }));
  };

  const handleToggleAdminProductVisibility = ({ id, value }) => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          adminOperation.updateAdminProductById({
            onResolve,
            onReject,
            id,
            field: 'is_active',
            value: value ? 1 : 0,
          }),
        ),
      response => {
        dispatch(updateAdminProductVisibility({ id, data: response.data }));
      },
    );
  };

  const handleDeleteAdminProduct = ({ id }) => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          adminOperation.deleteAdminProductById({
            id,
            onResolve,
            onReject,
          }),
        ),
      () => {
        dispatch(updateAdminProductOnDeleteById(id));
        dispatch(clearModalState());
      },
    );
  };

  const handleUpdateAdminProduct = ({ field, value, id }) => {
    dispatch(updateAdminProductById({ field, value, id }));
  };

  const handlePagination = param => {
    const { category_id, sort, order } = getQueryObj();
    handleNavSection(adminTab, param, false);
    dispatch(
      adminOperation.getAdminProducts({
        ...param,
        category_id,
        sort,
        order,
      }),
    );
  };

  const handleOpenModal = id => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        dataForConfirm: {
          id: id,
        },
        callback: data => handleDeleteAdminProduct(data),
      }),
    );
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          height: `calc(100% - 120px)`,
          overflowX: 'initial',
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            height: `calc(100% - 78px)`,
            position: 'relative',
          }}
        >
          <TableHead>
            <TableRow sx={{ display: 'flex', p: '12px 25px 12px 12px' }}>
              {AdminProductsConf(t).map((el, i) => {
                return (
                  <TableCell
                    key={el.label}
                    sx={{
                      borderBottom: 'none',
                      ...el.style,
                      p: 0,
                    }}
                  >
                    <OpacityContainer opacity={sortingType === el.type ? 1 : 0}>
                      <Typography
                        variant={'bodyBoldSmall'}
                        component={'p'}
                        color={color.gray_60}
                      >
                        {el.label}
                      </Typography>
                      {el.sort && (
                        <IconBtn
                          href={`${Sprite}#icon-sorting`}
                          onClick={() => handleSorting(el.type)}
                          size={16}
                          sx={{
                            marginLeft: '6px',
                          }}
                          rotateSvgX={
                            sortDirection === el.type ? '180deg' : false
                          }
                        />
                      )}
                    </OpacityContainer>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <Divider />
          <TableBody color={color}>
            {businessesData &&
              businessesData.length !== 0 &&
              businessesData.map((row, i) => (
                <TableRow
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: '20px 20px 20px 16px',
                    minHeight: '99px',
                    borderBottom: `1px solid ${color.line}`,
                    th: {
                      borderBottom: 'none',
                    },
                    '&:last-child, &:last-child th': { borderBottom: 0 },
                  }}
                  key={`p` + i}
                >
                  {AdminProductsConf(t).map((el, idx) => {
                    switch (el.value) {
                      case 'product':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{
                              p: 0,
                              ...el.style,
                            }}
                          >
                            <ImgPrev
                              src={row.image}
                              countImg={row.images_quantity}
                            />
                          </TableCell>
                        );
                      case 'name':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{
                              p: 0,
                              ...el.style,
                            }}
                          >
                            <LabelProduct
                              label={row.name}
                              article={row.sku}
                              parm={row.model}
                            />
                          </TableCell>
                        );
                      case 'price':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{
                              p: 0,
                              ...el.style,
                            }}
                          >
                            <FormControl
                              onKeyUp={e =>
                                handleUpdateAdminProductOnSubmit({
                                  id: row.id,
                                  field: 'price',
                                  e,
                                })
                              }
                            >
                              <TextInput
                                sx={{ height: '36px' }}
                                value={row.price}
                                type="text"
                                onChange={value =>
                                  handleUpdateAdminProduct({
                                    field: 'price',
                                    value,
                                    id: row.id,
                                  })
                                }
                                max="10"
                                digitsAndDotOnly
                                onBlur={e =>
                                  handleUpdateAdminProductsOnBlur({
                                    e,
                                    field: 'price',
                                    id: row.id,
                                  })
                                }
                                withOutSpace={true}
                              />
                            </FormControl>
                          </TableCell>
                        );
                      case 'price_cron':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{
                              p: 0,
                              ...el.style,
                            }}
                          >
                            <FormControl
                              onKeyUp={e =>
                                handleUpdateAdminProductOnSubmit({
                                  id: row.id,
                                  field: 'price_cron',
                                  e,
                                })
                              }
                            >
                              <TextInput
                                type="text"
                                sx={{ height: '36px' }}
                                value={row.price_cron}
                                onChange={value =>
                                  handleUpdateAdminProduct({
                                    field: 'price_cron',
                                    value,
                                    id: row.id,
                                  })
                                }
                                onBlur={e =>
                                  handleUpdateAdminProductsOnBlur({
                                    field: 'price_cron',
                                    id: row.id,
                                    e,
                                  })
                                }
                                max="10"
                                digitsAndDotOnly
                                withOutSpace={true}
                              />
                            </FormControl>
                          </TableCell>
                        );
                      case 'stock':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{
                              p: 0,
                              ...el.style,
                            }}
                          >
                            <FormControl
                              onKeyUp={e =>
                                handleUpdateAdminProductOnSubmit({
                                  id: row.id,
                                  field: 'stock',
                                  e,
                                })
                              }
                            >
                              <TextInput
                                digitsOnly
                                type="text"
                                sx={{ height: '36px' }}
                                value={row.stock}
                                onChange={value =>
                                  handleUpdateAdminProduct({
                                    field: 'stock',
                                    value,
                                    id: row.id,
                                  })
                                }
                                onBlur={e =>
                                  handleUpdateAdminProductsOnBlur({
                                    field: 'stock',
                                    id: row.id,
                                    e,
                                  })
                                }
                                withOutSpace={true}
                                max="10"
                              />
                            </FormControl>
                          </TableCell>
                        );
                      case 'actions':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{
                              p: 0,
                              ...el.style,
                              display: 'flex',
                              columnGap: '12px',
                            }}
                          >
                            <OutlineButtonBase
                              sx={{
                                minWidth: '36px',
                                height: '36px',
                              }}
                              onClick={() =>
                                handleToggleAdminProductVisibility({
                                  id: row.id,
                                  value: !row.is_active,
                                })
                              }
                            >
                              <Svg>
                                <use
                                  href={`${Sprite}#${
                                    row.is_active ? 'icon-eye' : 'icon-eye-show'
                                  }`}
                                />
                              </Svg>
                            </OutlineButtonBase>
                            <OutlineButtonBase
                              sx={{
                                minWidth: '36px',
                                height: '36px',
                              }}
                              href={`${Sprite}#icon-edit`}
                            >
                              <Svg>
                                <use href={`${Sprite}#icon-edit`} />
                              </Svg>
                            </OutlineButtonBase>
                            <OutlineButtonBase
                              sx={{
                                minWidth: '36px',
                                height: '36px',
                              }}
                              onClick={() => handleOpenModal(row.id)}
                            >
                              <Svg>
                                <use href={`${Sprite}#icon-delete`} />
                              </Svg>
                            </OutlineButtonBase>
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                          >
                            {row[el.value]}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              ))}
            {loadingProducts && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ p: 0, width: '100%', display: 'block' }}
                >
                  <ProgressLoader />
                </TableCell>
              </TableRow>
            )}
            {businessesData && businessesData.length === 0 && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ p: 0, width: '100%', display: 'block' }}
                >
                  <EmptyBox>
                    <img src={Box} alt="Box" />
                    <Typography
                      variant={'bodyBoldSmall'}
                      component={'p'}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: '40px',
                        mb: '20px',
                        color: color.gray,
                      }}
                    >
                      {t('admin:empty_title')}
                    </Typography>
                  </EmptyBox>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          meta={meta}
          handelPagination={handlePagination}
          subTitle={t('common:pagination.products')}
        />
      </TableContainer>
    </>
  );
});

const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);

  overflow-y: auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ color }) => color.gray_40};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ color }) => color.white};
    border-left: 1px solid ${({ color }) => color.line};
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const OpacityContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:hover button {
    opacity: 1;
  }

  & button {
    opacity: ${({ opacity }) => opacity};
  }
`;

const Svg = styled.svg`
  width: 15px;
  height: 15px;
`;

AdminProductsTable.propTypes = {
  handleNavSection: PropTypes.func.isRequired,
};
