import { theme } from '@root/styles';

export const whiteTooltipStyles = {
  tooltip: {
    sx: {
      backgroundColor: theme.color.white,
      border: theme.borders.tooltipGrayBorder,
      padding: '9px 10px',
      borderRadius: '4px',
      maxWidth: 400,
    },
  },

  arrow: {
    sx: {
      color: theme.color.white,
      '&:before': {
        border: theme.borders.tooltipGrayBorder,
      },
    },
  },
};

export const whiteTooltipPriceStyles = {
  bold: {
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: '130%',
    color: theme.color.gray,
  },
  light: {
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '12px',
    color: theme.palette.primary.main,
  },
};
