import styled from 'styled-components';

import { useTheme } from '@mui/material/styles';

export const Container = ({ children, width, padding, hidden = false }) => {
  const { color } = useTheme();
  return (
    <Component
      color={color.background}
      width={width}
      padding={padding}
      isHidden={hidden}
    >
      {children}
    </Component>
  );
};

const Component = styled.div`
  margin: 0 auto;
  min-width: ${({ width }) => width}px;
  background-color: ${props => props.color};
  padding: ${({ padding }) => (padding ? padding : 0)};
  overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  height: 100%;
`;

Container.className = Component;
