import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField, Empty } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { AvatarUI, CustomizedSwitches } from '@root/ui';

import adminOperation from '@redux/admin/admin-operation';
import { updateUserStatusById } from '@redux/admin/admin-slice';

import { Typography, useTheme } from '@mui/material';

export const UsersForm = ({ data }) => {
  const { email, city, birthday, phones, avatar, company, name, status, id } =
    data;
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const [isEdit, setIsEdit] = useState(false);
  const [userStatus, setUserStatus] = useState('');

  const dispatch = useDispatch();

  const { color } = useTheme();

  useEffect(() => {
    setUserStatus(status);
  }, [status]);

  const handleSetUserStatus = value => {
    setUserStatus(value ? 'active' : 'inactive');
  };
  const handleSetIsEdit = () => setIsEdit(prevIsEdit => !prevIsEdit);

  const getUserStatus = () => {
    return isEdit ? (
      <>
        <CustomizedSwitches
          sx={{ margin: 0 }}
          onChange={handleSetUserStatus}
          checked={userStatus === 'active'}
        />
        {userStatus === 'active'
          ? t('admin:active_status')
          : t('admin:inactive_status')}
      </>
    ) : (
      <StatusWrapper
        userStatus={userStatus}
        red={color.red}
        green={color.green}
      >
        {userStatus === 'active'
          ? t('admin:active_status')
          : t('admin:inactive_status')}
      </StatusWrapper>
    );
  };

  const handleUpdateUserStatus = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          adminOperation.updateUserById({
            status: userStatus,
            id,
            onResolve,
            onReject,
          }),
        ),
      () => {
        dispatch(updateUserStatusById({ status: userStatus, id }));
        setIsEdit(false);
      },
    );
  };

  const handleDiscardUserStatus = () => {
    setIsEdit(false);
    setUserStatus(status);
  };

  return (
    <Form>
      <AvatarUI src={avatar}>
        <Empty
          type={'EmptyLogo'}
          isCompany={false}
          name={name}
          noAvatarSx={{
            fontSize: '2rem',
          }}
        />
      </AvatarUI>
      <Typography
        variant={'h2'}
        sx={{ textTransform: 'uppercase', mt: '28px' }}
      >
        {name}
      </Typography>
      <Wrapper>
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          applyChanges={handleUpdateUserStatus}
          discardChanges={handleDiscardUserStatus}
          isEdit={isEdit}
          padding={'28px 0'}
          title={t('admin:people_table_conf.status')}
          value={getUserStatus()}
          variant={'bodyMedium'}
          goToStep={handleSetIsEdit}
          sx={{
            mr: '12px',
          }}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={'Email'}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={email}
          goToStep={() => {}}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          type={'phone'}
          padding={'28px 0'}
          title={t('admin:people_table_conf.phone')}
          variant={'bodyMedium'}
          sx={{ mr: '12px', ml: '-15px' }}
          value={phones}
          goToStep={() => {}}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:people_table_conf.business')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={company?.name}
          goToStep={() => {}}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:people_table_conf.counterparts')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={company?.official_name}
          goToStep={() => {}}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:people_table_conf.role')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={'Роль 1'}
          goToStep={() => {}}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:people_table_conf.birthday')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={birthday ? moment(birthday).format('DD.MM.YYYY') : ''}
          goToStep={() => {}}
        />
        <ContactField
          titleStyles={{ flex: '0 0 120px' }}
          padding={'28px 0'}
          title={t('admin:people_table_conf.city')}
          variant={'bodyMedium'}
          sx={{ mr: '12px' }}
          value={city?.name}
          goToStep={() => {}}
        />
      </Wrapper>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-bottom: 50px;
`;

const Wrapper = styled.div`
  margin-top: 28px;
`;

const StatusWrapper = styled.div`
  &::before {
    display: inline-block;
    content: '';
    width: 8px;
    height: 8px;
    background: ${({ userStatus, green, red }) =>
      userStatus === 'active' ? green : red};
    border-radius: 40px;
    margin-right: 5px;
  }
`;

UsersForm.propTypes = {
  data: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    city_id: PropTypes.number,
    company: PropTypes.exact({
      additional_info: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      created_at: PropTypes.string,
      dic: PropTypes.string,
      employees_quantity: PropTypes.number,
      ico: PropTypes.string,
      id: PropTypes.number,
      identification_code: PropTypes.number,
      legal_address: PropTypes.string,
      logo: PropTypes.string,
      mailing_address: PropTypes.string,
      name: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
      updated_at: PropTypes.string,
      user_id: PropTypes.number,
    }),
    created_at: PropTypes.string,
    deleted_at: PropTypes.string,
    deleted_by: PropTypes.string,
    email: PropTypes.string,
    email_verified_at: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        phone: PropTypes.string,
        confirm: PropTypes.bool,
        user_id: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
      }),
    ),
    registered_at: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.exact({
        created_at: PropTypes.string,
        guard_name: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        pivot: PropTypes.exact({
          model_id: PropTypes.number,
          model_type: PropTypes.string,
          role_id: PropTypes.number,
        }),
        updated_at: PropTypes.string,
      }),
    ),
    status: PropTypes.string,
    updated_at: PropTypes.string,
    use_price: PropTypes.string,
  }),
};
