export const cartConf = t => [
  {
    label: t('cart:cart_conf.all'),
    value: 'selectProduct',
    type: 'img',
    style: { maxWidth: '82px', width: '100%' },
  },
  {
    label: t('cart:cart_conf.name'),
    value: 'name',
    type: 'email',
    style: { marginLeft: '32px', maxWidth: '422px', width: '100%' },
  },
  {
    label: t('cart:cart_conf.price'),
    value: 'cost',
    type: 'cost',
    style: { marginLeft: '30px', maxWidth: '110px', width: '100%' },
  }, // change
  {
    label: t('cart:cart_conf.availability'),
    value: 'availability',
    type: 'availability',
    style: { marginLeft: '139px', maxWidth: '100px', width: '100%' },
  }, // change
  {
    label: '',
    value: 'action',
    type: 'action',
    style: { marginLeft: 'auto', maxWidth: '200px', width: '100%' },
  }, // change
];
