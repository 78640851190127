import axios from 'axios';

const $http = axios.create();
delete $http.defaults.headers.common['Authorization'];

const defaultNpApiKey = '123456';

export const novaPoshtaReqCities = (
  dropNpApiKey,
  modelName = 'Address',
  calledMethod = 'getCities',
  page = 1,
  search = '',
  ref = '',
) => {
  return $http.post('https://api.novaposhta.ua/v2.0/json/', {
    apiKey: !dropNpApiKey ? defaultNpApiKey : dropNpApiKey,
    modelName: modelName,
    calledMethod: calledMethod,
    methodProperties: {
      Page: page,
      Limit: 20,
      FindByString: search,
      Ref: ref.length ? ref : '',
    },
  });
};
export const novaPoshtaDepartmentOrPostOffice = (
  dropNpApiKey,
  ref,
  search = '',
  page = 1,
  isOffice,
) => {
  const typeWarehouse = !isOffice
    ? '841339c7-591a-42e2-8233-7a0a00f0ed6f'
    : 'f9316480-5f2d-425d-bc2c-ac7cd29decf0';

  return $http.post('https://api.novaposhta.ua/v2.0/json/', {
    apiKey: !dropNpApiKey ? defaultNpApiKey : dropNpApiKey,
    modelName: 'Address',
    calledMethod: 'getWarehouses',
    methodProperties: {
      Page: page,
      Limit: 20,
      FindByString: search,
      CityRef: ref,
      TypeOfWarehouseRef: typeWarehouse,
    },
  });
};

export const getNovaPoshtaDepartmentOrOfficeByRef = (
  dropNpApiKey,
  city_ref,
  department_or_office_ref,
  isOffice,
) => {
  const typeWarehouse = !isOffice
    ? '841339c7-591a-42e2-8233-7a0a00f0ed6f'
    : 'f9316480-5f2d-425d-bc2c-ac7cd29decf0';

  return $http.post('https://api.novaposhta.ua/v2.0/json/', {
    apiKey: !dropNpApiKey ? defaultNpApiKey : dropNpApiKey,
    modelName: 'Address',
    calledMethod: 'getWarehouses',
    methodProperties: {
      Limit: 20,
      CityRef: city_ref,
      Ref: department_or_office_ref,
      TypeOfWarehouseRef: typeWarehouse,
    },
  });
};
