import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { useTheme } from '@mui/material/styles';

export const EditButton = ({
  isEdit,
  discardChanges,
  applyChanges,
  goToStep,
}) => {
  const { color } = useTheme();
  return isEdit ? (
    <ButtonsWrapper>
      <IconBtn
        onClick={() => discardChanges && discardChanges()}
        href={`${Sprite}#icon-cross`}
        sx={{ stroke: color.red, fill: color.red }}
      />
      <IconBtn
        onClick={() => applyChanges && applyChanges()}
        href={`${Sprite}#icon-tick`}
        sx={{ stroke: color.green, fill: color.green }}
      />
    </ButtonsWrapper>
  ) : (
    <IconBtn
      href={`${Sprite}#icon-pan`}
      onClick={() => goToStep && goToStep()}
    />
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 7px;
  align-items: center;
`;

EditButton.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  discardChanges: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
};
