import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  configuration: {
    open: false,
  },
};

const notificationWatcherSlice = createSlice({
  name: 'notificationWatcher',
  initialState,
  reducers: {
    setOpenNotification(state, { payload }) {
      state.configuration = { ...state.configuration, ...payload };
    },
    clearNotificationState(state, { payload }) {
      state.configuration = initialState.configuration;
    },
  },
});

export const { setOpenNotification, clearNotificationState } =
  notificationWatcherSlice.actions;

export default notificationWatcherSlice.reducer;
