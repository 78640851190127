import React from 'react';

import PropTypes from 'prop-types';

import { Step, StepLabel, Stepper } from '@mui/material';

export const StepperBase = ({
  conf,
  step,
  defection = 'vertical',
  connector,
  text,
  iconComponent,
  sx = {},
  sxLabel,
  optional = () => {},
}) => {
  return (
    <Stepper activeStep={step} orientation={defection} connector={connector}>
      {conf.map(({ label, description }, i) => (
        <Step
          key={label}
          sx={{
            minHeight: '37px',
            ...sx,
          }}
        >
          <StepLabel
            optional={optional(description)}
            sx={{
              ...sxLabel(i),
              '& .Mui-completed': {
                fontWeight: '400 !important',
              },
            }}
            StepIconComponent={iconComponent}
          >
            {text(label, i)}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

StepperBase.propTypes = {
  conf: PropTypes.array.isRequired,
  step: PropTypes.number.isRequired,
  defection: PropTypes.string,
  connector: PropTypes.node,
  text: PropTypes.string,
  iconComponent: PropTypes.node,
  sx: PropTypes.object,
  sxLabel: PropTypes.object,
  optional: PropTypes.func.isRequired,
};
