import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getErrorStack, validEmail } from '@root/helpers';

export const useOrder = ({ servicesValidation, available_methods }) => {
  const [data, setData] = useState({
    name: '',
    email: '',
    surname: '',
    phone: '',
    delivery_type: 'Self_pickup',
    delivery_filial: '',
    delivery_info: {
      type: 'SELF_PICKUP',
      city_title: '',
      department_data: {},
      street_title: '',
      street_number: '',
      flat_num: '',
      zip_code: '',
      npPhone: '',
      date: '',
    },
    payment_type: 'Online',
    id: '',
    dont_call: false,
    discount: '',
  });
  const [error, setError] = useState({});
  const { t } = useTranslation(['validation']);

  const onChange = (name, key) => value => {
    if (key === 'dpd_courier') {
      setData(prevState => {
        return {
          ...prevState,
          delivery_info: {
            ...prevState.delivery_info,
            [name]: value,
          },
        };
      });
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const getDefaultChildrenType = value => {
    const items = available_methods?.delivery_types.find(
      el => el.key === value,
    ).items;
    return items.find(el => el.isDefault);
  };

  const onChangeRadio = name => event => {
    if (name === 'delivery_type') {
      const type = getDefaultChildrenType(event.target.value);
      setData(prevState => ({
        ...prevState,
        [name]: event.target.value,
        delivery_filial: '',
        delivery_info: {
          ...prevState.delivery_info,
          type: type?.key,
        },
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleSelectFilial = value => {
    setData(prevState => ({ ...prevState, delivery_filial: value }));
  };

  const handleCardRadio = val => {
    setData(prevState => {
      return {
        ...prevState,
        delivery_info: {
          ...prevState.delivery_info,
          type: val,
          city_title: '',
          city_ref: '',
          department_ref: '',
          department_title: '',
          post_office_ref: '',
          post_office_title: '',
          street_title: '',
          street_number: '',
          flat_num: '',
          zip_code: '',
          npPhone: '',
          date: '',
        },
      };
    });
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { name, surname, phone, email } = data;

    Object.keys({
      name,
      surname,
      phone,
      email,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (validEmail(email) && email !== '') {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:incorrect_email'),
      );
      flag = true;
    }

    Object.keys({ name, surname }).forEach(key => {
      if (data[key].length !== 0 && data[key].length < 2) {
        currentError = getErrorStack(
          currentError,
          key,
          `${t('validation:minLengthLetters')}2`,
        );
        flag = true;
      }
    });

    if (phone?.length < 12 && phone !== '') {
      currentError = getErrorStack(
        currentError,
        'phone',
        t('validation:input_phone_number'),
      );
      flag = true;
    }

    setError(currentError);

    flag = servicesValidation(data, setError, currentError, t, flag);

    return flag;
  };

  return {
    onChange,
    error,
    onChangeRadio,
    handleSelectFilial,
    handleCardRadio,
    validation,
    data,
    setData,
  };
};
