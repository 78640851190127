import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty } from '@root/components';
import { customStyles } from '@root/conf/SearchConf';
import { CheckboxBase, CustomScroll } from '@root/ui';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Button, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Card } from './Card';

export const MenuSearch = ({
  dataCard,
  total = 0,
  data,
  handelClearSearch,
  onChange,
  handleMoveToSearchPage,
  handleMoveToItemPage,
  addAnimation,
  handleAddToCart,
  indentFactorContainer = '210px',
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['search'], { useSuspense: false });

  const { name, model, description, sku, property } = data;
  return (
    <FlexContainer
      colors={{ white: color.white, line: color.line }}
      indentFactor={indentFactorContainer}
    >
      <Box>
        <Title>
          <Typography
            variant={'bodyBoldExSmall'}
            sx={{ color: color.gray_80 }}
            component={'p'}
          >
            {t('search:search_in')}
          </Typography>
          <ClearButton type="button" onClick={handelClearSearch}>
            <Typography
              variant={'bodySmall'}
              sx={{ color: color.main }}
              component={'p'}
            >
              {t('search:clear_btn')}
            </Typography>
          </ClearButton>
        </Title>
        <List>
          <Item>
            <CheckboxBase
              width={16}
              onChange={onChange('name')}
              checked={name}
            />
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray_80,
                marginLeft: '10px',
                marginRight: '23px',
              }}
              component={'p'}
            >
              {t('search:action_checkboxes.name')}
            </Typography>
          </Item>
          <Item>
            <CheckboxBase
              width={16}
              onChange={onChange('model')}
              checked={model}
            />
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray_80,
                marginLeft: '10px',
                marginRight: '23px',
              }}
              component={'p'}
            >
              {t('search:action_checkboxes.model')}
            </Typography>
          </Item>
          <Item>
            <CheckboxBase
              width={16}
              onChange={onChange('description')}
              checked={description}
            />
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray_80,
                marginLeft: '10px',
                marginRight: '23px',
              }}
              component={'p'}
            >
              {t('search:action_checkboxes.description')}
            </Typography>
          </Item>
          <Item>
            <CheckboxBase width={16} onChange={onChange('sku')} checked={sku} />
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray_80,
                marginLeft: '10px',
                marginRight: '23px',
              }}
              component={'p'}
            >
              {t('search:action_checkboxes.article')}
            </Typography>
          </Item>
          <Item>
            <CheckboxBase
              width={16}
              onChange={onChange('property')}
              checked={property}
            />
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray_80,
                marginLeft: '10px',
                marginRight: '23px',
              }}
              component={'p'}
            >
              {t('search:action_checkboxes.characteristics')}
            </Typography>
          </Item>
        </List>
      </Box>
      {dataCard && !!dataCard.length ? (
        <>
          <Divider />
          <ContentWrap>
            <CustomScroll vertical={true}>
              {dataCard.map((el, i, arr) => (
                <>
                  <Card
                    handleAddToCart={handleAddToCart}
                    data={el}
                    addAnimation={addAnimation}
                    key={i + 200}
                    handleMoveToItemPage={handleMoveToItemPage}
                    customCardSx={customStyles}
                  />
                  {i + 1 !== arr.length && <Divider />}
                </>
              ))}{' '}
            </CustomScroll>
          </ContentWrap>
          <Divider />
          <FooterSearch>
            <Button
              onClick={handleMoveToSearchPage}
              style={{
                display: 'flex',
                flexDirection: 'row',
                textTransform: 'none',
              }}
            >
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: color.main,
                }}
                component={'p'}
              >
                {`${t('search:search_res')} ${total}`}
              </Typography>
              <ArrowForwardRoundedIcon
                sx={{
                  fill: color.main,
                  width: '16px',
                  height: '16px',
                  marginLeft: '4px',
                }}
              />
            </Button>
          </FooterSearch>
        </>
      ) : (
        <>
          <Divider />
          <EmptySearchWrapper>
            <Empty type={'EmptySearchLogo'} />
          </EmptySearchWrapper>
        </>
      )}
    </FlexContainer>
  );
};

const EmptySearchWrapper = styled.div`
  padding: 32px 0;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${props => props.colors.white};
  z-index: 10;

  width: 100%;

  border: 1px solid ${props => props.colors.line};
  border-radius: 4px;

  height: calc(100% - ${({ indentFactor }) => indentFactor});
  max-height: 660px;
`;

const Box = styled.div`
  padding: 12px 16px;
`;

const ClearButton = styled.button`
  cursor: pointer;
`;

const FooterSearch = styled.div`
  padding: 8px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  margin-top: 13px;
`;

const Item = styled.li`
  display: flex;
`;

const ContentWrap = styled.div`
  height: 100%;
  overflow: hidden;
`;

MenuSearch.propTypes = {
  dataCard: PropTypes.arrayOf(
    PropTypes.exact({
      brand: PropTypes.string,
      can_buy: PropTypes.exact({
        price: PropTypes.bool,
        price_cron: PropTypes.bool,
        price_cron_no_dph: PropTypes.bool,
        price_no_dph: PropTypes.bool,
      }),
      cart: PropTypes.shape({
        cart_product_id: PropTypes.number,
        id: PropTypes.number,
        personal_amount: PropTypes.exact({
          price: PropTypes.string,
          price_cron: PropTypes.string,
          price_cron_no_dph: PropTypes.string,
          price_no_dph: PropTypes.string,
        }),
        quantity: PropTypes.number,
      }),
      description: PropTypes.string,
      short_description: PropTypes.string,
      has_analogs: PropTypes.bool,
      id: PropTypes.number,
      image: PropTypes.string,
      images_quantity: PropTypes.number,
      in_compare: PropTypes.bool,
      is_in_cart: PropTypes.bool,
      is_saved: PropTypes.bool,
      model: PropTypes.string,
      name: PropTypes.string,
      personal_price: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      price: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      prices: PropTypes.exact({
        price: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_cron: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_cron_no_dph: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_no_dph: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
      }),
      sku: PropTypes.string,
      slug: PropTypes.string,
      stock: PropTypes.number,
      stock_max: PropTypes.number,
      stock_percent: PropTypes.number,
      url: PropTypes.string,
      warehouses: PropTypes.arrayOf(
        PropTypes.exact({
          city_name: PropTypes.string,
          warehouses: PropTypes.exact({
            name: PropTypes.string,
            stock: PropTypes.number,
            stock_max: PropTypes.number,
            stock_percent: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
  total: PropTypes.number,
  data: PropTypes.exact({
    description: PropTypes.bool,
    model: PropTypes.bool,
    name: PropTypes.bool,
    property: PropTypes.bool,
    sku: PropTypes.bool,
  }),
  handelClearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleMoveToSearchPage: PropTypes.func.isRequired,
  handleMoveToItemPage: PropTypes.func.isRequired,
  addAnimation: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  indentFactorContainer: PropTypes.string,
};
