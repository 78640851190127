import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import {
  ButtonBase,
  ModalBase,
  OutlineButtonBase,
  TextArea,
  TextInput,
} from '@root/ui';

import categoryOperation from '@redux/category/category-operation';
import favoriteOperation from '@redux/favorite/favorite-operation';

import { useTheme } from '@mui/material';

export const CreateSavedModal = ({ open, handleClose }) => {
  const { color } = useTheme();
  const [savedData, setSavedData] = useState({
    name: '',
    description: '',
  });

  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'catalog'], { useSuspense: false });

  const onChangeText = name => value => {
    setSavedData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSetSavedList = () => {
    dispatch(favoriteOperation.postNewFavorite({ savedData })).then(e => {
      dispatch(categoryOperation.getCategory());
      handleClose();
    });
  };

  const { name, description } = savedData;
  return (
    <ModalBase
      open={open}
      onClose={handleClose}
      modalHeader={
        <HeaderDefault text={t('catalog:new_list')} onClose={handleClose} />
      }
    >
      <NewSavedListWrapper>
        <TextInput
          sx={{
            width: '100%',
            height: '36px',
          }}
          placeholder={t('catalog:fields.name')}
          max={50}
          onChange={onChangeText('name')}
          value={name}
        />
        <TextArea
          sx={{ marginTop: '8px', border: `1px solid ${color.line}` }}
          value={description}
          onChange={onChangeText('description')}
          placeholder={t('catalog:fields.description')}
          type="text"
          max={200}
        />
      </NewSavedListWrapper>
      <ButtonsWrapper>
        <OutlineButtonBase onClick={handleClose}>
          {t('common:buttons.cancel')}
        </OutlineButtonBase>
        <ButtonBase disabled={!name.length} onClick={handleSetSavedList}>
          {t('common:buttons.create')}
        </ButtonBase>
      </ButtonsWrapper>
    </ModalBase>
  );
};

const NewSavedListWrapper = styled.div`
  display: flex;
  width: 442px;
  margin-bottom: 8px;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

CreateSavedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
