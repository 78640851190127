import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const updateHomeConfig = createAsyncThunk(
  'home/updateHomeConfig',
  async ({ onResolve, onReject, conf }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('dashboard', { conf });

      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const getHomeBanner = createAsyncThunk(
  'home/getHomeBanner',
  async (homeConfig, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/banner');

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateHomeBanner = createAsyncThunk(
  'home/updateHomeBanner',
  async ({ onResolve, onReject, formData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('dashboard/admin/banner', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const deleteHomeBanner = createAsyncThunk(
  'home/deleteHomeBanner',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`dashboard/admin/banner/${id}`);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeTopSale = createAsyncThunk(
  'home/getHomeTopSalle',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/producthits');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createHomeTopSale = createAsyncThunk(
  'home/createHomeTopSalle',
  async ({ productList }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('dashboard/admin/producthits', {
        productList,
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeOrderChart = createAsyncThunk(
  'home/getHomeOrderChart',
  async (productList, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/orders');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeBuysMostOften = createAsyncThunk(
  'home/getHomeBuysMostOften',
  async (productList, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/productoften');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeOrders = createAsyncThunk(
  'home/getHomeOrders',
  async ({ page }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/orderslist', {
        params: {
          page: page,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//=============================Top-Sale-Modal=====================================//

//This methods work with modal Top-Sale-Modal
//Some api is duplicated

const getProductById = createAsyncThunk(
  'home/getProductById',
  async ({ id, page = 1, per_page = 10, sort }, thunkApi) => {
    try {
      const response = await axios.get(`/product/category/${id}`, {
        params: { page, per_page, sort },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getProductsBySearch = createAsyncThunk(
  'home/getProductsBySearch',
  async ({ q, per_page, page }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/product/search', {
        params: {
          q,
          page,
          per_page,
          fields: 'name,model,description,sku,property,',
        },
      });
      console.log(data);
      return { ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//=============================End-Top-Sale-Modal=====================================//

export default {
  updateHomeConfig,
  getHomeBanner,
  updateHomeBanner,
  getHomeTopSale,
  createHomeTopSale,
  getHomeOrderChart,
  getHomeBuysMostOften,
  getHomeOrders,
  getProductById,
  getProductsBySearch,
  deleteHomeBanner,
};
