import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, Pagination, SettingsMenu } from '@root/components';
import {
  AdminSettingMenu,
  BusinessConf,
  CounterpartsConf,
  PeopleConf,
} from '@root/conf';
import { correctPhone } from '@root/helpers';
import { useQueryParams } from '@root/hooks';
import { CheckboxBase, ProgressLoader } from '@root/ui';

import adminOperation from '@redux/admin/admin-operation';
import adminSelectors from '@redux/admin/admin-selectors';
import authSelectors from '@redux/auth/auth-selectors';

import {
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  CompanyLogo,
  InfoText,
  PeopleAvatar,
  Status,
  TextMap,
} from '../AdminTableElements';

export const AdminTable = ({
  handleNavSection,
  handleSetData,
  accordionActive,
}) => {
  const role = useSelector(authSelectors.getRole);
  const loadingBusiness = useSelector(adminSelectors.getLoadingBusiness);
  const loadingCounterparty = useSelector(
    adminSelectors.getLoadingCounterparty,
  );
  const loadingPeople = useSelector(adminSelectors.getLoadingPeople);
  const businessesData = useSelector(adminSelectors.getData);
  const meta = useSelector(adminSelectors.getMeta);

  const search = useSelector(adminSelectors.getAdminSearch);

  const loading = loadingBusiness || loadingCounterparty || loadingPeople;

  const { adminTab } = useParams();
  const { t } = useTranslation(['admin']);
  const { color } = useTheme();

  const dispatch = useDispatch();

  const { getQueryObj } = useQueryParams();

  useEffect(() => {
    if (!adminTab) return;
    const { page, per_page } = getQueryObj();
    const initPagination = { page, per_page };
    handleNavSection(adminTab, initPagination);
    switch (adminTab) {
      case 'businesses': {
        dispatch(adminOperation.getBusinessData({ ...initPagination }));
        break;
      }
      case 'counterparts': {
        dispatch(adminOperation.getCounterpartyData({ ...initPagination }));
        break;
      }
      case 'people': {
        dispatch(adminOperation.getPeopleData({ ...initPagination }));
        break;
      }
      default: {
        break;
      }
    }
  }, [adminTab]);

  const tableConf = useMemo(() => {
    if (!adminTab) return;
    switch (adminTab) {
      case 'businesses': {
        return BusinessConf(t);
      }
      case 'counterparts': {
        return CounterpartsConf(t);
      }
      case 'people': {
        return PeopleConf(t);
      }
      default: {
        return null;
      }
    }
  }, [adminTab]);

  const handlePagination = param => {
    const { q } = getQueryObj();

    handleNavSection(adminTab, { ...param, q });
    switch (adminTab) {
      case 'businesses': {
        q
          ? dispatch(
              adminOperation.getBusinessesSearch({
                search: q,
                role,
                ...param,
              }),
            )
          : dispatch(adminOperation.getBusinessData({ ...param }));
        break;
      }
      case 'counterparts': {
        q
          ? dispatch(
              adminOperation.getCounterpartsSearch({
                search: q,
                role,
                ...param,
              }),
            )
          : dispatch(adminOperation.getCounterpartyData({ ...param }));
        break;
      }
      case 'people': {
        q
          ? dispatch(
              adminOperation.getPeopleSearch({
                search: q,
                role,
                ...param,
              }),
            )
          : dispatch(adminOperation.getPeopleData({ role, ...param }));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClickCard = (param, id) => {
    const { handleSetSelectedItem, handleSetOpen, handleSetActiveModal } =
      handleSetData;

    handleSetSelectedItem({ param, id });
    switch (param) {
      case 'edit': {
        return;
      }
      case 'review': {
        handleSetOpen(true);
        handleSetActiveModal('review');
        break;
      }
      case 'archive': {
        return;
      }
      case 'personal_price': {
        handleSetOpen(true);
        handleSetActiveModal('personal_price');
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        height: `calc(100% - 120px)`,
        overflowX: 'initial',
      }}
    >
      <Table
        size="small"
        aria-label="a dense table"
        sx={{
          height: `calc(100% - 78px)`,
          position: 'relative',
        }}
      >
        <TableHead>
          <TableRow sx={{ display: 'flex', p: '12px 16px' }}>
            {tableConf &&
              tableConf.map((el, i) => {
                if (el.hide && accordionActive) return '';
                return el.value === 'checkBox' ? (
                  <CheckboxBase width={16} key={i} />
                ) : (
                  <TableCell
                    key={el.label}
                    sx={{
                      borderBottom: 'none',
                      ...el.style(accordionActive),
                      p: 0,
                    }}
                  >
                    <Typography
                      variant={'bodyBoldSmall'}
                      component={'p'}
                      color={color.gray_60}
                    >
                      {el.label}
                    </Typography>
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <Divider />
        <TableBody boderLeftColor={color.line}>
          {businessesData &&
            businessesData.length !== 0 &&
            businessesData.map((row, i) => (
              <TableRow
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: '20px 20px 20px 16px',
                  borderBottom: `1px solid ${color.line}`,
                  th: {
                    borderBottom: 'none',
                  },
                  '&:last-child, &:last-child th': { borderBottom: 0 },
                }}
                key={`p` + i}
              >
                {tableConf &&
                  tableConf.map((el, idx) => {
                    switch (el.value) {
                      case 'checkBox':
                        return (
                          <CheckboxBase key={'s' + idx + row.id} width={16} />
                        );
                      case 'user':
                        return (
                          <PeopleAvatar
                            style={el.style(accordionActive)}
                            row={row}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'role':
                        return (
                          <TextMap
                            style={el.style(accordionActive)}
                            textArr={row.roles}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'email':
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.email}
                            variant={'bodySmall'}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'phone':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{ p: 0, ...el.style(accordionActive) }}
                          >
                            {row.phones &&
                              row.phones.map(({ phone }) => (
                                <Typography
                                  color={color.gray}
                                  variant={'bodySmall'}
                                  component={'p'}
                                >
                                  {correctPhone(phone)}
                                </Typography>
                              ))}
                          </TableCell>
                        );
                      case 'city':
                        if (el.hide && accordionActive) return '';
                        if (row.city) {
                          return (
                            <InfoText
                              style={el.style(accordionActive)}
                              text={row.city?.name}
                              variant={'bodySmall'}
                              sx={{ textAline: ' center' }}
                              key={'s' + idx + row.id}
                            />
                          );
                        }
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={''}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'peopleBusinesses':
                        if (el.hide && accordionActive) return '';
                        if (row.company)
                          return (
                            <InfoText
                              style={el.style(accordionActive)}
                              text={row.company.name}
                              variant={'bodySmall'}
                              sx={{ textAline: ' center' }}
                              key={'s' + idx + row.id}
                            />
                          );
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={''}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'counterparts':
                        if (el.hide && accordionActive) return '';
                        if (row.company)
                          return (
                            <InfoText
                              style={el.style(accordionActive)}
                              text={row.company.official_name}
                              variant={'bodySmall'}
                              sx={{ textAline: ' center' }}
                              key={'s' + idx + row.id}
                            />
                          );
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={''}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'companyLogo':
                        if (el.hide && accordionActive) return '';
                        return row?.logo ? (
                          <CompanyLogo
                            style={el.style(accordionActive)}
                            img={row?.logo}
                            key={'s' + idx + row.id}
                          />
                        ) : (
                          <Empty
                            type={'EmptyLogo'}
                            key={'s' + idx + row.id}
                            isCompany
                            tag={'th'}
                            wrapperStyles={{
                              ...el.style(accordionActive),
                              marginLeft: '16px',
                            }}
                            contentStyles={{
                              maxWidth: '100px',
                              minWidth: '100px',
                              height: '28px',
                            }}
                          />
                        );
                      case 'companyName':
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.name}
                            variant={'bodyBoldMedium'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'type':
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.company_type}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'number':
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.contractor_code}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'longNumber':
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.identification_code}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'businesses':
                        if (el.hide && accordionActive) return '';
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.name}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'numberOfEmployees':
                        if (el.hide && accordionActive) return '';
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.employees_quantity}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'site':
                        return (
                          <InfoText
                            style={el.style(accordionActive)}
                            text={row.site}
                            variant={'bodySmall'}
                            sx={{ textAline: ' center' }}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'areasOfActivity':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{
                              p: 0,
                              ...el.style(accordionActive),
                            }}
                          >
                            {row.activity_areas &&
                              row.activity_areas.map(({ name }) => (
                                <Typography
                                  color={color.gray}
                                  variant={'bodySmall'}
                                  component={'p'}
                                >
                                  {name}
                                </Typography>
                              ))}
                          </TableCell>
                        );
                      case 'status':
                        if (el.hide && accordionActive) return '';
                        return (
                          <Status
                            style={el.style(accordionActive)}
                            status={row.status}
                            key={'s' + idx + row.id}
                          />
                        );
                      case 'edit':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                            sx={{ p: 0, ...el.style(accordionActive) }}
                          >
                            <SettingsMenu
                              handelClickCard={handleClickCard}
                              id={row.id}
                              isPersonal={adminTab === 'people'}
                              menuList={AdminSettingMenu}
                            />
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'s' + idx + row.id}
                          >
                            {row[el.value]}
                          </TableCell>
                        );
                    }
                  })}
              </TableRow>
            ))}
          {loading && (
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ p: 0, width: '100%', display: 'block' }}
              >
                <ProgressLoader />
              </TableCell>
            </TableRow>
          )}

          {businessesData && businessesData.length === 0 && !search && (
            <TableRow></TableRow>
          )}
          {businessesData && businessesData.length === 0 && search && (
            <TableRow
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Empty type={'EmptySearchLogo'} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        meta={meta}
        handelPagination={handlePagination}
        subTitle={t('admin:records')}
      />
    </TableContainer>
  );
};

const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);

  overflow-y: auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c5c8d2;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-left: ${({ boderLeftColor }) => `1px solid ${boderLeftColor}`};
  }
`;

AdminTable.propTypes = {
  handleNavSection: PropTypes.func.isRequired,
  handleSetData: PropTypes.func.isRequired,
  accordionActive: PropTypes.bool,
};
