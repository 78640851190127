import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { langConf } from '@root/conf';

export const LangWatcher = ({ children }) => {
  const navigation = useNavigate();
  const { lang } = useParams();

  useEffect(() => {
    if (lang && !langConf.includes(lang)) {
      navigation('/error-404');
    }
  }, [lang]);

  return <>{children}</>;
};
