export const updateFromCart = (arr, productsCart) => {
  if (!arr || arr.length === 0) return [];
  return [...arr].map(el => {
    const currentCartProduct = [...productsCart].find(
      favoriteProduct => favoriteProduct?.id === el.id,
    );
    if (currentCartProduct) {
      return currentCartProduct;
    } else {
      return el;
    }
  });
};

export const removeFromCart = (arr, id) => {
  if (!arr || arr.length === 0) return [];
  return [...arr].map(el => {
    if (el.id === id) {
      return { ...el, is_in_cart: false, cart: { amount: 0, quantity: 0 } };
    } else {
      return el;
    }
  });
};

export const updateProducts = (arr, payload) => {
  if (!arr || arr.length === 0) return [];
  return [...arr].map(el => {
    const newData = payload.products.find(cart => cart.id === el.id);
    if (newData) {
      return newData;
    } else {
      return { ...el, cart: [], is_in_cart: false };
    }
  });
};

export const updateNestedProductById = (product, payload) => {
  const { productId, products, key } = payload;

  if (Object.keys(product).length === 0) return {};
  if (!product[key] || product[key].length === 0) return product;

  const currentCartProduct = products.find(el => el?.id === productId);

  const newArr = [...product[key]].reduce((acc, el) => {
    if (el.id === productId) {
      currentCartProduct
        ? acc.push({ ...el, ...currentCartProduct })
        : acc.push({
            ...el,
            is_in_cart: false,
            cart: { amount: 0, quantity: 0 },
          });
    } else {
      acc.push(el);
    }
    return acc;
  }, []);

  return {
    ...product,
    [key]: newArr,
  };
};
