export const getNavMenu = t => [
  {
    path: '/',
    name: t('common:nav_menu.main'),
    icon: 'icon-home',
    role: ['admin', 'user'],
  },
  {
    path: '/catalog',
    name: t('common:nav_menu.catalog'),
    icon: 'icon-catalog',
    role: ['admin', 'user'],
  },
  {
    path: '/my-orders',
    name: t('common:nav_menu.my_orders'),
    icon: 'icon-orders',
    role: ['admin', 'user'],
  },
  {
    path: '/docs',
    name: t('common:nav_menu.docs'),
    icon: 'icon-docs',
    role: ['admin', 'user'],
  },
  {
    path: '/logistics',
    name: t('common:nav_menu.logistics'),
    icon: 'icon-logistics',
    role: ['admin', 'user'],
  },
  {
    path: '/admin-panel',
    name: t('common:nav_menu.admin_panel'),
    icon: 'icon-admin_panel',
    role: ['admin'],
  },
  {
    path: '/settings',
    name: t('common:nav_menu.settings'),
    icon: 'icon-setings',
    role: ['admin', 'user'],
  },
];
