import { useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { AccordionUI, OutlineButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const AccordionBase = ({
  data,
  expanded,
  handleChange,
  activeId,
  handleById,
  setActiveId,
  type = 'static',
  handleOpenModal,
}) => {
  const { color } = useTheme();
  const { name, slug: parentSlug, children } = data;

  const handleClick = (id, name) => {
    if (parentSlug !== 'ulozene') {
      handleById({ name, id, page: 1, per_page: 10 });
    }
  };

  const getSummary = useMemo(() => {
    return (
      <>
        {parentSlug === 'ulozene' ? (
          <svg
            color={color.gray_60}
            width={16}
            height={16}
            style={{
              marginRight: '4px',
              stroke: expanded === parentSlug ? color.main : color.gray_60,
            }}
          >
            <use href={`${Sprite}#icon-star`}></use>
          </svg>
        ) : (
          <Dots color={color.gray_60} />
        )}
        <Typography
          variant={'bodyMedium'}
          component={'p'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: expanded === parentSlug && `${color.main} !important`,
            textTransform: parentSlug === 'ulozene' ? 'capitalize' : '',
          }}
        >
          {name}
        </Typography>
      </>
    );
  }, [expanded, parentSlug]);

  const { category, categoryChildren, lang } = useParams();
  // const dispatch = useDispatch();
  const currentLang = lang ? `/${lang}` : '';
  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });

  const path = `/catalog/${category}${
    categoryChildren ? '/' + categoryChildren : ''
  }`;

  const Wrapper = ({ children, name, slug, id }) => {
    switch (type) {
      case 'static':
        return (
          <Slag
            key={name}
            onClick={() => {
              handleClick(id, name);
              setActiveId(id);
            }}
            style={{
              ...(activeId === id
                ? {
                    borderLeft: `2px solid ${color.main}`,
                    color: color.main,
                  }
                : {
                    borderLeft: `1px solid ${color.gray_80}`,
                    color: color.gray_80,
                  }),
            }}
            end
          >
            {children}
          </Slag>
        );

      case 'link':
        return (
          <NavLink
            key={name}
            to={
              currentLang +
              `/${
                parentSlug === 'ulozene' ? 'saved' : 'catalog'
              }/${parentSlug}/${slug ? slug : name}?id=${id}&page=1&per_page=10`
            }
            onClick={() => handleClick(id, name)}
            style={({ isActive }) => ({
              padding: '5px 0 5px  12px  ',
              ...(isActive || `/catalog/${parentSlug}/${slug}` === path
                ? {
                    borderLeft: `2px solid ${color.main}`,
                    color: color.main,
                  }
                : {
                    borderLeft: `1px solid ${color.gray_80}`,
                    color: color.gray_80,
                  }),
            })}
            end
          >
            {children}
          </NavLink>
        );
      default:
        break;
    }
  };
  const getColorActive = id =>
    activeId === id ? `${color.main} !important` : color.gray_80;

  return (
    <AccordionUI
      expanded={expanded === parentSlug}
      handleChange={handleChange(parentSlug)}
      summaryMurk={getSummary}
      dots={{
        '&::before': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          content: '""',
          width: '4px',
          height: '4px',
          background: color.gray_60,
          borderRadius: '40px',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      {children.map(({ name, slug, id }, i) => (
        <Wrapper name={name} slug={slug} id={id}>
          <Typography
            variant={'bodySmall'}
            sx={{}}
            component={'p'}
            color={type === 'static' ? getColorActive(id) : ''}
          >
            {name}
          </Typography>
        </Wrapper>
      ))}
      {parentSlug === 'ulozene' && (
        <OutlineButtonBase
          onClick={handleOpenModal}
          sx={{ width: '150px', height: '28px' }}
        >
          <Svg
            color={color.gray_60}
            width={16}
            height={16}
            rotateSvg={'180deg'}
          >
            <use href={`${Sprite}#icon-plus`}></use>
          </Svg>
          {t('common:buttons.new_list')}
        </OutlineButtonBase>
      )}
    </AccordionUI>
  );
};

const Dots = styled.span`
  width: 4px;
  height: 4px;
  margin-top: 8px;
  background: ${props => props.color};
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 5px;
`;

const Slag = styled.span`
  cursor: pointer;
  padding: 5px 0 5px 12px;
  color: inherit !important;
`;

const Svg = styled.svg`
  stroke: ${props => props.color};
  margin-right: 4px;
`;

AccordionBase.propTypes = {
  data: PropTypes.exact({
    children: PropTypes.arrayOf(PropTypes.object),
    icon: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleChange: PropTypes.func.isRequired,
  handleById: PropTypes.func.isRequired,

  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setActiveId: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func,
  type: PropTypes.oneOf(['static', 'link']),
};
