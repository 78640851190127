import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BaseModalHeader, CreateFilterModal } from '@root/components';
import { FiltersConf, WarehouseConf } from '@root/conf/FilterConf';
import { WarehousesModal } from '@root/modules/MenuModal/Filter/WarehousesModal';
import { DialogUi } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import categoryOperation from '@redux/category/category-operation';
import categorySelectors from '@redux/category/category-selectors';
import {
  clearWarehouseEditData,
  recordSearchData,
} from '@redux/category/category-slice';
import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';

import { useTheme } from '@mui/material/styles';

import { ManageFiltersTable } from '../ManageFilters';

export const ManageFilters = ({ isOpen, isClose, tabType }) => {
  const dispatch = useDispatch();
  const filteredDataWarehouses = useSelector(categorySelectors.filterWarehouse);
  const warehouseEditData = useSelector(
    categorySelectors.getManageWarehouseEditData,
  );
  const data = useSelector(categorySelectors.getManageFilters);
  const meta = useSelector(categorySelectors.getFiltersMeta);
  const loading = useSelector(categorySelectors.loading);
  const [searchValue, setSearchValue] = useState('');
  const [inView, setInView] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [warehouseModalOpen, setWarehouseModalOpen] = useState(false);
  const [item, setItem] = useState();
  const [pageCounter, setPageCounter] = useState(1);
  const role = useSelector(authSelectors.getRole);
  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();

  useEffect(() => {
    if (role === 'admin') {
      getManageFiltersList(1);
      // getManageWarehousesList();
    }
  }, []);

  useEffect(() => {
    if (inView) {
      if (meta.current_page !== meta.last_page) {
        setPageCounter(pageCounter + 1);
        getManageFiltersList(pageCounter + 1);
      }
    }
  }, [inView]);

  const getManageFiltersList = async page => {
    dispatch(
      categoryOperation.getManageFilters({
        page,
        search: searchValue,
        updateBySearch: false,
      }),
    );
  };

  const getManageWarehousesList = () => {
    dispatch(categoryOperation.getManageWarehouses());
  };

  const goToTop = () => {
    if (data.length) {
      const element = document.getElementById(data[0].id);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClose = () => {
    isClose(false);
  };

  const onChange = val => {
    setSearchValue(val);
  };

  const handleNewFilterOrWarehouses = () => {
    handleOpenModal();
    setItem(null);
  };

  const handleOpenModal = () => {
    dispatch(categoryOperation.getCategoryListToAdmin());
    setModalOpen(true);
  };

  const handleCloseFilterOrWarehouseModal = () => {
    if (tabType === 'filters') {
      setModalOpen(false);
    } else {
      setWarehouseModalOpen(false);
      dispatch(clearWarehouseEditData());
    }
  };

  const removeFilterOrWarehouseFromRow = rowId => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        dataForConfirm: {
          id: rowId,
        },
        callback: data => removeItemFromRow(data),
      }),
    );
  };

  const editFilterOrWarehouse = val => {
    if (tabType === 'filters') {
      setItem(val);
      handleOpenModal();
    } else {
      dispatch(categoryOperation.editManageWarehouse({ id: val.id }));
      setWarehouseModalOpen(true);
    }
  };

  const handleEnterSearch = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (tabType === 'filters') {
        setPageCounter(1);
        goToTop();
        dispatch(
          categoryOperation.getManageFilters({
            page: 1,
            search: searchValue,
          }),
        );
      } else {
        dispatch(recordSearchData(searchValue));
      }
    }
  };

  const handleCleanSearch = () => {
    if (tabType === 'filters') {
      setPageCounter(1);
      goToTop();
      setSearchValue('');
      dispatch(
        categoryOperation.getManageFilters({
          page: 1,
          search: '',
        }),
      );
    } else {
      setSearchValue('');
      dispatch(recordSearchData(''));
    }
  };

  const updateWarehouseData = payload => {
    const { id, public_name, sort, city_id } = payload;
    dispatch(
      categoryOperation.updateManageWarehouseData({
        id,
        public_name,
        sort,
        city_id,
      }),
    );
    handleCloseFilterOrWarehouseModal();
  };

  const removeItemFromRow = ({ id }) => {
    if (tabType === 'filters') {
      dispatch(categoryOperation.removeFilter({ id: id }));
    } else {
      dispatch(categoryOperation.removeManageWarehouseById({ id: id }));
    }
    dispatch(clearModalState());
  };

  return (
    <div>
      <DialogUi open={isOpen} onClose={handleClose} fullScreen={true}>
        {modalOpen && (
          <CreateFilterModal
            handleClose={handleCloseFilterOrWarehouseModal}
            open={modalOpen}
            changetItem={item}
          />
        )}
        {warehouseModalOpen && (
          <WarehousesModal
            loading={loading}
            isActionButtons={true}
            open={warehouseModalOpen}
            close={handleCloseFilterOrWarehouseModal}
            isSearch={false}
            defPadding={'0 0 16px 0'}
            data={warehouseEditData}
            createOrUpdate={updateWarehouseData}
          />
        )}
        <Wrapper>
          <BaseModalHeader
            mainTitle={
              tabType === 'filters'
                ? t('catalog:modals.settings_filter_modal.settings_filter')
                : t(
                    'catalog:modals.settings_warehouse_modal.settings_warehouse',
                  )
            }
            isCreateModal={tabType === 'filters'}
            defPadding={'0 20px'}
            closeModalCallback={handleClose}
            handleOpenModal={handleNewFilterOrWarehouses}
            handleCleanSearch={handleCleanSearch}
            handleEnterSearch={handleEnterSearch}
            onChange={onChange}
            value={searchValue}
          />
          <ManageFiltersTable
            data={tabType === 'filters' ? data : filteredDataWarehouses}
            callback={setInView}
            remove={removeFilterOrWarehouseFromRow}
            edit={editFilterOrWarehouse}
            conf={tabType === 'filters' ? FiltersConf : WarehouseConf}
            tabType={tabType}
          />
        </Wrapper>
      </DialogUi>
    </div>
  );
};

const Wrapper = styled.div``;

ManageFilters.propTypes = {
  isOpen: PropTypes.func.isRequired,
  isClose: PropTypes.func.isRequired,
  tabType: PropTypes.string.isRequired,
};
