import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OrderConfData } from '@root/conf';
import { BasicSelect, PhoneInput, TextInput } from '@root/ui';

import orderSelectors from '@redux/order/order-selectors';

import { Divider, RadioGroup, Typography, useTheme } from '@mui/material';

import { RadioItem } from '../RadioItem';
import { DeliveryClientInfo } from './DeliveryClientInfo';

export const FormPersonalInfo = ({
  data,
  error,
  onChange,
  onChangeRadio,
  handleSelectFilial,
  handleCardRadio,
  setData,
  available_methods,
}) => {
  const filialList = useSelector(orderSelectors.getFilialList);
  const { color } = useTheme();
  const { name, email, surname, phone, delivery_type, delivery_filial } = data;

  const { t } = useTranslation(['placing_order'], { useSuspense: false });
  return (
    <>
      {' '}
      <Box gap={12}>
        <Typography
          variant={'bodyBoldSmall'}
          sx={{ color: color.gray }}
          component={'h2'}
        >
          {t('placing_order:information_about_recipient')}
        </Typography>
        <TextInput
          error={error?.name?.init}
          errorText={error?.name?.text}
          sx={{ mt: '2px', width: '300px' }}
          styleWrapper={{ marginTop: '12px' }}
          value={name}
          onChange={onChange('name')}
          label={t('placing_order:fields.first_name')}
          placeholder={t('placing_order:fields.type_first_name')}
          type="text"
          max={50}
          required={true}
          // onBlur={hideValidation}
        />
        <TextInput
          error={error?.surname?.init}
          errorText={error?.surname?.text}
          sx={{ mt: '2px', width: '300px' }}
          styleWrapper={{ marginTop: '12px' }}
          value={surname}
          onChange={onChange('surname')}
          label={t('placing_order:fields.second_name')}
          placeholder={t('placing_order:fields.type_second_name')}
          type="text"
          max={50}
          required={true}
          // onBlur={hideValidation}
        />

        <TextInput
          error={error?.email?.init}
          errorText={error?.email?.text}
          sx={{ mt: '2px', width: '300px' }}
          styleWrapper={{ marginTop: '12px' }}
          value={email}
          onChange={onChange('email')}
          label={'Email'}
          placeholder={'Email'}
          type="text"
          max={50}
          required={true}
          withOutSpace={true}
          // onBlur={hideValidation}
        />

        <PhoneInput
          sx={{ mt: '2px', width: '300px' }}
          styleWrapper={{ marginTop: '12px' }}
          placeholder={'+420'}
          onChange={onChange('phone')}
          label={t('placing_order:fields.telephone')}
          value={phone}
          error={error?.phone?.init}
          errorText={error?.phone?.text}
        />
      </Box>
      <Box gap={20}>
        <Typography
          variant={'bodyBoldSmall'}
          sx={{ color: color.gray }}
          component={'h2'}
        >
          {t('placing_order:address_delivery')}
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={delivery_type}
          onChange={onChangeRadio('delivery_type')}
        >
          {available_methods?.delivery_types.map(el => (
            <>
              <RadioItem data={el} sx={{ marginLeft: '-9px' }} />
              {delivery_type === 'Self_pickup' && el.key === 'Self_pickup' && (
                <BasicSelect
                  error={error?.delivery_filial?.init}
                  helperText={error?.delivery_filial?.text}
                  sx={{
                    height: '36px',
                    p: 0,
                    width: '300px',
                    marginBottom: '23px',
                  }}
                  currentId={delivery_filial}
                  callBack={handleSelectFilial}
                  height={'36px !important'}
                  data={filialList}
                  name={t('placing_order:fields.select_pickup_point')}
                />
              )}
              {delivery_type === el.key && (
                <DeliveryClientInfo
                  error={error}
                  key={el.id}
                  deliveryType={el?.key}
                  conf={OrderConfData}
                  handleCardRadio={handleCardRadio}
                  onChange={onChange}
                  data={data}
                  setData={setData}
                  confBackEnd={el}
                />
              )}
              <Divider />
            </>
          ))}
        </RadioGroup>
      </Box>{' '}
    </>
  );
};

const Box = styled.div`
  margin-top: ${({ gap }) => gap}px;
`;

FormPersonalInfo.propTypes = {
  data: PropTypes.exact({
    delivery_filial: PropTypes.string,
    delivery_info: PropTypes.exact({
      city_title: PropTypes.string,
      date: PropTypes.string,
      department_data: PropTypes.object,
      flat_num: PropTypes.string,
      npPhone: PropTypes.string,
      street_number: PropTypes.string,
      street_title: PropTypes.string,
      type: PropTypes.string,
      zip_code: PropTypes.string,
    }),
    delivery_type: PropTypes.string,
    discount: PropTypes.string,
    dont_call: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    payment_type: PropTypes.string,
    phone: PropTypes.string,
    surname: PropTypes.string,
  }),
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeRadio: PropTypes.func.isRequired,
  handleSelectFilial: PropTypes.func.isRequired,
  handleCardRadio: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  available_methods: PropTypes.exact({
    delivery_types: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        items: PropTypes.arrayOf(
          PropTypes.exact({
            icon: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            key: PropTypes.string,
            subTitle: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        key: PropTypes.string,
        label: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    payment_types: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        key: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
};
