import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getCartList = createAsyncThunk('cart/getCartList', async thunkApi => {
  try {
    const { data } = await axios.get(`/cart`);

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addProductToCart = createAsyncThunk(
  'cart/addProductToCart',
  async ({ id, quantity, onResolve, onReject }, thunkApi) => {
    try {
      const { data } = await axios.post('/cart/add-to-cart', {
        product_id: id,
        quantity,
      });

      if (onResolve) onResolve(data);
      return { data, id };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const putOneToCart = createAsyncThunk(
  'cart/putOneToCart',
  async ({ id, quantity, onResolve, onReject }, thunkApi) => {
    try {
      const { data } = await axios.put(`/cart/update/${id}`, { quantity });

      if (onResolve) onResolve(data);
      return { data, id };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const deleteOneFromCart = createAsyncThunk(
  'cart/deleteOneFromCart',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const { data } = await axios.delete(`/cart/${id}`);
      if (onResolve) onResolve(data);
      return { id, data };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const clearCart = createAsyncThunk('cart/clearCart', async thunkApi => {
  try {
    const { data } = await axios.post(`/cart/clear`);

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addToOrder = createAsyncThunk(
  'cart/addToOrder',
  async ({ cart_products, navigation }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/order`, { cart_products });
      navigation('/catalog/placing-an-order');
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.data);
    }
  },
);
const postSavedToCart = createAsyncThunk(
  'cart/postSavedToCart',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/cart/favorite/${id}`);

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const deleteFavoriteFromCart = createAsyncThunk(
  'cart/deleteFavoriteFromCart',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/cart/favorite/${id}`);

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  getCartList,
  deleteFavoriteFromCart,
  addProductToCart,
  postSavedToCart,
  putOneToCart,
  deleteOneFromCart,
  clearCart,
  addToOrder,
};
