import { EmptyBlank } from './EmptyBlank';
import { EmptyBox } from './EmptyBox';
import { EmptyLogo } from './EmptyLogo';
import { EmptySearchLogo } from './EmptySearch';

export const Empty = ({ type, ...props }) => {
  const components = {
    EmptyBox: <EmptyBox {...props} />,
    EmptyLogo: <EmptyLogo {...props} />,
    EmptySearchLogo: <EmptySearchLogo {...props} />,
    EmptyBlank: <EmptyBlank {...props} />,
  };

  return <>{components[type]}</>;
};
