import { useEffect, useState } from 'react';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

import { addDays, getMonth, getYear } from 'date-fns';
import cs from 'date-fns/locale/cs';
import range from 'lodash/range';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Arrow_Back } from '@root/assets/svg';
import { DatePickerConf } from '@root/conf';

import { FormControl, Select, useTheme } from '@mui/material';

import { OutlinedInputCustom } from '../style/OutlinedInput';

registerLocale('cs', cs);

export const DatePickerCustom = ({
  onChange,
  value,
  placeholder,
  minDate = false,
  maxDate = true,
  minValue = '',
  maxValue = '',
}) => {
  const [startDate, setDate] = useState(value ? new Date(value) : '');
  const { color } = useTheme();
  const today = new Date();

  const { t } = useTranslation();

  const MyContainer = ({ className, children }) => {
    return (
      <div
        style={{
          color: color.white,
          borderRadius: '4px',
          height: '100%',
        }}
      >
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const selectDateHandler = d => {
    if (!d) {
      setDate('');
      onChange('');
      return;
    }
    setDate(d);
    onChange(moment(d).format('YYYY-MM-DD'));
  };

  const handleSelectYear = (value, actionType) => {
    actionType(value);
  };

  const handleSelectMonth = (value, actionType, options) => {
    actionType(options.indexOf(value));
  };
  const customSelect = (options, val, onChange, action) => {
    return (
      <Select
        native
        value={val}
        onChange={e => onChange(e.target.value, action, options)}
        sx={{
          color: color.gray,
          fontWeight: 700,
          fontSize: '0.875rem',
        }}
      >
        {options.map((option, i) => (
          <option key={option + i} value={option}>
            {option}
          </option>
        ))}
      </Select>
    );
  };

  const customDatePickerHeader = (
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  ) => {
    const years = range(1950, getYear(new Date()) + 1, 1);

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '5px',
        }}
      >
        <button
          type="button"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          style={{
            cursor: 'pointer',
          }}
        >
          <img src={Arrow_Back} alt="Icon Placeholder" />
        </button>
        <FormControl disabled={minDate} sx={{ minWidth: 70 }} size="small">
          {customSelect(years, getYear(date), handleSelectYear, changeYear)}
        </FormControl>
        <FormControl sx={{ minWidth: 70 }} size="small">
          {customSelect(
            DatePickerConf(t),
            DatePickerConf(t)[getMonth(date)],
            handleSelectMonth,
            changeMonth,
          )}
        </FormControl>
        <button
          type="button"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          style={{
            cursor: 'pointer',
          }}
        >
          <img
            src={Arrow_Back}
            alt="arrow"
            style={{
              transform: 'rotate(180deg)',
            }}
          />
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (!value || value === '') {
      setDate('');
    } else if (value) {
      setDate(new Date(value));
    }
  }, [value]);

  return (
    <Label>
      <DatePicker
        locale="cs"
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={selectDateHandler}
        minDate={minDate ? addDays(today, 3) : minValue}
        maxDate={maxDate ? today : maxValue}
        customInput={<OutlinedInputCustom sx={{ width: '100% !important' }} />}
        placeholderText={placeholder}
        calendarContainer={MyContainer}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) =>
          customDatePickerHeader(
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          )
        }
      />
    </Label>
  );
};

const Label = styled.label`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

DatePickerCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  minDate: PropTypes.bool,
  maxDate: PropTypes.bool,
};
