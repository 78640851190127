import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import styled from 'styled-components';

import { Logo } from '@root/components';
import { useNavigation } from '@root/hooks';
import { Container } from '@root/middleware';
import { Loader, ProgressLoader } from '@root/ui';
import { Locales } from '@root/widgets/Locales';

import authOperation from '@redux/auth/auth-operation';
import authSelectors from '@redux/auth/auth-selectors';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const TemplateSignUp = () => {
  const { navigation } = useNavigation();
  const dispatch = useDispatch();
  const token = useSelector(authSelectors.getUserToken);
  const loading = useSelector(authSelectors.getLoading);
  const { t } = useTranslation(['header']);

  return (
    <Page>
      <Container width={1400} padding={'0 100px'}>
        <Header>
          <Logo isSmall={false} />
          <LoginWrapper>
            <Locales />
            <Typography
              sx={{ ml: '60px' }}
              variant={'bodyMedium'}
              component={'p'}
            >
              {t('header:is_you_have_account')}
            </Typography>
            <Button
              variant="outlined"
              type="button"
              size={'medium'}
              sx={{ ml: '12px', p: '10px 20px', textTransform: 'revert' }}
              color="secondary"
              disableRipple={true}
              onClick={() => {
                token ? dispatch(authOperation.logOut()) : navigation('/login');
              }}
            >
              <Typography variant={'bodyBoldSmall'}>
                {' '}
                {t('header:login_btn')}
              </Typography>
            </Button>
          </LoginWrapper>
        </Header>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Container>
      {loading && <ProgressLoader position={'fixed'} />}
    </Page>
  );
};

const Page = styled.main`
  height: fit-content;
`;

const Header = styled.header`
  display: flex;

  padding: 23px 0;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-left: auto;
`;
