import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  ButtonBase,
  MessageError,
  ModalBase,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const Dpd = ({ data, setData, onChange, deliveryType, error }) => {
  const { color } = useTheme();

  const { t } = useTranslation(['common', 'placing_order'], {
    useSuspense: false,
  });

  const [open, setOpen] = useState(false);

  const { street_title, street_number, flat_num, zip_code, city_title } =
    data.delivery_info;

  window.addEventListener(
    'message',
    event => {
      if (
        event.data.dpdWidget &&
        event.data.dpdWidget.message === 'widgetClose'
      ) {
        setOpen(false);
      } else if (event.data.dpdWidget) {
        setData({
          ...data,
          delivery_info: {
            ...data.delivery_info,
            department_data: event.data.dpdWidget,
          },
        });
        setOpen(false);
      }
    },
    false,
  );

  const { department_data } = data.delivery_info;

  const renderContent = () => {
    switch (deliveryType) {
      case 'DPD_branch':
        return (
          <>
            {data.delivery_info.type === 'department' && (
              <ContentWrap>
                {open && (
                  <ModalBase open={open} onClose={() => setOpen(false)}>
                    <iframe
                      style={{ border: 'none' }}
                      src="https://api.dpd.cz/widget/latest/index.html"
                      width="1200px"
                      height="900px"
                    ></iframe>
                  </ModalBase>
                )}
                {department_data?.location ? (
                  <Cart>
                    <Column>
                      <Typography
                        variant={'bodyBoldSmall'}
                        color={color.gray}
                        sx={{}}
                      >
                        {t(
                          'placing_order:product_delivery.card_department.info_title',
                        )}
                      </Typography>
                      <Typography
                        variant={'bodySmall'}
                        color={color.gray}
                        sx={{
                          mt: '5px',
                        }}
                      >
                        {t(
                          'placing_order:product_delivery.card_department.city',
                        )}
                        : {department_data?.location?.address?.city}
                      </Typography>
                      <Typography
                        variant={'bodySmall'}
                        color={color.gray}
                        sx={{}}
                      >
                        {t('placing_order:delivery_address')}:{' '}
                        {department_data?.location?.address?.street}
                      </Typography>
                    </Column>

                    <OutlineButtonBase
                      sx={{
                        height: '36px',
                        minWidth: '36px !important',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <svg height={16} width={16}>
                        <use href={`${Sprite}#icon-pan`}></use>
                      </svg>
                    </OutlineButtonBase>
                    <OutlineButtonBase
                      sx={{
                        height: '36px',
                        minWidth: '36px !important',
                        alignItems: 'center',
                        ml: '10px',
                      }}
                      onClick={() => {
                        setData({
                          ...data,
                          delivery_info: {
                            ...data.delivery_info,
                            department_data: {},
                          },
                        });
                      }}
                    >
                      <svg height={16} width={16}>
                        <use href={`${Sprite}#icon-delete`}></use>
                      </svg>
                    </OutlineButtonBase>
                  </Cart>
                ) : (
                  <>
                    <ButtonBase
                      type="button"
                      size={'small'}
                      sx={{ flex: 1, maxWidth: '240px' }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <Typography variant={'bodyBoldLarge'}>
                        {t('placing_order:buttons.chose_department')}
                      </Typography>
                    </ButtonBase>
                    {error?.department_data?.init && (
                      <MessageError errorText={error?.department_data?.text} />
                    )}
                  </>
                )}
              </ContentWrap>
            )}
          </>
        );
      case 'Address':
        return (
          <>
            <TextInput
              error={error?.city_title?.init}
              errorText={error?.city_title?.text}
              id="input-with-icon-adornment"
              sx={{
                width: '306px',

                mb: '5px',
              }}
              placeholder={t('placing_order:product_delivery.fields.type_city')}
              max={100}
              onChange={onChange('city_title', 'dpd_courier')}
              value={city_title}
            />
            {data.delivery_info.type === 'dpd_courier' && (
              <ContentWrap>
                <ContentFormWrap>
                  <TextInput
                    placeholder={t(
                      'placing_order:product_delivery.fields.type_street',
                    )}
                    error={error?.street_title?.init}
                    errorText={error?.street_title?.text}
                    onChange={onChange('street_title', 'dpd_courier')}
                    value={street_title}
                  />
                  <TextInput
                    error={error?.street_number?.init}
                    errorText={error?.street_number?.text}
                    placeholder={t(
                      'placing_order:product_delivery.fields.build',
                    )}
                    onChange={onChange('street_number', 'dpd_courier')}
                    value={street_number}
                    withOutSpace={true}
                  />
                  <TextInput
                    placeholder={t(
                      'placing_order:product_delivery.fields.flat',
                    )}
                    onChange={onChange('flat_num', 'dpd_courier')}
                    value={flat_num}
                  />
                  <TextInput
                    error={error?.zip_code?.init}
                    errorText={error?.zip_code?.text}
                    placeholder={t(
                      'placing_order:product_delivery.fields.zip_code',
                    )}
                    onChange={onChange('zip_code', 'dpd_courier')}
                    value={zip_code}
                    max={10}
                    withOutSpace={true}
                  />
                  {/* <FormPhoneWrap>
                    <Typography variant={'bodySmall'} color={color.gray}>
                      {t(
                        'placing_order:product_delivery.fields.additional_telephone',
                      )}
                    </Typography>
                    <PhoneInput
                      error={error?.npPhone?.init}
                      errorText={error?.npPhone?.text}
                      onChange={onChange('npPhone', 'dpd_courier')}
                      value={npPhone}
                      sx={{
                        marginTop: '5px',
                      }}
                      placeholder={'+420'}
                    />
                  </FormPhoneWrap>
                  <DatePickerWrap>
                    <DatePickerCustom
                      placeholder={t(
                        'placing_order:product_delivery.fields.date',
                      )}
                      minDate={true}
                      maxDate={false}
                      value={date}
                      onChange={onChange('date', 'dpd_courier')}
                    />
                  </DatePickerWrap> */}
                </ContentFormWrap>
              </ContentWrap>
            )}
          </>
        );
      default:
        return <div>it is Default</div>;
    }
  };

  return <>{renderContent()}</>;
};

const ContentWrap = styled.div`
  padding-bottom: 15px;
`;

const ContentFormWrap = styled.div`
  display: grid;
  grid-template-columns: 179px 60px 60px;
  grid-column-gap: 4px;
  grid-row-gap: 10px;
`;

const FormPhoneWrap = styled.div`
  grid-column-end: span 3;
`;

const CheckBoxWrap = styled.div`
  grid-column-end: span 2;
  align-self: center;
  margin-left: 3px;
`;

const DatePickerWrap = styled.div``;

const Cart = styled.div`
  display: flex;
  flex-direction: row;

  padding: 16px;
  background: rgb(247, 248, 250);
  border-radius: 4px;
  margin-right: 15px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: auto;
`;

Dpd.propTypes = {
  data: PropTypes.exact({
    delivery_filial: PropTypes.string,
    delivery_info: PropTypes.exact({
      city_title: PropTypes.string,
      date: PropTypes.string,
      department_data: PropTypes.object,
      flat_num: PropTypes.string,
      npPhone: PropTypes.string,
      street_number: PropTypes.string,
      street_title: PropTypes.string,
      type: PropTypes.string,
      zip_code: PropTypes.string,
    }),
    delivery_type: PropTypes.string,
    discount: PropTypes.string,
    dont_call: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.number,
    its_drop: PropTypes.bool,
    name: PropTypes.string,
    payment_type: PropTypes.string,
    phone: PropTypes.string,
    surname: PropTypes.string,
  }),
  setData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  deliveryType: PropTypes.string,
  error: PropTypes.object,
};
