import { css } from '@emotion/css';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';

const styles = colors => css`
  & li {
    &:hover {
      background-color: ${colors.gray_20};
      color: ${colors.gray_80};
    }
  }

  .Mui-selected {
    background-color: ${colors.main} !important;
    color: ${colors.white};
  }
`;

export const MenuBody = ({
  children,
  anchorEl,
  open,
  handleClose,
  sx,
  horizontalPosition = 'center',
}) => {
  const { color } = useTheme();
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: horizontalPosition,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: horizontalPosition,
      }}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      variant={'selectedMenu'}
      sx={{
        ul: {
          border: `1px solid ${color.line}`,
          borderRadius: `4px`,
          p: '2px',
          ...sx,
        },
        li: {
          padding: '4px 5px',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: color.gray_20,
          },
        },
      }}
      MenuProps={{
        classes: {
          list: styles({
            gray_20: color.gray_20,
            gray_80: color.gray_80,
            main: color.main,
            white: color.white,
          }),
        },
      }}
    >
      {children}
    </Menu>
  );
};

MenuBody.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sx: PropTypes.object,
  horizontalPosition: PropTypes.string,
};
