import { createSlice } from '@reduxjs/toolkit';

import {
  updateComparison,
  updateFavorite,
  updateNestedProductById,
  updateProducts,
} from '@root/helpers';

import cartOperation from '../cart/cart-operation';
import comparisonsOperation from '../comparisons/comparisons-operation';
import favoriteOperation from '../favorite/favorite-operation';
import homeOperation from '../home/home-operation';
import optionOperation from '../option/option-operation';
import productOperation from './product-operation';

const initialState = {
  loading: false,
  loadingItemPage: true,
  loadingEditPage: false,
  product: [],
  meta: {},
  categoryName: '',
  productById: {},
  productByEditIdData: null,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    onClear(state, { payload }) {
      state.product = initialState.product;
      state.meta = initialState.meta;
      state.productById = initialState.productById;
      state.categoryName = initialState.categoryName;
      state.loadingItemPage = initialState.loadingItemPage;
    },
    onClearByEditIdData(state, { payload }) {
      state.productByEditIdData = initialState.productByEditIdData;
    },
    onClearProduct(state, { payload }) {
      state.product = initialState.product;
      state.meta = initialState.meta;
      state.categoryName = initialState.categoryName;
    },

    updateProductById(state, { payload }) {
      const { productId, addedToCardProducts } = payload;
      const currentCartProduct = addedToCardProducts.find(
        favoriteProduct => favoriteProduct?.id === productId,
      );

      const checkIsProductInCart = (key, defaultValue) =>
        currentCartProduct ? currentCartProduct[key] : defaultValue;

      state.productById = {
        ...state.productById,
        cart: checkIsProductInCart('cart', { amount: 0, quantity: 0 }),
        is_in_cart: checkIsProductInCart('is_in_cart', false),
      };
    },

    updateIsProductSavedById(state, { payload }) {
      state.productById = {
        ...state.productById,
        is_saved: !payload.is_saved,
      };
    },
    updateIsProductCompareById(state, { payload }) {
      state.productById = {
        ...state.productById,
        in_compare: !payload.in_compare,
      };
    },
  },
  extraReducers: {
    // ================================Global-Store-Listeners=============================//

    //====addBrands====//
    [optionOperation.addBrands.fulfilled]: (state, { payload }) => {
      const obj = payload.find(el => el.selected);

      state.productByEditIdData = {
        ...state.productByEditIdData,
        brand_id: obj.id,
      };
    },
    //====createHomeTopSale====//
    [homeOperation.createHomeTopSale.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [homeOperation.createHomeTopSale.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [homeOperation.createHomeTopSale.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====postProductToFavorite====//
    [favoriteOperation.postProductToFavorite.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.product = updateFavorite(state.product, id);
    },
    //====deleteProductFromSaved====//
    [favoriteOperation.deleteProductFromSaved.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.product = updateFavorite(state.product, id);
    },
    //====addToComparisons====//
    [comparisonsOperation.addToComparisons.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      state.product = updateComparison(state.product, id);
    },
    //====removeFromComparisons====//
    [comparisonsOperation.removeFromComparisons.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.product = updateComparison(state.product, id);
    },
    //====addProductToCart====//
    [cartOperation.addProductToCart.fulfilled]: (state, { payload }) => {
      const { data, id } = payload;

      state.product = updateProducts(state.product, data.data);
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'related_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'analogue_products',
      });
    },
    //====putOneToCart====//
    [cartOperation.putOneToCart.fulfilled]: (state, { payload }) => {
      const { data, id } = payload;

      state.product = updateProducts(state.product, data.data);
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'related_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'analogue_products',
      });
    },
    //====deleteOneFromCart====//
    [cartOperation.deleteOneFromCart.fulfilled]: (state, { payload }) => {
      const { data, id } = payload;

      state.product = updateProducts(state.product, data.data);
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'related_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'analogue_products',
      });
    },
    // ================================End-Global-Store-Listeners=============================//
    //====getProduct====//
    [productOperation.getProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.getProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.product = payload.data;
      state.meta = payload.meta;
      state.categoryName = payload.category;
    },
    [productOperation.getProduct.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getProductById====//
    [productOperation.getProductById.pending]: (state, action) => {},
    [productOperation.getProductById.fulfilled]: (state, { payload }) => {
      state.loadingItemPage = false;
      state.productById = payload.data;
    },
    [productOperation.getProductById.rejected]: (state, { payload }) => {
      state.loadingItemPage = false;
    },

    //====getProductByEditId====//
    [productOperation.getProductByEditId.pending]: (state, action) => {
      state.loadingEditPage = true;
    },
    [productOperation.getProductByEditId.fulfilled]: (state, { payload }) => {
      state.loadingEditPage = false;
      state.productByEditIdData = payload.data.product;
    },
    [productOperation.getProductByEditId.rejected]: (state, { payload }) => {
      state.loadingEditPage = false;
    },

    //====editProductByEditId====//
    [productOperation.editProductByEditId.pending]: (state, action) => {},
    [productOperation.editProductByEditId.fulfilled]: (state, { payload }) => {
      state.productById = payload;
    },
    [productOperation.editProductByEditId.rejected]: (state, { payload }) => {},

    // ---- getProductsBySearch ----- //
    [productOperation.getProductsBySearch.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [productOperation.getProductsBySearch.fulfilled]: (state, { payload }) => {
      state.product = payload.data;
      state.meta = payload.meta;
      state.loading = false;
    },
    [productOperation.getProductsBySearch.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // ---- addAnalogueProductsById ----- //
    [productOperation.addAnalogueProductsById.pending]: (
      state,
      { payload },
    ) => {
      state.loading = true;
    },
    [productOperation.addAnalogueProductsById.fulfilled]: (
      state,
      { payload },
    ) => {
      state.productById = payload.data;
      state.loading = false;
    },
    [productOperation.addAnalogueProductsById.rejected]: (
      state,
      { payload },
    ) => {
      state.loading = false;
    },

    // ---- addRelatedProductsById ----- //
    [productOperation.addRelatedProductsById.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [productOperation.addRelatedProductsById.fulfilled]: (
      state,
      { payload },
    ) => {
      state.productById = payload.data;
      state.loading = false;
    },
    [productOperation.addRelatedProductsById.rejected]: (
      state,
      { payload },
    ) => {
      state.loading = false;
    },
  },
});

export const {
  updateProductById,
  updateIsProductSavedById,
  onClear,
  updateIsProductCompareById,
  onClearProduct,
  onClearByEditIdData,
} = productSlice.actions;

export default productSlice.reducer;
