export const savedOrdersConf = [
  {
    label: 'Продукт',
    value: 'product',
    type: 'img',
    style: {},
  },
  {
    label: 'Назва',
    value: 'name',
    type: 'email',
    style: {
      width: '100%',
    },
  },
  {
    label: 'Ціна',
    value: 'cost',
    type: 'cost',
    style: {
      ml: '20px',
      maxWidth: '110px',
      width: '100%',
    },
  },
  {
    label: 'Кількість',
    value: 'quantity',
    type: 'quantity',
    style: {
      ml: '20px',
      maxWidth: '60px',
      width: '100%',
    },
  },
  {
    label: 'Наявність',
    value: 'managing',
    type: 'managing',
    style: {},
  },
];
