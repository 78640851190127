const getCities = state => state.option.cities;
const getActivityAreas = state => state.option.activityAreas;
const getHistoryFilters = state => state.option.historyFilters;
const getBrands = state => state.option.brands;
const getLangs = state => state.option.langs;

const optionSelectors = {
  getCities,
  getActivityAreas,
  getHistoryFilters,
  getBrands,
  getLangs,
};
export default optionSelectors;
