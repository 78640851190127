import { useEffect, useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ImgPrev, LabelProduct } from '@root/components';
import { orderConf } from '@root/conf';
import { useNavigation } from '@root/hooks';
import { theme } from '@root/styles';
import { IconBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import {
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const TableHistoryOrder = ({ data, currency, checkIsOrderSaved }) => {
  const { navigation } = useNavigation();
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const currencyCode = useSelector(userSelectors.getChoosedCurrency);
  const chosenCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  const { color } = useTheme();
  const { t } = useTranslation(['order_history'], { useSuspense: false });

  const handelDetailCard = id => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };

  useEffect(() => {
    setOpenCard(false);
    setChoseIdCard(null);
  }, [data]);

  const getCurrentProductEntities = (data, key) => {
    return checkIsOrderSaved() ? data.product[key] : data[key];
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ height: '100%', boxShadow: 'none', backgroundColor: 'transparent' }}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ display: 'flex', p: '12px 16px' }}>
            {orderConf(t).map(el => {
              return (
                <TableCell
                  key={el.label}
                  sx={{
                    borderBottom: 'none',
                    display: 'flex',
                    ...el.style,
                    p: 0,
                    flexDirection: 'row',
                  }}
                >
                  <OpacityContainer>
                    <Typography
                      variant={'bodyBoldSmall'}
                      component={'p'}
                      sx={{ color: color.gray_60 }}
                    >
                      {el.label}
                    </Typography>
                  </OpacityContainer>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      </Table>
      <Divider />
      <TableBody
        colors={{ gray: color.gray_40, white: color.white, line: color.line }}
      >
        {data &&
          data.map((row, idx) => (
            <>
              <TableRow
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: '20px 20px 20px 16px',
                  borderBottom: `1px solid ${color.line}`,
                  th: {
                    borderBottom: 'none',
                  },
                  '&:last-child, &:last-child th': { borderBottom: 0 },
                }}
                key={'pe' + row.id + idx}
              >
                <>
                  {orderConf(t).map((el, i) => {
                    switch (el?.value) {
                      case 'product':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                            onClick={() =>
                              navigation(getCurrentProductEntities(row, 'url'))
                            }
                          >
                            <ImgPrev
                              src={getCurrentProductEntities(row, 'image')}
                              countImg={getCurrentProductEntities(
                                row,
                                'images_quantity',
                              )}
                            />
                          </TableCell>
                        );

                      case 'name':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                            onClick={() =>
                              navigation(getCurrentProductEntities(row, 'url'))
                            }
                          >
                            <LabelProduct
                              label={getCurrentProductEntities(row, 'name')}
                              parm={getCurrentProductEntities(row, 'model')}
                              article={getCurrentProductEntities(row, 'sku')}
                            />
                          </TableCell>
                        );

                      case 'stock':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                          >
                            <Typography
                              variant={'bodySmall'}
                              component={'p'}
                              sx={{ color: color.gray_80 }}
                            >
                              {row.quantity} {t('order_history:piece')}
                            </Typography>
                          </TableCell>
                        );
                      case 'price':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{
                              p: 0,
                              ...el.style,
                            }}
                          >
                            <Typography
                              variant={'bodySmall'}
                              component={'p'}
                              sx={{ color: color.gray_80 }}
                            >
                              {checkIsOrderSaved() ? (
                                <>
                                  {row?.product?.personal_price[chosenCurrency]}{' '}
                                  {currencyCode}
                                </>
                              ) : (
                                <>
                                  {row?.price} {currency}
                                </>
                              )}
                            </Typography>
                          </TableCell>
                        );

                      case 'amount':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{ p: 0, ...el.style }}
                          >
                            <Typography
                              variant={'bodyBoldMedium'}
                              component={'p'}
                              color={theme.color.gray_80}
                            >
                              {checkIsOrderSaved() ? (
                                <>
                                  {row?.amounts_personal[chosenCurrency]}{' '}
                                  {currencyCode}
                                </>
                              ) : (
                                <>
                                  {row?.amount} {currency}
                                </>
                              )}
                            </Typography>
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{ ...el.style }}
                          >
                            <IconBtn
                              href={`${Sprite}#icon-arrow_card`}
                              onClick={() => handelDetailCard(row.id)}
                              size={16}
                              rotateSvg={
                                openCard && row.id === choseIdCard
                                  ? '180deg'
                                  : '0'
                              }
                            />
                          </TableCell>
                        );
                    }
                  })}
                </>
              </TableRow>
              {openCard && row.id === choseIdCard && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: '100%', display: 'block' }}
                  >
                    <Typography
                      sx={{}}
                      variant={'bodyBoldSmall'}
                      component={'h3'}
                    >
                      {t('order_history:product_description')}
                    </Typography>
                    <Typography
                      sx={{ mt: '8px' }}
                      variant={'bodySmall'}
                      component={'p'}
                    >
                      {getCurrentProductEntities(row, 'description') && (
                        <EllipsisText
                          text={getCurrentProductEntities(row, 'description')}
                          length={200}
                        />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </>
          ))}
      </TableBody>
    </TableContainer>
  );
};

const TableBody = styled.tbody`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const OpacityContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

TableHistoryOrder.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      amounts_personal: PropTypes.exact({
        price: PropTypes.number,
        price_cron: PropTypes.number,
        price_cron_no_dph: PropTypes.number,
        price_no_dph: PropTypes.number,
      }),
      amounts_product: PropTypes.exact({
        price: PropTypes.number,
        price_cron: PropTypes.number,
        price_cron_no_dph: PropTypes.number,
        price_no_dph: PropTypes.number,
      }),
      id: PropTypes.number,
      product: PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_cron: PropTypes.bool,
          price_cron_no_dph: PropTypes.bool,
          price_no_dph: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_cron: PropTypes.string,
            price_cron_no_dph: PropTypes.string,
            price_no_dph: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.string,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_cron: PropTypes.string,
          price_cron_no_dph: PropTypes.string,
          price_no_dph: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_cron: PropTypes.string,
          price_cron_no_dph: PropTypes.string,
          price_no_dph: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            personal: PropTypes.string,
            product: PropTypes.string,
          }),
          price_cron: PropTypes.exact({
            personal: PropTypes.string,
            product: PropTypes.string,
          }),
          price_cron_no_dph: PropTypes.exact({
            personal: PropTypes.string,
            product: PropTypes.string,
          }),
          price_no_dph: PropTypes.exact({
            personal: PropTypes.string,
            product: PropTypes.string,
          }),
        }),
        short_description: PropTypes.string,
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.array,
      }),
      product_id: PropTypes.number,
      quantity: PropTypes.number,
    }),
  ),
  currency: PropTypes.string,
  checkIsOrderSaved: PropTypes.func.isRequired,
};
