import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonBase, OutlineButtonBase } from '@root/ui';

export const FooterDefault = ({ conf }) => {
  const getBtn = confEl => {
    const { onClose, type, text, form } = confEl;
    switch (type) {
      case 'outline':
        return (
          <OutlineButtonBase onClick={() => onClose()}>
            {text}
          </OutlineButtonBase>
        );

      case 'inline':
        return (
          <ButtonBase type="submit" form={form}>
            {text}
          </ButtonBase>
        );

      default:
        break;
    }
  };

  return <ActionsWrapper>{conf.map(el => getBtn(el))}</ActionsWrapper>;
};

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 10px;
`;

FooterDefault.propTypes = {
  conf: PropTypes.arrayOf(
    PropTypes.exact({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      onClose: PropTypes.func,
      form: PropTypes.string,
    }),
  ),
};
