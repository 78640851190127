import { createSlice } from '@reduxjs/toolkit';

import operation from '@redux/favorite/favorite-operation';

const initialState = {
  loading: false,
  isFirstLoading: true,
  listTypesFavorite: [],
  newFavoriteId: null,
  listAllFavoriteProduct: [],
  favoriteName: '',
  favoriteDescription: '',
  meta: {},
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    onClearFavorite(state, { payload }) {
      state.listTypesFavorite = initialState.listTypesFavorite;
      state.newFavoriteId = initialState.newFavoriteId;
      state.listAllFavoriteProduct = initialState.listAllFavoriteProduct;
      state.favoriteName = initialState.favoriteName;
      state.favoriteDescription = initialState.favoriteDescription;
      state.meta = initialState.meta;
      state.isFirstLoading = initialState.isFirstLoading;
    },
    updateFavoriteById(state, { payload }) {
      const { favoriteProductId, addedToCardProducts } = payload;

      const currentCartProduct = addedToCardProducts.find(
        favoriteProduct => favoriteProduct?.id === favoriteProductId,
      );

      const checkIsProductInCart = (key, defaultValue) =>
        currentCartProduct ? currentCartProduct[key] : defaultValue;

      state.listAllFavoriteProduct = state.listAllFavoriteProduct.map(
        favoriteProduct =>
          favoriteProduct.id === favoriteProductId
            ? {
                ...favoriteProduct,
                cart: checkIsProductInCart('cart', { amount: 0, quantity: 0 }),
                is_in_cart: checkIsProductInCart('is_in_cart', false),
              }
            : favoriteProduct,
      );
    },
  },
  extraReducers: {
    //=========postNewFavorite=========//
    [operation.postNewFavorite.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.postNewFavorite.fulfilled]: (state, { payload }) => {
      state.newFavoriteId = payload.id;
      state.loading = false;
      state.isFirstLoading = false;
    },
    [operation.postNewFavorite.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isFirstLoading = false;
    },
    //=======getFavoritesList==========//
    [operation.getFavoritesList.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getFavoritesList.fulfilled]: (state, { payload }) => {
      state.listTypesFavorite = payload;
      state.loading = false;
      state.isFirstLoading = false;
    },
    [operation.getFavoritesList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isFirstLoading = false;
    },
    //=======deleteFavoriteById==========//
    [operation.deleteFavoriteById.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.deleteFavoriteById.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [operation.deleteFavoriteById.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //=======getListAllSavedProduct==========//
    [operation.getListAllSavedProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getListAllSavedProduct.fulfilled]: (state, { payload }) => {
      state.listAllFavoriteProduct = payload.data;
      state.meta = payload.meta;
      state.loading = false;
      state.isFirstLoading = false;
    },
    [operation.getListAllSavedProduct.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isFirstLoading = false;
    },
    //=======getListSavedProductByType==========//
    [operation.getListSavedProductByType.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getListSavedProductByType.fulfilled]: (state, { payload }) => {
      const { description, name } = payload.favorite.favorite;
      state.favoriteName = name;
      state.favoriteDescription = description;
      state.listAllFavoriteProduct = payload.data;
      state.meta = payload.meta;
      state.loading = false;
      state.isFirstLoading = false;
    },
    [operation.getListSavedProductByType.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isFirstLoading = false;
    },
    //=======deleteProductFromSaved==========//
    [operation.deleteProductFromSaved.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.deleteProductFromSaved.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [operation.deleteProductFromSaved.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //=========clearFavoriteData=========//
    [operation.clearFavoriteData.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.clearFavoriteData.fulfilled]: (state, { payload }) => {
      state.listAllFavoriteProduct = payload;
      state.loading = false;
    },
    [operation.clearFavoriteData.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const { onClearFavorite, updateFavoriteById } = favoriteSlice.actions;

export default favoriteSlice.reducer;
