import PropTypes from 'prop-types';

import { BigLogo, SmallLogo } from '@root/assets/svg';

export const Logo = ({ isSmall = false }) => {
  return <img src={isSmall ? SmallLogo : BigLogo} alt="Logo" />;
};

Logo.propTypes = {
  isSmall: PropTypes.bool,
};
