import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '@root/assets/image';
import { Sprite } from '@root/assets/svg';
import { Empty, ModalStatusOrder, SearchInput } from '@root/components';
import { getOrderTabList } from '@root/conf';
import { getCurrentDate, getFirstDateOfCurrentMonth } from '@root/helpers';
import { useNavigation, useQueryParams } from '@root/hooks';
import { ButtonBase, CustomScroll, ProgressLoader, ToggleBtn } from '@root/ui';

import optionSelectors from '@redux/option/option-selectors';
import orderOperation from '@redux/order/order-operation';
import orderSelectors from '@redux/order/order-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { ChipBox } from './ChipBox';
import { FilterList } from './FilterList';
import { CartHistory } from './Item';

export const HistoryList = ({
  data,
  setData,
  filterActive,
  setFilterActive,
  debounceSearchValue,
}) => {
  const orderHistory = useSelector(orderSelectors.getOrderHistory);
  const filters = useSelector(optionSelectors.getHistoryFilters);
  const meta = useSelector(orderSelectors.getOrderHistoryMeta);
  const loading = useSelector(orderSelectors.getLoadingHistory);
  const [choiceId, setChoiceId] = useState();
  const [chipHeight, setChipHeight] = useState();
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusOrder, setStatusOrder] = useState(null);
  const [orderEmail, setOrderEmail] = useState(null);
  const { color } = useTheme();
  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const { getQueryObj, handleUpdateString, handleHasQuery, appendParam } =
    useQueryParams();
  const orderId = Number(getQueryObj().order);
  const { t } = useTranslation(['order_history', 'search']);

  useEffect(() => {
    if (orderHistory.length > 0 && orderId && !statusOrder) {
      orderHistory.find(el => {
        if (el.id === orderId) {
          setStatusOrder(el.status);
          setOrderEmail(el.email);
          handelClick(orderId);
        }
      });
    }
  }, [orderHistory, orderId, statusOrder]);

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const handelClick = id => {
    setChoiceId(id);
    dispatch(orderOperation.getHistoryOrderById({ id }));
  };

  const handelClear = e => {
    setData({
      searchValue: '',
    });
    dispatch(
      orderOperation.getHistoryOrderBySearch({
        page: 1,
        search: '',
        filterActive,
      }),
    );
  };

  const handelSubmitFilter = valueRes => {
    handleHasQuery('filter');

    for (const key in valueRes) {
      const valueParam = `${key}:` + valueRes[key].join(',');
      if (valueRes[key].length > 0) appendParam('filter', valueParam);
    }
    handleUpdateString();

    dispatch(
      orderOperation.getHistoryOrderBySearch({
        page: 1,
        search: searchValue,
        filterActive: valueRes,
      }),
    );
  };

  const { searchValue } = data;

  return (
    <Components color={color}>
      <Search color={color}>
        <FlexWrapper>
          <SearchInput
            focus={false}
            onChange={onChange}
            searchValue={searchValue}
            placeholder={t('search:search_input_placeholder')}
            sx={{
              '& input': {
                fontSize: '0.875rem',
              },
            }}
          />
          <ToggleBtn
            size={36}
            onClick={handelClear}
            isActive={true}
            style={{ marginRight: '10px' }}
            href={`${Sprite}#icon-delete`}
          />

          <ToggleBtn
            size={36}
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
            isActive={true}
            href={filterOpen ? `${Sprite}#icon-cross` : `${Sprite}#icon-filter`}
          />
        </FlexWrapper>
        {Object.keys(filters).length > 0 && (
          <ChipBox
            filterActive={filterActive}
            setChipHeight={setChipHeight}
            setFilterActive={setFilterActive}
            handelSubmitFilter={handelSubmitFilter}
          />
        )}
      </Search>
      <HistoryListBody chipHeight={chipHeight}>
        <>{loading && <ProgressLoader />}</>
        {orderHistory.length > 0 ? (
          <ListOfHistory chipHeight={chipHeight}>
            {' '}
            <div style={{ height: '100%' }}>
              <CustomScroll vertical={true}>
                {orderHistory.map((el, idx, arr) => {
                  return (
                    <>
                      <CartHistory
                        data={el}
                        isActive={choiceId === el.id}
                        onClick={handelClick}
                      />
                      {idx + 1 === arr.length - 1 &&
                        meta?.current_page !== meta?.last_page && (
                          <InView
                            onChange={(inView, entry) =>
                              inView &&
                              dispatch(
                                orderOperation.getHistoryOrder({
                                  page: meta.current_page + 1,
                                  search: data.searchValue,
                                  filterActive,
                                }),
                              )
                            }
                          />
                        )}
                    </>
                  );
                })}
              </CustomScroll>{' '}
            </div>
          </ListOfHistory>
        ) : debounceSearchValue.length > 0 && orderHistory.length === 0 ? (
          <Empty type={'EmptySearchLogo'} />
        ) : (
          !loading && (
            <EmptyBox>
              <img src={Box} alt="Box" />
              <Typography
                variant={'bodyBoldSmall'}
                component={'p'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: '40px',
                  mb: '20px',
                  color: color.gray,
                }}
              >
                {t('order_history:add_first_order')}
              </Typography>
              <ButtonBase
                onClick={() => {
                  navigation('/catalog');
                }}
                sx={{
                  height: '38px',
                }}
              >
                {t('order_history:buttons.nav')}
              </ButtonBase>
            </EmptyBox>
          )
        )}
        {!isNaN(orderId) && (
          <ModalStatusOrder
            open={orderId}
            orderId={orderId}
            status={statusOrder}
            email={orderEmail}
            hideControl
          />
        )}{' '}
      </HistoryListBody>
      {filterOpen && (
        <FilterList
          filterActive={filterActive}
          setFilterActive={setFilterActive}
          handelSubmitFilter={handelSubmitFilter}
          filterClose={() => setFilterOpen(false)}
        />
      )}
    </Components>
  );
};

const Components = styled.div`
  width: 35%;
  border: 1px solid ${({ color }) => color.line};
  background: ${({ color }) => color.white};
  border-radius: 4px;
  height: calc(100vh - 180px);
  position: relative;
  overflow: hidden;
`;
const HistoryListBody = styled.div`
  position: relative;
  min-height: calc(100% - 57px - ${({ chipHeight }) => chipHeight}px);
  height: calc(100% - 57px - ${({ chipHeight }) => chipHeight}px);
`;

const ListOfHistory = styled.ul`
  position: relative;
  min-height: 100%;
  height: 100%;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 0;
`;

const Search = styled.form`
  position: relative;
  padding: 10px 11px;

  border-bottom: 1px solid ${({ color }) => color.line};
  z-index: 200;

  background-color: #fff;
`;

const FlexWrapper = styled.div`
  margin-top: ${({ gap }) => gap}px;
  display: flex;
`;

HistoryList.className = Components;

HistoryList.propTypes = {
  data: PropTypes.exact({
    searchValue: PropTypes.string,
  }),
  debounceSearchValue: PropTypes.string,
  setData: PropTypes.func.isRequired,
};
