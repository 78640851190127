const getData = state => state.admin.data;
const getLinks = state => state.admin.links;
const getMeta = state => state.admin.meta;
const getLoadingBusiness = state => state.admin.loadingBusiness;
const getLoadingCounterparty = state => state.admin.loadingCounterparty;
const getLoadingPeople = state => state.admin.loadingPeople;
const getLoadingProducts = state => state.admin.loadingProducts;
const getLoadingOrders = state => state.admin.loadingOrders;
const getAdminSearch = state => state.admin.search;

const adminSelectors = {
  getData,
  getLinks,
  getMeta,
  getLoadingBusiness,
  getLoadingCounterparty,
  getLoadingPeople,
  getLoadingProducts,
  getLoadingOrders,
  getAdminSearch,
};

export default adminSelectors;
