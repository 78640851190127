import { createSlice } from '@reduxjs/toolkit';

import operation from './admin-operation';

const initialState = {
  loadingBusiness: false,
  loadingCounterparty: false,
  loadingPeople: false,
  loadingProducts: false,
  loadingOrders: false,
  links: {},
  meta: {},
  data: [],
  search: '',
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    onClearAdmin: state => {
      state.links = initialState.links;
      state.meta = initialState.meta;
      state.data = initialState.data;
      state.search = initialState.search;
      state.loading = initialState.loading;
    },
    updateAdminProductOnDeleteById: (state, { payload }) => {
      state.data = [...state.data].filter(
        adminProduct => adminProduct.id !== payload,
      );
    },
    updateAdminProductById: (state, { payload }) => {
      state.data = [...state.data].map(product =>
        product.id === payload.id
          ? { ...product, [payload.field]: payload.value }
          : product,
      );
    },
    updateAdminProductVisibility: (state, { payload }) => {
      state.data = [...state.data].map(product =>
        product.id === payload.data.id ? payload.data : product,
      );
    },
    updateUserStatusById: (state, { payload }) => {
      state.data = [...state.data].map(user =>
        user.id === payload.id ? { ...user, status: payload.status } : user,
      );
    },

    updateAdminOrder: (state, { payload }) => {
      const { id, statuses } = payload;
      const value = statuses.find(el => el.status === 'current');

      state.data = [...state.data].map(product =>
        product.id === id ? { ...product, status: value.label } : product,
      );
    },
  },
  extraReducers: {
    //=====getBusiness=====//
    [operation.getBusinessData.pending]: (state, action) => {
      state.loadingBusiness = true;
    },
    [operation.getBusinessData.fulfilled]: (state, { payload }) => {
      const { links, meta, data } = payload;
      state.data = data;
      state.links = links;
      state.meta = meta;
      state.loadingBusiness = false;
    },
    [operation.getBusinessData.rejected]: (state, action) => {
      state.loadingBusiness = false;
    },
    //====getCounterpartyData====//
    [operation.getCounterpartyData.pending]: (state, action) => {
      state.loadingCounterparty = true;
    },
    [operation.getCounterpartyData.fulfilled]: (state, { payload }) => {
      const { links, meta, data } = payload;
      state.data = data;
      state.links = links;
      state.meta = meta;
      state.loadingCounterparty = false;
    },
    [operation.getCounterpartyData.rejected]: (state, action) => {
      state.loadingCounterparty = false;
    },
    //====getPeople====//
    [operation.getPeopleData.pending]: (state, action) => {
      state.loadingPeople = true;
    },
    [operation.getPeopleData.fulfilled]: (state, { payload }) => {
      const { links, meta, data } = payload;
      state.data = data;
      state.links = links;
      state.meta = meta;
      state.loadingPeople = false;
    },
    [operation.getPeopleData.rejected]: (state, action) => {
      state.loadingPeople = false;
    },
    //=====getBusinessesSearch====//
    [operation.getBusinessesSearch.pending]: (state, action) => {
      state.loadingBusiness = true;
    },
    [operation.getBusinessesSearch.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;
      state.loadingBusiness = false;
    },
    [operation.getBusinessesSearch.rejected]: (state, action) => {
      state.loadingBusiness = false;
    },
    //=====getCounterpartsSearch====//
    [operation.getCounterpartsSearch.pending]: (state, action) => {
      state.loadingCounterparts = true;
    },
    [operation.getCounterpartsSearch.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;
      state.loadingCounterparts = false;
    },
    [operation.getCounterpartsSearch.rejected]: (state, action) => {
      state.loadingCounterparts = false;
    },
    //=====getPeopleSearch====//
    [operation.getPeopleSearch.pending]: (state, action) => {
      state.loadingPeople = true;
    },
    [operation.getPeopleSearch.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;
      state.loadingPeople = false;
    },
    [operation.getPeopleSearch.rejected]: (state, action) => {
      state.loadingPeople = false;
    },
    //=====getAdminProducts====//
    [operation.getAdminProducts.pending]: (state, action) => {
      state.loadingProducts = true;
    },
    [operation.getAdminProducts.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;

      state.loadingProducts = false;
    },
    [operation.getAdminProducts.rejected]: (state, action) => {
      state.loadingProducts = false;
    },
    //=====getAdminOrderList====//
    [operation.getAdminOrderList.pending]: (state, action) => {
      state.loadingOrders = true;
    },
    [operation.getAdminOrderList.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;

      state.loadingOrders = false;
    },
    [operation.getAdminOrderList.rejected]: (state, action) => {
      state.loadingOrders = false;
    },
  },
});
export const {
  onClearAdmin,
  updateAdminProductOnDeleteById,
  updateAdminProductById,
  updateAdminProductVisibility,
  updateUserStatusById,
  updateAdminOrder,
} = adminSlice.actions;
export default adminSlice.reducer;
