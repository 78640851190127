const getUserName = state => state.user.userProfile.userName;
const getNpApiKey = state => state.user.userProfile.np_api_key;
const getAvatar = state => state.user.userProfile.avatar;

const getUserCompanyName = state =>
  state.user.userProfile.company.official_name;
const getUserCompany = state => state.user.userProfile.company;
const getTwoFactorAuthenticationInfo = state =>
  state.user.userProfile.security.two_factor_authentication_info;
const getTwoFactorAuthenticationInfoLoading = state =>
  state.user.twoFactorAuthenticationInfoLoading;
const getIsSaveLoginsActive = state =>
  state.user.userProfile.security.is_save_logins_active;
const getIsTwoFactorAuthenticationActive = state =>
  state.user.userProfile.security.is_two_factor_authentication_active;
const getActivateTwoFactorAuthenticationLoading = state =>
  state.user.activateTwoFactorAuthenticationLoading;
const getUserLogins = state => state.user.userProfile.logins;
const getIsUserLoginsLoading = state => state.user.loginsLoading;
const getUserProfile = state => state.user.userProfile;
const getUserChangePasswordAt = state =>
  state.user.userProfile.security.password_updated_at;
const getUserColorConf = state => state.user.userProfile.custom_color_conf;
const getUserCompanyConf = state => state.user.userProfile.custom_logo_menu;

const getLoading = state => state.user.loading;
const getPrices = state => state.user.prices;
const getChoosedCurrency = state => state.user.choosedCurrency;
const getChoosedCurrencyWidget = state => state.user.choosedCurrencyWidget;
const getCurrencyRates = state => state.user.currency_rates;

const userSelectors = {
  getUserName,
  getAvatar,
  getUserColorConf,
  getLoading,
  getPrices,
  getChoosedCurrency,
  getCurrencyRates,
  getNpApiKey,
  getChoosedCurrencyWidget,
  getUserProfile,
  getUserCompany,
  getUserLogins,
  getIsUserLoginsLoading,
  getTwoFactorAuthenticationInfo,
  getTwoFactorAuthenticationInfoLoading,
  getIsTwoFactorAuthenticationActive,
  getIsSaveLoginsActive,
  getUserChangePasswordAt,
  getActivateTwoFactorAuthenticationLoading,
  getUserCompanyName,
  getUserCompanyConf,
};
export default userSelectors;
