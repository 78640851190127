import React from 'react';

import { t } from 'i18next';
import styled from 'styled-components';

import { useNavigation } from '@root/hooks';
import { ButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const EmptyBox = ({
  title = t('home:empty_title'),
  subTitle = t('home:empty_subtitle'),
  buttonTittle = t('home:buttons.go_to_catalog'),
  boxStyles,
  children,
}) => {
  const { navigation } = useNavigation();
  const { color } = useTheme();

  return (
    <Wrapper styles={boxStyles}>
      {children}
      <Typography
        variant={'h1'}
        component={'p'}
        color={color.gray}
        sx={{ marginBottom: '24px' }}
      >
        {title}
      </Typography>
      <Typography
        variant={'bodyBoldSmall'}
        component={'span'}
        color={color.gray_60}
        sx={{ marginBottom: '24px' }}
      >
        {subTitle}
      </Typography>
      <ButtonBase
        sx={{ minHeight: '38px' }}
        onClick={() => navigation('/catalog')}
      >
        <Typography
          component={'span'}
          color={color.white}
          variant={'bodyBoldSmall'}
        >
          {buttonTittle}
        </Typography>
      </ButtonBase>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ styles }) => styles}
`;
