import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Navigation } from 'swiper';
import 'swiper/css/bundle';

import { Sprite } from '@root/assets/svg';
import { ToggleBtn } from '@root/ui';

import { useTheme } from '@mui/material';

import { CardSlider } from './CardSlider';

export const AdvertisingSlider = ({
  data,
  handleChoseItem,
  styledContainer,
}) => {
  const { color } = useTheme();
  //swiper
  const [_, setInit] = useState();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  //swiper

  return (
    <ContentWrapper style={{ ...styledContainer }}>
      <Swiper
        modules={[Navigation]}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        speed={700}
        slidesPerView={'auto'}
        style={{
          width: '100%',
        }}
        spaceBetween={18}
        onInit={() => {
          setInit(true);
        }}
      >
        {data.length > 0 &&
          data.map((el, i) => (
            <SwiperSlide style={{ width: 'auto' }}>
              <CardSlider
                key={el.id}
                data={el}
                addedToCart={el.is_in_cart}
                onClick={() => handleChoseItem(el)}
                availableNumber={el?.stock}
                chooseProduct={el.cart}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      <NavigationWrap>
        <NavigationSwiperNext
          ref={navigationNextRef}
          colors={{ white: color.white, line: color.line, gray: color.gray_60 }}
        >
          <ToggleBtn
            isActive={true}
            size={50}
            href={`${Sprite}#icon-arrow-right`}
          />
        </NavigationSwiperNext>
      </NavigationWrap>
      <NavigationWrap>
        <NavigationSwiperPrev
          ref={navigationPrevRef}
          colors={{ white: color.white, line: color.line, gray: color.gray_60 }}
        >
          <ToggleBtn
            isActive={true}
            size={50}
            href={`${Sprite}#icon-arrow-left`}
          />
        </NavigationSwiperPrev>
      </NavigationWrap>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background-color: transparent;
`;

const NavigationWrap = styled.div`
  .swiper-button-disabled {
    display: none;
  }
`;

const NavigationSwiperNext = styled.div`
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 129px;
  height: 140px;

  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  background: linear-gradient(
    270.24deg,
    #f7f8fa 0.24%,
    #f7f8fa 44.19%,
    rgba(247, 248, 250, 0) 101.19%
  );

  button {
    background-color: ${props => props.colors.white};
    border: 1px solid ${props => props.colors.line};
    fill: ${props => props.colors.gray};
    stroke: ${props => props.colors.gray};
  }
`;

const NavigationSwiperPrev = styled.div`
  position: absolute;
  left: -20px;
  top: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 129px;
  height: 140px;

  button {
    background-color: ${props => props.colors.white};
    border: 1px solid ${props => props.colors.line};
    fill: ${props => props.colors.gray};
    stroke: ${props => props.colors.gray};
  }
`;

AdvertisingSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      brand: PropTypes.string,
      can_buy: PropTypes.exact({
        price: PropTypes.bool,
        price_cron: PropTypes.bool,
        price_cron_no_dph: PropTypes.bool,
        price_no_dph: PropTypes.bool,
      }),
      cart: PropTypes.shape({
        cart_product_id: PropTypes.number,
        id: PropTypes.number,
        personal_amount: PropTypes.exact({
          price: PropTypes.string,
          price_cron: PropTypes.string,
          price_cron_no_dph: PropTypes.string,
          price_no_dph: PropTypes.string,
        }),
        quantity: PropTypes.number,
      }),
      description: PropTypes.string,
      has_analogs: PropTypes.bool,
      id: PropTypes.number,
      image: PropTypes.string,
      images_quantity: PropTypes.number,
      in_compare: PropTypes.bool,
      is_in_cart: PropTypes.bool,
      is_saved: PropTypes.bool,
      model: PropTypes.string,
      name: PropTypes.string,
      personal_price: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      price: PropTypes.exact({
        price: PropTypes.string,
        price_cron: PropTypes.string,
        price_cron_no_dph: PropTypes.string,
        price_no_dph: PropTypes.string,
      }),
      prices: PropTypes.exact({
        price: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_cron: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_cron_no_dph: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_no_dph: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
      }),
      sku: PropTypes.string,
      slug: PropTypes.string,
      stock: PropTypes.number,
      stock_max: PropTypes.number,
      stock_percent: PropTypes.number,
      url: PropTypes.string,
      warehouses: PropTypes.arrayOf(
        PropTypes.exact({
          city_name: PropTypes.string,
          warehouses: PropTypes.exact({
            name: PropTypes.string,
            stock: PropTypes.number,
            stock_max: PropTypes.number,
            stock_percent: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
  handleChoseItem: PropTypes.func.isRequired,
  styledContainer: PropTypes.object,
};
