import InputMask from 'react-input-mask';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar } from '@root/assets/svg';
import { sanitizeOnlyDigits, sanitizeOnlyDigitsAndDot } from '@root/helpers';
import { MessageError } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

import { OutlinedInputCustom } from '../style/OutlinedInput';

export const MaskInputUI = ({
  value = '',
  onChange,
  sx,
  placeholder,
  error = false,
  errorText,
  label,
  styleWrapper,
  mask,
  type,
  digitsOnly = false,
  digitsAndDotOnly = false,
  maskChar,
}) => {
  const { color } = useTheme();
  const handelChange = value => {
    if (value === '' || !value) return;
    let valueCopy = value;
    if (digitsAndDotOnly) {
      valueCopy = sanitizeOnlyDigitsAndDot(value);
    } else if (digitsOnly) {
      valueCopy = sanitizeOnlyDigits(value);
    }
    onChange(valueCopy);
  };

  const handelBlur = e => {
    if (value.length === 0) {
      onChange(null);
    }
  };

  return (
    <FlexContainer style={{ ...styleWrapper }}>
      {label && (
        <LabelWrapper>
          <RequiredStarImg src={RequiredStar} alt="Required Star" />
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray, ml: '2px' }}
            component={'h2'}
          >
            {label}
          </Typography>
        </LabelWrapper>
      )}
      <InputMask
        mask={mask}
        value={value}
        onChange={e => handelChange(e.target.value)}
        onBlur={handelBlur}
        maskChar={maskChar}
      >
        {inputProps => (
          <OutlinedInputCustom
            {...inputProps}
            placeholder={placeholder}
            error={error}
            type={type}
            sx={{
              ...sx,
            }}
          />
        )}
      </InputMask>
      {error && errorText && <MessageError errorText={errorText} />}
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const RequiredStarImg = styled.img`
  margin-top: 3px;
`;

MaskInputUI.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  styleWrapper: PropTypes.object,
  mask: PropTypes.string.isRequired,
  type: PropTypes.string,
  digitsOnly: PropTypes.bool,
  digitsAndDotOnly: PropTypes.bool,
  maskChar: PropTypes.string,
};
