import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

export const MenuButton = ({ children, open, handleClick, sx }) => {
  const { color } = useTheme();
  return (
    <Button
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      disableRipple={true}
      disableFocusRipple={true}
      disableElevation={true}
      sx={{
        stroke: color.gray,
        color: color.gray,

        p: 0,

        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',

        letterSpacing: '0.02em',
        textTransform: 'uppercase',

        '&:hover': {
          stroke: color.main,
          color: color.main,
          backgroundColor: 'transparent',
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

MenuButton.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
};
