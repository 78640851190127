import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Double_Check } from '@root/assets/svg';
import { correctPhone } from '@root/helpers';
import { OutlineButtonBase, PhoneInput } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { ConfirmModal } from './ModalConfirmPhones';

export const PhoneConfirmation = ({
  sx,
  placeholder,
  onChange,
  value = '',
  margin,
  confirm,
  changeConfirm,
  error,
  errorText,
  autoCompleteType,
  ...prop
}) => {
  const [phone, setPhone] = useState();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation(['registration'], { useSuspense: false });
  const { color } = useTheme();

  useEffect(() => {
    setPhone(correctPhone(value));
  }, []);

  const isEmpty = value ? value.replace('+', '').length === 12 : false;

  return (
    <Wrapper margin={margin}>
      <PhoneInput
        sx={{ ...sx }}
        placeholder={placeholder}
        onChange={onChange}
        setPhoneModal={setPhone}
        value={value}
        error={error}
        errorText={errorText}
        data-test-id="Phone"
        autoCompleteType={autoCompleteType}
        {...prop}
      />

      {isEmpty &&
        value !== '' &&
        (confirm ? (
          <TextConfirm>
            <img src={Double_Check} alt="Double Check" width={16} />
            <Typography
              variant={'bodyBoldSmall'}
              align={'center'}
              sx={{ ml: '8px', mr: '7px', color: color.green }}
            >
              {t('registration:step_two.confirm_label')}
            </Typography>
          </TextConfirm>
        ) : (
          <OutlineButtonBase
            type="button"
            sx={{ ml: '12px', minWidth: '114px', height: '48px' }}
            onClick={e => setOpen(true)}
          >
            <Typography variant={'bodyBoldSmall'} align={'center'}>
              {t('registration:step_two.confirm_phone_btn')}
            </Typography>
          </OutlineButtonBase>
        ))}
      {open && (
        <ConfirmModal
          handleClose={() => setOpen(false)}
          open={open}
          phone={phone}
          changeConfirm={changeConfirm}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin: ${prop => prop.margin};
`;

const TextConfirm = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 12px;
`;
PhoneConfirmation.propTypes = {
  sx: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  confirm: PropTypes.bool.isRequired,
  changeConfirm: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
};
