import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, SearchInput } from '@root/components';
import { ButtonBase, CustomScroll, ProgressLoader, TextInput } from '@root/ui';

import PercentIcon from '@mui/icons-material/Percent';
import { Typography, useTheme } from '@mui/material';

import { getPersonalDiscount, postPersonalDiscount } from './API';

export const PersonalPrice = ({ id, onClose }) => {
  const [data, setDate] = useState([]);
  const { t } = useTranslation(['common', 'admin'], { useSuspense: false });
  const [searchValue, setSearchValue] = useState('');
  const { color } = useTheme();

  useEffect(() => {
    (async function () {
      const res = await getPersonalDiscount(id);
      setDate(res.data);
    })();
  }, []);

  const onChangeDiscount = id => value => {
    setDate(
      data.map(el => {
        if (id === el.id) {
          el.discount = value;
        }
        return el;
      }),
    );
  };

  const onChangeSearchInput = name => value => {
    setSearchValue(value);
  };

  const onSubmit = async e => {
    e.preventDefault();
    onClose();
    return await postPersonalDiscount({ id, data: { discounts: data } });
  };

  const { filterBrand } = useMemo(() => {
    const toLowerCaseSearch = searchValue.toLowerCase();

    const filterBrand = [...data].filter(item =>
      item.name?.toLowerCase().includes(toLowerCaseSearch),
    );

    return { filterBrand };
  }, [data, searchValue]);

  return (
    <Component onSubmit={onSubmit}>
      <Typography variant={'h2'} sx={{ textTransform: 'uppercase' }}>
        {t('common:buttons.personal_price')}
      </Typography>

      <SearchInput
        focus={false}
        onBlur={() => {}}
        onChange={onChangeSearchInput}
        searchValue={searchValue}
        placeholder={t('admin:personal_discount.search_title')}
        sx={{
          '& input': {
            fontSize: '0.875rem',
          },
          mt: '5px',
          mb: '12px',
        }}
      />
      <Content>
        <List>
          <CustomScroll vertical={true}>
            {data.length > 0 ? (
              filterBrand.length > 0 ? (
                filterBrand.map(({ name, discount, id }) => (
                  <Row key={id}>
                    <Typography
                      sx={{ mr: '10px', width: '150px' }}
                      variant={'bodyLarge'}
                      color={color.gray}
                      component={'p'}
                    >
                      {name}:
                    </Typography>
                    <TextInput
                      styleWrapper={{ flex: 1 }}
                      sx={{
                        width: '100%',
                        height: '36px',
                        pl: '0',
                        input: { pl: '10px' },
                      }}
                      placeholder={t(
                        'admin:personal_discount.placeholder_discount',
                      )}
                      max={50}
                      onChange={onChangeDiscount(id)}
                      value={discount}
                      digitsAndDotOnly
                      digitsLimit={100}
                      withOutSpace={true}
                      startAdornment={
                        <PercentIcon
                          sx={{
                            px: '5px',
                            width: '26px',
                            height: '36px',
                            borderRight: `1px solid${color.line}`,
                            backgroundColor: color.line,
                            borderRadius: '4px',
                          }}
                        />
                      }
                    />
                  </Row>
                ))
              ) : (
                <Empty type={'EmptySearchLogo'} useSuspense={false} />
              )
            ) : (
              <ProgressLoader />
            )}
          </CustomScroll>
        </List>
        <ButtonWrapper>
          <ButtonBase type="submit">{t('common:buttons.save')}</ButtonBase>
        </ButtonWrapper>
      </Content>
    </Component>
  );
};
const Component = styled.form`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
`;

const List = styled.ul`
  position: relative;
  height: calc(100% - 40px);
  li + li {
    margin-top: 10px;
  }
`;

const Row = styled.li`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: grid;
  margin-top: auto;
`;

PersonalPrice.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
