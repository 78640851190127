import { NovaPoshtaImg } from '@root/assets/image';
import { BigLogo } from '@root/assets/svg';

export const OrderConfData = [
  {
    id: 1,
    value: 'DPD_branch',
    data: [
      {
        key: 'department',
        isCustomRadioBtn: true,
        subTitle: '',
        title: '“Нова Пошта” до відділення',
        src: NovaPoshtaImg,
        radioStyle: {
          position: 'absolute',
          padding: 0,
          top: '26px',
          left: '10px',
        },
        labelStyle: {
          position: 'relative',
          marginLeft: 'initial',
        },
        imgStyle: {
          width: '100px',
        },
      },
    ],
  },
  {
    id: 1,
    value: 'Address',
    data: [
      {
        key: 'dpd_courier',
        isCustomRadioBtn: true,
        subTitle: '',
        title: '“Нова Пошта” кур’єр',
        src: NovaPoshtaImg,
        radioStyle: {
          position: 'absolute',
          padding: 0,
          top: '26px',
          left: '10px',
        },
        labelStyle: {
          position: 'relative',
          marginLeft: 'initial',
        },
        imgStyle: {
          width: '100px',
        },
      },
    ],
  },
];
