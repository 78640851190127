import * as React from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme, size, variant }) => ({
  color: theme.color.white,
  backgroundColor: theme.color.main,
  padding: theme.padding.baseButton[size],
  textTransform: 'none',
  boxShadow: 'none !important',
  '&:hover': {
    background: `linear-gradient(0deg, ${theme.gradient.main}, ${theme.gradient.main}), ${theme.color.main}`,
  },
  '&:active': {
    background: `linear-gradient(0deg, ${theme.gradient.main}, ${theme.gradient.main}), ${theme.color.main}`,
  },
}));

export const ButtonBase = ({
  children,
  type,
  onClick,
  size,
  sx,
  disabled,
  form,
}) => {
  return (
    <ColorButton
      sx={{ ...sx }}
      type={type}
      onClick={onClick}
      variant={'contained'}
      size={size}
      disabled={disabled}
      disableRipple={true}
      form={form}
    >
      {children}
    </ColorButton>
  );
};

ButtonBase.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  form: PropTypes.string,
};
