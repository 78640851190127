import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { HeaderDefault } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { theme } from '@root/styles';
import { ButtonBase, IconBtn, ModalBase, TextArea, TextInput } from '@root/ui';

import savedOrdersOperation from '@redux/saved-orders/saved-orders-operation';

import { Typography } from '@mui/material';

export const CreateSavedOrderModal = ({
  open,
  handleClose,
  onSavedOrderCreate,
}) => {
  const [savedData, setSavedData] = useState({
    name: '',
    description: '',
  });

  const dispatch = useDispatch();

  const { t } = useTranslation(['cart', 'common'], { useSuspense: false });

  const handleChangeText = name => value => {
    setSavedData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleCreateSavedOrder = async () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          savedOrdersOperation.createSavedOrder({
            savedData,
            onResolve,
            onReject,
          }),
        ),
      response => onSavedOrderCreate(),
    );
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      modalHeader={
        <HeaderDefault
          text={t('cart:buttons.save_order')}
          onClose={handleClose}
        />
      }
      modalBottom={
        <ButtonBase
          sx={{ marginTop: '8px' }}
          disabled={!savedData.name.length}
          onClick={handleCreateSavedOrder}
        >
          {t('common:buttons.save')}
        </ButtonBase>
      }
    >
      <CreateSavedOrderModalWrapper>
        <TextInput
          sx={{
            width: '100%',
            height: '36px',
          }}
          placeholder={t('common:name')}
          max={50}
          onChange={handleChangeText('name')}
          value={savedData.name}
          required
        />
        <TextArea
          sx={{
            marginTop: '8px',
            border: `1px solid ${theme.color.line}`,
            width: '100%',
          }}
          value={savedData.description}
          onChange={handleChangeText('description')}
          placeholder={t('common:description')}
          type="text"
          max={200}
        />
      </CreateSavedOrderModalWrapper>
    </ModalBase>
  );
};
const CreateSavedOrderModalWrapper = styled.div`
  width: 442px;
  text-align: right;
`;

CreateSavedOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSavedOrderCreate: PropTypes.func.isRequired,
};
