import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty } from '@root/components';
import { CustomScroll, IconBtn } from '@root/ui';

import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

const TableBody = styled.tbody`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 142px);
`;

export const ManageFiltersTable = ({
  data,
  callback,
  remove,
  edit,
  conf,
  tabType,
}) => {
  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'initial',
      }}
    >
      <Table size="small" aria-label="a dense table" sx={{ height: '100%' }}>
        <TableHead sx={{ borderBottom: `1px solid ${color.line}` }}>
          <TableRow sx={{ display: 'flex', p: '12px 20px' }}>
            {conf.map(el => {
              return (
                <TableCell
                  key={el.label}
                  sx={{
                    borderBottom: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    ...el.style,
                    p: 0,
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant={'bodyBoldSmall'}
                    component={'p'}
                    sx={{ color: color.gray_60 }}
                  >
                    {t(`common:table.table_heads.${el.value}`)}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <InView onChange={inView => callback(inView)}>
          {({ ref }) => (
            <TableBody>
              <CustomScroll vertical={true}>
                {data && data.length !== 0 ? (
                  data.map((row, idx, arr) => (
                    <>
                      <TableRow
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: '16px 20px',
                          height: 'auto',
                          th: {
                            borderBottom: 'none',
                          },
                          '&:last-child, &:last-child th': { borderBottom: 0 },
                        }}
                        key={row.id}
                        id={row.id}
                      >
                        {conf.map((el, idx) => {
                          switch (el?.value) {
                            case 'name':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'name-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {row?.name}
                                </TableCell>
                              );
                            case 'city':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'city-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {row?.city || '-'}
                                </TableCell>
                              );
                            case 'category':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'category-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {row?.category_name}
                                </TableCell>
                              );
                            case 'public_name':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'public_name-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {row?.public_name || '-'}
                                </TableCell>
                              );
                            case 'guid':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'guid-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {row?.guid || '-'}
                                </TableCell>
                              );
                            case 'value':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'value-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                  }}
                                >
                                  {row.values &&
                                    row.values.map(({ name, id }, index) => (
                                      <Typography
                                        variant={'bodyBoldSmall'}
                                        component={'span'}
                                        sx={{
                                          fontWeight: 400,
                                          marginRight: '5px',
                                        }}
                                        key={id}
                                      >
                                        {index + 1 !== row.values.length
                                          ? `${name},`
                                          : name}
                                      </Typography>
                                    ))}
                                </TableCell>
                              );
                            case 'order-sort':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'order-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {row?.sort}
                                </TableCell>
                              );
                            case 'edit':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'edit-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  <IconBtn
                                    onClick={() => edit(row)}
                                    href={`${Sprite}#icon-pan`}
                                    sx={{
                                      margin: '0 auto',
                                    }}
                                  />
                                </TableCell>
                              );
                            case 'remove':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'remove-' + row.id}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                    color: color.gray_80,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  <IconBtn
                                    onClick={() => remove(row.id)}
                                    href={`${Sprite}#icon-bin`}
                                    sx={{
                                      margin: '0 auto',
                                    }}
                                  />
                                </TableCell>
                              );
                            default:
                              break;
                          }
                        })}
                      </TableRow>
                      {tabType === 'filters' && arr.length === idx + 1 && (
                        <TableRow
                          ref={ref}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: '16px 20px',
                            height: 'auto',
                            th: {
                              borderBottom: 'none',
                            },
                            '&:last-child, &:last-child th': {
                              borderBottom: 0,
                            },
                            opacity: 0,
                          }}
                        >
                          Table row test
                        </TableRow>
                      )}
                    </>
                  ))
                ) : (
                  <TableRow
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: '16px 20px',
                      height: '100%',
                    }}
                  >
                    <Empty type={'EmptySearchLogo'} />
                  </TableRow>
                )}
              </CustomScroll>
            </TableBody>
          )}
        </InView>
      </Table>
    </TableContainer>
  );
};

ManageFiltersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      city: PropTypes.string,
      guid: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      public_name: PropTypes.string,
      sort: PropTypes.number,
    }),
  ),
  callback: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  conf: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      style: PropTypes.object,
      value: PropTypes.string,
    }),
  ),
  tabType: PropTypes.string.isRequired,
};
