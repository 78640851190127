import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import PropTypes from 'prop-types';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const SelectPerPage = styled(Select)(({ theme, size, variant }) => ({
  position: 'relative',
  padding: '7px 8px',
  border: `1px solid ${theme.color.line}`,
  backgroundColor: `${theme.color.white}`,
  height: '36px',

  fontWeight: 700,
  fontSize: '0.75rem',
  lineHeight: 1.3,

  color: `${theme.color.gray_80}`,

  '.MuiSelect-select': {
    padding: '0 !important',
  },
  '.MuiSelect-icon': {
    width: '16px',
    height: '16px',
    top: '8px',
  },
}));

const styles = colors => css`
  top: 0;
  transform: translate(0, -100%) !important;

  & ul {
    border: 1px solid ${colors.line};
    border-radius: 4px;
    padding: 0 !important;
  }

  & li {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.3;

    padding: 4px 5px;
    border-radius: 2px;

    &:hover {
      background-color: ${colors.gray};
    }
  }

  & .Mui-selected {
    background-color: ${colors.main} !important;
    color: ${colors.white};
  }
`;

export const PaginationSelect = ({
  name,
  data = [],
  currentId = '',
  helperText,
  error,
  callBack,
  sx,
  height,
  ...prop
}) => {
  const [value, setValue] = useState('');
  const { color } = useTheme();

  useEffect(() => {
    setValue(currentId);
  }, [currentId]);

  const handleChange = event => {
    setValue(event.target.value);
    callBack(event.target.value);
  };

  return (
    <Box sx={{ ...sx }}>
      <FormControl error={error} fullWidth>
        <SelectPerPage
          id="demo-simple-select"
          value={value}
          onChange={handleChange}
          inputProps={{
            classes: {
              input: styles({
                line: color.line,
                gray: color.gray_20,
                main: color.main,
                white: color.white,
              }),
            },
          }}
          MenuProps={{
            classes: {
              paper: styles({
                line: color.line,
                gray: color.gray_20,
                main: color.main,
                white: color.white,
              }),
            },
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}
          {...prop}
        >
          {data.map(el => (
            <MenuItem value={el.id} key={el.name} variant={'selectedMenu'}>
              {el.name}
            </MenuItem>
          ))}
        </SelectPerPage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

PaginationSelect.propTypes = {
  name: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.number,
  }),
  currentId: PropTypes.number,
  helperText: PropTypes.string,
  error: PropTypes.object,
  callBack: PropTypes.func.isRequired,
  sx: PropTypes.object,
  height: PropTypes.string,
};
