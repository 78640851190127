import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { HeaderDefault } from '@root/components';
import { ImageCropper } from '@root/components/ImageCropper';
import { ModalBase } from '@root/ui';

import { useTheme } from '@mui/material';

export const CropperModal = ({
  open,
  setIsOpen,
  handleSetImage,
  file,
  stencilVariant,
  outputImageOptions,
  movable,
  resizable,
  stencilSize,
  stencilLines,
  stencilGrid,
}) => {
  const { t } = useTranslation('common', { useSuspense: false });
  const handleCloseModal = () => setIsOpen(false);
  const { color } = useTheme();

  return (
    <ModalBase
      open={open}
      sx={{ maxWidth: '580px', width: '100%' }}
      modalHeader={
        <HeaderDefault
          text={t('common:cropper_modal.title')}
          onClose={handleCloseModal}
        />
      }
    >
      {file && (
        <ImageCropper
          stencilGrid={stencilGrid}
          stencilLines={stencilLines}
          handleSetImage={handleSetImage}
          handleCloseModal={handleCloseModal}
          file={file}
          stencilVariant={stencilVariant}
          stencilSize={stencilSize}
          outputImageOptions={outputImageOptions}
          movable={movable}
          resizable={resizable}
        />
      )}
    </ModalBase>
  );
};

CropperModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  handleSetImage: PropTypes.func.isRequired,
  file: PropTypes.object,
  stencilVariant: PropTypes.object,
  outputImageOptions: PropTypes.object,
  movable: PropTypes.bool,
  resizable: PropTypes.bool,
  stencilSize: PropTypes.bool,
  stencilLines: PropTypes.bool,
  stencilGrid: PropTypes.bool,
};
