import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import style from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ActionBtn } from '@root/conf/FilterConf';
import { useQueryParams } from '@root/hooks';
import {
  AccordionUI,
  ButtonBase,
  CheckboxBase,
  CustomScroll,
  NavigationButton,
  RadioCustom,
} from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import categorySelectors from '@redux/category/category-selectors';
import { choseWarehousesHandler } from '@redux/category/category-slice';
import productOperation from '@redux/product/product-operation';

import {
  Divider,
  Drawer,
  List,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { ManageFilters } from './ManageFiltersDialog';

const drawerWidth = 0;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px 16px 0 0',
  justifyContent: 'flex-start',
  alignItems: 'center',
  ...theme.mixins.toolbar,
}));

const DrawerSubHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0 20px 0 20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '48px !important',
}));

export const DrawerFilters = ({
  isOpen,
  isClose,
  filterActive,
  setFilterActive,
  handleResetFilter,
  setAllWarehouses,
  allWarehouses,
}) => {
  const activeDrawerStyles = {
    width: '240px',
    marginRight: '32px',
  };
  const [navFilter, setNavFilter] = useState('filters');
  const filtersCollection = useSelector(categorySelectors.getFilters);
  const stock = useSelector(categorySelectors.getStock);
  const warehousesList = useSelector(categorySelectors.getListWarehouses);
  const currentRole = useSelector(authSelectors.getRole);
  const warehouseId = useSelector(categorySelectors.getWarehouseId);
  const {
    handleUpdateString,
    handleHasQuery,
    getQueryObj,
    appendParam,
    replaceQueryParam,
  } = useQueryParams();

  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();

  useEffect(() => {
    setFilterActive({});
  }, [getQueryObj().id]);

  useEffect(() => {
    handleHasQuery('filter');
    for (const key in filterActive) {
      const valueParam = `${key}:` + filterActive[key].join(',');
      if (filterActive[key].length > 0) appendParam('filter', valueParam);
    }
    if (Object.keys(filterActive).length === 0) return;
    handelUpdate();
  }, [filterActive]);

  const handelCheckBox = (name, childrenName) => value => {
    const findArr = filterActive[name];
    replaceQueryParam('page', 1);

    if (findArr) {
      if (findArr.includes(childrenName)) {
        setFilterActive({
          ...filterActive,
          [name]: findArr.filter(el => el !== childrenName),
        });
      } else {
        setFilterActive({
          ...filterActive,
          [name]: [...filterActive[name], childrenName],
        });
      }
    } else {
      setFilterActive({ [name]: [childrenName], ...filterActive });
    }
  };

  const handelUpdate = () => {
    handleUpdateString();
    dispatch(
      productOperation.getProduct({
        ...getQueryObj(),
        filter: filterActive,
      }),
    );
  };

  const handelStock = stockValue => () => {
    if (stockValue) {
      handleHasQuery('stock');
    } else {
      appendParam('stock', 1);
    }

    handelUpdate();
  };

  const handelChoseWarehouses = nestedId => {
    dispatch(choseWarehousesHandler({ nestedId }));
    setAllWarehouses(false);
  };

  const handleChoseAllWarehouses = () => {
    dispatch(choseWarehousesHandler({ nestedId: false }));
    setAllWarehouses(true);
  };

  const getSummary = title => {
    return (
      <>
        <Typography
          variant={'bodySmall'}
          component={'p'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            fontWeight: '700',
          }}
        >
          {title}
        </Typography>
      </>
    );
  };

  const getMarckUp = useMemo(
    () => () => {
      switch (navFilter) {
        case 'filters':
          return (
            <>
              <List sx={{ padding: '18px 20px' }}>
                <ListSubWrap>
                  <ListContentWrap>
                    <CheckboxBase
                      width={16}
                      onChange={handelStock(getQueryObj().stock)}
                      checked={getQueryObj().stock}
                    >
                      <Typography
                        variant={'bodyMedium'}
                        sx={{
                          marginLeft: '8px',
                          lineHeight: 1.3,
                        }}
                        component={'p'}
                      >
                        {stock?.name}
                      </Typography>
                    </CheckboxBase>
                  </ListContentWrap>
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      marginLeft: '8px',
                      color: color.gray_60,
                    }}
                    component={'span'}
                  >
                    {stock?.products_count}
                  </Typography>
                </ListSubWrap>
              </List>
              <Divider sx={{ borderWidth: '1px' }} />
              {filtersCollection.map(item => (
                <>
                  <AccordionUI
                    summaryMurk={getSummary(item.name)}
                    defaultExpanded={true}
                  >
                    {item.values.map(nestedItem => (
                      <AccordionWrap>
                        <ListContentWrap
                          pointerEvents={
                            nestedItem.products_count === 0 ? 'none' : 'initial'
                          }
                          opacity={nestedItem.products_count === 0 ? '.6' : '1'}
                        >
                          <CheckboxBase
                            width={16}
                            onChange={handelCheckBox(
                              item.slug,
                              nestedItem.slug,
                            )}
                            checked={
                              filterActive[item.slug]
                                ? filterActive[item.slug].includes(
                                    nestedItem.slug,
                                  )
                                : false
                            }
                          >
                            <Typography
                              variant={'bodyMedium'}
                              sx={{
                                marginLeft: '8px',
                                lineHeight: 1.3,
                              }}
                              component={'p'}
                            >
                              {nestedItem.name}
                            </Typography>
                          </CheckboxBase>
                        </ListContentWrap>
                        {nestedItem.products_count > 0 && (
                          <Typography
                            variant={'bodySmall'}
                            sx={{
                              marginLeft: '8px',
                              color: color.gray_60,
                            }}
                            component={'span'}
                          >
                            {nestedItem.products_count}
                          </Typography>
                        )}
                      </AccordionWrap>
                    ))}
                  </AccordionUI>
                  <Divider sx={{ borderWidth: '1px' }} />
                </>
              ))}
            </>
          );
        case 'werehouse':
          return (
            <>
              <List sx={{ padding: '18px 20px' }}>
                <ListItem>
                  <RadioCustom
                    checked={allWarehouses}
                    onClick={handleChoseAllWarehouses}
                    width={16}
                    sx={{ padding: 0 }}
                  />
                  <Typography
                    variant={'bodyMedium'}
                    sx={{
                      marginLeft: '8px',
                      color: color.gray_80,
                    }}
                    component={'p'}
                  >
                    Всі склади
                  </Typography>
                </ListItem>
              </List>
              <Divider sx={{ borderWidth: '1px' }} />
              {Object.values(warehousesList).map(item => (
                <>
                  <ListWrap key={`warehouses-list-${item.id}`}>
                    {item.title && (
                      <Typography
                        variant={'uppercase'}
                        sx={{
                          marginBottom: '16px',
                        }}
                      >
                        {item.title}
                      </Typography>
                    )}
                    <List sx={{ padding: '0' }}>
                      {item.warehouses.map(nested => (
                        <ListItem
                          key={`warehouses-place-${nested.id}`}
                          justifyContent={'space-between'}
                          marginBottom={'16px'}
                        >
                          <ListContentWrap>
                            <RadioCustom
                              checked={warehouseId === nested.id}
                              width={16}
                              onClick={() => handelChoseWarehouses(nested.id)}
                              sx={{ padding: 0 }}
                            />
                            <Typography
                              variant={'bodyMedium'}
                              sx={{
                                marginLeft: '8px',
                                color: color.gray_80,
                              }}
                              component={'p'}
                            >
                              {nested.title}
                            </Typography>
                          </ListContentWrap>
                        </ListItem>
                      ))}
                    </List>
                  </ListWrap>
                  <Divider sx={{ borderWidth: '1px' }} />
                </>
              ))}
            </>
          );
        default:
          break;
      }
    },
    [
      allWarehouses,
      getQueryObj().stock,
      filterActive,
      filtersCollection,
      navFilter,
      stock?.name,
      stock?.products_count,
      warehouseId,
      warehousesList,
    ],
  );

  const handleChoseItem = value => {
    setNavFilter(value);
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          paddingTop: '82px',
          '& .MuiDrawer-paper': {
            position: 'inherit',
            width: '240px',
            boxSizing: 'border-box',
          },
          ...(isOpen && activeDrawerStyles),
        }}
        variant="persistent"
        anchor="right"
        open={isOpen}
      >
        <DrawerHeader sx={{ minHeight: 'fit-content !important' }}>
          {ActionBtn.map((btn, idx) => (
            <NavigationButton
              key={idx}
              isActive={btn.value === navFilter}
              onClick={() => handleChoseItem(btn.value)}
            >
              {t(`catalog:filter.${btn.value}`)}
            </NavigationButton>
          ))}
        </DrawerHeader>
        <Divider sx={{ borderWidth: '1px' }} />
        {currentRole === 'admin' && (
          <>
            <DrawerSubHeader>
              <ButtonBase
                sx={{
                  padding: 0,
                  color: color.gray_80,
                  backgroundColor: 'transparent',

                  '&:hover': {
                    background: 'transparent',
                  },
                }}
                onClick={() => setOpenDialog(true)}
              >
                <Svg fill={color.gray_60} width={16} height={16}>
                  <use href={`${Sprite}#icon-pan`}></use>
                </Svg>
                <Typography variant={'bodySmall'}>
                  {t('common:buttons.edit')}
                </Typography>
              </ButtonBase>
            </DrawerSubHeader>
            <Divider sx={{ borderWidth: '1px' }} />
          </>
        )}
        <DrawerSubHeader>
          <ButtonBase
            sx={{
              padding: 0,
              color: color.main,
              backgroundColor: 'transparent',
              '&:hover': {
                background: 'transparent',
              },
            }}
            onClick={() => isClose(false)}
          >
            <Typography variant={'bodySmall'}>
              {t('catalog:filter.buttons.close_filters')}
            </Typography>
          </ButtonBase>
          <ButtonBase
            onClick={handleResetFilter}
            sx={{
              padding: 0,
              color: color.gray_60,
              backgroundColor: 'transparent',
              '&:hover': {
                background: 'transparent',
              },
            }}
          >
            <Typography variant={'bodySmall'}>
              {t('common:buttons.reset')}
            </Typography>
          </ButtonBase>
        </DrawerSubHeader>
        <Divider sx={{ borderWidth: '1px' }} />
        <Wrapper>
          <CustomScroll vertical={true}>{getMarckUp()}</CustomScroll>
        </Wrapper>
      </Drawer>
      <ManageFilters
        tabType={navFilter}
        isOpen={openDialog}
        isClose={setOpenDialog}
      />
    </>
  );
};

const ListWrap = style.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px 4px 24px;  
`;

const ListSubWrap = style.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListItem = style.li`
  display: flex;
  align-items: center;
  justify-content: ${prop => prop.justifyContent};
  margin-bottom: ${prop => prop.marginBottom};
`;

const ListContentWrap = style.div`
  display: flex;
  align-items: center;
  pointer-events: ${prop => prop.pointerEvents};
  opacity: ${prop => prop.opacity};
`;

const AccordionWrap = style.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  
  &:first-child {
    padding-top: 6px;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const Svg = style.svg`
  margin-right: 5px;
  fill: ${props => props.fill};
`;

const Wrapper = style.div`
  overflow: hidden;
  height: calc(100% - 68px);
`;
DrawerFilters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isClose: PropTypes.bool.isRequired,
  filterActive: PropTypes.object,
  setFilterActive: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
  setAllWarehouses: PropTypes.func.isRequired,
  allWarehouses: PropTypes.bool,
};
