import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar } from '@root/assets/svg';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const InputLabel = ({
  disableSelfCenter = false,
  children,
  required = false,
  requiredStarPosition = 'right',
  aligning = 'center',
  padding = '',
}) => {
  const { color } = useTheme();
  return (
    <LabelWrapper
      disableSelfCenter={disableSelfCenter}
      requiredStarPosition={
        requiredStarPosition === 'right' ? 'row' : 'row-reverse'
      }
      aligning={aligning}
      padding={padding}
    >
      <Typography variant="bodyMedium" color={color.gray}>
        {children}
      </Typography>
      {required && <RequiredStarImg src={RequiredStar} alt="Required Star" />}
    </LabelWrapper>
  );
};

const RequiredStarImg = styled.img``;

const LabelWrapper = styled.div`
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: ${({ aligning }) => aligning};
  justify-content: ${({ requiredStarPosition }) =>
    requiredStarPosition === 'right' ? 'flex-start' : 'flex-end'};
  flex-direction: ${({ requiredStarPosition }) =>
    requiredStarPosition === 'right' ? 'row' : 'row-reverse'};
  column-gap: 3px;
  min-width: 151px;
`;

InputLabel.propTypes = {
  disableSelfCenter: PropTypes.bool,
  children: PropTypes.node,
  required: PropTypes.bool,
  requiredStarPosition: PropTypes.string,
  aligning: PropTypes.string,
  padding: PropTypes.string,
};
