import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

export const CopyToClipboard = ({
  children,
  copyText,
  styles,
  onCopySuccess,
  copyIcon = true,
}) => {
  const handleCopyToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(copyText);
    } else {
      document.execCommand('copy', true, copyText);
    }

    onCopySuccess && onCopySuccess();
  };
  return (
    <CopyToClipboardWrapper style={styles} onClick={handleCopyToClipboard}>
      {children}
      {copyIcon && <IconBtn href={`${Sprite}#icon-copy`} />}
    </CopyToClipboardWrapper>
  );
};

const CopyToClipboardWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

CopyToClipboard.propTypes = {
  children: PropTypes.node,
  copyText: PropTypes.string.isRequired,
  styles: PropTypes.object,
  onCopySuccess: PropTypes.func.isRequired,
};
