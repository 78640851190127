import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { correctPhone } from '@root/helpers/phoneMask';
import { IconBtn, OutlineButtonBase, RadioCustom } from '@root/ui';

import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';
import { choseTemplate } from '@redux/order/order-slice';

import { FormControlLabel, Typography, useTheme } from '@mui/material';

export const CartItem = ({
  type = 'delivery',
  data,
  edit,
  clone,
  removeItem,
  pin,
  hidePin = false,
  hideDellBtn = false,
}) => {
  const { color } = useTheme();
  const dispatch = useDispatch();

  const getMurkUp = () => {
    const { title } = data;
    switch (type) {
      case 'delivery':
        const { name, surname, phone, address, email } = data?.info;
        return (
          <ContentWrapper>
            {' '}
            <Box>
              <div>
                {' '}
                <Typography
                  variant={'bodyBoldSmall'}
                  sx={{ color: color.grey }}
                  component={'h4'}
                >
                  {surname} {name}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{ color: color.grey }}
                  component={'p'}
                >
                  {correctPhone(phone)}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{ color: color.grey }}
                  component={'p'}
                >
                  {email}
                </Typography>
              </div>

              <div>
                <Typography
                  variant={'bodyBoldSmall'}
                  sx={{ color: color.grey }}
                  component={'h4'}
                >
                  {title}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{ color: color.grey }}
                  component={'p'}
                >
                  {address}
                </Typography>
              </div>
            </Box>
          </ContentWrapper>
        );

      case 'payment':
        return (
          <ContentWrapper>
            {' '}
            <Box>
              <div>
                {' '}
                <Typography
                  variant={'bodyBoldSmall'}
                  sx={{ color: color.grey }}
                  component={'h4'}
                >
                  {title}
                </Typography>
              </div>
            </Box>
          </ContentWrapper>
        );

      default:
        break;
    }
  };

  const { is_pinned, id, is_selected } = data;
  return (
    <Wrapper color={color}>
      <FormControlLabel
        value={data.id}
        control={
          <RadioCustom
            sx={{ pt: 0 }}
            checked={is_selected}
            onClick={() => dispatch(choseTemplate({ id, type }))}
          />
        }
        componentsProps={{}}
        disableTypography={true}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '70%',
        }}
        label={getMurkUp()}
      />

      <ActionWrapper>
        <div>
          {[
            { icon: 'icon-pan', action: edit, hide: false },
            { icon: 'icon-copy', action: clone, hide: false },
            {
              icon: 'icon-delete',
              action: () => {
                dispatch(
                  setOpenModal({
                    open: true,
                    key: 'confirm',
                    isHiddenHeader: true,
                    inactiveBackdrop: true,
                    dataForConfirm: {
                      id: data.id,
                      entity: type,
                    },
                    callback: data => {
                      removeItem(data);
                      dispatch(clearModalState());
                    },
                  }),
                );
              },
              hide: hideDellBtn,
            },
          ].map(({ icon, action, hide }, idx) => (
            <OutlineButtonBase
              sx={{
                height: '36px',
                minWidth: '36px !important',
                alignItems: 'center',
                marginLeft: idx !== 0 && '9px',
                display: hide ? 'none' : 'inline',
              }}
              onClick={() => {
                action();
              }}
            >
              <svg height={16} width={16}>
                <use href={`${Sprite}#${icon}`}></use>
              </svg>
            </OutlineButtonBase>
          ))}
        </div>
        {!hidePin && (
          <IconBtn
            size="16px"
            href={is_pinned ? `${Sprite}#icon-is-pinned` : `${Sprite}#icon-pin`}
            onClick={pin}
            sx={{}}
          />
        )}
      </ActionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  display: flex;
  justify-content: space-between;

  padding: 16px;

  background: ${({ color }) => color.gray_20};
  border-radius: 4px;
  min-height: 108px;
`;

const ContentWrapper = styled.div`
  width: 70%;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
`;

const Box = styled.div`
  div + div {
    margin-top: 10px;
  }
`;

CartItem.propTypes = {
  type: PropTypes.string,
  data: PropTypes.exact({
    entity: PropTypes.string,
    id: PropTypes.number,
    info: PropTypes.exact({
      address: PropTypes.string,
      delivery_info: PropTypes.exact({
        city_ref: PropTypes.string,
        city_title: PropTypes.string,
        date: PropTypes.string,
        department_ref: PropTypes.string,
        department_title: PropTypes.string,
        elevator: PropTypes.bool,
        flat_num: PropTypes.string,
        floor_num: PropTypes.string,
        npPhone: PropTypes.string,
        post_office_ref: PropTypes.string,
        post_office_title: PropTypes.string,
        street_number: PropTypes.string,
        street_title: PropTypes.string,
        time: PropTypes.string,
        type: PropTypes.string,
      }),
      is_drop: PropTypes.bool,
      middle_name: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      surname: PropTypes.string,
    }),
    is_pinned: PropTypes.number,
    is_selected: PropTypes.bool,
    key: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
  }),
  edit: PropTypes.func.isRequired,
  clone: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  pin: PropTypes.func.isRequired,
  hidePin: PropTypes.bool,
  hideDellBtn: PropTypes.bool,
};
