import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes, { number } from 'prop-types';
import styled from 'styled-components';

import { EditModeInput, InputBtnConfirm } from '@root/components';
import { categoryList } from '@root/conf';

import categorySelectors from '@redux/category/category-selectors';
import optionOperation from '@redux/option/option-operation';
import optionSelectors from '@redux/option/option-selectors';
import productSelectors from '@redux/product/product-selectors';

import { MenuItem, Typography, useTheme } from '@mui/material';

export const ProductTitle = ({
  data,
  isEdit,
  editData,
  handleLangData,
  lang,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['product_details'], {
    useSuspense: false,
  });
  const brands = useSelector(optionSelectors.getBrands);
  const category = useSelector(categorySelectors.getCategoryListToEdit);
  const loadingEdit = useSelector(productSelectors.getLoadingEditPage);
  const dispatch = useDispatch();

  return (
    <NameWrapper>
      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="input"
        conf={{
          onChange: handleLangData,
          value: editData?.name[lang],
          error: {},
          key: 'name',
          placeholder: t('product_details:placeholder.name_of_product'),
        }}
      >
        <Typography
          variant={'h1'}
          sx={{
            color: color.gray,
          }}
        >
          {data.name}
        </Typography>
      </EditModeInput>
      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="input"
        conf={{
          onChange: handleLangData,
          value: editData?.model,
          error: {},
          key: 'model',
          placeholder: t('product_details:placeholder.model_of_product'),
        }}
      >
        {' '}
        <Typography
          variant={'h3'}
          sx={{
            color: color.gray_80,
          }}
        >
          {data.model}
        </Typography>
      </EditModeInput>

      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="select"
        conf={{
          onChange: handleLangData,
          value: editData?.brand_id,
          error: {},
          key: 'brand_id',
          placeholder: t('product_details:placeholder.brand_of_product'),
          options: brands,
          group: false,
          customItem: (
            <MenuItem
              sx={{
                backgroundColor: `${color.white} !important`,
                color: `${color.white} !important`,
                '&:hover': {
                  backgroundColor: `${color.white} !important`,
                  color: `${color.white} !important`,
                },
                '&:active': {
                  backgroundColor: `${color.white}!important`,
                  color: `${color.white}!important`,
                },
              }}
            >
              <InputBtnConfirm
                handleConfirm={name => {
                  document
                    .getElementsByClassName('MuiBackdrop-invisible')[0]
                    .click();
                  dispatch(optionOperation.addBrands({ name }));
                }}
                placeholder={t('product_details:placeholder.add_new_brand')}
              />
            </MenuItem>
          ),
        }}
      >
        {' '}
        <Typography
          variant={'h3'}
          sx={{
            color: color.gray_80,
          }}
        >
          {data?.brand}
        </Typography>
      </EditModeInput>

      <Typography
        variant={'bodyMedium'}
        sx={{
          color: color.gray_60,
        }}
      >
        {t('product_details:product')}: {data.sku}
      </Typography>

      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="select"
        conf={{
          onChange: handleLangData,
          value: editData?.category_id,
          error: {},
          key: 'category_id',
          placeholder: t(
            'product_details:placeholder.main_category_of_product',
          ),
          options: categoryList(category),
          group: true,
        }}
      />

      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="multipleSelect"
        conf={{
          onChange: handleLangData,
          value: editData?.categories,
          error: {},
          key: 'categories',
          placeholder: t(
            'product_details:placeholder.other_category_of_product',
          ),
          options: categoryList(category),
        }}
      />
    </NameWrapper>
  );
};

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

ProductTitle.propTypes = {
  data: PropTypes.exact({
    analogue_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.exact({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    documents: PropTypes.array,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.exact({
        big: PropTypes.string,
        full: PropTypes.string,
        thumbnail: PropTypes.string,
        thumbnail_second: PropTypes.string,
      }),
    ),
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    property_categories: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
        properties: PropTypes.arrayOf(
          PropTypes.exact({
            id: PropTypes.number,
            is_pinned: PropTypes.bool,
            name: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    related_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        relation_quantity: PropTypes.number,
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
  }),
  isEdit: PropTypes.bool.isRequired,
  editData: PropTypes.exact({
    brand_id: PropTypes.number,
    categories: PropTypes.arrayOf(number),
    category_id: PropTypes.number,
    id: PropTypes.number,
    model: PropTypes.string,
    name: PropTypes.exact({ uk: PropTypes.string, en: PropTypes.string }),
  }),
  handleLangData: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
