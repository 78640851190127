import { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CropperModal } from '@root/components/Modal/CropperModal';
import { FileLoader } from '@root/ui';

export const AvatarManager = ({
  handleSetImage,
  stencilVariant,
  isLogo,
  title,
  img,
  movable,
  resizable,
  stencilSize,
  outputImageOptions,
  stencilGrid,
  stencilLines,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const fileRef = useRef();

  useEffect(() => {
    setIsOpen(!!file);
  }, [file]);

  return (
    <AvatarManagerWrapper>
      <FileLoader
        fileRef={fileRef}
        file={img}
        setFile={setFile}
        title={title}
        handleSetImage={handleSetImage}
        isLogo={isLogo}
      />
      <CropperModal
        handleSetImage={handleSetImage}
        file={file}
        open={isOpen}
        setIsOpen={setIsOpen}
        stencilLines={stencilLines}
        stencilGrid={stencilGrid}
        stencilVariant={stencilVariant}
        outputImageOptions={outputImageOptions}
        movable={movable}
        resizable={resizable}
        stencilSize={stencilSize}
      />
    </AvatarManagerWrapper>
  );
};

const AvatarManagerWrapper = styled.div``;

AvatarManager.propTypes = {
  handleSetImage: PropTypes.func.isRequired,
  stencilVariant: PropTypes.object,
  includeSvg: PropTypes.bool,
  title: PropTypes.string,
  img: PropTypes.string,
  movable: PropTypes.bool,
  resizable: PropTypes.bool,
  stencilSize: PropTypes.bool,
  outputImageOptions: PropTypes.object,
  stencilGrid: PropTypes.bool,
  stencilLines: PropTypes.bool,
};
