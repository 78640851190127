import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  configuration: {
    open: false,
  },
};

const modalWatcherSlice = createSlice({
  name: 'modalWatcher',
  initialState,
  reducers: {
    setOpenModal(state, { payload }) {
      state.configuration = payload;
    },
    clearModalState(state, { payload }) {
      state.configuration = initialState.configuration;
    },
  },
});

export const { setOpenModal, clearModalState } = modalWatcherSlice.actions;

export default modalWatcherSlice.reducer;
