import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { TextInput } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const Product = ({
  product,
  index,
  TextInputAdornment,
  onChangePrice,
  onBlur,
}) => {
  const { color } = useTheme();
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const currencyWidgetDetails = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  );
  const choosedCurrency = useSelector(userSelectors.getChoosedCurrencyWidget);
  const { t } = useTranslation('common');

  return (
    <PriceWrapper>
      <PriceTopWrapper color={color.line}>
        <Img src={product?.image} />
        <Label>
          <Typography color={color.gray} variant="bodyBoldSmall" component="p">
            {product?.model}
          </Typography>
          <Typography color={color.gray_60} variant="bodySmall">
            {t('article')}: {product?.sku}
          </Typography>
        </Label>
      </PriceTopWrapper>
      <PriceBottomWrapper colors={{ gray: color.gray_20, line: color.line }}>
        <TextInput
          endAdornment={
            <TextInputAdornment
              type="start"
              text={choosedCurrency.id === 4 ? choosedCurrency.code_full : '€'}
            />
          }
          sx={{ height: '32px', maxWidth: '180px' }}
          type={'number'}
          value={product.priceUsd}
          onChange={onChangePrice('priceUsd', index)}
          onBlur={onBlur}
        />
        {choosedCurrency.id !== 3 && choosedCurrency.id !== 4 && (
          <Typography variant="bodyMedium" color={color.gray}>
            {Number(product?.choosedCurrencyPrice).toFixed(2)}{' '}
            {currencyWidgetDetails.title}
          </Typography>
        )}
      </PriceBottomWrapper>
    </PriceWrapper>
  );
};

const PriceWrapper = styled.div``;

const PriceBottomWrapper = styled.div`
  background: ${props => props.colors.gray};
  border: 1px solid ${props => props.colors.line};
  padding: 9px 23px;
  border-top: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const PriceTopWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.color};
  border-radius: 4px 4px 0 0;
  padding: 9px 23px;
`;

const Img = styled.img`
  width: 30px;
  margin-right: 15px;
`;

const Label = styled.div``;
