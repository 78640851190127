import { createSlice } from '@reduxjs/toolkit';

import operationProduct from '../product/product-operation';
import operation from './option-operation';

const initialState = {
  cities: [],
  activityAreas: [],
  historyFilters: {},
  brands: [],
  langs: [],
  loading: false,
};

const authSlice = createSlice({
  name: 'option',
  initialState,
  extraReducers: {
    // ================================Global-Store-Listeners=============================//
    //====setProductByEditId====//
    [operationProduct.getProductByEditId.fulfilled]: (state, { payload }) => {
      const { brands, langs } = payload.data;

      state.brands = brands;
      state.langs = langs;
    },
    // ================================End-Global-Store-Listeners=============================//
    //====getCity====//
    [operation.getCity.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getCity.fulfilled]: (state, { payload }) => {
      state.cities = payload;
      state.loading = false;
    },
    [operation.getCity.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getActivityAreas====//
    [operation.getActivityAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getActivityAreas.fulfilled]: (state, { payload }) => {
      state.activityAreas = payload;
      state.loading = false;
    },
    [operation.getActivityAreas.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getHistoryFilters====//
    [operation.getHistoryFilters.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getHistoryFilters.fulfilled]: (state, { payload }) => {
      state.historyFilters = payload;
      state.loading = false;
    },
    [operation.getHistoryFilters.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====addBrands====//
    [operation.addBrands.pending]: (state, action) => {},
    [operation.addBrands.fulfilled]: (state, { payload }) => {
      state.brands = payload;
    },
    [operation.addBrands.rejected]: (state, { payload }) => {},
  },
});
export default authSlice.reducer;
