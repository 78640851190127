import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { MenuBody, MenuButton, MenuItemCustoms } from '@root/ui';

import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const Rate = () => {
  const { color } = useTheme();
  const currency = useSelector(userSelectors.getPrices);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).title;
  const [btnText, setBtnText] = useState(choosedCurrency);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRate = rateChose => {
    setBtnText(currency[rateChose].title);

    dispatch(
      userOperation.putCurrencyValue({
        price: rateChose,
      }),
    );
  };

  return (
    <div>
      {' '}
      <MenuButton open={open} handleClick={handleClick}>
        <Svg color={color} width="18" height="18">
          <use href={`${Sprite}#icon-rate`}></use>
        </Svg>
        <Typography
          variant={'bodyBoldSmall'}
          sx={{ letterSpacing: '0.02em', lineHeight: 1, padding: '2px 0' }}
        >
          {btnText}
        </Typography>
      </MenuButton>
      <MenuBody
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        sx={{ width: '98px' }}
      >
        {Object.keys(currency).length > 0 &&
          Object.keys(currency).map((item, idx) => (
            <MenuItemCustoms
              key={idx}
              handelClick={e => {
                handleClose();
                handleChangeRate(currency[item].field_name);
              }}
              selected={choosedCurrency === currency[item].title}
            >
              <Typography variant={'bodySmall'}>
                {currency[item].title}
              </Typography>
            </MenuItemCustoms>
          ))}
      </MenuBody>
    </div>
  );
};

const Svg = styled.svg`
  margin-right: 4px;
`;
