// Old version props validation from ua!!
import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DatePickerCustom, PhoneInput, TextInput } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ViatecDelivery = ({ error, data, onChange }) => {
  const { color } = useTheme();

  const { t } = useTranslation(['common'], { useSuspense: false });

  const {
    street_title,
    city_title,
    street_number,
    flat_num,
    zip_code,
    npPhone,
    date,
    time,
  } = data.delivery_info;

  return (
    <ContentWrap>
      <TextInput
        sx={{
          maxWidth: '308px',
          marginBottom: '10px',
          input: {
            '&:disabled': {
              WebkitTextFillColor: color.gray_80,
            },
          },
        }}
        disabled
        placeholder={t('placing_order:product_delivery.viatec.fields.city')}
        onChange={onChange('city_title', 'np_courier')}
        value={city_title}
      />
      <ContentFormWrap>
        <TextInput
          placeholder={t('placing_order:product_delivery.fields.type_street')}
          error={error?.street_title?.init}
          errorText={error?.street_title?.text}
          onChange={onChange('street_title', 'np_courier')}
          value={street_title}
        />
        <TextInput
          error={error?.street_number?.init}
          errorText={error?.street_number?.text}
          placeholder={t('placing_order:product_delivery.fields.build')}
          onChange={onChange('street_number', 'np_courier')}
          value={street_number}
          withOutSpace={true}
        />
        <TextInput
          placeholder={t('placing_order:product_delivery.fields.flat')}
          onChange={onChange('flat_num', 'np_courier')}
          value={flat_num}
          withOutSpace={true}
        />
        <TextInput
          placeholder={t('placing_order:product_delivery.fields.floor')}
          onChange={onChange('zip_code', 'np_courier')}
          value={zip_code}
          withOutSpace={true}
        />
        <FormPhoneWrap>
          <Typography variant={'bodySmall'} color={color.gray}>
            {t('placing_order:product_delivery.fields.additional_telephone')}
          </Typography>
          <PhoneInput
            error={error?.npPhone?.init}
            errorText={error?.npPhone?.text}
            onChange={onChange('npPhone', 'np_courier')}
            value={npPhone}
            sx={{
              marginTop: '5px',
            }}
            placeholder={'+420'}
          />
        </FormPhoneWrap>
        <DatePickerWrap>
          <DatePickerCustom
            placeholder={t('placing_order:product_delivery.fields.date')}
            minDate={true}
            maxDate={false}
            value={date}
            onChange={onChange('date', 'np_courier')}
          />
        </DatePickerWrap>
        <TextInput
          disabled
          styleWrapper={{
            gridColumnEnd: 'span 2',
          }}
          sx={{
            input: {
              '&:disabled': {
                WebkitTextFillColor: color.gray_80,
              },
            },
          }}
          placeholder={t('placing_order:product_delivery.fields.from_10_to_20')}
          value={time}
          onChange={onChange('time', 'np_courier')}
          withOutSpace={true}
        />
      </ContentFormWrap>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  padding-bottom: 15px;
`;

const CheckBoxWrap = styled.div`
  grid-column-end: span 2;
  align-self: center;
  margin-left: 3px;
`;

const DatePickerWrap = styled.div``;

const FormPhoneWrap = styled.div`
  grid-column-end: span 3;
`;

const ContentFormWrap = styled.div`
  display: grid;
  grid-template-columns: 179px 60px 60px;
  grid-column-gap: 4px;
  grid-row-gap: 10px;
`;
ViatecDelivery.propTypes = {
  error: PropTypes.object,
  data: PropTypes.exact({
    delivery_filial: PropTypes.string,
    delivery_info: PropTypes.exact({
      city_ref: PropTypes.string,
      city_title: PropTypes.string,
      date: PropTypes.string,
      department_ref: PropTypes.string,
      department_title: PropTypes.string,
      elevator: PropTypes.bool,
      flat_num: PropTypes.string,
      floor_num: PropTypes.string,
      npPhone: PropTypes.string,
      post_office_ref: PropTypes.string,
      post_office_title: PropTypes.string,
      street_number: PropTypes.string,
      street_title: PropTypes.string,
      time: PropTypes.string,
      type: PropTypes.string,
    }),
    delivery_type: PropTypes.string,
    discount: PropTypes.string,
    dont_call: PropTypes.bool,
    id: PropTypes.number,
    its_drop: PropTypes.bool,
    middle_name: PropTypes.string,
    name: PropTypes.string,
    payment_type: PropTypes.string,
    phone: PropTypes.string,
    surname: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};
