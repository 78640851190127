import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Bar = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open, drawerWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  color: theme.color.gray,
  maxHeight: '200px',
  padding: '16px 15px',
  background: theme.color.white,
  border: `1px solid ${theme.color.line_light}`,
  borderRadius: '2px',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    display: 'none',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const MenuBurger = ({ open, handleDrawerOpen, drawerWidth }) => {
  const { t } = useTranslation(['catalog'], { useSuspense: false });
  return (
    <Bar open={open} drawerWidth={drawerWidth}>
      <IconBtn
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        display={open && 'none'}
        href={`${Sprite}#icon-menu_burger`}
      />
      <Typography
        sx={{
          transform: 'rotate(180deg)',
          writingMode: 'vertical-lr',
          mt: '10px',
          lineHeight: 1.1,
        }}
        variant={'bodyBoldLarge'}
        component={'p'}
      >
        {t('catalog:category_title')}
      </Typography>
    </Bar>
  );
};
