import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '@mui/material/styles';

export const ToggleBtn = ({
  href,
  onClick,
  size = 16,
  isActive,
  isDisabled,
  refs,
  ...prop
}) => {
  const { color } = useTheme();
  return (
    <Button
      type={'button'}
      onClick={onClick}
      color={color}
      size={size}
      isActive={isActive}
      disabled={isDisabled}
      ref={refs}
      {...prop}
    >
      <Svg width={16} height={16}>
        <use href={href}></use>
      </Svg>
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: ${prop => prop.size}px;
  height: ${prop => prop.size}px;
  fill: ${({ color, isActive }) => (isActive ? color.white : color.gray_60)};
  stroke: ${({ color, isActive }) => (isActive ? color.white : color.gray_60)};
  background-color: ${({ color, isActive, defaultBackgroundColor }) =>
    isActive ? color.main : defaultBackgroundColor || 'transparent'};
  cursor: pointer;

  border: ${({ color, isActive, disableBorder }) =>
    disableBorder ? 'none' : isActive ? color.main : `1px solid ${color.line}`};
  border-radius: 4px;
`;

const Svg = styled.svg``;

ToggleBtn.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  refs: PropTypes.object,
};
