import React from 'react';

import { ButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ListSectionsItem = ({ title, counter, onClick, active }) => {
  const { color } = useTheme();

  return (
    <ButtonBase
      sx={{
        height: 32,
        background: active ? color.main : color.white,
        '&:hover': {
          background: active ? color.main : 'transparent',
        },
        lineHeight: 1.2,
      }}
      onClick={onClick}
    >
      <Typography
        color={active ? color.white : color.gray_80}
        variant={active ? 'bodyBoldSmall' : 'bodySmall'}
      >
        {title} ({counter})
      </Typography>
    </ButtonBase>
  );
};
