import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  BasicSelect,
  ButtonBase,
  FileLoader,
  MaskInputUI,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import Typography from '@mui/material/Typography';

export const AboutCompany = ({ initData, pathParrent }) => {
  const [data, setData] = useState({
    official_name: '',
    company_type_id: '',
    contractor_code: '',
    identification_code: '',
    legal_address: {
      street_name_legal: '',
      house_number_legal: '',
      zip_code_legal: '',
      city_legal: '',
      country_legal: '',
    },
    mailing_address: {
      street_name_mailing: '',
      house_number_mailing: '',
      zip_code_mailing: '',
      city_mailing: '',
      country_mailing: '',
    },

    is_delete_file: false,
  });
  const [logo, setLogo] = useState(null);
  const [error, setError] = useState({});
  const fileRef = useRef();
  const { t } = useTranslation(['registration', 'validation', 'common'], {
    useSuspense: false,
  });
  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  useEffect(() => {
    const {
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
      logo,
      legal_address,
      mailing_address,
    } = initData.company;

    setData({
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
      legal_address: legal_address ? legal_address : {},
      mailing_address: mailing_address ? mailing_address : {},
    });
    setLogo(logo);
  }, [dispatch, initData]);

  const handelSubmit = e => {
    e.preventDefault();

    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.signUpStepThierd({
            formData: serialize(
              {
                ...data,
                logo: typeof logo === 'string' ? null : logo,
              },
              {
                indices: true,
                nullsAsUndefineds: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      () => navigation(`${pathParrent}/3`),
    );
  };

  const onChange = (name, subName) => value => {
    if (name === 'identification_code') {
      setData(prevState => ({
        ...prevState,
        identification_code: value ? 'CZ' + value : '',
      }));
    } else if (subName === 'legal_address' || subName === 'mailing_address') {
      const dataCopy = JSON.parse(JSON.stringify(data));
      dataCopy[subName][name] = value;
      setData(dataCopy);
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const {
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
      legal_address: {
        street_name_legal,
        house_number_legal,
        zip_code_legal,
        city_legal,
        country_legal,
      },
      mailing_address: {
        street_name_mailing,
        house_number_mailing,
        zip_code_mailing,
        city_mailing,
        country_mailing,
      },
    } = data;

    Object.keys({
      street_name_legal,
      house_number_legal,
      zip_code_legal,
      city_legal,
      country_legal,
    }).forEach(key => {
      if (!data.legal_address[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    Object.keys({
      street_name_mailing,
      house_number_mailing,
      zip_code_mailing,
      city_mailing,
      country_mailing,
    }).forEach(key => {
      if (!data.mailing_address[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (!official_name || official_name === '') {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t('validation:required'),
      );
      flag = true;
    } else if (official_name.length < 2) {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t(`${t('validation:minLengthLetters')} 2`),
      );
      flag = true;
    }

    if (!company_type_id || company_type_id === '') {
      currentError = getErrorStack(
        currentError,
        'company_type_id',
        t('validation:required'),
      );
      flag = true;
    }

    if (!contractor_code || contractor_code === '') {
      currentError = getErrorStack(
        currentError,
        'contractor_code',
        t('validation:required'),
      );
      flag = true;
    } else if (contractor_code.length < 8) {
      currentError = getErrorStack(
        currentError,
        'contractor_code',
        t(`${t('validation:minLengthLetters')} 8`),
      );
      flag = true;
    }

    if (!identification_code || identification_code === '') {
      currentError = getErrorStack(
        currentError,
        'identification_code',
        t('validation:required'),
      );
      flag = true;
    } else if (identification_code.length < 10) {
      currentError = getErrorStack(
        currentError,
        'identification_code',
        t(`${t('validation:minLengthLetters')} 8`),
      );
      flag = true;
    }

    if (logo)
      if (logo?.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'logo',
          t('validation:file_size'),
        );
        flag = true;
      }
    setError(currentError);
    return flag;
  };

  const handleSetImage = img => {
    if (!img) setData(prevState => ({ ...prevState, is_delete_file: true }));
    setLogo(img);
  };

  const {
    official_name,
    company_type_id,
    contractor_code,
    identification_code,
    legal_address: {
      street_name_legal,
      house_number_legal,
      zip_code_legal,
      city_legal,
      country_legal,
    },
    mailing_address: {
      street_name_mailing,
      house_number_mailing,
      zip_code_mailing,
      city_mailing,
      country_mailing,
    },
  } = data;

  return (
    <Wrapper>
      <Form onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t('registration:step_tree.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_tree.slogan')}
        </Typography>

        <FileLoader
          fileRef={fileRef}
          file={logo}
          setFile={setLogo}
          title={t('registration:step_tree.loader.title')}
          handleSetImage={handleSetImage}
          isLogo={true}
          initError={error?.logo}
        />

        <TextInput
          error={error?.official_name?.init}
          errorText={error?.official_name?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={official_name}
          onChange={onChange('official_name')}
          placeholder={t('registration:field.legal_name_of_the_company', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <BasicSelect
          error={error?.company_type_id?.init}
          helperText={error?.company_type_id?.text}
          currentId={company_type_id}
          data={[
            { id: 1, name: t('registration:option.tov') },
            { id: 2, name: t('registration:option.fop') },
          ]}
          name={t('registration:field.type_of_cooperation', { req: '*' })}
          callBack={onChange('company_type_id')}
          sx={{ mt: '28px' }}
          height="48px"
        />

        <TextInput
          error={error?.contractor_code?.init}
          errorText={error?.contractor_code?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={contractor_code}
          onChange={onChange('contractor_code')}
          placeholder={t('registration:field.company_registration_number', {
            req: '*',
          })}
          type="text"
          max={8}
          digitsOnly={true}
          withOutSpace={true}
        />

        <MaskInputUI
          error={error?.identification_code?.init}
          errorText={error?.identification_code?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={identification_code}
          onChange={onChange('identification_code')}
          placeholder={t('registration:field.ipn', {
            req: '*',
          })}
          type="text"
          max={9}
          digitsOnly={true}
          withOutSpace={false}
          mask="CZ999999999"
          maskChar={''}
          requireMaskLength={0}
        />

        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_tree.slogan_legal_address')}
        </Typography>

        <TextInput
          error={error?.street_name_legal?.init}
          errorText={error?.street_name_legal?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={street_name_legal}
          onChange={onChange('street_name_legal', 'legal_address')}
          placeholder={t('registration:field.street_name', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <TextInput
          error={error?.house_number_legal?.init}
          errorText={error?.house_number_legal?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={house_number_legal}
          onChange={onChange('house_number_legal', 'legal_address')}
          placeholder={t('registration:field.house_number', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <TextInput
          error={error?.zip_code_legal?.init}
          errorText={error?.zip_code_legal?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={zip_code_legal}
          onChange={onChange('zip_code_legal', 'legal_address')}
          placeholder={t('registration:field.zip_code', {
            req: '*',
          })}
          type="text"
          max={10}
          withOutSpace={true}
        />

        <TextInput
          error={error?.country_legal?.init}
          errorText={error?.country_legal?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={country_legal}
          onChange={onChange('country_legal', 'legal_address')}
          placeholder={t('registration:field.country', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <TextInput
          error={error?.city_legal?.init}
          errorText={error?.city_legal?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={city_legal}
          onChange={onChange('city_legal', 'legal_address')}
          placeholder={t('registration:field.city', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_tree.slogan_mailing_address')}
        </Typography>

        <TextInput
          error={error?.street_name_mailing?.init}
          errorText={error?.street_name_mailing?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={street_name_mailing}
          onChange={onChange('street_name_mailing', 'mailing_address')}
          placeholder={t('registration:field.street_name', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <TextInput
          error={error?.house_number_mailing?.init}
          errorText={error?.house_number_mailing?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={house_number_mailing}
          onChange={onChange('house_number_mailing', 'mailing_address')}
          placeholder={t('registration:field.house_number', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <TextInput
          error={error?.zip_code_mailing?.init}
          errorText={error?.zip_code_mailing?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={zip_code_mailing}
          onChange={onChange('zip_code_mailing', 'mailing_address')}
          placeholder={t('registration:field.zip_code', {
            req: '*',
          })}
          type="text"
          max={10}
          withOutSpace={true}
        />
        <TextInput
          error={error?.country_mailing?.init}
          errorText={error?.country_mailing?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={country_mailing}
          onChange={onChange('country_mailing', 'mailing_address')}
          placeholder={t('registration:field.country', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <TextInput
          error={error?.city_mailing?.init}
          errorText={error?.city_mailing?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={city_mailing}
          onChange={onChange('city_mailing', 'mailing_address')}
          placeholder={t('registration:field.city', {
            req: '*',
          })}
          type="text"
          max={255}
        />

        <WrapperRow>
          <Wrapper>
            <OutlineButtonBase
              type="button"
              sx={{ flex: 1, maxWidth: '208px' }}
              onClick={() => {
                navigation(`${pathParrent}/1`);
              }}
            >
              <Typography variant={'bodyBoldLarge'} align={'center'}>
                {t('common:buttons.back')}
              </Typography>
            </OutlineButtonBase>

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ flex: 1, ml: '12px', maxWidth: '208px' }}
            >
              <Typography variant={'bodyBoldLarge'}>
                {t('common:buttons.continue')}
              </Typography>
            </ButtonBase>
          </Wrapper>
        </WrapperRow>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const WrapperRow = styled.div`
  margin-top: 28px;
`;

const Svg = styled.svg`
  margin-right: 4px;
`;

AboutCompany.propTypes = {
  initData: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    city_id: PropTypes.number,
    company: PropTypes.exact({
      activity_areas: PropTypes.arrayOf(
        PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
      ),
      additional_info: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      employees: PropTypes.array,
      employees_quantity: PropTypes.number,
      identification_code: PropTypes.number,
      legal_address: PropTypes.object,
      logo: PropTypes.string,
      mailing_address: PropTypes.object,
      name_company: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
    }),
    email: PropTypes.string,
    logins: PropTypes.object,
    name: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.exact({ confirm: PropTypes.bool, phone: PropTypes.string }),
    ),
    security: PropTypes.exact({
      saveLogs: PropTypes.bool,
    }),
    status: PropTypes.string,
  }),
  pathParrent: PropTypes.string.isRequired,
};
