import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getProductsBySearch = createAsyncThunk(
  'search/getProductsBySearch',
  async (
    { search, fields, per_page, page, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.get('/product/search', {
        params: { q: search, per_page: 6, fields },
      });
      if (onResolve) onResolve({ ...response.data, search });
      return { ...response.data, search };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getProductsBySearchForPage = createAsyncThunk(
  'search/getProductsBySearchForPage',
  async (
    { search, fields, per_page, page, sort, order, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.get('/product/search', {
        params: {
          q: search,
          per_page,
          fields,
          page,
          sort,
          order,
        },
      });
      if (onResolve) onResolve({ ...response.data, search });
      return { ...response.data, search };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

export default {
  getProductsBySearch,
  getProductsBySearchForPage,
};
