import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getBusinessData = createAsyncThunk(
  'admin/getBusinessData',
  async ({ page, per_page }, thunkApi) => {
    try {
      const response = await axios.get(`admin/company`, {
        params: { page, per_page },
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getAdminOrderList = createAsyncThunk(
  'admin/getAdminOrderList',
  async ({ page, per_page, id }, thunkApi) => {
    try {
      const response = await axios.get(`order/admin`, {
        params: { page, per_page, id },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getCounterpartyData = createAsyncThunk(
  'admin/getCounterpartyData',
  async ({ page, per_page, q }, thunkApi) => {
    try {
      const response = await axios.get(`admin/counterparty`, {
        params: { page, per_page },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
const getPeopleData = createAsyncThunk(
  'admin/getPeople',
  async ({ page, per_page, q }, thunkApi) => {
    try {
      const response = await axios.get(`admin/user`, {
        params: { page, per_page, q },
        headers: {
          Accept: 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const updateUserById = createAsyncThunk(
  'admin/updateUserById',
  async ({ status, id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.post(`admin/user/${id}`, null, {
        params: { status },
      });
      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getBusinessesSearch = createAsyncThunk(
  'admin/getBusinessesSearch',
  async ({ search, role, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${role}/company/search`, {
        params: { q: search, page, per_page },
      });

      return { ...response.data, search };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCounterpartsSearch = createAsyncThunk(
  'admin/getCounterpartsSearch',
  async ({ search, role, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${role}/counterparty/search`, {
        params: { q: search, page, per_page },
      });

      return { ...response.data, search };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getPeopleSearch = createAsyncThunk(
  'admin/getPeopleSearch',
  async ({ search, role, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${role}/user/search`, {
        params: { q: search, page, per_page },
      });

      return { ...response.data, search };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getAdminProducts = createAsyncThunk(
  'admin/getAdminProducts',
  async (
    { search, order, category_id, sort, page, per_page },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.get(`product/admin`, {
        params: {
          page: page,
          per_page: per_page,
          search,
          sort_field: sort,
          sort_direction: order,
          category_id,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const deleteAdminProductById = createAsyncThunk(
  'admin/deleteAdminProductById',
  async ({ onResolve, onReject, id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`product/admin/${id}`);
      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const updateAdminProductById = createAsyncThunk(
  'admin/deleteAdminProductById',
  async ({ onResolve, onReject, id, field, value }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`product/admin/${id}`, {
        field,
        value,
      });
      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

export default {
  getBusinessData,
  getCounterpartyData,
  getPeopleData,
  getBusinessesSearch,
  getCounterpartsSearch,
  getPeopleSearch,
  getAdminProducts,
  deleteAdminProductById,
  updateAdminProductById,
  getAdminOrderList,
  updateUserById,
};
