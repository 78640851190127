import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';

import comparisonsSelectors from '@redux/comparisons/comparisons-selectors';

import { Typography, useTheme } from '@mui/material';

export const ComparisonsButton = () => {
  const totalCount = useSelector(comparisonsSelectors.getComparisonsTotalCount);
  const { color } = useTheme();
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';
  return (
    <NavLink to={currentLang + '/comparisons'}>
      <ComparisonsInner
        background={totalCount > 0 ? color.main : color.white}
        isActive={totalCount > 0}
        borderColor={color.line}
        gray_20={color.gray_20}
      >
        <CounterWrap>
          <Svg active={totalCount > 0 ? color.white : color.gray_60}>
            <use href={`${Sprite}#icon-counter`}></use>
          </Svg>
          {totalCount > 0 && (
            <ItemsCounter color={color.white}>
              <Typography
                variant={'bodyBoldExSmall'}
                sx={{
                  color: color.main,
                }}
                component={'span'}
              >
                {totalCount}
              </Typography>
            </ItemsCounter>
          )}
        </CounterWrap>
      </ComparisonsInner>
    </NavLink>
  );
};

const ComparisonsInner = styled.div`
  cursor: pointer;
  background-color: ${props => props.background};
  min-width: 50px;
  height: 50px;
  border: 1px solid ${props => (props.borderColor ? props.borderColor : '')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s;
  padding-left: 17px;
  padding-right: 17px;

  &:hover {
    box-shadow: ${({ isActive }) =>
      isActive && '0px 4px 10px 0px rgba(57, 99, 225, 0.28)'};
    background-color: ${({ isActive, gray_20 }) => !isActive && gray_20};
  }
`;

const CounterWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ItemsCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 15px;
  color: ${props => props.color};
  margin-bottom: 23px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props => props.color};
  padding: 4px;
  margin-left: 1px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -1.6px;
    left: -1px;
    border: 3px solid transparent;
    border-top: 0;
    border-bottom: 6px solid white;
    transform: rotate(-130deg);
  }
`;

const Svg = styled.svg`
  width: 17px;
  height: 17px;
  fill: ${props => props.active};
`;
