export const OrderConf = t => [
  {
    label: t('admin:order_table_conf.number'),
    value: 'number',
    type: 'number',
    style: () => {
      return { maxWidth: '65px', width: '100%' };
    },
  },
  {
    label: t('admin:order_table_conf.user'),
    value: 'user',
    type: 'user',
    style: () => {
      return { marginLeft: '10px', maxWidth: '250px', width: '100%' };
    },
  },
  {
    label: t('admin:order_table_conf.recipient'),
    value: 'recipient',
    type: 'recipient',
    style: () => {
      return { marginLeft: '10px', maxWidth: '150px', width: '100%' };
    },
  },
  {
    label: t('admin:order_table_conf.phone'),
    value: 'phone',
    type: 'phone',
    style: () => {
      return { marginLeft: '10px', maxWidth: '150px', width: '100%' };
    },
  },
  {
    label: 'Email',
    value: 'email',
    type: 'email',
    style: () => {
      return { marginLeft: '10px', maxWidth: '150px', width: '100%' };
    },
  },
  {
    label: t('admin:order_table_conf.date'),
    value: 'date',
    type: 'date',
    style: () => {
      return { marginLeft: '10px', maxWidth: '150px', width: '100%' };
    },
  },
  {
    label: t('admin:order_table_conf.sum'),
    value: 'sum',
    type: 'sum',
    style: () => {
      return { marginLeft: '10px', maxWidth: '150px', width: '100%' };
    },
  },
  {
    label: t('admin:order_table_conf.status'),
    value: 'status',
    type: 'status',
    style: () => {
      return { marginLeft: '10px', maxWidth: '150px', width: '100%' };
    },
  },
  {
    label: '',
    value: 'edit',
    type: 'action',
    style: () => {
      return { marginLeft: 'auto', maxWidth: '64px', width: '100%' };
    },
  },
];
