import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const postNewFavorite = createAsyncThunk(
  'favorite/postNewFavorite',
  async ({ savedData, onResolve, onReject }, { rejectWithValue }) => {
    const { name, description } = savedData;
    try {
      const { data } = await axios.post('/favorites', { name, description });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getFavoritesList = createAsyncThunk(
  'favorite/getFavoritesList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/favorites');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const postProductToFavorite = createAsyncThunk(
  'favorite/postProductToFavorite',
  async ({ favorites, product_id, saved, onResolve, onReject }, thunkApi) => {
    try {
      const { data } = await axios.post('/favorites/saved-products', {
        favorites,
        product_id,
        saved,
      });

      if (onResolve) onResolve(data.data);
      return { ...data.data, id: product_id };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const deleteFavoriteById = createAsyncThunk(
  'favorite/deleteFavoriteById',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const { data } = await axios.delete(`/favorites/${id}`);
      if (onResolve) onResolve(data.data);
      thunkApi.dispatch(getFavoritesList());
      return { ...data.data, id };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getListAllSavedProduct = createAsyncThunk(
  'favorite/getListAllSavedProduct',
  async ({ page, per_page, sort, order }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/favorites/saved-products`, {
        params: { page, per_page, sort, order },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getListSavedProductByType = createAsyncThunk(
  'favorite/getListSavedProductByType',
  async ({ id, page, per_page, sort, order }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/favorites/${id}/saved-products`, {
        params: { page, per_page, sort, order },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const deleteProductFromSaved = createAsyncThunk(
  'favorite/deleteProductFromSaved',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const { data } = await axios.delete(`/favorites/product/${id}`);
      if (onResolve) onResolve(data.data);

      return { data: data.data, id };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const clearFavoriteData = createAsyncThunk(
  'favorite/clearFavoriteData',
  async payload => payload,
);

export default {
  postNewFavorite,
  clearFavoriteData,
  getFavoritesList,
  postProductToFavorite,
  deleteFavoriteById,
  getListAllSavedProduct,
  getListSavedProductByType,
  deleteProductFromSaved,
};
