import React from 'react';

import PropTypes from 'prop-types';

import { ColorlibStepIconRoot } from '@root/styles';
import { StepperBase } from '@root/ui';

import { Box, Typography, useTheme } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&`]: {
    marginLeft: '5px',
    height: '20px',
    position: 'relative',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    top: '-26px',
    position: 'absolute',
    height: '46px',
    borderColor: theme.color.line,
  },
}));

const stepsEx = [
  {
    label: 'Прийнято',
    description: `20.01.23 13:15:03`,
  },
  {
    label: 'На діагностиці',
    description: '20.01.23 13:15:03',
  },
  {
    label: 'В ремонті',
    description: ``,
  },
];

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    />
  );
}

export const StepperWithOutSpace = ({ steps, activeStep = 1 }) => {
  const { color } = useTheme();

  return (
    <Box sx={{ maxWidth: 400 }}>
      <StepperBase
        conf={steps}
        step={activeStep}
        defection={'vertical'}
        text={(label, index) => (
          <Typography
            variant={index === activeStep ? 'bodyBoldSmall' : 'bodySmall'}
            color={index === activeStep ? color.main : color.gray_80}
            component={'p'}
          >
            {label}
          </Typography>
        )}
        sxLabel={index => {
          return {
            alignItems: 'stretch',
            padding: 0,
          };
        }}
        connector={<QontoConnector />}
        iconComponent={ColorlibStepIcon}
        optional={description => (
          <Typography variant={'bodySmall'} color={color.gray_60}>
            {' '}
            {description}
          </Typography>
        )}
      />
    </Box>
  );
};

StepperWithOutSpace.propTypes = {
  steps: PropTypes.number.isRequired,
  activeStep: PropTypes.array.isRequired,
};
